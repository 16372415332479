<div class="p-grid p-ai-center p-jc-center h-100">
  <p-card class="login" >
    <div class="login-logo p-mt-4">
      <img alt="logo" src="assets/img/logo.png">
    </div>
    <div class="p-m-4">
      Hemos detectado un cambio en tu conexión.<br /><br />
      Es posible que hayas conectado AxialCRM con otro usuario en otra pestaña de tu navegador.<br /><br />
      Para continuar utilizando AxialCRM correctamente en esta pestaña, debes recargar la aplicación.
      <div class="p-mt-4">
        <p-button label="Recargar" (click)="reload()"></p-button>
      </div>
    </div>
  </p-card>
</div>
