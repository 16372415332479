<ul class="breadcrumb">
    <li><a [routerLink]="['/home']"><span class="color-soft-grey-axial">{{'general.breadcrumb.you_are' | translate}} </span>{{'general.breadcrumb.home' | translate}}</a></li>
    <li><i class="pi pi-angle-right"></i></li>
    <li><a [routerLink]="['/configuration/entities']">{{ 'general.configuration' | translate}}</a></li>
    <li><i class="pi pi-angle-right"></i></li>
    <li><a [routerLink]="['/configuration/organizationsTypes']">{{'organizationType.entity.name_plural' | translate}}</a></li>
</ul>
<p-card styleClass="p-card-content-fit">
    <ng-template pTemplate="header">
      <div class="p-card-title">
        {{'organizationType.entity.name_plural' | translate}}
        <div class="p-card-title-actions">
          <a (click)="edit(null)">
            <fa-icon [icon]="['fas', 'plus-circle']"></fa-icon>{{'general.action.add' | translate}}
          </a>
        </div>
      </div>
    </ng-template>
    <div class="p-grid">
        <div class="p-col-12">
            <p-table #grid [value]="organizationsTypes" [lazy]="true" (onLazyLoad)="loadData($event)">
                <ng-template pTemplate="header">
                    <tr>
                      <th pSortableColumn="name" style="width: 75%;">{{'organizationType.entity_fields.name' | translate}}</th>
                        <th width="100"></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-organizationType>
                    <tr (dblclick)="edit(organizationType)">
                      <td>{{organizationType.name}}</td>
                        <td class="grid-actions">
                            <a (click)="edit(organizationType)">
                                <fa-icon [icon]="['far', 'edit']"></fa-icon>
                            </a>
                            <a (click)="delete(organizationType)" class="color-danger">
                                <fa-icon [icon]="['far', 'trash-alt']"></fa-icon>
                            </a>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</p-card>
