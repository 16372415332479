import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { CoreDialogService } from 'src/app/core/dialogs/core-dialog.service';
import { ViewComponent } from 'src/app/core/view/view.component';
import { ViewsService } from 'src/app/core/view/views.service';
import { OrganizationsService } from '../../organizations.service';
import { ContactsService } from '../organizations-contacts-detail/contacts.service';
import { OrganizationsContactsDetailComponent } from '../organizations-contacts-detail/organizations-contacts-detail.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-organizations-contacts-view',
  templateUrl: './organizations-contacts-view.component.html',
  styleUrls: ['./organizations-contacts-view.component.scss']
})
export class OrganizationsContactsViewComponent implements OnInit {
  @ViewChild("view") public viewComponent: ViewComponent;
  public organization: any = {};
  public contacts: any[];
  public totalRecords: any;
  public loading = false;
  public lastGridEvent: any;

  public first;
  public last;
  constructor(
    private route: ActivatedRoute,
    private dialogService: DialogService,
    private organizationsService: OrganizationsService,
    private messageService: MessageService,
    private contactsService: ContactsService,
    private coreDialogService: CoreDialogService,
    private viewsService: ViewsService,
    private router: Router,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
  }
  loadData($event) {
    this.route.parent.params.subscribe(params => {
      this.loadDataOrganization(params['id']);
      this.lastGridEvent = $event;
      this.loading = true;
      var contactParams = {
        _page: $event.first,
        _pageSize: $event.rows,
        _sortBy: $event.sortField ? $event.sortField : "",
        _sortDirection: $event.sortOrder > 0 ? "asc" : "desc",
        organization_id: params['id']
      }
      this.contactsService.all(contactParams).subscribe({
        next: (data: any) => {
          this.totalRecords = data.totalRows;
          this.contacts = data.rows;
          this.loading = false;
        },
        error: (error: any) => {
          this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
          this.loading = false;
        }
      });
    });
  }
  loadDataOrganization(id: number) {
    this.organizationsService.get(id).subscribe({
      next: (data: any) => {
        this.organization = data;
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
      }
    });
  }
  view(contact) {
    this.router.navigate(["/contacts/" + (this.organization.id + "/") + (contact ? contact.id : 0)]);
    /*
    const ref = this.dialogService.open(OrganizationsContactsDetailComponent,{
      data: {
        id: this.organization.id,
        contactId: contact?contact.id:0
      },
      //header: contact? "Detalles del contacto":"Nuevo contacto",
      width: '70%',
    });
    ref.onClose.subscribe((data:any) =>{
      if (typeof (this.viewComponent) !== "undefined") this.viewsService.changeView(this.viewComponent.code);
      this.loadData(this.lastGridEvent);
    });
    */
  }
  delete(contact) {
    this.coreDialogService.confirm({
      message: this.translateService.instant("component.contacts.delete_message", { name: contact.name }),
      header: this.translateService.instant("general.confirmation_delete_title"),
      icon: "pi pi-info-circle",
      accept: () => {
        this.contactsService.delete(contact.id).subscribe({
          next: (data: any) => {
            this.messageService.add({ closable: false, severity: 'success', detail: this.translateService.instant("general.confirmation_delete") });
            this.loadData(this.lastGridEvent);
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        });
      },
      reject: () => {

      }
    })
  }
}
