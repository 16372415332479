<div class="p-grid">
    <!--Tipos de organización-->
            <div class="p-col-11 p-field">
              <label for="name">{{'organizationType.entity_fields.name' | translate}}</label>
            <input pInputText type="text" name="name" [(ngModel)]="organizationType.name">
        </div>
        <div class="p-col-1 p-field">
          <label for="color">{{'organizationType.entity_fields.color' | translate}}</label>
            <p-colorPicker name="color" [(ngModel)]="organizationType.color" appendTo="body"></p-colorPicker>
        </div>
    <!--Opciones-->
    <div class="p-col-12">
        <button pButton class="p-button-danger" *ngIf="organizationType.id>0" (click)="delete()" icon="pi pi-trash"
            label="{{'general.action.delete' | translate}}"></button>
        <button pButton class="p-ml-2" style="float: right;" (click)="isDuplied()" icon="pi pi-check"
            label="{{'general.action.save' | translate}}"></button>
        <button pButton class="p-button-secondary p-button-text" style="float: right;" (click)="cancel()" icon="pi pi-times"
            label="{{'general.action.cancel' | translate}}"></button>
    </div>
</div>
