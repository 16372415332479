import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CoreDialogService } from 'src/app/core/dialogs/core-dialog.service';
import { ReportsService } from '../../reports.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-report-clonator',
  templateUrl: './report-clonator.component.html',
  styleUrls: ['./report-clonator.component.scss']
})
export class ReportClonatorComponent implements OnInit {

  public report: any = {};

  constructor(
    private route: ActivatedRoute,
    private messageService: MessageService,
    private config: DynamicDialogConfig,
    private dynamicDialogRef: DynamicDialogRef,
    private coreDialogService: CoreDialogService,
    private reportsService: ReportsService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.loadData(this.config.data.report_id);
    });
  }

  loadData(id) {
    this.reportsService.get(id).subscribe(
      data => {
        this.report = data;
        this.report.name = this.translateService.instant("component.reports.detail.copy_of", { name: this.report.name });
        delete this.report.id;
      },
      error => {
        this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
      }
    );
  }
  cloneReport() {
    if (this.report.name.length > 0) {
      this.reportsService.add(this.report).subscribe(
        data => {
          this.messageService.add({ closable: false, severity: 'success', summary: this.translateService.instant("component.reports.detail.report_duplicated_correct") });
          this.dynamicDialogRef.close(data);
        },
        error => {
          this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
        }
      );
    }else{
      this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: this.translateService.instant("component.reports.detail.name_cant_be_empty") });
    };
  }
  cancel() {
    this.dynamicDialogRef.close();
  }
}
