import { Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { RolesService } from '../../roles/roles.service';
import { TransactionStatusDetailComponent } from '../transaction-status-detail/transaction-status-detail.component';

@Component({
  selector: 'app-transaction-status-blocking-roles',
  templateUrl: './transaction-status-blocking-roles.component.html',
  styleUrls: ['./transaction-status-blocking-roles.component.scss']
})
export class TransactionStatusBlockingRolesComponent implements OnInit {
  public rolesFilters: any = {};
  public roles: any[];
  public totalRecords: number;
  public loading: boolean = false;
  public selected_role_status: any;

  public first;
  public last;
  constructor(
    private rolesService: RolesService,

    private messageService: MessageService,
    @Inject(TransactionStatusDetailComponent) public parent: TransactionStatusDetailComponent
  ) { }

  ngOnInit(): void {
    this.loadRoles();
  }

  loadRoles() {
    this.rolesService.all({}).subscribe(
      data => {
        this.roles = data.rows;
      },
      error => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error.title });
      }
    );
  }
  delete(role) {
    var index = this.parent.transactionStatus.metadata.rolesStatus.findIndex(r => r.id == role.id);
    this.parent.transactionStatus.metadata.rolesStatus.splice(index, 1);
  }

  onChangeRole($event) {
    if (this.parent.transactionStatus.metadata.rolesStatus == null) this.parent.transactionStatus.metadata.rolesStatus = [];
    if (this.parent.transactionStatus.metadata.rolesStatus.findIndex(r => r.id == $event.value.id) < 0) this.parent.transactionStatus.metadata.rolesStatus.push({ id: $event.value.id, name: $event.value.name });
    this.selected_role_status = null;
  }
}
