import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EntitiesService {

  url = "/api/entities";
  cacheEntities: any[] = [];

  constructor(
    private http: HttpClient
  ) { }

  initCache(cbFn:Function){
    this.http.get<any>(this.url + "/all").subscribe(
      data => {
        this.cacheEntities = data;
        cbFn(true);
      },
      error => {
        console.log(error);
        cbFn(false);
      }
    );
  }
  
  all(params): Observable<any> {
    return this.http.get<any>(this.url, { params: params });
  }

  get(id): Observable<object> {
    return this.http.get<object>("/api/entitiesCustom/" + id);
  }

  getRelatedEntities(id): Observable<object> {
    return this.http.get<object>(this.url+ "/related_entities/" + id);
  }

  getByCode(code:string): Observable<any> {
    let observable = Observable.create(observer => {
      let entity = this.cacheEntities.find(m => m.code == code);
      if (entity == null) {
        this.http.get<any>(this.url + "/bycode/" + code).subscribe(data => {
          observer.next(data);
          observer.complete();
        });
      } else {
        //devolvemos un clon
        var clonedEntity = JSON.parse(JSON.stringify(entity));
        observer.next(clonedEntity);
        observer.complete();
      }
    });

    return observable;
  }

  save(id, obj): Observable<object> {
    return this.http.put<object>("/api/entitiesCustom/" + id, obj);
  }

  add(obj): Observable<object> {
      return this.http.post<object>(this.url, obj);
  }

  delete(id): Observable<object> {
      return this.http.delete<object>(this.url + "/" + id);
  }

  isDuplied(id, obj): Observable<any>{
    return this.http.post<any>(this.url+"/"+ id + "/isDuplied", obj);
  }

}
