<div class="p-col p-field">
  <div>
    <p-progressBar [value]="loadingPercent"></p-progressBar>
  </div>
  <div class="p-mt-4">
    <p-message severity="warn" class="p-mt-2"
               text="{{'component.imports.detail.process_background' | translate}}"></p-message>
  </div>
</div>
<button pButton class="p-button-secondary" style="float: right;" (click)="cancel()">{{'component.imports.detail.action.close' | translate}}</button>
