import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { FeaturesService } from 'src/app/features/features.service';
import { CompanyLicenseService } from './company-license.service';

@Component({
  selector: 'app-company-license',
  templateUrl: './company-license.component.html',
  styleUrls: ['./company-license.component.scss']
})
export class CompanyLicenseComponent implements OnInit {

  public license: any;
  public features: any[];
  constructor(
    private licensesService: CompanyLicenseService,
    private featuresService: FeaturesService,

    private messageService: MessageService,
  ) { }

  ngOnInit(): void {
    this.loadData();
    this.loadFeatures();
  }
  loadData(){
    this.licensesService.getLastLicense().subscribe({
      next: (data: any) => {
        this.license = data;
      },
      error: (error: any) => {
        this.messageService.add({ severity: 'error', detail: error.error.title });
      }
    });
  }

  loadFeatures(){
    this.featuresService.all({}).subscribe({
      next: (data: any) => {
        this.features = data.rows;
      },
      error: (error: any) => {
        this.messageService.add({ severity: 'error', detail: error.error.title });
      }
    });
  }
  getFeaturesInGroup(group) {
    return this.features.filter(m => m.group == group);
  }
}
