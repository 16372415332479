<app-loader></app-loader>
<div class="h-100 vertical-menu {{configCssClass()}} {{isOpen?'':'menu-collapsed'}}">
    <app-topbar *ngIf="showTopbar"></app-topbar>
    <app-sidebar *ngIf="showSidebar"></app-sidebar>
    <div class="layout-content" [@routeAnimations]="prepareRoute(outlet)">
        <router-outlet #outlet="outlet" (activate)="onActivate($event)"></router-outlet>
        <!--div class="version-info p-m-2 p-d-flex p-flex-column p-ai-center">
            <div>Versión {{version.version}} ({{version.date}})</div>
            <div>
                <small>{{version.commit}}</small>
            </div>
        </div-->
    </div>
</div>
<p-toast position="bottom-right"></p-toast>