import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FamiliesRoutingModule } from './families-routing.module';
import { FamiliesListComponent } from './families-list/families-list.component';
import { FamiliesDetailComponent } from './families-detail/families-detail.component';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { TableModule } from 'primeng/table';
import { CoreModule } from 'src/app/core/core.module';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
  declarations: [FamiliesListComponent, FamiliesDetailComponent],
  imports: [
    CommonModule,
    FamiliesRoutingModule,
    InputTextModule,
    ButtonModule,
    TableModule,
    ConfirmDialogModule,
    DialogModule,
    FormsModule,
    CheckboxModule,
    CoreModule,
    CardModule,
    FontAwesomeModule,
    TranslateModule
  ],
  exports: [FamiliesListComponent, FamiliesDetailComponent]
})
export class FamiliesModule { }
