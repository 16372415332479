import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-entity-field-masive-options',
  templateUrl: './entity-field-masive-options.component.html',
  styleUrls: ['./entity-field-masive-options.component.scss']
})
export class EntityFieldMasiveOptionsComponent implements OnInit {

  public text: string = "";

  constructor(
    private dynamicDialogRef: DynamicDialogRef,
    private messageService: MessageService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {

  }

  cancel() {
    this.dynamicDialogRef.close();
  }
  save() {
    var finalItems = [];

    if (this.text.includes("|") || this.text.includes("\t")) {
      var items = this.text.split("\n").filter(m => m.length > 0);
      var errorLine = 0;

      for (let index = 0; index < items.length; index++) {
        const item = items[index];
        if (this.text.includes("|")) {
          var keyValue = item.split("|");
          keyValue[0] = keyValue[0].trim();
          keyValue[1] = keyValue[1].trim();
        } else {
          var keyValue = item.split("\t");
        }
        if (keyValue[0].length == 0) {
          errorLine = index + 1;
          this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: this.translateService.instant("component.entity.detail_fields_fields.error_empty_key", { errorLine: errorLine }) });
          break;
        } else {
          finalItems.push(keyValue);
        }
      }
      if (errorLine == 0) this.dynamicDialogRef.close(finalItems);
    } else {
      this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: this.translateService.instant("component.entity.detail_fields_fields.error_separator") });
    }
  }
}
