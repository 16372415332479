import { Component, OnInit, ViewChild } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { CoreDialogService } from 'src/app/core/dialogs/core-dialog.service';
import { ViewComponent } from 'src/app/core/view/view.component';
import { SectorsDetailComponent } from '../sectors-detail/sectors-detail.component';
import { SectorsService } from '../sectors.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-sectors-list',
  templateUrl: './sectors-list.component.html',
  styleUrls: ['./sectors-list.component.scss']
})
export class SectorsListComponent implements OnInit {
  @ViewChild("view") public view: ViewComponent;
  public sectorsFilters: any = {};
  public tasksActionsTypes: any[];
  private lastGridEvent: any;
  public totalRecords: number;
  public loading: boolean = false;

  constructor(
    private sectorsService: SectorsService,
    private coreDialogService: CoreDialogService,
    private dialogService: DialogService,
    private messageService: MessageService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
  }

  loadData($event) {
    this.lastGridEvent = $event;
    Promise.resolve(null).then(() => this.loading = true);
    /*var params = {
      _page: $event.first,
      _pageSize: $event.rows,
      _sortBy: $event.sortField ? $event.sortField : "",
      _sortDirection: $event.sortOrder > 0 ? "asc" : "desc"
    }*/
    //params = Object.assign(params, this.organizationsFilters);
    this.sectorsService.all({}).subscribe({
      next: (data:any) => {
        this.totalRecords = data.totalRows;
        this.tasksActionsTypes = data.rows;
        Promise.resolve(null).then(() => this.loading = false);
      },
      error: (error:any) => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
        Promise.resolve(null).then(() => this.loading = false);
      }
    });
  }
  editSector(sector) {
    const ref = this.dialogService.open(SectorsDetailComponent, {
      data: {
        id: sector != null ? sector.id : null
      },
      header: sector != null ? this.translateService.instant("component.sectors.edit_sector") : this.translateService.instant("component.sectors.add_sector"),
      width: '45%'
    });
    ref.onClose.subscribe((data: any) => {
      this.view.fireEvent("sectors.list.results", "reload");
    });
  }
  delete(sector) {
    this.coreDialogService.confirm({
      message: this.translateService.instant("component.sectors.delete_message", { name: sector.name }),
      header: this.translateService.instant("general.confirmation_delete_title"),
      icon: 'pi pi-info-circle',
      accept: () => {
        this.sectorsService.delete(sector.id).subscribe({
          next: (data:any) => {
            //this.loadData(this.lastGridEvent);
            this.messageService.add({ closable: false, severity: "success", detail: this.translateService.instant("general.confirmation_delete") });
            this.view.fireEvent("sectors.list.results", "reload");
          },
          error: (error:any) => {
            this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
          }
        });
      },
      reject: () => {

      }
    })
  }

  onEvent(event: any) {
    if (event.component == "sectors.list.filters") {
      if (event.event == "action" && event.action.name == "search") this.view.fireEvent("sectors.list.results", "reload");
    } else if (event.component == "sectors.list.results") {
      if (event.event == "dblclick") this.editSector(event.data);
      if (event.event == "action" && event.action.name == "add") this.editSector(null);
      if (event.event == "action" && event.action.name == "edit") this.editSector(event.data);
      if (event.event == "action" && event.action.name == "delete") this.delete(event.data);
    }
  }
}
