import { DatePipe } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TemplatesService } from 'src/app/configuration/templates/templates.service';
import { ViewComponent } from 'src/app/core/view/view.component';
import { ReportsService } from '../../reports.service';
import { AttachmentsService } from 'src/app/attachments/attachments.service';
import { ViewsService } from 'src/app/core/view/views.service';
import { CoreDialogService } from 'src/app/core/dialogs/core-dialog.service';
import { TranslateService } from '@ngx-translate/core';
import { CampaignsService } from 'src/app/campaigns/campaigns.service';
import { UserMailAccountsService } from 'src/app/users/users-mail-accounts-detail/user-mail-accounts.service';
import { MailingAttachmentsComponent } from 'src/app/mailing/mailing-attachments/mailing-attachments.component';
import { environment } from 'src/environments/environment';
import { CampaignsContactsService } from 'src/app/campaigns/campaigns-contacts.service';
import { CampaignsOrganizationsService } from 'src/app/campaigns/campaigns-organizations.service';
import { TasksService } from 'src/app/tasks/tasks.service';
import { TransactionsService } from 'src/app/transactions/transactions.service';
import { MeetingsService } from 'src/app/organizations/organizations-detail/organizations-meetings-detail/meetings.service';
import { NotificationsService } from 'src/app/notifications/notifications.service';

@Component({
  selector: 'app-report-campaign',
  templateUrl: './report-campaign.component.html',
  styleUrls: ['./report-campaign.component.scss']
})
export class ReportCampaignComponent {
  @ViewChild("view") public view: ViewComponent;
  public campaigns: any[] = [];
  public entity_id: any;
  public entity_pk_id: any;
  public selected_campaign_id: any;
  public selectedMailAccount: any = null;
  public emails: any = [];
  public results: any = [];
  public resultsCorrects: any = [];
  public totalRows: number = 0;
  public rows: any = Array<any>();
  public entity: any = {};
  public reportId: number;
  public selected: any[] = [];
  public schedules: any[] = [];
  public tinyMceConfig: any;

  public add_organizations_checkbox: boolean;
  public add_contacts_checkbox: boolean;
  public visible_organizations_checkbox: boolean;
  public visible_contacts_checkbox: boolean;

  constructor(
    private route: ActivatedRoute,
    private campaignsService: CampaignsService,
    private campaignsContactsService: CampaignsContactsService,
    private campaignsOrganizationsService: CampaignsOrganizationsService,
    private tasksService: TasksService,
    private transactionsService: TransactionsService,
    private meetingsService: MeetingsService,
    private notificationsService: NotificationsService,
    private messageService: MessageService,
    private config: DynamicDialogConfig,
    private dynamicDialogRef: DynamicDialogRef,
    private datePipe: DatePipe,
    private reportsService: ReportsService,
    private router: Router,
    private dialogService: DialogService,
    private viewsService: ViewsService,
    private coreDialogService: CoreDialogService,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
    this.schedules = [{ label: this.translateService.instant("component.reports.detail.send_now"), value: "now" }, { label: this.translateService.instant("component.reports.detail.select_datetime"), value: "customdate" }]
    this.entity_id = this.config.data.entity_id;
    this.entity_pk_id = this.config.data.entity_pk_id;
    this.loadCampaigns();
    this.route.params.subscribe(params => {
      this.loadData(this.config.data.reportId, this.config.data.reportResults, this.config.data.entity, this.config.data.entity_id, this.config.data.entity_pk_id);
    });
  }

  loadData(reportId: number, results: any, entity: any, entity_id: number, entity_pk_id: number) {
    this.reportId = reportId;
    this.results = results;
    this.entity = entity;

    this.revealCheckboxes();

    this.entity_id = entity_id;
    this.entity_pk_id = entity_pk_id;
    this.selected = this.results.values.map(m => m.base_id + "");

    this.reportsService.GetElementsFromReport(this.selected, this.reportId).subscribe({
      next: (res: any) => {
        this.resultsCorrects = res;
        this.selected = this.resultsCorrects.map(m => m + "");
        this.rows = this.results.values.filter(m => this.selected.includes(m.base_id + ""));
        this.totalRows = this.rows.length;
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
      }
    });
  }

  loadCampaigns() {
    this.campaignsService.all({}).subscribe({
      next: (data: any) => {
        this.campaigns = data.rows;
        this.campaigns.unshift({ name: this.translateService.instant("component.reports.detail.none") });
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
      }
    });
  }
  cancel() {
    this.dynamicDialogRef.close();
  }

  getRowValue(header, value = "", item) {

    if (typeof (this.entity.fields) !== "undefined") {

      let field = this.entity.fields.find(m => m.id == header.entity_field_id);
      let property = field.model_property;
      if (field.model_property_label != null && field.model_property_label != "") property = field.model_property_label;

      let formatFn = field.formatFn;
      if (formatFn == null && field.configuration != null && field.configuration.formatFn != null) {
        formatFn = field.configuration.formatFn;
      }
      if (formatFn != null) {
        var format = formatFn.split("|");
        if (format[0] == "date") value = this.formatDate(value, format);
        if (format[0] == "suffix") value = this.formatSuffix(value, format);
        if (format[0] == "phone") value = this.formatPhone(value, format);
        if (format[0] == "mail") value = this.formatMail(value, format);
        if (format[0] == "www") value = this.formatWWW(value, format);
        //if (format[0] == "badge") value = this.formatBadge(field, value, format);
        if (format[0] == "image") value = this.formatImage(field, format);
        if (format[0] == "currency") value = this.formatCurrency(value, format);
        if (format[0] == "number") value = this.formatNumber(value, format);
      }
      if (String(value) === "false") value = "No";
      if (String(value) === "true") value = "Si";
      if (value == null || value == "") value = " ";
    }
    return value;
  }

  formatDate(value: any, args: any[]) {
    return this.datePipe.transform(value, args[1]);
  }
  formatSuffix(value: any, args: any[]) {
    return value + args[1];
  }
  formatPhone(value: any, args: any[]) {
    if (value == null) return "";
    var formattedValue = "<a href='tel:" + value + "'>" + value + "</a>";
    return formattedValue;
  }
  formatMail(value: any, args: any[]) {
    if (value == null) return "";
    var formattedValue = "<a href='mailto:" + value + "'>" + value + "</a>";
    return formattedValue;
  }
  formatWWW(value: any, args: any[]) {
    if (value == null) return "";
    if (!value.startsWith("http")) {
      value = "https://" + value;
    }
    var formattedValue = "<a href='" + value + "' target='_blank'>" + value + "</a>";
    return formattedValue;
  }
  formatBadge(field: any, value: any, args: any[]) {
    //TODO: Falta revisar este.
    if (value == null) return "";
    let className = "";
    let subClassName = "";
    if (field) {
      let refValue = "";
      if (args[1] != "") {
        try {
          let valorID = eval("row." + field.model_property);
          subClassName = args[1] + valorID;
        } catch (e) {
          console.error(`Error evaluando this.model.${field.entityField.model_property}`);
        }
      }
      className = "badge badge-block " + subClassName;
      var formattedValue = "<div class='" + className + "'>" + value + "</div>";
    }
    return formattedValue;
  }
  formatImage(value: any, args: any[]) {
    if (value == null) return "";
    var formattedValue = `<div class="d-flex justify-content-left align-items-center"><div class="avatar-wrapper"><div class="avatar  me-1"><img src="../../../app-assets/images/avatars/2-small.png" alt="Avatar" height="32" width="32"></div></div><div class="d-flex flex-column"><a href="app-user-view.html" class="user_name text-truncate"><span class="fw-bold">Zsazsa McCleverty</span></a><small class="emp_post text-muted">@zmcclevertye</small></div></div>`;
    return formattedValue;
  }
  formatCurrency(value: any, args: any[]) {
    if (value == null) return "0 €";
    var formattedValue = new Intl.NumberFormat(environment.companyConfiguration.locale, { style: "currency", currency: environment.companyConfiguration.currency, useGrouping: true }).format(value);
    return formattedValue;
  }
  formatNumber(value: any, args: any[]) {
    if (value == null) return "0";
    var formattedValue = new Intl.NumberFormat(environment.companyConfiguration.locale).format(value)
    return formattedValue;
  }

  onMailAccountChange() {
  }

  saveCampaign() {
    console.log("asdf entity ", this.entity, this.entity_id, this.entity_pk_id);
    console.log("asdf entity.code ", this.entity.code);
    console.log("asdf selected_campaign_id :  ", this.selected_campaign_id);
    console.log("asdf selected:  ", this.selected);
    switch (this.entity.code) {
      case "contact":
        this.saveCampaignContacts();
        break;
      case "organization":
        this.saveCampaignOrganizations();
        break;
      case "task":
        this.saveTasksToCampaign();
        break;
      case "oportunity":
        this.saveTransactionsToCampaign();
        break;
      case "sale":
        this.saveTransactionsToCampaign();
        break;
      case "transaction":
        this.saveTransactionsToCampaign();
        break;
      case "meeting":
        this.saveMeetingsToCampaign();
        break;
      case "notifications":
        this.saveNotificationsToCampaign();
        break;
      default:
        break;
    }
  }

  saveTasksToCampaign() {
    this.tasksService.addMultiplesToCampaign({ campaign_id: this.selected_campaign_id, ids: this.selected, add_organizations_checkbox: this.add_organizations_checkbox, add_contacts_checkbox: this.add_contacts_checkbox }).subscribe({
      next: (data: any) => {
        this.messageService.add({ closable: false, severity: 'success', summary: this.translateService.instant("component.reports.added_elements_to_campaign") });
        this.dynamicDialogRef.close();
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
      }
    });
  }

  saveTransactionsToCampaign() {
    this.transactionsService.addMultiplesToCampaign({ campaign_id: this.selected_campaign_id, ids: this.selected, add_organizations_checkbox: this.add_organizations_checkbox, add_contacts_checkbox: this.add_contacts_checkbox }).subscribe({
      next: (data: any) => {
        this.messageService.add({ closable: false, severity: 'success', summary: this.translateService.instant("component.reports.added_elements_to_campaign") });
        this.dynamicDialogRef.close();
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
      }
    });
  }

  saveMeetingsToCampaign() {
    this.meetingsService.addMultiplesToCampaign({ campaign_id: this.selected_campaign_id, ids: this.selected, add_organizations_checkbox: this.add_organizations_checkbox, add_contacts_checkbox: this.add_contacts_checkbox }).subscribe({
      next: (data: any) => {
        this.messageService.add({ closable: false, severity: 'success', summary: this.translateService.instant("component.reports.added_elements_to_campaign") });
        this.dynamicDialogRef.close();
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
      }
    });
  }

  saveNotificationsToCampaign() {
    this.notificationsService.addMultiplesToCampaign({ campaign_id: this.selected_campaign_id, ids: this.selected, add_organizations_checkbox: this.add_organizations_checkbox, add_contacts_checkbox: this.add_contacts_checkbox }).subscribe({
      next: (data: any) => {
        this.messageService.add({ closable: false, severity: 'success', summary: this.translateService.instant("component.reports.added_elements_to_campaign") });
        this.dynamicDialogRef.close();
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
      }
    });
  }

  saveCampaignOrganizations() {
    this.campaignsOrganizationsService.addMultiples({ campaign_id: this.selected_campaign_id, ids: this.selected }).subscribe({
      next: (data: any) => {
        this.messageService.add({ closable: false, severity: 'success', summary: this.translateService.instant("component.reports.added_elements_to_campaign") });
        this.dynamicDialogRef.close();
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
      }
    });
  }

  saveCampaignContacts() {
    this.campaignsContactsService.addMultiples({ campaign_id: this.selected_campaign_id, ids: this.selected, add_organizations_checkbox: this.add_organizations_checkbox, add_contacts_checkbox: this.add_contacts_checkbox }).subscribe({
      next: (data: any) => {
        this.messageService.add({ closable: false, severity: 'success', summary: this.translateService.instant("component.reports.added_elements_to_campaign") });
        this.dynamicDialogRef.close();
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
      }
    });
  }

  revealCheckboxes() {
    switch (this.entity.code) {
      case "contact":
        this.visible_contacts_checkbox = false;
        this.visible_organizations_checkbox = true;
        break;
      case "organization":
        this.visible_contacts_checkbox = false;
        this.visible_organizations_checkbox = false;
        break;
      case "task":
        this.visible_contacts_checkbox = true;
        this.visible_organizations_checkbox = true;
        break;
      case "oportunity":
        this.visible_contacts_checkbox = true;
        this.visible_organizations_checkbox = true;
        break;
      case "sale":
        this.visible_contacts_checkbox = true;
        this.visible_organizations_checkbox = true;
        break;
      case "transaction":
        this.visible_contacts_checkbox = true;
        this.visible_organizations_checkbox = true;
        break;
      case "meeting":
        this.visible_contacts_checkbox = true;
        this.visible_organizations_checkbox = true;
        break;
      case "notifications":
        this.visible_contacts_checkbox = false;
        this.visible_organizations_checkbox = false;
        break;
      default:
        this.visible_contacts_checkbox = false;
        this.visible_organizations_checkbox = false;
        break;
    }
  }
}
