import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CampaignsRoutingModule } from './campaigns-routing.module';
import { CampaignsDetailComponent } from './campaigns-detail/campaigns-detail.component';
import { CampaignsListComponent } from './campaigns-list/campaigns-list.component';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { CarouselModule } from 'primeng/carousel';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { SplitButtonModule } from 'primeng/splitbutton';
import { TableModule } from 'primeng/table';
import { TimelineModule } from 'primeng/timeline';
import { CoreModule } from '../core/core.module';
import { ChartModule } from 'primeng/chart';
import { CampaignsChartComponent } from './campaigns-chart/campaigns-chart.component';
import { CampaignAddElementComponent } from './campaign-add-element/campaign-add-element.component';


@NgModule({
  declarations: [
    CampaignsDetailComponent,
    CampaignsListComponent,
    CampaignsChartComponent,
    CampaignAddElementComponent
  ],
  imports: [
    CommonModule,
    CampaignsRoutingModule,
    ButtonModule,
    TableModule,
    ConfirmDialogModule,
    DialogModule,
    FormsModule,
    CheckboxModule,
    CoreModule,
    CardModule,
    FontAwesomeModule,
    InputTextareaModule,
    DropdownModule,
    CalendarModule,
    SplitButtonModule,
    TimelineModule,
    CarouselModule,
    ChartModule,
    TranslateModule
  ],
  exports: [
    CampaignsDetailComponent,
    CampaignsListComponent
  ]
})
export class CampaignsModule { }
