<div class="p-grid">
  <!--Rol-->
  <div class="p-col-12 p-field">
    <label for="name">{{'role.entity_fields.name'|translate}}</label><br>
    <input name="name" type="text" pInputText [(ngModel)]="role.name">
  </div>

  <div class="p-col-12">
    <p-checkbox name="checked_all" [(ngModel)]="checked_all" (onChange)="check_all()" [binary]="true"></p-checkbox>
    <label for="checked_all" class="p-ml-2">{{'general.select_all'|translate}}</label>
  </div>
  <!--Permisos-->
  <div class="p-col-12">
    <p-accordion>
      <div *ngFor="let permissionGroup of permissionsGroup">
        <p-accordionTab *ngIf="getPermissions(permissionGroup).length > 0">
          <ng-template pTemplate="header">{{ 'permissions_groups.' + permissionGroup.code | translate }}</ng-template>
          <ng-template pTemplate="content">
            <div class="p-grid">
              <div *ngFor="let permission of getPermissions(permissionGroup)" class="p-col-4">
                <label>
                  <p-checkbox name="groupname" value="{{permission.id}}"
                              [(ngModel)]="selectedPermissions" (onChange)="updateChecked()"></p-checkbox>
                  {{ 'permissions.name.' + permission.code | translate }}
                  <i pTooltip="{{ 'permissions.description.' + permission.code | translate }}" tooltipPosition="bottom" class="pi pi-info-circle" style="font-size: 11px; color: #4EA294 !important; "></i>
                </label>
              </div>
            </div>
          </ng-template>
        </p-accordionTab>
      </div>
    </p-accordion>
  </div>
  <!--div *ngFor="let permissionGroup of permissionsGroup" class="p-col-3">
      <p-card [header]="permissionGroup.name">
          <div *ngFor="let permission of getPermissions(permissionGroup)" class="p-field p-col-12">
              <label>
                  <p-checkbox name="groupname" value="{{permission.id}}" [(ngModel)]="selectedPermissions"></p-checkbox>
                  {{permission.name}}
              </label>
          </div>
      </p-card>
  </div-->
  <!--Opciones-->
  <div class="p-col-12">
    <button pButton class="p-button-danger" *ngIf="role.id>0" (click)="delete()" icon="pi pi-trash"
            label="{{'general.action.delete' | translate}}"></button>
    <button pButton class="p-ml-2" style="float: right;" (click)="isDuplied()" icon="pi pi-check"
            label="{{'general.action.save' | translate}}"></button>
    <button pButton class="p-button-secondary p-button-text" style="float: right;" (click)="cancel()"
            icon="pi pi-times" label="{{'general.action.cancel' | translate}}"></button>
  </div>
</div>
<p-confirmDialog></p-confirmDialog>
