import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UtilsService } from 'src/app/core/utils.service';

@Injectable({
  providedIn: 'root'
})
export class MeetingsService {
  url = "/api/meetings";

  constructor(
    private http: HttpClient,
    private utilsService: UtilsService
  ) { }

  all(params): Observable<any> {
    return this.http.get<any>(this.url, { params: params });
  }

  get(id): Observable<object> {
    return this.http.get<object>(this.url + "/" + id);
  }

  save(id, obj): Observable<any> {
    var finalObj = this.utilsService.clearObject(obj, ["meetingUsers"]);
    return this.http.put<object>(this.url + "/" + id, finalObj);
  }

  setNewDates(id, obj): Observable<any> {
    var finalObj = this.utilsService.clearObject(obj, ["meetingUsers"]);
    return this.http.put<object>(this.url + "/setNewDates/" + id, finalObj);
  }

  sendInvitations(id, template_id, html): Observable<object> {
    var params = { id: id, template_id: template_id, html: html };
    return this.http.post<object>(this.url + "/" + id + "/send", params);
  }

  add(obj): Observable<any> {
    return this.http.post<object>(this.url, obj);
  }

  delete(id): Observable<object> {
    return this.http.delete<object>(this.url + "/" + id);
  }

  users(params): Observable<any> {
    return this.http.get<any>(this.url + "/users", { params: params });
  }

  isDuplied(obj): Observable<any> {
    return this.http.post<any>(this.url + "/isDuplied", obj);
  }
  addMultiplesToCampaign(obj): Observable<any> {
    return this.http.post<any>(this.url + "/addMultiplesToCampaign", obj);
  }
}
