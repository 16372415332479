<div class="p-grid">
  <div class="p-col p-field">
      <div class="p-col-12 p-field">
        <label for="schedule">{{'component.mailMarketing.send.schedule' | translate}}</label>
        <div class="p-grid">
          <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
            <p-dropdown name="schedule" [(ngModel)]="email.schedule" [options]="schedules" optionValue="value"
              optionLabel="label" placeholder="{{'component.mailMarketing.send.schedule' | translate}}"></p-dropdown>
          </div>
          <div *ngIf="email.schedule=='customdate'" class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
              <p-calendar  name="date" [(ngModel)]="email.date" appendTo="body" dateFormat="dd/mm/yy" hourFormat="24" [showIcon]="true" [showTime]="true" [stepMinute]="15"></p-calendar>

          </div>
        </div>
    </div>  </div>
</div>
<div class="p-grid">
  <div class="p-col">
      <button class="p-ml-2" style="float: right;" pButton type="button" label="{{'general.send_mails' | translate}}" (click)="send()"></button>
      <button pButton class="p-button-link p-button-secondary" style="float: right;" (click)="cancel()" icon="pi pi-times" label="{{'general.action.cancel' | translate}}"></button>
  </div>
</div>