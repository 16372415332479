<div class="custom-datetime-overlaypanel-backdrop" *ngIf="is_showed" (click)="hide()"></div>
<p-overlayPanel #op appendTo="body" [dismissable]="false" styleClass="custom-datetime-overlaypanel"
  class="custom-datetime-overlaypanel" (onShow)="onShow()" (onHide)="onHide()">
  <ng-template pTemplate="content">
    <div class="custom-datetime-selector" *ngIf="!range">
      <div class="p-d-flex p-jc-center">
        <p-calendar [inline]="true" [(ngModel)]="date" (onSelect)="onSelect('calendar')"
          firstDayOfWeek="1"></p-calendar>
      </div>
      <div class="mt-2 p-d-flex p-jc-center custom-time-selector" *ngIf="showTime">
        <p-calendar [inline]="true" [timeOnly]="true" [(ngModel)]="time" [stepMinute]="stepMinute"
          (onSelect)="onSelect('time')"></p-calendar>
      </div>
    </div>
    <div class="custom-datetime-selector" *ngIf="range">
      <div class="p-d-flex">
        <div class="p-p-3 custom-selector-options">
          <a (click)="generate('-', 'week',7)">{{'calendar.configure.last_week' | translate}}</a>
          <a (click)="generate('-', 'week',14)">{{'calendar.configure.last_15_days' | translate}}</a>
          <a (click)="generate('-', 'month',1)">{{'calendar.configure.last_month' | translate}}</a>
          <a (click)="generate('-', 'month',3)">{{'calendar.configure.last_3_months' | translate}}</a>
          <a (click)="generate('-', 'month',6)">{{'calendar.configure.last_6_months' | translate}}</a>
          <a (click)="generate('-', 'year',1)">{{'calendar.configure.last_year' | translate}}</a>
          <hr />
          <a (click)="generate('.', 'week',7)">{{'calendar.configure.this_week' | translate}}</a>
          <hr />
          <a (click)="generate('+', 'week',6)">{{'calendar.configure.next_week' | translate}}</a>
          <a (click)="generate('+', 'week',13)">{{'calendar.configure.next_15_days' | translate}}</a>
          <a (click)="generate('+', 'month',1)">{{'calendar.configure.next_month' | translate}}</a>
          <a (click)="generate('+', 'month',3)">{{'calendar.configure.next_3_months' | translate}}</a>
          <a (click)="generate('+', 'month',6)">{{'calendar.configure.next_6_months' | translate}}</a>
          <a (click)="generate('+', 'year',1)">{{'calendar.configure.next_year' | translate}}</a>
        </div>
        <p-calendar [inline]="true" [(ngModel)]="date_range" (onSelect)="onSelectRange()" firstDayOfWeek="1"
          selectionMode="range" [numberOfMonths]="2"></p-calendar>
      </div>
    </div>
  </ng-template>
</p-overlayPanel>
<div #targetEl [class.custom-datetime-input-upfront]="is_showed" class="p-inputgroup p-inputgroup-with-addons">
  <p-inputMask *ngIf="!range" [mask]="mask" [(ngModel)]="date_string" [placeholder]="placeholder"
    (onFocus)="onInputMaskFocus($event)" (onComplete)="onComplete($event)" styleClass="custom-datetime-input"
    class="flex-grow-1"></p-inputMask>

  <input *ngIf="range" type="text" pInputText [(ngModel)]="dates_string" [placeholder]="placeholder"
    styleClass="custom-datetime-input" class="flex-grow-1" />

  <button type="button" pButton (click)="onClear()" [hidden]="date_string==''">
    <fa-icon [icon]="['fas', 'xmark']"></fa-icon>
  </button>
  <button type="button" pButton (click)="op.toggle($event, targetEl)">
    <fa-icon [icon]="['fas', 'calendar-days']"></fa-icon>
  </button>
</div>