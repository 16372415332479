<div style="max-height: 240px; overflow-y: auto;">
  <p-timeline [value]="tasksHistories" styleClass="hide-oposite p-mt-4">
    <ng-template pTemplate="marker" let-event>
      <span class="custom-marker p-shadow-2 p-badge p-component" [style.backgroundColor]="'#607d8b'" style="width: 10px;overflow: hidden;margin: 0px; padding: 0px 3px;">
        {{event.responsible_by_name}}
      </span>
    </ng-template>
    <ng-template pTemplate="content" let-event>
      <b *ngIf="event.taskActionType!=null">{{event.taskActionType.name}} - {{event.task_action_scheduled_date | date:'dd/MM/yy HH:mm'}}</b>
      <b *ngIf="event.taskActionType==null">Sin tipo de acción - {{event.task_action_scheduled_date | date:'dd/MM/yy HH:mm'}}</b>
      <span *ngIf="event.id == tasksHistories[0].id" style="float: right;">
        <!--a (click)="editTaskHistory(event)" style="cursor: pointer;"><fa-icon [icon]="['far', 'edit']"></fa-icon></a-->
        <a class="color-danger" *ngIf="canDeleteHistory" (click)="deleteTaskHistory(event)" style="cursor: pointer;"><fa-icon [icon]="['far', 'trash-alt']"></fa-icon></a>
      </span>
      <!--<b *ngIf="event.taskActionType==null" style="white-space: break-spaces;">{{event.message}}<br></b>-->
      <div><small style="white-space: break-spaces;word-break:break-word;">{{event.message}}</small></div>
      <small>{{event.created_by_name}} - {{event.created_at | date:'dd/MM/yy HH:mm'}}</small>
    </ng-template>
  </p-timeline>
  </div>
