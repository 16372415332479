import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { CoreDialogService } from 'src/app/core/dialogs/core-dialog.service';
import { ViewComponent } from 'src/app/core/view/view.component';
import { ViewsService } from 'src/app/core/view/views.service';
import { OrganizationsService } from '../../organizations.service';
import { ContactsService } from '../organizations-contacts-detail/contacts.service';
import { MeetingsService } from '../organizations-meetings-detail/meetings.service';
import { OrganizationsMeetingsDetailComponent } from '../organizations-meetings-detail/organizations-meetings-detail.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-organizations-meetings-view',
  templateUrl: './organizations-meetings-view.component.html',
  styleUrls: ['./organizations-meetings-view.component.scss']
})
export class OrganizationsMeetingsViewComponent implements OnInit {
  @ViewChild("view") public viewComponent: ViewComponent;
  public organization: any = {};
  public meetings: any[];
  public totalRecords: any;
  public loading = false;
  public lastGridEvent: any;

  public first;
  public last;
  constructor(
    private route: ActivatedRoute,
    private dialogService: DialogService,
    private organizationsService: OrganizationsService,
    private messageService: MessageService,
    private meetingsService: MeetingsService,
    private coreDialogService: CoreDialogService,
    private viewsService: ViewsService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
  }

  loadData($event){
    this.route.parent.params.subscribe(params => {
      this.loadDataOrganization(params['id']);
      this.lastGridEvent = $event;
      this.loading = true;
      var contactParams = {
        _page: $event.first,
        _pageSize: $event.rows,
        _sortBy: $event.sortField ? $event.sortField : "",
        _sortDirection: $event.sortOrder > 0 ? "asc" : "desc",
        organization_id: params['id']
      }
      this.meetingsService.all(contactParams).subscribe({
        next: (data: any) => {
          this.totalRecords = data.totalRows;
          this.meetings = data.rows;
          this.loading = false;
        },
        error: (error: any) => {
          this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
          this.loading = false;
        }
      });
    });
  }
  loadDataOrganization(id: number){
    this.organizationsService.get(id).subscribe({
      next: (data: any) => {
        this.organization = data;
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
      }
    });
  }
  view(meeting) {
    const ref = this.dialogService.open(OrganizationsMeetingsDetailComponent,{
      data: {
        id: this.organization.id,
        meetingId: meeting?meeting.id:0,
        contactId: meeting?meeting.contact_id:0
      },
     // header: meeting? "Detalles de la reunión":"Nueva reunión",
      width: '90%',
      /*baseZIndex: 999*/
    });
    ref.onClose.subscribe((data:any) =>{
      if (typeof (this.viewComponent) !== "undefined") this.viewsService.changeView(this.viewComponent.code);
      this.loadData(this.lastGridEvent);
    })
  }
  delete(meeting){
    this.coreDialogService.confirm({      
      message: this.translateService.instant("component.organizations.delete_message_meeting", { subject: meeting.subject }),
      header: this.translateService.instant("general.confirmation_delete_title"),
      icon:"pi pi-info-circle",
      accept:()=>{
        this.meetingsService.delete(meeting.id).subscribe({
          next: (data: any) => {
            this.messageService.add({ closable: false, severity: 'success', detail: this.translateService.instant("general.confirmation_delete") });
            this.loadData(this.lastGridEvent);
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        });
      },
      reject: () => {
        
      }
    })
  }
}
