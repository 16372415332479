import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EmailValidator } from '@angular/forms';
import { Observable } from 'rxjs';
import { LoaderService } from '../core/loader/loader.service';
import { TasksActionTypesService } from '../tasks/tasks-action-types.service';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {
  url = "/api/reports";

  constructor(private http: HttpClient, private loaderService: LoaderService) { }

  all(params): Observable<any> {
    return this.http.get<any>(this.url, { params: params });
  }

  get(id): Observable<object> {
    return this.http.get<object>(this.url + "/" + id);
  }

  fields(id): Observable<object> {
    return this.http.get<object>(this.url + "/" + id + "/fields");
  }

  save(id, obj): Observable<object> {
    return this.http.put<object>(this.url + "/" + id, obj);
  }
  savePermissions(id, obj): Observable<object> {
    return this.http.put<object>(this.url + "/" + id, obj);
  }

  add(obj): Observable<object> {
    return this.http.post<object>(this.url, obj);
  }

  delete(id): Observable<object> {
    return this.http.delete<object>(this.url + "/" + id);
  }

  customVoidCombos(url, params): Observable<any> {
    return this.http.get<any>(url, { params: params });
  }

  loadData(params, _page, _pageSize): Observable<any> {
    if (_page == null) return this.http.post<object>(this.url + "/loadData", params);
    return this.http.post<object>(this.url + "/loadData/" + _page + "/" + _pageSize, params);
  }
  loadDataMap(params): Observable<any> {
    return this.http.post<object>(this.url + "/loadDataMap", params);
  }
  loadDataGraph(params): Observable<any> {
    return this.http.post<object>(this.url + "/loadDataGraph", params);
  }

  listGraphsHome(): Observable<any> {
    this.loaderService.skipRequest(this.url + "/listGraphsHome", null);
    return this.http.get<object>(this.url + "/listGraphsHome");
  }

  deleteDataMasive(code, params): Observable<object> {
    return this.http.get<object>(this.url + "/DeleteDataMasive/" + code + "/" + params);
  }
  checkIfExistSaleOrNeedToCreate(recreate, ids, value): Observable<object> {
    return this.http.post<object>(this.url + "/checkIfExistSaleOrNeedToCreate/" + recreate, { listIds: ids, value: value });
  }

  updateDataMasive(code, ids, params): Observable<object> {
    return this.http.post<object>(this.url + "/UpdateDataMasive/" + code, { listIds: ids, results: params });
  }
  getOrganizations(reportId): Observable<object> {
    return this.http.get<any>(this.url + "/GetOrganizations/" + reportId);
  }

  addMultiplesTasks(task, selectedIds, reportId, transactionsIds, campaign_id = null, add_organizations_checkbox = null, add_contacts_checkbox = null): Observable<object> {
    return this.http.post<object>("api/tasks" + "/AddMultiplesTasks/", { task: task, selectedIds: selectedIds, reportId: reportId, transactionsIds: transactionsIds, campaign_id: campaign_id, add_organizations_checkbox: add_organizations_checkbox, add_contacts_checkbox: add_contacts_checkbox });
  }

  sendEmailMarketing(email, selectedIds, reportId): Observable<object> {
    return this.http.post<object>("api/NotificationsEmails" + "/SendEmailMarketing/", { email: email, selectedIds: selectedIds, reportId: reportId });
  }
  sendEmail(email): Observable<object> {
    return this.http.post<object>("api/NotificationsEmails" + "/SendEmail/", { email: email });
  }
  GetContactsAllowedComunicationFromReport(selectedIds, reportId): Observable<object> {
    return this.http.post<object>(this.url + "/GetContactsAllowedComunicationFromReport/", { selectedIds: selectedIds, reportId: reportId });
  }
  GetElementsFromReport(selectedIds, reportId): Observable<object> {
    return this.http.post<object>(this.url + "/GetElementsFromReport/", { selectedIds: selectedIds, reportId: reportId });
  }
}
