import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AttachmentsTypesListComponent } from './attachments-types-list/attachments-types-list.component';

const routes: Routes = [{path:'zones', component:AttachmentsTypesListComponent}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AttachmentsTypesRoutingModule { }
