import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TasksService {
  url = "/api/tasks";

  constructor(private http: HttpClient) { }

  all(params): Observable<any> {
    return this.http.get<any>(this.url, { params: params });
  }

  get(id): Observable<any> {
    return this.http.get<object>(this.url + "/" + id);
  }

  getNextPrevInfo(id): Observable<any> {
    return this.http.get<any>(this.url + "/getNextPrevInfo/" + id);
  }

  save(id, obj): Observable<object> {
    return this.http.put<object>(this.url + "/" + id, obj);
  }

  add(obj): Observable<object> {
    return this.http.post<object>(this.url, obj);
  }

  delete(id): Observable<object> {
    return this.http.delete<object>(this.url + "/" + id);
  }

  //Se podría borrar unlink().
  unlink(id, obj): Observable<any> {
    return this.http.post<object>(this.url + "/unlink/"+id,obj);
  }

  link(id, obj, type): Observable<any> {
    return this.http.post<object>(this.url + "/link/"+type+"/"+id,obj);
  }
  
  getNextPrev(viewedId, params): Observable<any> {
    params.viewedId = viewedId;
    return this.http.get<any>(this.url + "/getNextPrev", { params: params });
  } 
  
  isDuplied(obj):Observable<any>{
    return this.http.post<any>(this.url+"/isDuplied",obj);
  }
  
  addMultiplesToCampaign(obj): Observable<any> {
    return this.http.post<any>(this.url + "/addMultiplesToCampaign", obj);
  }
}
