import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Components
import { CoreModule } from '../core/core.module';
import { ChartModule } from 'primeng/chart';
import { CardModule } from 'primeng/card';
import { PreloadRoutingModule } from './preload-routing.module';
import { PreloadComponent } from './preload.component';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
    imports: [
      CommonModule,
      PreloadRoutingModule,
      ChartModule,
      CoreModule,            
    CardModule,
    TranslateModule
    ],
    declarations: [
      PreloadComponent
    ]
  })
  export class PreloadModule {
  }
