<p-card>
    <div class="p-grid">
        <!--<div class="p-col-12 p-field">
            <label for="code">Código</label>
            <input name="code" type="text" pInputText disabled [ngModel]="view.code" />
        </div>-->
        <div class="p-col-12 p-field">
          <label for="name">{{'view.configure.name' | translate}}</label>
            <input name="name" type="text" pInputText [required]="true" [(ngModel)]="view.name" />
        </div>
        <div class="p-col-12 p-field">
          <label for="to">{{'view.configure.who_can_use' | translate}}</label>
            <p-dropdown name="to" [required]="true" [options]="options" optionLabel="label" optionValue="value"
                appendTo="body" [(ngModel)]="view.metadata.to"></p-dropdown>
        </div>
        <div class="p-col-12 p-field">
          <p-checkbox name="default" *ngIf="view.metadata.to!='onlyme'" [(ngModel)]="view.metadata.default" [binary]="true" [ngStyle]="{'float': 'left'}"></p-checkbox>
          <label for="default" *ngIf="view.metadata.to!='onlyme'" style="float: left; margin-top: 2px; margin-left: 5px">{{'view.configure.convert_default' | translate}}</label>
        </div>
        <div class="p-col-12">
            <!--p-button label="Borrar" icon="pi pi-trash" styleClass="p-button-danger" (click)="delete()"></p-button>
            <div class="flex-grow-1"></div-->

            <p-button label="{{'general.action.save' | translate}}" icon="pi pi-check" styleClass="p-ml-2 floatRight" (click)="save()" *ngIf="(view.id && viewTemplate.user_id == created_by) || !view.id"> </p-button>
            <p-button label="{{'general.action.cancel' | translate}}" icon="pi pi-times" styleClass="p-button-secondary floatRight" (click)="cancel()">
            </p-button>
            <p-button label="{{'general.action.delete' | translate}}" icon="pi pi-trash" styleClass="p-mr-2 floatRight p-button-danger"
                (click)="delete()" *ngIf="view.id && viewTemplate.user_id == created_by"> </p-button>
        </div>
    </div>
</p-card>
