import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ZonesRoutingModule } from './zones-routing.module';
import { ZonesListComponent } from './zones-list/zones-list.component';
import { ZonesDetailComponent } from './zones-detail/zones-detail.component';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { TableModule } from 'primeng/table';
import { CoreModule } from 'src/app/core/core.module';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
  declarations: [ZonesListComponent, ZonesDetailComponent],
  imports: [
    CommonModule,
    ZonesRoutingModule,
    InputTextModule,
    ButtonModule,
    TableModule,
    ConfirmDialogModule,
    DialogModule,
    FormsModule,
    CheckboxModule,
    CoreModule,
    CardModule,
    FontAwesomeModule,
    AppRoutingModule,
    TranslateModule
  ],
  exports:[
    ZonesListComponent,
    ZonesDetailComponent
  ]
})
export class ZonesModule { }
