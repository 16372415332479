import { Component, OnInit, ViewChild } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { CoreDialogService } from 'src/app/core/dialogs/core-dialog.service';
import { ViewComponent } from 'src/app/core/view/view.component';
import { OriginsService } from '../origins.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-origins-detail',
  templateUrl: './origins-detail.component.html',
  styleUrls: ['./origins-detail.component.scss']
})
export class OriginsDetailComponent implements OnInit {

  @ViewChild("view") public view: ViewComponent;
  public origin: any = {};
  constructor(
    private originsService: OriginsService,

    private coreDialogService: CoreDialogService,
    private dynamicDialogRef: DynamicDialogRef,
    private messageService: MessageService,
    private config: DynamicDialogConfig,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.loadData(this.config.data.id);
  }
  loadData(id) {
    if (id) {
      this.originsService.get(id).subscribe({
        next: (data: any) => {
          this.origin = data;
        },
        error: (error: any) => {
          this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
        }
      });
    }
  }
  save(event: any) {
    if (!event.valid) {
      this.messageService.add({ closable: false, severity: "error", detail: this.translateService.instant("general.form_incomplete") });
      return;
    }
    if (typeof this.origin.id === "undefined") {
      this.originsService.add(this.origin).subscribe({
        next: (data: any) => {
          this.origin = data;
          this.dynamicDialogRef.close(this.origin);
          this.messageService.add({
            closable: false, severity: 'success', summary: this.translateService.instant("component.origin.detail.add_origin_correct") });
        },
        error: (error: any) => {
          this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
        }
      });
    } else {
      this.originsService.save(this.origin.id, this.origin).subscribe({
        next: (data: any) => {
          this.messageService.add({ closable: false, severity: "success", detail: this.translateService.instant("component.origin.detail.save_origin_correct") });
          this.dynamicDialogRef.close(this.origin);
        },
        error: (error: any) => {
          this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
        }
      });
    }
  }
  cancel() {
    this.dynamicDialogRef.close();
  }
  delete() {
    this.coreDialogService.confirm({
      message: this.translateService.instant("component.origin.delete_message", { name: this.origin.name }),
      header: this.translateService.instant("general.confirmation_delete_title"),
      icon: "pi pi-info-circle",
      accept: () => {
        this.originsService.delete(this.origin.id).subscribe({
          next: (data: any) => {
            this.messageService.add({ closable: false, severity: 'success', detail: this.translateService.instant("general.confirmation_delete") });
            this.dynamicDialogRef.close();
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        });
      },
      reject: () => {

      }
    })
  }
  onEvent(event) {
    if (event.component == "origins.edit.general") {
      if (event.event == "action" && event.action.name == "delete") this.delete();
      if (event.event == "action" && event.action.name == "cancel") this.cancel();
      if (event.event == "action" && event.action.name == "save") this.save(event);
    }
  }
}
