<ul class="breadcrumb">
    <li><a [routerLink]="['/home']"><span class="color-soft-grey-axial">{{'general.breadcrumb.you_are' | translate}} </span>{{'general.breadcrumb.home' | translate}}</a></li>
    <li><i class="pi pi-angle-right"></i></li>
    <li><a [routerLink]="['/configuration/entities']">Configuración</a></li>
    <li><i class="pi pi-angle-right"></i></li>
    <li><a [routerLink]="['/configuration/roles']">Roles</a></li>
</ul>
<p-card>
    <ng-template pTemplate="header">
      <div class="p-card-title">
        {{ 'general.filter.title' | translate}}
        <div class="p-card-title-actions">
          <a (click)="clearFilters()">{{'general.filter.clean' | translate}}</a>
          <a (click)="search()"><i class="fas fa-search"></i>&nbsp;{{ 'general.action.search' | translate}} </a>
        </div>
      </div>
    </ng-template>
    <div class="p-grid">
        <div class="p-col p-field">
          <label for="name">{{ 'role.entity_fields.name' | translate}}</label>
            <input id="name" type="text" pInputText [(ngModel)]="rolesFilters.name" />
        </div>
    </div>
</p-card>

<p-card styleClass="p-card-content-fit p-mt-3">
    <!--Añadir rol-->
    <ng-template pTemplate="header">
      <div class="p-card-title">
        {{ 'role.entity.name_plural' | translate}}
        <div class="p-card-title-actions">
          <a (click)="edit(null)">
            <fa-icon [icon]="['fas', 'plus-circle']"></fa-icon>{{ 'general.action.new' | translate}}
          </a>
        </div>
      </div>
    </ng-template>
    <div class="p-grid">
        <!--Tabla de roles-->
        <div class="p-col-12">
            <!--<input class="p-inputtext-sm" type="text" pInputText placeholder="Búsqueda por nombre" style="width: 100%;"
                (input)="dt1.filterGlobal($event.target.value, 'contains')" />-->
            <p-table #grid [value]="roles" [lazy]="true" (onLazyLoad)="loadData($event)" [loading]="loading"
                [paginator]="true" [rows]="10" [totalRecords]="totalRecords" [showCurrentPageReport]="true"
                currentPageReportTemplate="{{ 'general.resumen_table_records' | translate: { first: first, last: last, totalRecords: totalRecords } }}"
                [rowsPerPageOptions]="[5,10,25,50]" paginatorDropdownAppendTo="body">
                <ng-template pTemplate="header">
                    <tr>
                      <th pSortableColumn="id" width="100">{{ 'role.entity_fields.id' | translate}}</th>
                      <th pSortableColumn="name" style="width: 75%;">{{ 'role.entity_fields.name' | translate}}</th>
                        <th width="100"></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-role>
                    <tr (dblclick)="edit(role)">
                        <td>{{role.id}}</td>
                        <td>{{role.name}}</td>
                        <td class="grid-actions">
                            <a (click)="edit(role)">
                                <fa-icon [icon]="['far', 'edit']"></fa-icon>
                            </a>
                            <a (click)="delete(role)" class="color-danger">
                                <fa-icon [icon]="['far', 'trash-alt']"></fa-icon>
                            </a>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</p-card>
<p-confirmDialog></p-confirmDialog>
