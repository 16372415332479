import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TemplatesRoutingModule } from './templates-routing.module';
import { TemplatesListComponent } from './templates-list/templates-list.component';
import { TemplatesDetailComponent } from './templates-detail/templates-detail.component';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { SplitButtonModule } from 'primeng/splitbutton';
import { TableModule } from 'primeng/table';
import { TimelineModule } from 'primeng/timeline';
import { CalendarModule } from 'src/app/calendar/calendar.module';
import { CoreModule } from 'src/app/core/core.module';
import { TabViewModule } from 'primeng/tabview';
import { AccordionModule } from 'primeng/accordion';
import { HtmlPreviewComponent } from './html-preview/html-preview.component';
import { EditorModule } from '@tinymce/tinymce-angular';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
  declarations: [TemplatesListComponent, TemplatesDetailComponent, HtmlPreviewComponent],
  imports: [
    TemplatesRoutingModule,
    CommonModule,
    ButtonModule,
    TableModule,
    ConfirmDialogModule,
    DialogModule,
    FormsModule,
    CheckboxModule,
    CoreModule,
    CardModule,
    FontAwesomeModule,
    InputTextareaModule,
    DropdownModule,
    CalendarModule,
    SplitButtonModule,
    TimelineModule,
    TabViewModule,
    AccordionModule,
    EditorModule,
    TranslateModule
  ],
  exports:[TemplatesListComponent, TemplatesDetailComponent]
})
export class TemplatesModule { }
