import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { CoreDialogService } from 'src/app/core/dialogs/core-dialog.service';
import { ViewComponent } from 'src/app/core/view/view.component';
import { TransactionTypesDetailComponent } from '../transaction-types-detail/transaction-types-detail.component';
import { TransactionTypesService } from '../transaction-types.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-transaction-types-list',
  templateUrl: './transaction-types-list.component.html',
  styleUrls: ['./transaction-types-list.component.scss']
})
export class TransactionTypesListComponent implements OnInit {
  @ViewChild("view") public view: ViewComponent;
  public transactionsTypesFilters: any = {};
  
  constructor(
    private transactionTypesService:TransactionTypesService,
    private dialogService: DialogService,
    private messageService: MessageService,
    private coreDialogService: CoreDialogService,
    private router: Router,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
  }

  viewTransactionType(transactionType) {
    const ref = this.dialogService.open(TransactionTypesDetailComponent, {
      data: {
        id: transactionType?transactionType.id:0
      },
      width: '40%',
    });
    ref.onClose.subscribe((data: any) => {
      this.view.fireEvent("transactionTypes.list.results","reload");
    })
  }
  delete(transactionType) {
    this.coreDialogService.confirm({
      message: this.translateService.instant("component.attachmentType.delete_message", { name: transactionType.name }),
      header: this.translateService.instant("general.confirmation_delete_title"),
      icon: "pi pi-info-circle",
      accept: () => {
        this.transactionTypesService.delete(transactionType.id).subscribe({
          next: (data: any) => {
            this.messageService.add({ closable: false, severity: 'success', detail: this.translateService.instant("general.confirmation_delete") });
            location.reload();

          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        });
      },
      reject: () => { }
    })
  }
  onEvent(event: any) {
    if (event.component == "transactionTypes.list.filters") {
      if (event.event == "action" && event.action.name == "search") this.view.fireEvent("transactionTypes.list.results", "reload");
    }else if(event.component=="transactionTypes.list.results"){
      if(event.event=="dblclick") this.viewTransactionType(event.data);
      if(event.event=="action" && event.action.name=="add") this.viewTransactionType(null);
      if(event.event=="action" && event.action.name=="edit") this.viewTransactionType(event.data);
      if(event.event=="action" && event.action.name=="delete") this.delete(event.data);
    }
  }
}
