import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MessageService } from 'primeng/api';
import { CustomComponent } from 'src/app/core/view/component/custom/custom-component.interface';
import { ViewComponent } from 'src/app/core/view/view.component';
import { TrackingService } from '../tracking/tracking.service';
import { ActivatedRoute } from '@angular/router';
import { EntitiesService } from '../configuration/entities.service';
import { EntitiesFieldsService } from '../configuration/entities-fields.service';
import { DatePipe, formatDate } from '@angular/common';
import { UtilsService } from '../core/utils.service';

@Component({
  selector: 'app-entity-history-line',
  templateUrl: './entity-history-line.component.html',
  styleUrls: ['./entity-history-line.component.scss']
})
export class EntityHistoryLineComponent implements OnInit, AfterViewInit, CustomComponent {
  @Input() public data: any
  @Input() public entity: any;
  @ViewChild('container') container: ElementRef;

  history_data: any = {
    columns: [],
    values: [],
    total: 0
  };
  transaction_type_id: any;

  public lastGridEvent: any = { "first": 0, "rows": 10, "sortOrder": 1, "filters": {}, "globalFilter": null };
  public loading: boolean = false;
  public have_tracking_notes: boolean = false;
  public rowsPerPageOptions: number[] = [5, 10, 25, 50];
  public rowsPerPage: number = 5;
  public totalRows: number = 0;

  public first; 
  public last;
  public mode: string = '';

  constructor(
    private messageService: MessageService,
    private trackingService: TrackingService,
    private utilsService: UtilsService
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    //detectar ancho del contenedor
    Promise.resolve(null).then(() => {
      if (this.container.nativeElement.offsetWidth < 700) {
        this.mode = 'card';
        this.loadHistory(this.lastGridEvent);
      } else {
        this.mode = 'table';
      }
    });
  }

  onPageChange($event) {
    this.loadHistory($event);
  }

  loadHistory($event: any) {
    this.have_tracking_notes = Object.keys(this.data).includes('tracking_notes');
    this.lastGridEvent = $event;
    let params = {
      _page: $event.first / $event.rows,
      _pageSize: $event.rows,
      _sortBy: $event.sortField ? $event.sortField : "",
      _sortDirection: $event.sortOrder > 0 ? "asc" : "desc",
      entity_id: this.entity.id,
      entity_pk_id: this.data.id
    };
    if (typeof (this.data.id) !== "undefined") {
      Promise.resolve(null).then(() => this.loading = true);
      this.trackingService.history(params).subscribe({
        next: (data:any) => {
          data.values.forEach((item, idx) => {
            //dejamos abierto solo el ultimo recibido
            item.is_opened = idx == 0 ? true : false;
          });
          this.history_data = data;
          this.totalRows = data.total;
          Promise.resolve(null).then(() => this.loading = false);
        },
        error: (error: any) => {
          this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          Promise.resolve(null).then(() => this.loading = false);
        }
      });

    }
  }

  toogleEvent(event: any) {
    let ret = event.is_opened ? false : true;
    event.is_opened = ret;
  }

  onEvent(component: string, event: string, args: any) {
    if (component == "entityHistory.custom" && event == "reload") {
      this.loadHistory(this.lastGridEvent);
    }
  }

  getColumns(mode: string) {
    let ret: any[] = [];
    if (mode == 'header') {
      let featured_entity_field_ids = [];
      if (this.entity?.metadata?.tracking?.featured_entity_field_ids != null) featured_entity_field_ids = this.entity.metadata.tracking.featured_entity_field_ids;
      ret = this.history_data.columns.filter(m => m.visible && (m.property == 'user_name' || m.property == 'tracking_date' || m.property == 'channel' || featured_entity_field_ids.includes(m.entity_field_id)));
    } else if (mode == 'detail') {
      ret = this.history_data.columns.filter(m => m.visible && m.property != 'user_name' && m.property != 'tracking_date');
    } else {
      ret = this.history_data.columns.filter(m => m.visible);
    }
    
    return ret;
  }

  getValueFor(item: any, field: any) {
    let value = item[field.property];
    let formatFn = field.formatFn;
    if (formatFn == null && field.configuration != null && field.configuration.formatFn != null) {
      formatFn = field.configuration.formatFn;
    }
    if (formatFn != null)
    {
      var format = formatFn.split("|");
      if (format[0] == "date") value = this.utilsService.formatDate(value, format);
      if (format[0] == "suffix") value = this.utilsService.formatSuffix(value, format);
      if (format[0] == "phone") value = this.utilsService.formatPhone(value, format);
      if (format[0] == "mail") value = this.utilsService.formatMail(value, format);
      if (format[0] == "www") value = this.utilsService.formatWWW(value, format);
      //if (format[0] == "badge") value = this.utilsService.formatBadge(field, row, value, format);
      //if (format[0] == "badgeColor") value = this.utilsService.formatBadgeColor(field, row, value, format);
      if (format[0] == "image") value = this.utilsService.formatImage(field, format);
      if (format[0] == "icon") value = this.utilsService.formatIcon(value, format);
      if (format[0] == "currency") value = this.utilsService.formatCurrency(value, format);
      if (format[0] == "number") value = this.utilsService.formatNumber(value, format);
    }
    if (value == null || value == "") value = " ";
    return value;
  
  }
  

  getValueForIdx(item: any, idx: number) {
    let values = Object.values(item);
    return values[idx];
  }
}
