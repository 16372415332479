import { Component, OnInit, ViewChild } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CoreDialogService } from 'src/app/core/dialogs/core-dialog.service';
import { ViewComponent } from '../../../core/view/view.component';
import { FamiliesService } from '../families.service';
import { TransactionsService } from '../../../transactions/transactions.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-families-detail',
  templateUrl: './families-detail.component.html',
  styleUrls: ['./families-detail.component.scss']
})
export class FamiliesDetailComponent implements OnInit {
  @ViewChild("view") public view: ViewComponent;
  public family: any = {};
  constructor(
    private familiesService: FamiliesService,

    private config: DynamicDialogConfig,
    private messageService: MessageService,
    private dynamicDialogRef: DynamicDialogRef,
    private coreDialogService: CoreDialogService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.loadFamilies(this.config.data.id);
  }

  loadFamilies(id) {
    if (id) {
      this.familiesService.get(id).subscribe({
        next: (data: any) => {
          this.family = data;
        },
        error: (error: any) => {
          this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
        }
      });
    }
  }

  save(event: any) {
    if (!event.valid) {
      this.messageService.add({ closable: false, severity: "error", detail: this.translateService.instant("general.form_incomplete") });
      return;
    }
    if (typeof this.family.id === "undefined") {
      this.familiesService.add(this.family).subscribe({
        next: (data: any) => {
          this.dynamicDialogRef.close();
          this.messageService.add({ closable: false, severity: 'success', summary: this.translateService.instant("component.families.detail.add_correct") });
        },
        error: (error: any) => {
          this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
        }
      });
    } else {
      this.familiesService.save(this.family.id, this.family).subscribe({
        next: (data: any) => {
          this.familiesService.add({ closable: false, severity: "success", detail: this.translateService.instant("component.families.detail.save_correct") });
          this.dynamicDialogRef.close();
        },
        error: (error: any) => {
          this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
        }
      });
    }
  }
  cancel() {
    this.dynamicDialogRef.close();
  }
  delete() {
    this.coreDialogService.confirm({
      message: this.translateService.instant("component.families.delete_message", { name: this.family.name }),
      header: this.translateService.instant("general.confirmation_delete_title"),
      icon: "pi pi-info-circle",
      accept: () => {
        this.familiesService.delete(this.family.id).subscribe(
          data => {
            this.messageService.add({ closable: false, severity: 'success', detail: this.translateService.instant("general.confirmation_delete") });
            this.dynamicDialogRef.close();
          },
          error => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        )
      },
      reject: () => {

      }
    })
  }

  isDuplied(family, event) {
    if (!event.valid) {
      this.messageService.add({ closable: false, severity: "error", detail: this.translateService.instant("general.form_incomplete") });
      return;
    }
    this.familiesService.isDuplied(family).subscribe(
      data =>{
        if(data==true){
          this.coreDialogService.notify({
            message: this.translateService.instant("component.families.detail.types_duplicates"),
            header: 'Error',
            icon: 'far exclamation-circle',
            color: 'var(--orange-500)',
            accept:() => { }
          });
        }else{
          this.save(event);
        }
      }
    );
  }

  onEvent(event) {
    if (event.component == "families.edit.general") {
      if (event.event == "action" && event.action.name == "delete") this.delete();
      if (event.event == "action" && event.action.name == "cancel") this.cancel();
      if (event.event == "action" && event.action.name == "save") this.isDuplied(this.family,event);
    }
    if (event.component == "view" && event.event == "entity-loaded") {
      this.config.header = typeof (this.family.id) !== "undefined" ?
        this.translateService.instant("component.families.detail.detail_entity", { entity_name: this.view.getEntityBaseName() }) :
        this.translateService.instant("component.families.detail.new_entity", { entity_name: this.view.getEntityBaseName() })
    }
  }
}
