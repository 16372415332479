<p-card class="">
    <ng-template pTemplate="header">
      <div class="p-card-title">
        {{'task.entity.name_plural' | translate}}
      </div>
    </ng-template>    
    <div class="color-danger" *ngIf="ret.days_last_activity == null">{{'component.organizations.tasks_widget.no_tasks' | translate}}.</div>
    <div class="color-danger" *ngIf="ret.days_last_activity == 0">{{'component.organizations.tasks_widget.last_task_created' | translate}} <b>{{'component.organizations.tasks_widget.today' | translate}}</b>.</div>
    <div class="color-danger" *ngIf="ret.days_last_activity == 1">{{'component.organizations.tasks_widget.last_task_created' | translate}} <b>{{'component.organizations.tasks_widget.yesterday' | translate}}</b>.</div>
    <div class="color-danger" *ngIf="ret.days_last_activity > 1"><b>{{ret.days_last_activity}}</b> {{'component.organizations.tasks_widget.days_last_tasks' | translate}}</div>
    <div><b>{{ret.pending_activities}}</b> {{'component.organizations.tasks_widget.pending_tasks' | translate}}</div>
</p-card>
