
<div styleClass="p-mb-4">
  <div>

    <div class="bloque_kuki" style=" display: flex; flex-direction: column; text-align: center; align-items: center; padding: 0px 2rem;">
      <div class="p-mb-2"><i class="{{info.icon}}" style="font-size: 40px; color: {{info.color }}"></i></div>
      <div>
        {{info.message}}
        <small style="font-size: 12px;" *ngIf="info.smallMsg"><br />{{info.smallMsg}}</small>
      </div>
    </div>



    <!--<p-message severity="success" text="{{'transactions.sage.not_send' | translate
          }}" class="p-d-block p-mb-4"></p-message>


  <p-messages  *ngIf="data.erp_id" severity="success" style="width: 100%">
    <ng-template pTemplate>
      <i class="pi pi-fw pi-check" style="font-size: 25px;"></i>
      <div class="p-ml-2">
        Registro enlazado con SAGE <br />{{'transactions.sage.send_to_sage' | translate}}
      </div>
    </ng-template>
  </p-messages>

  <p-messages severity="error" *ngIf="data.organization?.erp_id == null" style="width: 100%">
    <ng-template pTemplate>
      <i class=" pi pi-fw pi-briefcase" style="font-size: 20px;"></i>
      <div class="p-ml-2">
        La organización no tiene CIF
        <br />
        {{'transactions.sage.not_vatNumber_organization' | translate}}
      </div>
    </ng-template>
  </p-messages>
    -->
  </div>
</div>
