<ul class="breadcrumb">
  <li><a [routerLink]="['/home']"><span class="color-soft-grey-axial">{{'general.breadcrumb.you_are' | translate}} </span>{{'general.breadcrumb.home' | translate}}</a></li>
  <li><i class="pi pi-angle-right"></i></li>
  <li><a [routerLink]="['/configuration/entities']">{{ 'general.configuration' | translate}}</a></li>
  <li><i class="pi pi-angle-right"></i></li>
  <li><a [routerLink]="['/configuration/countries']">{{ 'country.entity.name_plural' | translate}}</a></li>
  <!--<li *ngIf="view"><a [routerLink]="['/configuration/countries']">{{view.getEntityBaseName(true)}}</a></li>-->
</ul>
<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000"></p-confirmDialog>

<!--<app-view #view [code]="'countries.list'" [model]="countriesFilters" (onEvent)="onEvent($event)"></app-view>-->
<div class="grid">
  <div class="p-md-12">
    <p-card>
      <ng-template pTemplate="header">
        <div class="p-card-title">
          {{ 'general.filter.title' | translate}}
          <div class="p-card-title-actions">
            <a (click)="search()"><i class="fas fa-search"></i>&nbsp;{{ 'general.action.search' | translate}} </a>
          </div>
        </div>
      </ng-template>
      <div class="p-grid">
        <div class="p-field p-mb-0 p-md-4 p-pb-0">
          <label for="name">{{ 'country.entity_fields.name' | translate}}</label>
          <input id="name" type="text" pInputText [(ngModel)]="countriesFilters.name" />
        </div>
        <div class="p-field p-mb-0 p-md-4 p-pb-0">
          <label for="iso_code">{{ 'country.entity_fields.iso_code' | translate}}</label>
          <input id="iso_code" type="text" pInputText [(ngModel)]="countriesFilters.iso_code" />
        </div>
      </div>
      <!--div class="p-grid">
          <div class="p-col p-ai-right">
              <p-button label="Limpiar filtros" (click)="clearFilters()" styleClass="p-button-secondary"></p-button>
              <p-button label="Buscar" (click)="search()" class="p-ml-2"></p-button>
          </div>
      </div-->
    </p-card>
  </div>
  <div class="p-md-12">
    <p-card styleClass="p-card-content-fit">
      <ng-template pTemplate="header">
        <div class="p-card-title">

          {{ 'country.entity.name_plural' | translate}}
          <div class="p-card-title-actions">
            <a (click)="edit({ id: 0 })"><fa-icon [icon]="['fas', 'plus-circle']"></fa-icon> {{ 'general.action.new' | translate}}</a>
          </div>
        </div>
      </ng-template>
      <p-table #grid [value]="countries"
               [lazy]="true" (onLazyLoad)="loadData($event)" [loading]="loading"
               [paginator]="true" [rows]="10" [totalRecords]="totalRecords" [showCurrentPageReport]="true"
               currentPageReportTemplate="{{ 'general.resumen_table_records' | translate: { first: first, last: last, totalRecords: totalRecords } }}" [rowsPerPageOptions]="[5,10,25,50]" paginatorDropdownAppendTo="body">
        <ng-template pTemplate="header">
          <tr>
            <th pSortableColumn="id" width="100">{{ 'country.entity_fields.id' | translate}}</th>
            <th pSortableColumn="iso_code_2" width="200">{{ 'country.entity_fields.iso_code_2' | translate}}</th>
            <th pSortableColumn="iso_code_3" width="200">{{ 'country.entity_fields.iso_code_3' | translate}}</th>
            <th pSortableColumn="name">{{ 'country.entity_fields.name' | translate}}</th>
            <th width="100"></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-country>
          <tr (dblclick)="edit(country)">
            <td>{{country.id}}</td>
            <td>{{country.iso_code_2}}</td>
            <td>{{country.iso_code_3}}</td>
            <td>{{country.name}}</td>
            <td class="grid-actions">
              <a (click)="edit(country)"><fa-icon [icon]="['far', 'edit']"></fa-icon></a>
              <a (click)="delete(country)" class="color-danger"><fa-icon [icon]="['far', 'trash-alt']"></fa-icon></a>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </p-card>
  </div>
</div>
