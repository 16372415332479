import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TransactionsLinesRoutingModule } from './transactions-lines-routing.module';
import { TransactionLinesDetailComponent } from './transaction-lines-detail/transaction-lines-detail.component';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { TableModule } from 'primeng/table';
import { CalendarModule } from 'src/app/calendar/calendar.module';
import { CoreModule } from 'src/app/core/core.module';
import { UserCalendarModule } from 'src/app/user-calendar/user-calendar.module';
import { FullCalendarModule } from '@fullcalendar/angular';
import { EditorModule } from '@tinymce/tinymce-angular';
import { TransactionLinesDetailCustomComponent } from './transaction-lines-detail-custom/transaction-lines-detail-custom.component';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
  declarations: [TransactionLinesDetailComponent, TransactionLinesDetailCustomComponent],
  imports: [
    CommonModule,
    TransactionsLinesRoutingModule,
    ButtonModule,
    CardModule,
    CoreModule,
    FontAwesomeModule,
    TableModule,
    FormsModule,
    DropdownModule,
    ConfirmDialogModule,
    InputTextModule,
    EditorModule,
    CalendarModule,
    CheckboxModule,
    UserCalendarModule,
    FullCalendarModule,
    TranslateModule
  ]
})
export class TransactionsLinesModule { }
