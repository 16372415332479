<p-card styleClass="p-card-content-fit">
    <ng-template pTemplate="header">
      <div class="p-card-title">
        {{'component.transactionsStatus.detail.not_bloqued' | translate}}
      </div>
    </ng-template>
    <div class="p-grid">
        <div class="p-col-12 p-field">
          <label for="roles">
            {{'component.transactionsStatus.detail.roles' | translate}}
          </label>
            <p-dropdown name="roles" [options]="roles" [(ngModel)]="selected_role_status" placeholder="{{'general.select' | translate}}"
                optionLabel="name" (onChange)="onChangeRole($event)">
            </p-dropdown>
        </div>
        <div class="p-col-12">
            <p-table #grid [value]="parent.transactionStatus.metadata.rolesStatus" [paginator]="true" [rows]="10"
                [totalRecords]="totalRecords" [showCurrentPageReport]="true"
                currentPageReportTemplate="{{ 'general.resumen_table_records' | translate: { first: first, last: last, totalRecords: totalRecords } }}"
                [rowsPerPageOptions]="[5,10,25,50]"  paginatorDropdownAppendTo="body">
                <ng-template pTemplate="header">
                    <tr>
                      <th pSortableColumn="id" width="100">
                        {{'transactionsStatus.entity_fields.id' | translate}}
                      </th>
                      <th pSortableColumn="name" style="width: 75%;">
                        {{'transactionsStatus.entity_fields.name' | translate}}
                      </th>
                        <th width="100"></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-role>
                    <tr>
                        <td>{{role.id}}</td>
                        <td>{{role.name}}</td>
                        <td class="grid-actions">
                            <!--a (click)="edit(role)">
                        <fa-icon [icon]="['far', 'edit']"></fa-icon>
                    </a-->
                            <a (click)="delete(role)" class="color-danger">
                                <fa-icon [icon]="['far', 'trash-alt']"></fa-icon>
                            </a>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</p-card>
