import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login-form/login-form.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { LoginChangeComponent } from './session-change/login-change.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'login-change', component: LoginChangeComponent },
  { path: 'page-not-found', component: PageNotFoundComponent }
];

@NgModule({
    imports: [
        RouterModule.forChild(routes)
    ],
    exports: [
        RouterModule
    ]
})
export class LoginRoutingModule {
}
