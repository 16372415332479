import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { ViewComponent } from 'src/app/core/view/view.component';
import { OrganizationsService } from 'src/app/organizations/organizations.service';
import { CampaignsService } from '../campaigns.service';
import { CoreDialogService } from 'src/app/core/dialogs/core-dialog.service';
import { DialogService } from 'primeng/dynamicdialog';
import { ViewsService } from 'src/app/core/view/views.service';
import { TranslateService } from '@ngx-translate/core';
import { ComponentService } from 'src/app/core/view/component.service';
import { MailingDetailComponent } from 'src/app/mailing/mailing-detail/mailing-detail.component';
import { Location } from '@angular/common';
import { MailMarketingNotificationToStatsComponent } from 'src/app/mail-marketing/mail-marketing-notification-to-stats/mail-marketing-notification-to-stats.component';
import { CampaignsContactsService } from '../campaigns-contacts.service';
import { CampaignsOrganizationsService } from '../campaigns-organizations.service';
import { MeetingsService } from 'src/app/organizations/organizations-detail/organizations-meetings-detail/meetings.service';
import { TasksService } from 'src/app/tasks/tasks.service';
import { TransactionsService } from 'src/app/transactions/transactions.service';
import { NotificationsService } from 'src/app/notifications/notifications.service';
import { CampaignAddElementComponent } from '../campaign-add-element/campaign-add-element.component';

@Component({
  selector: 'app-campaigns-detail',
  templateUrl: './campaigns-detail.component.html',
  styleUrls: ['./campaigns-detail.component.scss']
})
export class CampaignsDetailComponent {
  public campaign: any = {};
  public organization: any = {};
  public last_organization_id;
  public previousUrl;
  @ViewChild("view") public view: ViewComponent;
  constructor(
    private route: ActivatedRoute,
    private organizationsService: OrganizationsService,
    private meetingsService: MeetingsService,
    private tasksService: TasksService,
    private campaignContactsService: CampaignsContactsService,
    private campaignOrganizationsService: CampaignsOrganizationsService,
    private notificationMailsService: NotificationsService,
    private messageService: MessageService,
    private campaignsService: CampaignsService,
    private transactionsService: TransactionsService,
    private coreDialogService: CoreDialogService,
    private dialogService: DialogService,
    private router: Router,
    private viewsService: ViewsService,
    private translateService: TranslateService,
    public componentService: ComponentService,
    private _location: Location
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.loadData(params["id"]);
    });
    this.route.queryParams.subscribe(params => {
      this.previousUrl = params['returnUrl'];
    });
  }

  loadData(id: number) {
    if (id != 0) {
      this.campaignsService.get(id).subscribe({
        next: (data: any) => {
          this.campaign = data;
          this.last_organization_id = this.campaign.organization_id;
          //this.view.setModel(this.campaign);
          this.refreshAll();
        },
        error: (error: any) => {
          this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
        }
      });
    } else {
      this.route.params.subscribe(params => {
        this.campaign.organization_id = parseInt(params["organizationId"]);
      });
      //this.campaign.organization_id = this.config.data.id;
    }
  }

  loadDataOrganization(id: number) {
    this.organizationsService.get(id).subscribe({
      next: (data: any) => {
        this.organization = data;
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
      }
    });
  }

  cancel() {
    this._location.back();
    //this.dynamicDialogRef.close();
  }

  save(event: any) {
    if (!event.valid) {
      this.messageService.add({
        closable: false, severity: "error", detail: this.translateService.instant("general.form_incomplete")
      });
      return;
    }
    if (this.campaign.email != null) this.campaign.email = this.campaign.email.trim();
    if (this.campaign.email != null && this.campaign.email.length > 0 && !this.validateEmail(this.campaign.email)) {
      this.messageService.add({
        closable: false, severity: "error", detail: this.translateService.instant("component.campaigns.detail.invalid_mail")
      });
      return;
    }
    if (typeof this.campaign.id === "undefined") {
      this.campaignsService.add(this.campaign).subscribe({
        next: (data: any) => {
          this.messageService.add({
            closable: false, severity: 'success', summary: this.translateService.instant("component.campaigns.detail.add_campaign_correct")
          });
          //this.dynamicDialogRef.close(data);
          //this.loadData(data.id);
          this.router.navigate(["/campaigns/" + data.id]);
          //this._location.back();

        },
        error: (error: any) => {
          this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
        }
      });
    } else {
      if (this.last_organization_id != this.campaign.organization_id) {
        //this.confirmMoveData();
      } else {
        this.campaignsService.save(this.campaign.id, this.campaign).subscribe({
          next: (data: any) => {
            if (this.campaign.disabled == true) {
              this.alertDisable();
            } else {
              this.messageService.add({
                closable: false, severity: "success", detail: this.translateService.instant("component.campaigns.detail.save_campaign_correct")
              });
              //this.dynamicDialogRef.close(data);
              if (this.previousUrl != null && this.previousUrl != "") {
                this.router.navigate([this.previousUrl]);
              } else {
                this._location.back();
              }
            }
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
          }
        });
      }
    }
  }

  delete() {
    this.coreDialogService.confirm({
      message: this.translateService.instant("component.organizations.delete_message", { name: this.campaign.name }),
      header: this.translateService.instant("general.confirmation_delete_title"), // this.translateService.instant("general.confirmation_delete_title"),
      icon: "pi pi-info-circle",
      accept: () => {
        this.campaignsService.delete(this.campaign.id).subscribe({
          next: (data: any) => {
            this.messageService.add({ closable: false, severity: 'success', detail: this.translateService.instant("general.confirmation_delete") });
            this.refreshAll();
            //this.dynamicDialogRef.close();
            //this._location.back();
            if (this.previousUrl != null && this.previousUrl != "") {
              this.router.navigate([this.previousUrl]);
            } else {
              this.router.navigate(['/campaigns']);
            }
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        });
      },
      reject: () => {

      }
    })
  }
  newMail(emailAcc) {
    const ref = this.dialogService.open(MailingDetailComponent, {
      data: {
        to: emailAcc,
        entity_id: 2,
        entity_pk_id: this.campaign.id,
        organization_id: this.campaign.organization_id,
        campaign_id: this.campaign.id,
        //template_type_id: 1,
        template_type_code: "MAIL",
        searchRelated: true
      },
      header: this.translateService.instant("general.new_mail_title"),//"Nuevo mensaje de correo",
      width: '70%',
    });
    ref.onClose.subscribe((data: any) => {
      if (data) {
        //this.dynamicDialogRef.close(data);
        location.reload();
      }
      if (typeof (this.view) !== "undefined") this.viewsService.changeView(this.view.code);
    });
  }

  isDuplied(campaign, event) {
    if (!event.valid) {
      this.messageService.add({ closable: false, severity: "error", detail: this.translateService.instant("general.form_incomplete") });
      return;
    }
    this.campaignsService.isDuplied(campaign).subscribe(
      data => {
        if (data == true) {
          this.coreDialogService.notify({
            message: this.translateService.instant("component.campaigns.detail.campaign_duplicate"), //"Ya existe un campaigno con estos datos proporcionados.",
            header: this.translateService.instant("general.error"),
            icon: 'far, exclamation-circle',
            color: 'var(--orange-500)',
            accept: () => { }
          });
        } else {
          this.save(event);
        }
      }
    );
  }

  refreshAll() {
    this.view.view?.configuration?.components?.forEach(component => {
      this.view.fireEvent(component.code, "reload");
    });
  }

  /*
  openNotificationStats() {
    const ref = this.dialogService.open(MailMarketingNotificationToStatsComponent, {
      data: {
        //organization_id: this.campaign.organization_id,
        campaign_id: this.campaign.id,
      },
      header: this.translateService.instant("component.campaigns.detail.report_clicks"),
      width: '70%'
    });
    ref.onClose.subscribe((data: any) => {
      //nothing
    })
  }
*/
  openCall(tlfn: string): void {
    // Verificar si el navegador soporta la función de llamada telefónica
    if ('open' in window) {
      // Utilizar el protocolo tel: seguido del número de teléfono
      window.open(`tel:${tlfn}`);
    } else {
      // Manejar el caso donde la función de llamada telefónica no está soportada
      console.log('Tu navegador no soporta la función de llamada telefónica.');
    }
  }


  validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  openEmails() {
    if (this.campaign.id != null) this.router.navigate(["/emails", (this.organization.id ? this.organization.id : 0), this.campaign.id]); this.cancel();
  }

  alertDisable() {
    this.coreDialogService.notify({
      message: this.translateService.instant("component.campaigns.detail.disabled_campaign_alert"),//"Se ha dado de baja el campaigno.",
      header: this.translateService.instant("general.error"),
      icon: 'far exclamation-circle',
      color: 'var(--orange-500)',
      accept: () => {
        this.messageService.add({ closable: false, severity: "success", detail: this.translateService.instant("component.campaigns.detail.save_campaign_correct") });
        //this._location.back();
      }
    });
  }

  openNotificationStats() {
    const ref = this.dialogService.open(MailMarketingNotificationToStatsComponent, {
      data: {
        //organization_id: this.contact.organization_id,
        //contact_id: this.contact.id,
      },
      header: this.translateService.instant("component.mailMarketing.toStats.report_clicks"),
      width: '70%'
    });
    ref.onClose.subscribe((data: any) => {
      //nothing
    })
  }

  openOrganization(organization) {
    window.open("/organizations/0/" + (organization.id ? organization.id : 0));
  }
  openMeeting(meeting: any) {
    var params = {
      id: meeting ? meeting.company_id : 0,
      contactId: meeting ? meeting.contact_id : 0,
      meetingId: meeting ? meeting.id : 0,
      deactivated: true
    };

    // Construir la URL con los parámetros de consulta
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/meetings/', meeting ? meeting.id : 0], { queryParams: params })
    );

    // Abrir la URL en una nueva pestaña
    window.open(url, '_blank');
  }

  openTransaction(transaction, type_id) {
    window.open("/transactions/" + type_id + "/" + (transaction.id ? transaction.id : 0))
  }
  openContact(contact) {
    window.open("/contacts/" + (contact.id ? contact.id : 0));
  }

  unlinkContact(contact) {
    this.coreDialogService.confirm({
      message: this.translateService.instant("component.campaigns.unlink_contact", { fullname: contact.fullname }),
      header: this.translateService.instant("general.confirmation_delete_title"),
      icon: "pi pi-info-circle",
      accept: () => {
        this.campaignContactsService.unlinkContact({ contact_id: contact.id, campaign_id: this.campaign.id }).subscribe({
          next: (data: any) => {
            this.messageService.add({ closable: false, severity: 'success', detail: this.translateService.instant("general.confirmation_delete") });
            this.refreshAll();
            //this.dynamicDialogRef.close();
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        })
      },
      reject: () => {

      }
    });
  }

  unlinkOrganization(organization) {
    this.coreDialogService.confirm({
      message: this.translateService.instant("component.campaigns.unlink_organization", { name: organization.name }),
      header: this.translateService.instant("general.confirmation_delete_title"),
      icon: "pi pi-info-circle",
      accept: () => {
        this.campaignOrganizationsService.unlinkOrganization({ organization_id: organization.id, campaign_id: this.campaign.id }).subscribe({
          next: (data: any) => {
            this.messageService.add({ closable: false, severity: 'success', detail: this.translateService.instant("general.confirmation_delete") });
            this.refreshAll();
            //this.dynamicDialogRef.close();
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        })
      },
      reject: () => {

      }
    });
  }

  unlinkMeeting(meeting) {
    meeting.campaign_id = null;
    this.coreDialogService.confirm({
      message: this.translateService.instant("component.campaigns.unlink_meeting", { subject: meeting.subject }),
      header: this.translateService.instant("general.confirmation_delete_title"),
      icon: "pi pi-info-circle",
      accept: () => {
        this.meetingsService.save(meeting.id, meeting).subscribe({
          next: (data: any) => {
            this.messageService.add({ closable: false, severity: 'success', detail: this.translateService.instant("general.confirmation_delete") });
            this.refreshAll();
            //this.dynamicDialogRef.close();
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        })
      },
      reject: () => {

      }
    });
  }
  unlinkTask(task) {
    task.campaign_id = null;
    this.coreDialogService.confirm({
      message: this.translateService.instant("component.campaigns.unlink_task", { subject: task.subject }),
      header: this.translateService.instant("general.confirmation_delete_title"),
      icon: "pi pi-info-circle",
      accept: () => {
        this.tasksService.save(task.id, task).subscribe({
          next: (data: any) => {
            this.messageService.add({ closable: false, severity: 'success', detail: this.translateService.instant("general.confirmation_delete") });
            this.refreshAll();
            //this.dynamicDialogRef.close();
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        })
      },
      reject: () => {

      }
    });
  }
  unlinkTransaction(transaction) {
    transaction.campaign_id = null;
    var message = "component.campaigns_unlink_";
    if (transaction.transaction_type_id == 1) message += "oportunity";
    else if (transaction.transaction_type_id == 2) message += "sale";
    else message += "transaction";
    this.coreDialogService.confirm({
      message: this.translateService.instant("component.campaigns.unlink_transaction", { description: transaction.description }),
      header: this.translateService.instant("general.confirmation_delete_title"),
      icon: "pi pi-info-circle",
      accept: () => {
        this.transactionsService.save(transaction.id, transaction).subscribe({
          next: (data: any) => {
            this.messageService.add({ closable: false, severity: 'success', detail: this.translateService.instant("general.confirmation_delete") });
            this.refreshAll();
            //this.dynamicDialogRef.close();
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        })
      },
      reject: () => {

      }
    });
  }

  unlinkEmail(mail) {
    mail.campaign_id = null;
    this.coreDialogService.confirm({
      message: this.translateService.instant("component.campaigns.unlink_notificationEmails", { subject: mail.subject }),
      header: this.translateService.instant("general.confirmation_delete_title"),
      icon: "pi pi-info-circle",
      accept: () => {
        this.notificationMailsService.unlinkEmail(mail.id).subscribe({
          next: (data: any) => {
            this.messageService.add({ closable: false, severity: 'success', detail: this.translateService.instant("general.confirmation_delete") });
            this.refreshAll();
            //this.dynamicDialogRef.close();
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        })
      },
      reject: () => {

      }
    });
  }

  openTask(task) {
    window.open("/tasks/" + (task.id ? task.id : 0));
  }

  addElementToCampaign(entity) {
    const ref = this.dialogService.open(CampaignAddElementComponent, {
      data: {
        campaign_id: this.campaign.id,
        entity: entity
      },
      header: this.translateService.instant('component.campaigns.detail.add_element'),
      width: "75%",
      /*baseZIndex: 1000*/
    });
    ref.onClose.subscribe((data: any) => {
      this.loadData(this.campaign.id);
    });
  }
  viewMail(data) {
    const ref = this.dialogService.open(MailingDetailComponent, {
      data: {
        mail_id: data.id,
        //entity_id: data.notificationEmails.entity.id,
        //template_type_id: 1,
        template_type_code: "MAIL",
        searchRelated: true
      },
      header: this.translateService.instant("general.new_mail_title"),
      width: '70%',
    });
  };

  onEvent(event: any) {
    if (event.event == "refreshAll" && event.action.name == "refreshAll") this.loadData(this.campaign.id);
    if (event.component == "campaigns.detail") {
      //if (event.event == "action" && event.action.name == "openNotificationStats") this.openNotificationStats();
      if (event.event == "action" && event.action.name == "delete") this.delete();
      if (event.event == "action" && event.action.name == "cancel") this.cancel();
      if (event.event == "action" && event.action.name == "save") this.isDuplied(this.campaign, event);
      if (event.event == "fieldAction" && event.action.name == "new-mail") this.newMail(event.value);
      if (event.event == "action" && event.action.name == "openEmails") this.openEmails();
      if (event.event == "fieldAction" && event.action.name == "openCall") this.openCall(event.value);
    }

    if (event.component == "campaigns.opportunities") {
      if (event.event == "action" && event.action.name == "edit") this.openTransaction(event.data, 1);
      if (event.event == "dblclick") this.openTransaction(event.data, 1);
      if (event.event == "action" && event.action.name == "unlink") this.unlinkTransaction(event.data);
      if (event.event == "action" && event.action.name == "add") this.addElementToCampaign("oportunity");
    }

    if (event.component == "campaigns.sales") {
      if (event.event == "action" && event.action.name == "edit") this.openTransaction(event.data, 2);
      if (event.event == "dblclick") this.openTransaction(event.data, 2);
      if (event.event == "action" && event.action.name == "unlink") this.unlinkTransaction(event.data);
      if (event.event == "action" && event.action.name == "add") this.addElementToCampaign("sale");
    }

    if (event.component == "campaigns.tasks") {
      if (event.event == "action" && event.action.name == "edit") this.openTask(event.data);
      if (event.event == "dblclick") this.openTask(event.data);
      if (event.event == "action" && event.action.name == "unlink") this.unlinkTask(event.data);
      if (event.event == "action" && event.action.name == "add") this.addElementToCampaign("task");
    }

    if (event.component == "campaigns.contacts") {
      if (event.event == "action" && event.action.name == "sendEmailContact") {
        //Botón enviar email a contacto
      }
      if (event.event == "action" && event.action.name == "edit") this.openContact(event.data);
      if (event.event == "dblclick") this.openContact(event.data);
      if (event.event == "action" && event.action.name == "unlink") this.unlinkContact(event.data);
      if (event.event == "action" && event.action.name == "add") this.addElementToCampaign("contact");
    }

    if (event.component == "campaigns.meetings") {
      if (event.event == "action" && event.action.name == "calendar") {
        //Botón abrir calendario.
      }
      if (event.event == "dblclick") this.openMeeting(event.data);
      if (event.event == "action" && event.action.name == "edit") this.openMeeting(event.data);
      if (event.event == "action" && event.action.name == "unlink") this.unlinkMeeting(event.data);
      if (event.event == "action" && event.action.name == "add") this.addElementToCampaign("meeting");
    }

    if (event.component == "campaigns.mails") {
      if (event.event == "action" && event.action.name == "resend-mail") {
        //Reenviar email desde widget.
      }
      if (event.event == "action" && event.action.name == "view") {
        this.viewMail(event.data);
      }
      if (event.event == "action" && event.action.name == "unlink") this.unlinkEmail(event.data);
      if (event.event == "action" && event.action.name == "add") this.addElementToCampaign("notificationEmails");
    }

    if (event.component == "campaigns.organizations") {
      if (event.event == "dblclick") this.openOrganization(event.data);
      if (event.event == "action" && event.action.name == "edit") this.openOrganization(event.data);
      if (event.event == "action" && event.action.name == "unlink") this.unlinkOrganization(event.data);
      if (event.event == "action" && event.action.name == "add") this.addElementToCampaign("organization");
    }

    if (event.component == "view" && event.event == "entity-loaded") {
      //this.config.header = typeof (this.campaign.id) !== "undefined" ? "Detalles del " + this.view.getEntityBaseName() : "Nuevo " + this.view.getEntityBaseName();
    }
  }

}
