import { Component, OnInit, ViewChild } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AttachmentsDetailComponent } from 'src/app/attachments/attachments-detail/attachments-detail.component';
import { AttachmentsService } from 'src/app/attachments/attachments.service';
import { TaxRatesService } from 'src/app/configuration/tax-rates/tax-rates.service';
import { CoreDialogService } from 'src/app/core/dialogs/core-dialog.service';
import { ViewComponent } from 'src/app/core/view/view.component';
import { ViewsService } from 'src/app/core/view/views.service';
import { ProductsService } from '../products.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-products-detail',
  templateUrl: './products-detail.component.html',
  styleUrls: ['./products-detail.component.scss']
})
export class ProductsDetailComponent implements OnInit {
  @ViewChild("view") public view: ViewComponent;
  public product: any = {}
  public tax_rates: any;
  public lastGridEvent: any;

  constructor(
    private productsService: ProductsService,
    private taxRatesService: TaxRatesService,
    private attachmentsService: AttachmentsService,
    private messageService: MessageService,
    private config: DynamicDialogConfig,
    private dialogService: DialogService,
    private coreDialogService: CoreDialogService,
    private dynamicDialogRef: DynamicDialogRef,
    private viewsService: ViewsService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.loadTaxRates();
    this.loadData(this.config.data.id);
  }
  loadData(id) {
    if (id) {
      this.productsService.get(id).subscribe(
        data => {
          this.product = data;
          //Eliminado, no se porqué estaba.
          //this.product.tax_percent = this.product.tax_percent + "";

        },
        error => {
          this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
        }
      );
    }
  }
  loadTaxRates() {
    this.taxRatesService.all({}).subscribe(
      data => {
        this.tax_rates = data.rows;
      },
      error => {
        this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
      }
    );
  }
  save(event: any) {
    if (!event.valid) {
      this.messageService.add({ closable: false, severity: "error", detail: this.translateService.instant("general.form_incomplete") });
      return;
    }
    if (true) { //this.view.viewForm.valid){
      this.product.family = null;
      this.product.subfamily = null;
      this.product.taxRate = null;

      if (typeof this.product.id === "undefined") {
        this.productsService.add(this.product).subscribe({
          next: (data: any) => {
            this.product = data;
            this.loadData(this.product.id);
            this.messageService.add({ closable: false, severity: 'success', summary: this.translateService.instant("component.products.detail.add_product_correct") });
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
          }
        });
      } else {
        this.productsService.save(this.product.id, this.product).subscribe({
          next: (data: any) => {
            this.messageService.add({ closable: false, severity: "success", detail: this.translateService.instant("component.products.detail.save_product_correct") });
            this.dynamicDialogRef.close();
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
          }
        });
      }
    } 
  }
  cancel() {
    this.dynamicDialogRef.close();
  }
  delete() {
    this.coreDialogService.confirm({
      message: this.translateService.instant("component.products.delete_message", { name: this.product.name }),
      header: this.translateService.instant("general.confirmation_delete_title"),
      icon: "pi pi-info-circle",
      accept: () => {
        this.productsService.delete(this.product.id).subscribe({
          next: (data: any) => {
            this.messageService.add({ closable: false, severity: 'success', detail: this.translateService.instant("general.confirmation_delete") });
            this.dynamicDialogRef.close();
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        });
      },
      reject: () => {

      }
    })
  }

  downloadFile(attachment) {
    this.attachmentsService.download(attachment.guid);
  }

  viewAttachment(attachment) {
    const ref = this.dialogService.open(AttachmentsDetailComponent, {
      data: {
        id: attachment ? attachment.id : 0,
        entity_id: 8,
        entity_pk_id: this.product.id,
        organization_id: this.product.organization_id
      },
      //header: attachment ? "Detalles del adjunto" : "Nuevo adjunto",
      width: '70%',
      /*baseZIndex: 999*/
    });
    ref.onClose.subscribe((data: any) => {
      if (typeof (this.view) !== "undefined") this.viewsService.changeView(this.view.code);
      this.view.fireEvent("products.edit.attachments", "reload");
    })
  }

  deleteAttachment(attachment) {
    this.coreDialogService.confirm({
      message: this.translateService.instant("component.products.edit.delete_message_attachments", { filename: attachment.filename }),
      header: this.translateService.instant("general.confirmation_delete_title"),
      icon: "pi pi-info-circle",
      accept: () => {
        this.attachmentsService.delete(attachment.id).subscribe({
          next: (data: any) => {
            this.messageService.add({ closable: false, severity: 'success', detail: this.translateService.instant("general.confirmation_delete") });
            this.view.fireEvent("products.edit.attachments", "reload");
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        });
      },
      reject: () => {

      }
    })
  }
  isDuplied(product, event) {
  
    if (!event.valid) {
      this.messageService.add({ closable: false, severity: "error", detail: this.translateService.instant("general.form_incomplete") });
      return;
    }
    this.product.family = null;
    this.product.subfamily = null;
    this.product.taxRate = null;

    this.productsService.isDuplied(this.product).subscribe({
      next: (data: any) => {
        if (data == true) {
          this.coreDialogService.notify({
            message: this.translateService.instant("component.products.detail.product_duplicate"),
            header: 'Error',
            icon: 'far exclamation-circle',
            color: 'var(--orange-500)',
            accept: () => { }
          });
        } else {
          this.save(event);
        }
      }
    });
  }
  onEvent(event) {
    if (event.component == "products.edit.general") {
      if (event.event == "onChange") {
        var net_price = parseFloat(this.product.net_price);
        if (this.product.tax_rate_id > 0) {
          this.product.price = net_price + (net_price * this.tax_rates.find(m => m.id == this.product.tax_rate_id).percent / 100);
        } else {
          this.product.price = net_price ? net_price : 0;
        }
        //this.product.price = net_price + (net_price * this.product.tax_percent/100);
      }
      if (event.event == "action" && event.action.name == "delete") this.delete();
      if (event.event == "action" && event.action.name == "cancel") this.cancel();
      if (event.event == "action" && event.action.name == "save") this.isDuplied(this.product, event);
    } else if (event.component == "products.edit.attachments") {
      if (event.event == "action" && event.action.name == "add") this.viewAttachment(null);
      if (event.event == "action" && event.action.name == "delete") this.deleteAttachment(event.data);
      if (event.event == "dblclick") this.viewAttachment(event.data);
      if (event.event == "action" && event.action.name == "download") this.downloadFile(event.data);
    }
    if (event.component == "view" && event.event == "entity-loaded") {

      this.config.header = typeof (this.product.id) !== "undefined" ?
        this.translateService.instant("component.products.detail.detail_entity", { entity_name: this.view.getEntityBaseName() })
        :
        this.translateService.instant("component.products.detail.new_entity", { entity_name: this.view.getEntityBaseName() });
    }
  }
}
