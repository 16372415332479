import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LoaderService } from 'src/app/core/loader/loader.service';

@Injectable({
  providedIn: 'root'
})
export class Office365Service {

  url = "/api/microsoft";

  constructor(private http: HttpClient, private loaderService:LoaderService) { }

  authorize(): Observable<object> {
    return this.http.get<object>(this.url + "/office365/authorize");
  }

  me(): Observable<object> {
    this.loaderService.skipRequest(this.url + "/office365/me", null);
    return this.http.get<object>(this.url + "/office365/me");
  }

  users(id): Observable<object> {
    return this.http.get<object>(this.url + "/office365/" + id + "/users");
  }

}
