import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { NotificationsService } from 'src/app/notifications/notifications.service';

@Component({
  selector: 'app-mail-marketing-send',
  templateUrl: './mail-marketing-send.component.html',
  styleUrls: ['./mail-marketing-send.component.scss']
})
export class MailMarketingSendComponent implements OnInit {

  public schedules: any[] = [];
  public email = {
    id: null,
    schedule: "now",
    date: new Date()
  }

  constructor(private messageService: MessageService,
    private dynamicDialogRef: DynamicDialogRef,
    private notificationsService: NotificationsService,
    public config: DynamicDialogConfig,
    public translateService: TranslateService) { }

  ngOnInit() {
    //console.log(this.config.data);
    this.schedules = [
      { label: this.translateService.instant('component.mailMarketing.send.send_now'), value: "now" },
      { label: this.translateService.instant('component.mailMarketing.send.custom_date'), value: "customdate" }
    ];
    this.email.id = this.config.data.notificationId;
  }

  send() {
    var params = { id: this.email.id, date: this.email.date };
    //console.log(params);

    this.notificationsService.markToSend(params).subscribe(
      data => {
        this.dynamicDialogRef.close();
      },
      error => {
        this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
      }
    )
  }

  cancel() {
    this.dynamicDialogRef.close();
  }

}
