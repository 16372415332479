import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CoreDialogService } from 'src/app/core/dialogs/core-dialog.service';
import { ViewComponent } from 'src/app/core/view/view.component';
import { UsersService } from 'src/app/users/users.service';
import { ImportService } from '../import.service';
import {FileUploadModule} from 'primeng/fileupload';
import {HttpClientModule} from '@angular/common/http';
import { Table } from 'primeng/table';
import { ImportDetailComponent } from '../import-detail/import-detail.component';
import { ImportProgressbarComponent } from '../import-detail/import-progressbar/import-progressbar.component';
import { ViewsService } from 'src/app/core/view/views.service';
import { TranslateService } from '@ngx-translate/core';
import { ComponentService } from '../../core/view/component.service';

@Component({
  selector: 'app-import-list',
  templateUrl: './import-list.component.html',
  styleUrls: ['./import-list.component.scss']
})
export class ImportListComponent implements OnInit {
  @ViewChild("view") public view: ViewComponent;
  @ViewChild("grid") private grid: any;
  public imports:any = Array<any>();
  public filters:any = {};
  public totalRecords:number;
  public loading:boolean = false;
  private lastGridEvent:any;

  public first;
  public last;
  constructor(
    private importService: ImportService,
    private usersService: UsersService,
    private dialogService: DialogService,
    private messageService: MessageService,
    private coreDialogService: CoreDialogService,
    private viewsService: ViewsService,
    private router: Router,
    private translateService: TranslateService,
    public componentService: ComponentService
  ) { }

  ngOnInit() {
  }
  
  loadData($event) {
    console.log("loadData");

    this.lastGridEvent = $event;
    Promise.resolve(null).then(() => this.loading = true);
    var params = {
      _page: $event.first,
      _pageSize: $event.rows,
      _sortBy: $event.sortField ? $event.sortField : "date",
      _sortDirection: $event.sortOrder > 0 ? "desc" : "asc"
    };
    params = Object.assign(params, this.filters);
    this.importService.all(params).subscribe({
      next: (data: any) => {
        this.totalRecords = data.totalRows;
        this.imports = data.rows;

        Promise.resolve(null).then(() => this.loading = false);
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: "error", detail: error.error.title });

        Promise.resolve(null).then(() => this.loading = false);
      }
    });
  }
  clearFilters(){
    this.filters = {};
    this.search();
  }
  search(){
    this.lastGridEvent.first = 0;
    this.loadData(this.lastGridEvent);
  }

  add(importId){
    if(typeof(importId) === "undefined" || importId == null) importId =0;
    const ref = this.dialogService.open(ImportDetailComponent, {
      data: {
        id: importId,
      },
      header: (importId == 0 ? this.translateService.instant("component.imports.list.new_import") : this.translateService.instant("component.imports.list.re-import")),
      width: '70%'
    });
    ref.onClose.subscribe((data: any) => {
      //if (typeof (this.view) !== "undefined") this.viewsService.changeView(this.view.code); no hay vistas
      this.search();
    })

  }

  delete(importElement) {
    this.coreDialogService.confirm({
      message: this.translateService.instant("component.imports.delete_message", { name: importElement.name }),
      header: this.translateService.instant("general.confirmation_delete_title"),
      icon: "pi pi-info-circle",
      accept: () => {
        this.importService.delete(importElement.id).subscribe({
          next: (data: any) => {
            this.messageService.add({ closable: false, severity: 'success', detail: this.translateService.instant("general.confirmation_delete") });
            this.loadData(this.lastGridEvent);
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        });
      },
      reject: () => {

      }
    });
  }

  saveImport(importData, redirect, closepopup) {
    this.importService.save(importData.id, importData).subscribe({
      next: (data: any) => {
        if (redirect) this.popupConfirmProcessing(importData);
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
      }
    });
  }
  popupConfirmProcessing(importData) {
    const ref = this.dialogService.open(ImportProgressbarComponent, {
      data: {
        id: importData.id
      },
      header: this.translateService.instant("component.imports.list.import_progress"),
      width: '35%',
    });
    ref.onClose.subscribe((data: any) => {
      //this.dynamicDialogRef.close();
      this.search();
    })
  }

  openInfo(importData) {

    if (importData.status == "validating") {
      this.importService.summarize(importData.id).subscribe({
        next: (data: any) => {

          let stringMessage = "";
          data.forEach(element => {
            var textcreate = this.translateService.instant("component.imports.list.create");
            if (element.onlyLink) textcreate = this.translateService.instant("component.imports.list.not_found");
            stringMessage += "<b>" + this.componentService.getEntityName(element.entity, true) + ": </b>" + "<br>" + textcreate + ": " + element.insert + "<br>" + this.translateService.instant("component.imports.list.update") + ": " + element.updates + "<br><br>";
          });
          console.log(stringMessage);
          importData.updatedBy = null;
          importData.createdBy = null;
          this.coreDialogService.confirm({
            message: stringMessage + '<br>' + this.translateService.instant("component.imports.list.question_import"),
            header: this.translateService.instant("component.imports.list.question_import_title"),
            icon: 'pi pi-info-circle',
            accept: () => {
              importData.status = "validated";
              //importData.entity = null;
              this.saveImport(importData, true, false);
            },
            reject: () => {
              importData.status = "validating";
              importData.message = stringMessage;
              //importData.entity = null;
              this.saveImport(importData, false, true);
            }
          });
        },
        error: (error: any) => {
          this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
        }
      });
    } else {
      //Es un fallo
      var title = "";
      if (importData.status == 'success') title = this.translateService.instant("component.imports.list.what_was_done");
      this.coreDialogService.notify({
        message: importData.message,
        header: 'Error',
        icon: 'far exclamation-circle',
        color: 'var(--orange-500)',
        accept: () => { }
      });
    }
  }

  duplicate(importId: number) {
    this.coreDialogService.confirm({
      message: this.translateService.instant("component.imports.list.question_duplicate"),
      header: this.translateService.instant("component.imports.list.question_duplicate_title"),
      icon: "pi pi-info-circle",
      accept: () => {
        this.importService.duplicate(importId).subscribe({
          next: (data: any) => {
            this.messageService.add({ closable: false, severity: 'success', detail: this.translateService.instant("component.imports.list.duplicate_correct") });
            this.loadData(this.lastGridEvent);
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        });
      },
      reject: () => {

      }
    })
  }

}
