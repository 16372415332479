import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BusinessService {
  url = "/api/business";

  constructor(private http: HttpClient) {

  }

  all(params): Observable<any> {
    return this.http.get<any>(this.url, { params: params });
  }

  get(id): Observable<object> {
    return this.http.get<object>(this.url + "/" + id);
  }

  save(id, obj): Observable<object> {
    if (id != 0 && typeof id !== "undefined") {
      return this.http.put<object>(this.url + "/" + id, obj);
    } else {
      return this.add(obj);
    }
  }

  add(obj): Observable<object> {
    return this.http.post<object>(this.url, obj);
  }

  delete(id): Observable<boolean> {
    return this.http.delete<boolean>(this.url + "/" + id);
  }
  isDuplied(obj): Observable<any> {
    return this.http.post<any>(this.url + "/isDuplied", obj);
  }
}
