import { Component, Input, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UIChart } from 'primeng/chart';
import { CampaignsService } from '../campaigns.service';

@Component({
  selector: 'app-campaigns-chart',
  templateUrl: './campaigns-chart.component.html',
  styleUrls: ['./campaigns-chart.component.scss']
})
export class CampaignsChartComponent {
  @ViewChild('chart') chart: UIChart;

  @Input() public data: any;
  @Input() public entity: any;

  public id: any;
  public ret: any = {};
  public height = 0;

  public basicData: any;
  public options: any;
  public labels: any[] = [];
  public backgroundColors: any[] = [];
  public dataFormated: any[] = [];


  constructor(
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private campaignsService: CampaignsService,
  ) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.id = params['id'];
      //this.loadStatsOpportunities(this.id);
      //this.loadBasicDataYears();
      if(this.id) this.loadStats(this.id);
    })
  }

  loadStats(id) {
    this.campaignsService.getStats(id).subscribe({
      next: (data: any) => {
        this.ret = data;
        this.loadBasicDataYears();
      },
      error: (error: any) => {
        //TODO: messageService.
      }
    });
  }

  onEvent(event: string) {
    this.refresh();
  }

  refresh() {
    //this.loadStatsOpportunities(this.id);
    this.chart.reinit();
    this.chart.refresh();
  }

  loadBasicDataYears() {
    var anyoActual = new Date().getFullYear();
    if (this.ret.actualDate != null) anyoActual = new Date(this.ret.actualDate).getFullYear();

    var cost_string = this.translateService.instant("component.campaigns.stats.costs");
    var income_string = this.translateService.instant("component.campaigns.stats.income");
    var expected_income_string = this.translateService.instant("component.campaigns.stats.expected_income");
    this.basicData = {
      labels: ["Total €"],
      datasets: [
        {
          label: cost_string,
          backgroundColor: ['#ED8686'],
          data: [this.ret.cost]
        },
        {
          label: expected_income_string,
          backgroundColor: ['#90cdc4'],
          data: [this.ret.expected_income]
        },
        {
          label: income_string,
          backgroundColor: ['#E0ED31'],
          data: [this.ret.income]
        }
      ]
    };
    this.options = {
      plugins: {
        legend: {
          labels: {
            usePointStyle: true,
          }
        }
      }
    };
  };
}
