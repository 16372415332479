import { Component, ComponentFactoryResolver, ComponentRef, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { ViewsService } from '../../views.service';
import { CustomComponent } from './custom-component.interface';
import { ViewComponent } from '../../view.component';
import { ComponentService } from '../../component.service';
import { DebugService } from '../../../debug.service';

@Component({
  selector: 'app-view-component-custom',
  templateUrl: 'view-component-custom.component.html',
  styleUrls: ['view-component-custom.component.scss'],
})
export class ViewComponentCustomComponent {

  //@ViewChild("customComponentContainer", { static: true }) public customComponentContainer: ElementRef;
  @ViewChild('customComponentContainer', { read: ViewContainerRef }) customComponentContainer: ViewContainerRef;
  //@ViewChild(AdDirective, { static: true }) adHost!: AdDirective;

  @Output() onEvent: EventEmitter<any> = new EventEmitter();

  @Input("view") public view: ViewComponent;
  @Input("component") public component: any = {};
  @Input("entity") public entity:any = null;
  @Input("index") public index:number = 0;
  @Input("model") public model:any = {};
  @Input("showConfigOptions") public showConfigOptions:boolean = false;

  private componentRef:ComponentRef<CustomComponent> = null;


  constructor(
    public debugService: DebugService,
    public viewContainerRef: ViewContainerRef,
    private viewsService:ViewsService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private componentService: ComponentService
  ) { 
  }

  ngAfterViewInit(): void {
    let component = this.viewsService.GetComponentByName(this.component.component);
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);
    this.componentRef = this.customComponentContainer.createComponent<CustomComponent>(componentFactory);
    this.componentRef.instance.view = this.view;
    this.componentRef.instance.data = this.model;
    this.componentRef.instance.entity = this.entity;
  }

  fireEvent(component:string, event:string, args:any){
    if(event=="reload"){
      this.componentRef.instance.onEvent(component, event, args);
    }
  }

  getActionLabel(action: any) {
    //return this.componentService.getActionLabel(action, this.view.code + "-" + this.component.code);
    return this.componentService.getActionLabel(action,  this.component.code);
  }
}
