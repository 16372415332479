import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { UsersService } from 'src/app/users/users.service';
import { SearchService } from '../../core/search.service';
import { ContactsService } from '../../organizations/organizations-detail/organizations-contacts-detail/contacts.service';

@Component({
  selector: 'app-users-searcher',
  templateUrl: './users-searcher.component.html',
  styleUrls: ['./users-searcher.component.scss']
})
export class UsersSearcherComponent implements OnInit {
  public emails: any[] = [];
  public existingEmails: any[] = [];
  public users: any[] = [];
  public others: any[] = [];
  public usersFilters = {};
  public contacts: any[] = [];
  public resultsEmails: any;
  public searchResults: any[] = [];
  public selectedItem: any;

  constructor(
    private usersService: UsersService,
    private dynamicDialogRef: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private contactService: ContactsService,
    private messageService: MessageService,
    private searchService: SearchService
  ) { }

  ngOnInit(): void {
    this.resultsEmails = { users: [], contacts: [] }
    this.loadEmails();
  }
  loadEmails() {
    this.existingEmails = this.config.data.emails.map(e => e.email);
    if (this.config.data.organizationId) this.loadContacts(this.config.data.organizationId);
    this.loadUsers();
  }

  loadContacts(organizationId) {
    this.contactService.all({ organization_id: organizationId }).subscribe({
      next: (data: any) => {
        this.contacts = data.rows;
        //Recorremos para preseleccionar si ya está en la lista.
        for (let u of this.existingEmails) {
          var emailSearch = this.contacts.filter(m => m.email == u);
          if (emailSearch.length > 0) {
            if (emailSearch[0].email != "" && emailSearch[0].email != null) {
              this.resultsEmails.contacts.push(emailSearch[0].id + "");
            }
          }
        }
      },
      error: (error: any) => {

      }
    });
  }

  loadUsers() {
    var params = {};
    if (this.config.data.viewAll) params = { viewAll: this.config.data.viewAll }
    params = Object.assign(params, this.usersFilters);
    this.usersService.all(params).subscribe(
      data => {
        this.users = data.rows;
        //Recorremos para preseleccionar si ya está en la lista.
        for (let u of this.existingEmails) {
          var emailSearch = this.users.filter(m => m.email == u);
          if (emailSearch.length > 0) {
            if (emailSearch[0].email != "" && emailSearch[0].email != null) {
              this.resultsEmails.users.push(emailSearch[0].id + "");
            }
          }
        }
      },
      error => {

      }
    );
  }

  filterSearch(event) {
    let filtered: any[] = [];
    let query = event.query;
    if (query.length >= 4) {
      filtered.push(
        {
          email: query
        }
      );
      this.searchService.onlyPeople({ email: query }).subscribe({
        next: (data: any) => {
          data.forEach(element => {
            filtered.push(element);
          });
          this.searchResults = filtered;
        },
        error: (error: any) => {
          this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
        }
      });
    }
  }

  search() {
    this.loadUsers();
  }
  clear() {
    this.usersFilters = {};
    this.loadUsers();
  }
  addElementToArray(event) {
    if (event) {
      if (event.email) this.others.push({ id: event.id, name: event.text, email: event.email, organization_id: event.organization_id });
      this.selectedItem = null;
    }
  }
  save() {
    //Usuarios
    for (let u of this.resultsEmails.users) {
      var emailSearch = this.users.filter(m => m.id == u);
      if (emailSearch.length > 0) {
        if (emailSearch[0].email != "" && emailSearch[0].email != null) {
          if (this.existingEmails.indexOf(emailSearch[0].email) === -1) {
            this.emails.push({ name: emailSearch[0].name, email: emailSearch[0].email, contact_id: emailSearch[0].contact_id, organization_id: emailSearch[0].organization_id });
          }
        }
      }
    }
    //Contactos
    for (let c of this.resultsEmails.contacts) {
      var emailSearch = this.contacts.filter(m => m.id == c);
      if (emailSearch.length > 0) {
        if (emailSearch[0].email != "" && emailSearch[0].email != null) {
          if (this.existingEmails.indexOf(emailSearch[0].email) === -1) { //si no existe en los que ya están añadidos, lo añadimos
            this.emails.push({ name: emailSearch[0].name, email: emailSearch[0].email, contact_id: emailSearch[0].id, organization_id: emailSearch[0].organization_id });
          }
        }
      }
    }
    //Otros contactos por si no hay empresa

    if (this.resultsEmails.others) {
      for (let c of this.resultsEmails.others) {
        var emailSearch = this.others.filter(m => m.id == c);
        if (emailSearch.length > 0) {
          if (emailSearch[0].email != "" && emailSearch[0].email != null) {
            if (this.existingEmails.indexOf(emailSearch[0].email) === -1) { //si no existe en los que ya están añadidos, lo añadimos
              this.emails.push({ name: emailSearch[0].name, email: emailSearch[0].email, contact_id: emailSearch[0].id, organization_id: emailSearch[0].organization_id });
            }
          }
        }
      }
    }
    this.dynamicDialogRef.close({ emails: this.emails, destiny: this.config.data.destiny });
  }
  cancel() {
    this.dynamicDialogRef.close();
  }
}
