import { getLocaleDateTimeFormat } from '@angular/common';
import { Input, ViewChild } from '@angular/core';
import { Component, ComponentFactoryResolver, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UIChart } from 'primeng/chart';
import { Subscription } from 'rxjs';
import { CustomComponent } from 'src/app/core/view/component/custom/custom-component.interface';
import { OrganizationsService } from '../../organizations.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-opportunities-widget',
  templateUrl: './opportunities-widget.component.html',
  styleUrls: ['./opportunities-widget.component.scss']
})
export class OpportunitiesWidgetComponent implements OnInit, CustomComponent {
  @ViewChild('chart') chart: UIChart;
  @Input() public data: any;
  @Input() public entity: any;

  public id;
  public ret: any = {};

  constructor(
    private organizationService: OrganizationsService,
    private route: ActivatedRoute, private translateService: TranslateService
  ) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.id = params['id'];
      this.loadStatsOpportunities(this.id);
    });
  }

  onEvent(event: string) {
    this.refresh();
  }

  refresh() {
    this.loadStatsOpportunities(this.id);
    this.chart.reinit();
    this.chart.refresh();
  }

  loadStatsOpportunities(id) {
    this.organizationService.statsOpportunities(id).subscribe({
      next: (data: any) => {
        this.ret = data;
        this.loadBasicDataYears();
      },
      error: (error: any) => {
        //TODO: messageService aquí.
      }
    });
  }

  //Configuración de tablas horizontales para años.
  public basicDataYears: any;
  public horizontalOptionsYears: any;
  loadBasicDataYears() {
    var anyoActual = new Date().getFullYear();
    if (this.ret.actualDate != null) anyoActual = new Date(this.ret.actualDate).getFullYear();

    this.basicDataYears = {
      labels: [anyoActual + "      ", anyoActual - 1 + "      ", anyoActual - 2 + "      ", anyoActual - 3 + "      ", anyoActual - 4 + "      "],
      datasets: [
        {
          label: this.translateService.instant("component.organizations.opportunities_widget.total_sales_per_years") ,
          backgroundColor: '#90cdc4',
          data: this.ret.sumYears
        }
      ]
    };

    this.horizontalOptionsYears = {
      indexAxis: 'y',
      tooltips: {
        callbacks: {
          label: function (tooltipItem, data) {
            var label = new Intl.NumberFormat('es-ES').format(tooltipItem.value);
            return label + " €";
          }
        }
      },
      plugins: {
        legend: {
          labels: {
            color: '#495057'
          }
        }
      },
      scales: {
        x: {
          ticks: {
            color: '#495057'
          },
          grid: {
            color: '#ebedef'
          }
        },
        y: {
          ticks: {
            color: '#495057'
          },
          grid: {
            color: '#ebedef'
          }
        }
      }
    };
  };
}
