import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CoreDialogService } from 'src/app/core/dialogs/core-dialog.service';
import { OrganizationsTypesService } from 'src/app/organizations/organizations-types.service';

@Component({
  selector: 'app-organizations-types-detail',
  templateUrl: './organizations-types-detail.component.html',
  styleUrls: ['./organizations-types-detail.component.scss']
})
export class OrganizationsTypesDetailComponent implements OnInit {

  public organizationType: any = {}

  constructor(
    private dynamicDialogRef: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private messageService: MessageService,
    private organizationsTypesService: OrganizationsTypesService,
    private coreDialogService: CoreDialogService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.loadData(this.config.data.id);
  }
  loadData(id) {
    if (id != 0 && id != null) {
      this.organizationsTypesService.get(id).subscribe({
        next: (data: any) => {
          this.organizationType = data;
        },
        error: (error: any) => {
          this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
        }
      });
    }
  }
  cancel() {
    this.dynamicDialogRef.close();
  }
  delete() {
    this.coreDialogService.confirm({
      message: '¿Quieres borrar el tipo ' + this.organizationType.name + '?',
      header: this.translateService.instant("general.confirmation_delete_title"),
      icon: 'pi pi-info-circle',
      accept: () => {
        this.organizationsTypesService.delete(this.organizationType.id).subscribe({
          next: (data: any) => {
            this.messageService.add({ closable: false, severity: 'success', detail: 'Borrado correctamente' });
            this.dynamicDialogRef.close(data);
          },
          error: (error: any) => {
            this.messageService.add({ severity: "error", detail: error.error.title });
          }
        });
      },
      reject: () => {

      }
    });
  }

  isDuplied() {
    this.organizationsTypesService.isDuplied(this.organizationType).subscribe(
      data => {
        if (data == true) {
          this.coreDialogService.notify({
            message: "Ya existe un tipo de organización con estos datos proporcionados.",
            header: 'Error',
            icon: 'far exclamation-circle',
            color: 'var(--orange-500)',
            accept: () => { }
          });
        } else {
          this.save();
        }
      }
    );
  }

  save() {
    if (typeof this.organizationType.id === "undefined") {
      this.organizationsTypesService.add(this.organizationType).subscribe({
        next: (data: any) => {
          this.organizationType = data;
          this.loadData(this.organizationType.id);
          this.messageService.add({ closable: false, severity: "success", detail: this.translateService.instant("component.organizationType.detail.add_correct") });
        },
        error: (error: any) => {
          this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
        }
      });
    } else {
      this.organizationsTypesService.save(this.organizationType.id, this.organizationType).subscribe({
        next: (data: any) => {
          this.messageService.add({ closable: false, severity: "success", detail: this.translateService.instant("component.organizationType.detail.save_correct") });
          this.dynamicDialogRef.close();
        },
        error: (error: any) => {
          this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
        }
      });
    }
  }
}

