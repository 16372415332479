  <div class="p-grid p-dialog-custom-header border-top">
    <div class="p-inputgroup">
      <input [(ngModel)]="searchText" id="searchText" name="searchText" type="text" pInputText placeholder="{{'general.filter.search_input' | translate}}" autocomplete="false" />
      <button pButton class="p-button-secondary" (click)="clear()" icon="pi pi-times"></button>
    </div>
  </div>

  <div class="p-dialog-content-if-header">
    <div *ngIf="entities.length>1" style="width: 100%">
      <div *ngFor="let entity of entities ; let i = index">
        <p-panel id="panel-{{i}}" inputId="panel-{{i}}" #panel [toggleable]="true" [collapsed]="false" header="{{componentService.getEntityName(entity)}}" showHeader="false" styleClass="p-panel-no-border">
          <div *ngFor="let field of fieldsInEntitiesField(entity.name, searchText)" class="p-mb-2">
            <p-checkbox name="groupname" value="{{field.id}}" [(ngModel)]="selectedEntitiesFields" class="p-mr-3"></p-checkbox>
            {{componentService.getFieldDescription(field, false)}}
          </div>
          <div *ngIf="fieldsInEntitiesField(entity.name, searchText)==0">
            <small>{{'general.filter.no_fields_search' | translate}}</small>
          </div>
        </p-panel>
      </div>
    </div>
    <div *ngIf="entities.length==1" class="p-m-4">
      <div *ngFor="let field of fieldsFiltered(searchText)">
        <p-checkbox name="groupname" value="{{field.id}}" [(ngModel)]="selectedEntitiesFields" class="p-mr-3"></p-checkbox>
        {{componentService.getFieldDescription(field, false)}}
      </div>
        <div *ngIf="fieldsFiltered(searchText)==0">
          <small>{{'general.filter.no_fields_search' | translate}}</small>
        </div>
    </div>
  </div>

  <div class="p-dialog-footer">
    <div class="p-grid">
      <div class="p-col">
        <button pButton class="p-button-secondary" style="float: right;" (click)="save()" icon="pi pi-save" label="{{'general.action.apply' | translate}}"></button>
        <button pButton class="p-button-link p-button-secondary" style="float: right;" (click)="cancel()" icon="pi pi-times" label="{{'general.action.cancel' | translate}}"></button>

      </div>
    </div>
  </div>
