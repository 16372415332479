<div class="p-grid p-jc-center p-mt-4">
    <fa-icon [icon]="icon" style="font-size: 48px;" [ngStyle]="myStyle()"></fa-icon>
</div>
<div class="p-mt-4" style="text-align: center"> 
    <div [innerHTML]="getHtml()"></div>
</div>
<div class="p-grid p-jc-center p-mt-4">
    <p-button *ngIf="!show_button_ok" label="No" styleClass="p-button-link" (click)="reject()"></p-button>
    <p-button *ngIf="!show_button_ok" label="Sí" (click)="accept()"></p-button>
    <p-button *ngIf="show_button_ok" label="Ok" (click)="accept()"></p-button>
</div>
