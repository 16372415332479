import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MailingDetailComponent } from './mailing-detail/mailing-detail.component';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { SplitButtonModule } from 'primeng/splitbutton';
import { TableModule } from 'primeng/table';
import { TimelineModule } from 'primeng/timeline';
import { CoreModule } from '../core/core.module';
import { ChipsModule } from 'primeng/chips'
import { EditorModule } from '@tinymce/tinymce-angular';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { FileUploadModule } from 'primeng/fileupload';
import { MailingAttachmentsComponent } from './mailing-attachments/mailing-attachments.component';
import { AccordionModule } from 'primeng/accordion';
import { TabViewModule } from 'primeng/tabview';
import { MailingToDetailComponent } from './mailing-to-detail/mailing-to-detail.component';
import { MessageModule } from 'primeng/message';
import { ToastModule } from 'primeng/toast';
import { UsersSearcherComponent } from './users-searcher/users-searcher.component';
import { MultiSelectModule } from 'primeng/multiselect';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [MailingDetailComponent, MailingAttachmentsComponent, MailingToDetailComponent, UsersSearcherComponent],
  imports: [
    CommonModule,
    InputTextModule,
    ButtonModule,
    TableModule,
    ConfirmDialogModule,
    DialogModule,
    FormsModule,
    CheckboxModule,
    CoreModule,
    CardModule,
    FontAwesomeModule,
    InputTextareaModule,
    DropdownModule,
    CalendarModule,
    SplitButtonModule,
    TimelineModule,
    ChipsModule,
    EditorModule,
    AutoCompleteModule,
    FileUploadModule,
    AccordionModule,
    TabViewModule,
    ToastModule,
    MessageModule,
    MultiSelectModule,
    TranslateModule
  ],
  exports: [MailingDetailComponent, UsersSearcherComponent]
})
export class MailingModule { }
