<p-card styleClass="p-card-content-fit">
    <!--Añadir Contacto-->
    <ng-template pTemplate="header">
        <div class="p-card-title">
            {{'contact.entity.name_plural' | translate}}
            <div class="p-card-title-actions">
              <a (click)="view(null)">
                <fa-icon [icon]="['fas', 'plus-circle']"></fa-icon>{{'component.contact.slist.action.add' | translate }}
              </a>
            </div>
        </div>
    </ng-template>
    <div class="p-grid">
        <!--Tabla de contacts-->
        <div class="p-col-12">
            <p-table #grid [value]="contacts" [lazy]="true" (onLazyLoad)="loadData($event)" [loading]="loading"
                [paginator]="true" [rows]="10" [totalRecords]="totalRecords" [showCurrentPageReport]="true"
                currentPageReportTemplate="{{ 'general.resumen_table_records' | translate: { first: first, last: last, totalRecords: totalRecords } }}"
                [rowsPerPageOptions]="[5,10,25,50]" paginatorDropdownAppendTo="body">
                <ng-template pTemplate="header">
                    <tr>
                      <th pSortableColumn="name">
                        {{'contact.entity_fields.name' | translate }}
                      </th>
                      <th pSortableColumn="surname">{{'contact.entity_fields.surname' | translate }}</th>
                      <th pSortableColumn="lastname">{{'contact.entity_fields.lastname' | translate }}</th>
                      <th pSortableColumn="position">{{'contact.entity_fields.position' | translate }}</th>
                      <th pSortableColumn="phone_1">{{'contact.entity_fields.phone1' | translate }}</th>
                        <th width="100"></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-contact>
                    <tr (dblclick)="view(contact)">
                        <td>{{contact.name}}</td>
                        <td>{{contact.surname}}</td>
                        <td>{{contact.lastname}}</td>
                        <td>{{contact.position}}</td>
                        <td>{{contact.phone_1}}</td>
                        <td class="grid-actions">
                            <a (click)="view(contact)">
                                <fa-icon [icon]="['far', 'edit']"></fa-icon>
                            </a>
                            <a (click)="delete(contact)" class="color-danger">
                                <fa-icon [icon]="['far', 'trash-alt']"></fa-icon>
                            </a>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</p-card>
