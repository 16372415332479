import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { LoginService } from 'src/app/login/login.service';
import { EntitiesDetailComponent } from '../entities-detail/entities-detail.component';
import { EntitiesService } from '../entities.service';
import { ComponentService } from '../../core/view/component.service';
import { DebugService } from '../../core/debug.service';

@Component({
  selector: 'app-entities-list',
  templateUrl: './entities-list.component.html',
  styleUrls: ['./entities-list.component.scss']
})
export class EntitiesListComponent implements OnInit {
  public entitiesFilters: any = {};
  public entities: any[];
  public totalRecords: number;
  public loading: boolean = false;
  private lastGridEvent: any;

  public first;
  public last;
  //Permisos:
  public access_entities_fields: boolean;

  constructor(
    private entitiesService: EntitiesService,
    private dialogService: DialogService,
    private messageService: MessageService,
    private loginService: LoginService,
    public componentService: ComponentService,

    public debugService: DebugService
  ) { }

  ngOnInit(): void {
    this.access_entities_fields = this.loginService.hasPermission("ACCESS_ENTITIES_FIELDS");
  }

  search() {
    this.lastGridEvent.first = 0;
    this.loadData(this.lastGridEvent);
  }

  clearFilters() {
    this.entitiesFilters = {};
    this.search();
  }
  loadData($event) {
    this.lastGridEvent = $event;
    Promise.resolve(null).then(() => this.loading = true);
    var params = {
      _page: $event.first,
      _pageSize: $event.rows,
      _sortBy: $event.sortField ? $event.sortField : "",
      _sortDirection: $event.sortOrder > 0 ? "asc" : "desc"
    };
    params = Object.assign(params, this.entitiesFilters);
    this.entitiesService.all(params).subscribe({
      next: (data: any) => {
        this.totalRecords = data.totalRows;
        this.entities = data.rows;
        this.entities.forEach((item: any) => {
          item.name = this.componentService.getEntityName(item, false);
          if (this.debugService.enabled) item.name += "  (" + (item.code + ".entity.name") + ")";
          item.name_plural = this.componentService.getEntityName(item, true);
        });
        Promise.resolve(null).then(() => this.loading = false);
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
        Promise.resolve(null).then(() => this.loading = false);
      }
    });
  }
  edit(entity) {
    if (this.access_entities_fields) {
      const ref = this.dialogService.open(EntitiesDetailComponent, {
        data: {
          id: entity.id
        },
       // header: "Detalle de Entidad - " + entity.name,
        width: '70%',
        /*baseZIndex: 999*/
      });
      ref.onClose.subscribe((data: any) => {
        if (data != null) {
          this.loadData(this.lastGridEvent);
        }
        this.loadData(this.lastGridEvent);
      });
    }
  }
}
