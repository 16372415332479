<!--app-view #view [code]="'mailAccount.edit'" [model]="mailAccount" (onEvent)="onEvent($event)"></app-view-->

<!--p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000"></p-confirmDialog-->
<form #formDetails="ngForm">
    <p-card header="{{'component.mailAccount.detail.detail' | translate}}">
        <div class="p-grid">
            <div class="p-col-12 p-field">
              <label for="type">{{ 'mailAccount.entity_fields.type' | translate}}</label>
                <p-dropdown name="type" [options]="mail_types" optionValue="code" placeholder="{{ 'general.select' | translate}}"
                    appendTo="body" optionLabel="name" [(ngModel)]="mailAccount.type" required
                    [disabled]="mailAccount.id">
                </p-dropdown>
            </div>
        </div>

        <div class="p-grid" *ngIf="mailAccount.type == 'smtp'">
            <div class="p-col-6 p-field">
              <label for="name">{{ 'mailAccount.entity_fields.name' | translate}}</label>
                <input name="name" type="text" pInputText [(ngModel)]="mailAccount.name" required />
            </div>
            <div class="p-col-6 p-field">
              <label for="mail_out_from">{{ 'mailAccount.entity_fields.mail_out_from' | translate}}</label>
                <input name="mail_out_from" type="text" pInputText [(ngModel)]="mailAccount.mail_out_from" placeholder="Ej: mail@dominio.com" required />
            </div>
            <div class="p-col-7 p-field">
              <label for="mail_out_server">{{ 'mailAccount.entity_fields.mail_out_server' | translate}}</label>
                <input name="mail_out_server" type="text" pInputText [(ngModel)]="mailAccount.mail_out_server"
                    required />
            </div>
            <div class="p-col-3 p-field">
              <label for="mail_out_port">{{ 'mailAccount.entity_fields.mail_out_port' | translate}}</label>
                <input name="mail_out_port" type="text" pInputText [(ngModel)]="mailAccount.mail_out_port" required />
            </div>
            <div class="p-col-2 p-field">
              <label for="mail_out_ssl">{{ 'mailAccount.entity_fields.mail_out_ssl' | translate}}</label>
                <p-checkbox name="mail_out_ssl" [(ngModel)]="mailAccount.mail_out_ssl" [binary]="true"></p-checkbox>
            </div>
            <div class="p-col-6 p-field">
              <label for="mail_out_username">{{ 'mailAccount.entity_fields.mail_out_username' | translate}}</label>
                <input name="mail_out_username" type="text" pInputText [(ngModel)]="mailAccount.mail_out_username"
                    required />
            </div>
            <div class="p-col-6 p-field">
              <label for="mail_out_password">{{ 'mailAccount.entity_fields.mail_out_password' | translate}}</label>
                <input name="mail_out_password" type="password" pInputText [(ngModel)]="mailAccount.mail_out_password"
                    required />
            </div>
        </div>

        <div class="p-grid" *ngIf="mailAccount.type == 'office365' && mailAccount.auth_info!=null">
            <div class="p-col-6 p-field">
              <label for="name">{{ 'mailAccount.entity_fields.name' | translate}}</label>
                <input name="name" type="text" pInputText [(ngModel)]="mailAccount.name" required disabled />
            </div>
            <div class="p-col-6 p-field">
              <label for="mail_out_from">{{ 'mailAccount.entity_fields.mail_out_from' | translate}}</label>
                <input name="mail_out_from" type="text" pInputText [(ngModel)]="mailAccount.mail_out_from" required
                    disabled />
            </div>
            <div class="p-col-12 p-field">
              <label for="status">{{ 'mailAccount.entity_fields.status' | translate}}</label>
                <input name="status" type="text" pInputText [(ngModel)]="status" disabled />
            </div>
        </div>
      
        <div class="p-grid" *ngIf="mailAccount.type == 'mailchimp' || mailAccount.type == 'mailjet'">
            <div class="p-col-6 p-field">
              <label for="name">{{ 'mailAccount.entity_fields.name' | translate}}</label>
                <input name="name" type="text" pInputText [(ngModel)]="mailAccount.name" required />
            </div>
            <div class="p-col-6 p-field">
              <label for="mail_out_from">{{ 'mailAccount.entity_fields.mail_out_from' | translate}}</label>
                <input name="mail_out_from" type="text" pInputText [(ngModel)]="mailAccount.mail_out_from" placeholder="Ej: mail@dominio.com" required />
            </div>
        </div>

        <div class="p-grid" *ngIf="mailAccount.type == 'gmail' && mailAccount.auth_info!=null">
          <div class="p-col-6 p-field">
            <label for="name">{{ 'mailAccount.entity_fields.name' | translate}}</label>
              <input name="name" type="text" pInputText [(ngModel)]="mailAccount.name" required disabled />
          </div>
          <div class="p-col-6 p-field">
            <label for="mail_out_from">{{ 'mailAccount.entity_fields.mail_out_from' | translate}}</label>
              <input name="mail_out_from" type="text" pInputText [(ngModel)]="mailAccount.mail_out_from" required
                  disabled />
          </div>
          <div class="p-col-12 p-field">
            <label for="status">{{ 'mailAccount.entity_fields.status' | translate}}</label>
              <input name="status" type="text" pInputText [(ngModel)]="status" disabled />
          </div>
      </div>

        <ng-template pTemplate="footer">
            <div class="p-d-flex">
                <p-button label="{{'general.action.delete' | translate}}" icon="pi pi-trash" styleClass="p-button-danger"
                    (click)="delete()" *ngIf="mailAccount.id!=null"></p-button>
                <!--<p-button label="Realizar prueba" styleClass="p-button-secondary p-button-text" icon="pi pi-play"
                    *ngIf="mailAccount.type!='office365'" (click)="testAccount()"></p-button>-->
                <div class="flex-grow-1"></div>
                <p-button label="{{'general.action.cancel' | translate}}" icon="pi pi-times" styleClass="p-button-secondary p-button-text"
                    (click)="cancel()"></p-button>
                <p-button label="{{'general.action.save' | translate}}" icon="pi pi-check" styleClass="p-ml-2" (click)="isDuplied()"
                    *ngIf="mailAccount.type!='office365' || mailAccount.type == 'office365' && mailAccount.connected"></p-button>
                <p-button label="{{'general.action.connect' | translate}}" icon="pi pi-check" styleClass="p-ml-2" (click)="connectAccount()"
                    *ngIf="mailAccount.type == 'office365' && !mailAccount.connected"></p-button>
            </div>
        </ng-template>
    </p-card>
</form>
