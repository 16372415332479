<ul class="breadcrumb">
    <li><a [routerLink]="['/home']"><span class="color-soft-grey-axial">{{'general.breadcrumb.you_are' | translate}} </span>{{'general.breadcrumb.home' | translate}}</a></li>
    <li><i class="pi pi-angle-right"></i></li>
    <li><a [routerLink]="['/configuration/entities']">{{ 'general.configuration' | translate}}</a></li>
    <li><i class="pi pi-angle-right"></i></li>
    <li><a [routerLink]="['/configuration/provinces']">{{ 'province.entity.name_plural' | translate}}</a></li>
    <li><i class="pi pi-angle-right"></i></li>
    <li [hidden]="province.id==null"><a [routerLink]="['/configuration/provinces', province.id]">{{province.name}}</a></li>
    <li [hidden]="province.id!=null">{{'component.province.detail.new_entity' | translate}}</li>
</ul>
<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000"></p-confirmDialog>
<form #formDetails="ngForm">
    <p-card header="{{'component.province.detail.detail' | translate}}">
        <div class="p-grid">
            <div class="p-col-6 p-field">
              <label for="name">{{ 'province.entity_fields.name' | translate}}</label>
                <input name="name" type="text" pInputText [(ngModel)]="province.name" required />
            </div>
            <div class="p-col-6 p-field">
              <label for="country">{{ 'province.entity_fields.country_id' | translate}}</label>
                <p-dropdown name="country" [options]="countries" optionLabel="name" optionValue="id" [(ngModel)]="province.country_id" placeholder="{{ 'general.select' | translate}}" required></p-dropdown>
            </div>
        </div>
        <ng-template pTemplate="footer">
            <div class="p-d-flex">
                <p-button label="{{'general.action.delete' | translate}}" icon="pi pi-trash" styleClass="p-button-danger"
                    (click)="delete()" *ngIf="province.id!=null"></p-button>
                <div class="flex-grow-1"></div>
                <p-button label="{{'general.action.cancel' | translate}}" icon="pi pi-times" styleClass="p-button-secondary p-button-text"
                    (click)="cancel()"></p-button>
                <p-button label="{{'general.action.save' | translate}}" icon="pi pi-check" styleClass="p-ml-2" (click)="isDuplied()"></p-button>
            </div>
        </ng-template>
    </p-card>
</form>
