import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ZonesService {
  url = "/api/zones";

  constructor(private http: HttpClient) { }
  
  all(params): Observable<any> {
    return this.http.get<any>(this.url, { params: params });
  }
  getComboZones(params): Observable<any>{
    return this.http.get<any>(this.url+"/combo",{params:params});
  }
  combo(params): Observable<any> {
    return this.http.get<any>(this.url + "/Combo", { params: params });
  }
  get(id): Observable<object> {
    return this.http.get<object>(this.url + "/" + id);
  }

  save(id, obj): Observable<object> {
      return this.http.put<object>(this.url + "/" + id, obj);
  }

  add(obj): Observable<object> {
      return this.http.post<object>(this.url, obj);
  }

  delete(id): Observable<object> {
      return this.http.delete<object>(this.url + "/" + id);
  }

  isDuplied(obj):Observable<any>{
    return this.http.post<any>(this.url+"/isDuplied",obj);
  }
}
