import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeListComponent } from './home-list/home-list.component';
import { OrganizationsMeetingsDetailComponent } from '../organizations/organizations-detail/organizations-meetings-detail/organizations-meetings-detail.component';

const routes: Routes = [
    { path: 'home', component: HomeListComponent },
    { path: 'meetings/:id', component: OrganizationsMeetingsDetailComponent },
];

@NgModule({
    imports: [
        RouterModule.forChild(routes)
    ],
    exports: [
        RouterModule
    ]
})
export class HomeRoutingModule {
}
