import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog'
import { UsersService } from '../../../../users/users.service';

@Component({
  selector: 'app-integration-edit-gmail-user-config',
  templateUrl: './user-config.component.html',
  styleUrls: ['./user-config.component.scss']
})
export class IntegrationEditGMailUserEditComponent implements OnInit {

  public contactsModes: any = [
    { name: "Axial <= GMail", value: "<" },
    { name: "Axial => GMail", value: ">" },
    { name: "Axial <=> GMail", value: "<>" }
  ];
  public calendarModes: any = [
    { name: "Axial <= GMail", value: "<" },
    { name: "Axial => GMail", value: ">" },
    { name: "Axial <=> GMail", value: "<>" }
  ];
  public mailModes: any = [
    { name: "Axial <= GMail", value: "<" }
  ];

  public users: any[] = [];
  public user: any = {}
;

  constructor(
    private config:DynamicDialogConfig,
    private dynamicDialogRef: DynamicDialogRef,
    private messageService: MessageService,
    private usersService: UsersService
  ) {
    this.user = JSON.parse(JSON.stringify(config.data.user));
  }

  ngOnInit() {
    this.loadUsers();
    
  }

  loadUsers() {
    this.usersService.all({}).subscribe(
      data => this.users = data.rows,
      error => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
      }
    );
  }

  save() {
    this.dynamicDialogRef.close(this.user);
  }

  cancel() {
    this.dynamicDialogRef.close();
  }

}
