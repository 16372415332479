<ul class="breadcrumb">
  <li><a [routerLink]="['/home']"><span class="color-soft-grey-axial">{{'general.breadcrumb.you_are' | translate}} </span>{{'general.breadcrumb.home' | translate}}</a></li>
  <li><i class="pi pi-angle-right"></i></li>
  <li><a [routerLink]="['/configuration/entities']">{{ 'general.configuration' | translate}}</a></li>
  <li><i class="pi pi-angle-right"></i></li>
  <li><a [routerLink]="['/configuration/taxRates']">{{'taxRate.entity.name' | translate}}</a></li>
  <li><i class="pi pi-angle-right"></i></li>
  <li [hidden]="taxRate.id==null"><a [routerLink]="['/configuration/taxRates', taxRate.id]">{{taxRate.name}}</a></li>
  <li [hidden]="taxRate.id!=null">{{'component.taxRates.action.new' | translate}}</li>
</ul>
<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000"></p-confirmDialog>
<form #formDetails="ngForm">
    <p-card header="{{'component.taxRates.detail.detail' | translate}}">
        <div class="p-grid">
            <div class="p-col-6 p-field">
              <label for="name">{{'taxRate.entity_fields.name' | translate}}</label>
                <input name="name" type="text" pInputText [(ngModel)]="taxRate.name" required />
            </div>
            <div class="p-col-6 p-field">
              <label for="percent">{{'taxRate.entity_fields.percent' | translate}}</label>
                <input name="percent" type="number" pInputText [(ngModel)]="taxRate.percent" required />
            </div>
        </div>
        <ng-template pTemplate="footer">
            <div class="p-d-flex">
                <p-button label="{{'general.action.delete' | translate}}" icon="pi pi-trash" styleClass="p-button-danger"
                    (click)="delete()" *ngIf="taxRate.id!=null"></p-button>
                <div class="flex-grow-1"></div>
                <p-button label="{{'general.action.cancel' | translate}}" icon="pi pi-times" styleClass="p-button-secondary p-button-text"
                    (click)="cancel()"></p-button>
                <p-button label="{{'general.action.save' | translate}}" icon="pi pi-check" styleClass="p-ml-2" (click)="save()"></p-button>
            </div>
        </ng-template>
    </p-card>
</form>
