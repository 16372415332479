import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MailMarketingListComponent } from './mail-marketing-list/mail-marketing-list.component';

const routes: Routes = [
    { path: "mailMarketing", component: MailMarketingListComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MailMarketingRoutingModule { }
