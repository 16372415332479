import { Injectable, EventEmitter } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { LoginService } from './login.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {

  onStateChange: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    private router: Router,
    private loginService: LoginService
  ) {
    //var savedToken = localStorage.getItem("token");
    //if (savedToken != null) loginService.setLoggedState(true);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.onStateChange.emit(state.url); //hacemos un emit de la ruta.

    if (this.loginService.isLoggedIn()) {
      // autorizado
      return true;
    }

    //no autorizado, llevamos a login
    this.loginService.setLoggedState(false);
    this.router.navigate(['login'], { queryParams: { returnUrl: state.url } });
    return false;
  }
}
