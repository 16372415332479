import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfigurationService } from '../configuration/configuration.service';
import { EntitiesService } from '../configuration/entities.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-preload',
  templateUrl: './preload.component.html',
  styleUrls: ['./preload.component.scss']
})

export class PreloadComponent {

  constructor(
    private entitiesService: EntitiesService,
    private route: ActivatedRoute,
    private router: Router,
    private configurationService: ConfigurationService
  ) {
    this.loadCustomCss();
    this.loadCompanyConfiguration();
  }

  loadCustomCss() {
    this.configurationService.css().subscribe(
      data => {
        var head = document.getElementsByTagName("head")[0];
        var style = document.createElement("style");
        style.textContent = data;
        head.appendChild(style);
        this.preloadData();
      },
      error => {
        console.error(error);
      }
    );
  }

  loadCompanyConfiguration() {
    this.configurationService.configuration().subscribe(
      data => {
        //console.log(data);
        environment.companyConfiguration = data;
      },
      error => {
        console.error(error);
      }
    );
  }

  preloadData() {
    //precargamos entidades
    this.entitiesService.initCache((success) => {
      this.goToUrl();
    });
  }


  goToUrl() {
    this.route.queryParams
      .subscribe(params => {
        if (params.returnUrl.indexOf("/preload") == 0) {
          this.router.navigateByUrl("/home");
        } else {
          this.router.navigateByUrl(params.returnUrl);
        }
      }
    );
  }

}


