import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { MessageService } from 'primeng/api';
import { FormGroup, NgForm } from '@angular/forms';
import { CoreFormService } from 'src/app/core/forms/core-forms.service';
import { CoreDialogService } from 'src/app/core/dialogs/core-dialog.service';
import { TranslateService } from '@ngx-translate/core';
import { CountriesService } from '../countries.service';

@Component({
  selector: 'app-countries-detail',
  templateUrl: './countries-detail.component.html',
  styleUrls: ['./countries-detail.component.scss']
})

export class CountriesDetailComponent implements OnInit {
  @ViewChild("formDetails") public formDetails: NgForm;
  public country: any = {
    iso_code_2: "",
    iso_code_3: "",
    name: ""
  };

  constructor(
    private countriesService: CountriesService,
    private route: ActivatedRoute, private router: Router,
    private messageService: MessageService,
    private coreFormService: CoreFormService,
    private coreDialogService: CoreDialogService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.loadData(params['id']);
    });
  }

  loadData(id: number) {
    if (id != 0) {
      this.countriesService.get(id).subscribe({
        next: (data: any) => {
          this.country = data;
        },
        error: (error: any) => {
          this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
        }
      });
    }
  }

  cancel() {
    this.router.navigate(['/configuration/countries']);
  }

  save() {
    if (this.coreFormService.validate(this.formDetails)) {
      this.countriesService.save(this.country.id, this.country).subscribe({
        next: (data: any) => {
          var _msg = (this.country.id != 0 && typeof this.country.id !== "undefined") ? "modificado" : "añadido";
          this.messageService.add({ closable: false, severity: 'success', summary: "País " + _msg + " correctamente" });
          this.router.navigate(['/configuration/countries']);
        },
        error: (error: any) => {
          this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
        }
      });
    }
  }

  delete() {
    this.coreDialogService.confirm({
      message: this.translateService.instant("component.country.delete_message", { name: this.country.name }),
      header: this.translateService.instant("general.confirmation_delete_title"),
      icon: 'pi pi-info-circle',
      accept: () => {
        this.countriesService.delete(this.country.id).subscribe({
          next: (data: any) => {
            this.messageService.add({ closable: false, severity: 'success', detail: this.translateService.instant("general.confirmation_delete") });
            this.router.navigate(['/configuration/countries']);
          },
          error: (error: any) => {
          this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
        }
        });
      },
      reject: () => {

      }
    });
  }

  isDuplied() {
    var errorMessage = "";
    if (this.country.iso_code_2.length != 2) {
      errorMessage += "El código ISO 2 debe tener 2 carácteres. \n";
      this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: this.translateService.instant("component.country.detail.iso_2_characters") });
    }
    if (this.country.iso_code_3.length != 3) {
      errorMessage += "El código ISO 3 debe tener 3 carácteres. \n";
      this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: this.translateService.instant("component.country.detail.iso_3_characters") });
    }
    if (this.country.name.length <= 0) {
      //errorMessage += "El nombre no puede estar vacío. \n";
      this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: this.translateService.instant("component.country.detail.name_not_empty") });
    }
    /*if(errorMessage){
      this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: errorMessage });
    }*/
    this.countriesService.isDuplied(this.country).subscribe(
      data => {
        if (data == true) {
          this.coreDialogService.notify({
            message: this.translateService.instant("component.country.detail.types_duplicates"),
            header: 'Error',
            icon: 'far exclamation-circle',
            color: 'var(--orange-500)',
            accept: () => { }
          });
        } else {
          this.save();
        }
      }
    );
  }
}
