import { Component, OnInit, ViewChild } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CoreDialogService } from 'src/app/core/dialogs/core-dialog.service';
import { ViewComponent } from '../../../core/view/view.component';
import { ZonesService } from '../zones.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-zones-detail',
  templateUrl: './zones-detail.component.html',
  styleUrls: ['./zones-detail.component.scss']
})
export class ZonesDetailComponent implements OnInit {

  @ViewChild("view") public view: ViewComponent;
  public zone: any = {};
  constructor(
    private zonesService: ZonesService,

    private config: DynamicDialogConfig,
    private messageService: MessageService,
    private dynamicDialogRef: DynamicDialogRef,
    private coreDialogService: CoreDialogService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.loadZones(this.config.data.id);
  }

  loadZones(id) {
    if (id) {
      this.zonesService.get(id).subscribe({
        next: (data: any) => {
          this.zone = data;
        },
        error: (error: any) => {
          this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
        }
      });
    }
  }

  save(event: any) {
    if (!event.valid) {
      this.messageService.add({ closable: false, severity: "error", detail: this.translateService.instant("general.form_incomplete") });
      return;
    }
    if (typeof this.zone.id === "undefined") {
      this.zonesService.add(this.zone).subscribe({
        next: (data: any) => {
          this.zone = data;
          this.loadZones(this.zone.id);
          this.messageService.add({ closable: false, severity: 'success', summary: this.translateService.instant("component.zones.detail.add_correct") });
          this.dynamicDialogRef.close();
        },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
          }
      });
    } else {
        this.zonesService.save(this.zone.id, this.zone).subscribe({
          next: (data: any) => {
            this.zonesService.add({ closable: false, severity: "success", detail: this.translateService.instant("component.zones.detail.save_correct") });
            this.dynamicDialogRef.close();
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
          }
        });
    }
  }
  cancel() {
    this.dynamicDialogRef.close();
  }
  delete() {
    this.coreDialogService.confirm({
      message: this.translateService.instant("component.zones.delete_message", { name: this.zone.name }),
      header: this.translateService.instant("general.confirmation_delete_title"),
      icon: "pi pi-info-circle",
      accept: () => {
        this.zonesService.delete(this.zone.id).subscribe({
          next: (data: any) => {
            this.messageService.add({ closable: false, severity: 'success', detail: this.translateService.instant("general.confirmation_delete") });
            this.dynamicDialogRef.close();
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        });
      },
      reject: () => {

      }
    })
  }
  isDuplied(zone, event) {
    if (!event.valid) {
      this.messageService.add({ closable: false, severity: "error", detail: this.translateService.instant("general.form_incomplete") });
      return;
    }
      this.zonesService.isDuplied(zone).subscribe({
        next: (data: any) => {
          if (data == true) {
            this.coreDialogService.notify({
              message: this.translateService.instant("component.zones.detail.zones_duplicates"),
              header: 'Error',
              icon: 'far exclamation-circle',
              color: 'var(--orange-500)',
              accept: () => { }
            });
          } else {
            this.save(event);
          }
        }
      });
  }
  onEvent(event) {
    if (event.component == "zone.edit.general") {
      if (event.event == "action" && event.action.name == "delete") this.delete();
      if (event.event == "action" && event.action.name == "cancel") this.cancel();
      if (event.event == "action" && event.action.name == "save") this.isDuplied(this.zone, event);
    }
    if (event.component == "view" && event.event == "entity-loaded") {
      this.config.header = typeof (this.zone.id) !== "undefined" ?
        this.translateService.instant("component.zones.detail.detail_entity", { entity_name: this.view.getEntityBaseName() })
        :
        this.translateService.instant("component.zones.detail.new_entity", { entity_name: this.view.getEntityBaseName() });
    }
  }
}
