<p-tabView>

  <p-tabPanel header="{{'component.entity.detail.data.title' | translate}}">
    <div class="p-grid">
      <div class="p-col-3 p-field">
        <label for="name">{{'entity.entity_fields.name' | translate}}</label>
        <input name="name" type="text" pInputText [(ngModel)]="entity.name" required />
      </div>
      <div class="p-col-4 p-field">
        <label for="name_plural">{{'entity.entity_fields.name_plural' | translate}}</label>
        <input name="name_plural" type="text" pInputText [(ngModel)]="entity.name_plural" required />
      </div>
      <div class="p-col-3 p-field">
        <label for="shortname">{{'entity.entity_fields.shortname' | translate}}</label>
        <input name="shortname" type="text" pInputText [(ngModel)]="entity.shortname" />
      </div>
      <div class="p-col-2 p-field">
        <p-button label="{{'general.action.save' | translate}}" icon="pi pi-check" styleClass="p-mt-3"
          (click)="save()"></p-button>
      </div>
    </div>
    <p-card styleClass="p-card-content-fit">

      <ng-template pTemplate="header">
        <div class="p-card-title">
          {{'component.entity.detail.additional_fields' | translate}}
          <div class="p-card-title-actions" *ngIf="create_fields && entity.allow_metadata">
            <a (click)="edit(null)">
              <fa-icon [icon]="['fas', 'plus-circle']"></fa-icon>{{'component.entity.detail.new_field' | translate}}
            </a>
          </div>
        </div>
      </ng-template>
      <p-table #grid [value]="entitiesFields" [lazy]="true" (onLazyLoad)="loadData($event)" [loading]="loading"
        [paginator]="true" [rows]="25" [totalRecords]="totalRecords" [showCurrentPageReport]="true"
        currentPageReportTemplate="{{ 'general.resumen_table_records' | translate: { first: first, last: last, totalRecords: totalRecords } }}"
        [rowsPerPageOptions]="[25,50, 100]" [reorderableColumns]="true" (onRowReorder)="onRowReorder($event)">
        <ng-template pTemplate="header">
          <tr>
            <th width="35"></th>
            <th width="50" pSortableColumn="position">{{'component.entity.detail.position' | translate}}</th>
            <th pSortableColumn="model_property" width="150">{{'component.entity.detail.property' | translate}}</th>
            <th pSortableColumn="description">{{'component.entity.detail.name' | translate}}</th>
            <th width="100"></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-entityField let-index="rowIndex">
          <tr (dblclick)="edit(entityField)" [pReorderableRow]="index"
            [ngStyle]="{'background-color':entityField.is_disabled ? '#e7e7e7' : 'transparent'}">
            <td width="35">
              <span class="pi pi-bars" pReorderableRowHandle></span>
            </td>
            <td width="50">{{entityField.position}}</td>
            <td>{{entityField.model_property}}</td>
            <td><span *ngIf="entityField.is_disabled"><b>({{'component.entity.detail.inactive' |
                  translate}})&nbsp;</b></span>{{entityField.description}}</td>
            <td class="grid-actions">
              <a (click)="edit(entityField)">
                <fa-icon [icon]="['far', 'edit']"></fa-icon>
              </a>
              <a (click)="delete(entityField)" *ngIf="!entityField.is_base_field" class="color-danger">
                <fa-icon [icon]="['far', 'trash-alt']"></fa-icon>
              </a>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </p-card>
  </p-tabPanel>

  <p-tabPanel header="{{'component.entity.detail.configuration' | translate}}">
    <form #formDetails="ngForm">
      <p-card header="{{'component.entity.detail.detail' | translate}}">
        <div class="p-grid">
          <ngx-monaco-editor [options]="editorOptions" [(ngModel)]="modelMonaco" [ngModelOptions]="{standalone: true}"
            (init)="editorInit($event)" (ngModelChange)="onMonacoChange($event)" style="height: 500px; width: 100%;">
          </ngx-monaco-editor>
        </div>
        <ng-template pTemplate="footer">
          <div class="p-d-flex">
            <p-button label="{{'general.action.cancel' | translate}}" icon="pi pi-times"
              styleClass="p-button-secondary p-button-text" (click)="cancel()"></p-button>
            <p-button label="{{'general.action.save' | translate}}" icon="pi pi-check" styleClass="p-ml-2"
              (click)="save()"></p-button>
          </div>
        </ng-template>
      </p-card>

    </form>

  </p-tabPanel>
  <p-tabPanel header="{{'component.entity.detail.duplicity' | translate}}">
    <div class="p-grid">
      <div *ngFor="let entityField of entitiesFieldsForDuplicities" class="p-col-3">
        <p-checkbox name="duplicities" value="{{entityField.id}}" label="{{entityField.description}}"
          [(ngModel)]="selectedEntities"></p-checkbox>
      </div>
    </div>
    <ng-template pTemplate="footer">
      <div class="p-d-flex" style="text-align:right;">
        <p-button label="{{'general.action.cancel' | translate}}" icon="pi pi-times"
          styleClass="p-button-secondary p-button-text" (click)="cancel()">
        </p-button>
        <p-button label="{{'general.action.save' | translate}}" icon="pi pi-check" styleClass="p-ml-2"
          (click)="save()"></p-button>
      </div>
    </ng-template>
  </p-tabPanel>
</p-tabView>
<p-confirmDialog #cd [style]="{width: '50vw'}">
  <ng-template pTemplate="header">
    <h3>{{'general.confirmation_delete_title' | translate}}</h3>
  </ng-template>
  <ng-template pTemplate="footer">
    <button type="button" pButton icon="pi pi-times" label="{{'general.no' | translate}}"
      (click)="cd.reject()"></button>
    <button type="button" pButton icon="pi pi-check" label="{{'general.yes' | translate}}"
      (click)="cd.accept()"></button>
  </ng-template>
</p-confirmDialog>