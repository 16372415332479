import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { LoaderService } from './loader.service';
import { LoginService } from '../../login/login.service';
import { MessageService } from 'primeng/api';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {

  private requestPendings: number = 0;
  private is_connection_error:boolean = false;

  constructor(
    private loaderService: LoaderService, 
    private loginService: LoginService,
    private messageService: MessageService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let isSkipedRequest = this.loaderService.isRequestSkiped(request);
    if (request.method!='GET' || !isSkipedRequest) this.requestPendings++;
    this.loaderService.onPendingRequest.emit({ pendingRequests: this.requestPendings, loadingPercent: 0 });
    var  clonedRequest = request;

    if (request.url != "https://auth.getbee.io/apiauth") {
      //Se discrimina si es la autenticación del bee porque fallaba el cors, alguna de estas propiedades de la petición no le gustaba.
      var token = localStorage.getItem("token");
      let requestHeaders = request.headers;
      let code = this.loginService.get("code") ?? '';
      let language = this.loginService.get("language") ?? 'es';

      clonedRequest = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token).set('code', code).set('language', language) });
      
    }
   

    return next.handle(clonedRequest).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          if (request.method != 'GET' || !isSkipedRequest) this.requestPendings--;
          this.loaderService.removeSkipRequest(request);
          this.loaderService.onPendingRequest.emit({ pendingRequests: this.requestPendings, loadingPercent: 0 });
        }
        return event;
      }),
      catchError((err) => {
        console.error(err)
        this.loaderService.removeSkipRequest(request);
        if (request.method != 'GET' || !isSkipedRequest) this.requestPendings--;
        this.loaderService.onPendingRequest.emit({ pendingRequests: this.requestPendings, loadingPercent: 0 });
        if (err.status == 401) {
          console.log("Request unauthorized. Logging out.", request.urlWithParams.toString());
          this.loginService.logout();
        } else if (err.status == 404) {
          console.log("Not found. Logging out.", request.urlWithParams.toString());
          this.loginService.notFound();
        } else if (err.status == 0) {
          console.log("Not found. Logging out.", request.urlWithParams.toString());
          if(!this.is_connection_error){
            this.messageService.add({ severity: 'error', detail: "No se pudo conectar con el servidor. Por favor, revise su conexión e intentelo nuevamente." });
            setTimeout(() => { this.is_connection_error = false; }, 1000);
          }
          this.is_connection_error = true;
        } else {
          return throwError(err);
        }
      })
    );

  }
}
