<p-card styleClass="p-card-content-fit">
    <!--Añadir Contacto-->
    <ng-template pTemplate="header">
      <div class="p-card-title">
        {{'meeting.entity.name_plural' | translate}}
        <div class="p-card-title-actions">
          <a (click)="view(null)">
            <fa-icon [icon]="['fas', 'plus-circle']"></fa-icon>{{'component.organizations.meetings.new_meeting' | translate}}
          </a>
        </div>
      </div>
    </ng-template>
    <div class="p-grid">
        <!--Tabla de contacts-->
        <div class="p-col-12">
            <p-table #grid [value]="meetings" [lazy]="true" (onLazyLoad)="loadData($event)" [loading]="loading"
                [paginator]="true" [rows]="10" [totalRecords]="totalRecords" [showCurrentPageReport]="true"
                currentPageReportTemplate="{{ 'general.resumen_table_records' | translate: { first: first, last: last, totalRecords: totalRecords } }}"
                [rowsPerPageOptions]="[5,10,25,50]" paginatorDropdownAppendTo="body">
                <ng-template pTemplate="header">
                    <tr>
                      <th pSortableColumn="subject">{{'meeting.entity_fields.subject' | translate}}</th>
                      <th pSortableColumn="from">{{'meeting.entity_fields.from' | translate}}</th>
                      <th pSortableColumn="to">{{'meeting.entity_fields.to' | translate}}</th>
                        <th width="100"></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-meeting>
                    <tr (dblclick)="view(meeting)">
                        <td>{{meeting.subject}}</td>
                        <td>{{meeting.from | date:'short'}}</td>
                        <td>{{meeting.to | date:'short'}}</td>
                        <td class="grid-actions">
                            <a (click)="view(meeting)">
                                <fa-icon [icon]="['far', 'edit']"></fa-icon>
                            </a>
                            <a (click)="delete(meeting)" class="color-danger">
                                <fa-icon [icon]="['far', 'trash-alt']"></fa-icon>
                            </a>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</p-card>
