<ul class="breadcrumb">
  <li><a [routerLink]="['/home']"><span class="color-soft-grey-axial">{{'general.breadcrumb.you_are' | translate}}
      </span>{{'general.breadcrumb.home' | translate}}</a></li>
  <li><i class="pi pi-angle-right"></i></li>
  <li><a [routerLink]="['/configuration/entities']">{{'general.configuration'|translate}}</a></li>
  <li><i class="pi pi-angle-right"></i></li>
  <li *ngIf="view"><a [routerLink]="['/configuration/templates']">{{view.getEntityBaseName(true)}}</a></li>
</ul>

<!--p-card>
  <ng-template pTemplate="header">
    <div class="p-card-title">
      Filtros
      <div class="p-card-title-actions">
        <a (click)="search()"><i class="fas fa-search"></i>&nbsp;Buscar </a>
      </div>
    </div>
  </ng-template>
  <div class="p-grid">
    <div class="p-col-6 p-field">
      <label for="template_type">Tipo de plantilla</label>
      <p-dropdown [options]="template_types" optionLabel="name" optionValue="id" [(ngModel)]="templatesFilters.template_type_id"
        placeholder="{{'general.select'|translate}}"></p-dropdown>
    </div>
    <div class="p-col-6 p-field">
      <label for="entity_id">Tipo de datos</label>
      <p-dropdown [options]="entities" optionLabel="name" optionValue="id" [(ngModel)]="templatesFilters.entity_id"
        placeholder="{{'general.select'|translate}}"></p-dropdown>
    </div>
    <div class="p-col-6 p-field">
      <label for="name">Nombre</label>
      <input id="name" type="text" pInputText [(ngModel)]="templatesFilters.name" />
    </div>
    <div class="p-col-6 p-field">
      <label for="subject">Asunto</label>
      <input id="subject" type="text" pInputText [(ngModel)]="templatesFilters.subject" />
    </div>
  </div>
  <div class="p-grid">
    <div class="p-col p-ai-right">
      <p-button label="Limpiar filtros" (click)="clearFilters()" styleClass="p-button-secondary"></p-button>
      <p-button label="Buscar" (click)="search()" class="p-ml-2"></p-button>
    </div>
  </div>
</p-card>
<br-->
<!--p-card styleClass="p-card-content-fit">
  <ng-template pTemplate="header">
    <div class="p-card-title">
      Plantillas
      <div class="p-card-title-actions">
        <a (click)="edit({ id: 0 })">
          <fa-icon [icon]="['fas', 'plus-circle']"></fa-icon> Nueva plantilla
        </a>
      </div>
    </div>
  </ng-template>
  <p-table #grid [value]="templates" [lazy]="true" (onLazyLoad)="loadData($event)" [loading]="loading"
    [paginator]="true" [rows]="10" [totalRecords]="totalRecords" [showCurrentPageReport]="true"
    currentPageReportTemplate="{{ 'general.resumen_table_records' | translate: { first: first, last: last, totalRecords: totalRecords } }}"
    [rowsPerPageOptions]="[5,10,25,50]" paginatorDropdownAppendTo="body">
    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="id" width="100">ID</th>
        <th pSortableColumn="name">Nombre</th>
        <th pSortableColumn="subject">Asunto</th>
        <th pSortableColumn="template_type_id">Tipo de plantilla</th>
        <th pSortableColumn="entity_id">Tipo de datos</th>
        <th pSortableColumn="createdBy">Creado por</th>
        <th pSortableColumn="updatedBy">Modificado por</th>
        <th pSortableColumn="updated_at">Últ. Modificación</th>
        <th pSortableColumn="listZones">Zonas</th>
        <th pSortableColumn="listBusiness">Sociedades</th>
        <th width="100"></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-template>
      <tr (dblclick)="edit(template)">
        <td>{{template.id}}</td>
        <td>{{template.name}}</td>
        <td>{{template.subject}}</td>
        <td>{{template.templatetype?.name}}</td>
        <td>{{template.entity?.name}}</td>
        <td>{{template.createdBy?.name}}</td>
        <td>{{template.updatedBy?.name}}</td>
        <td>{{template.updated_at | date: 'dd/MM/yyyy hh:mm'}}</td>
        <td [title]="template.listZones">{{ template.listZones.length > 10 ? (template.listZones | slice:0:10) + '...' :
          template.listZones }}</td>
        <td [title]="template.listBusiness">{{ template.listBusiness.length > 10 ? (template.listBusiness | slice:0:10)
          + '...' : template.listBusiness }}</td>
        <td class="grid-actions">
          <a (click)="edit(template)">
            <fa-icon [icon]="['far', 'edit']"></fa-icon>
          </a>
          <a (click)="delete(template)" class="color-danger">
            <fa-icon [icon]="['far', 'trash-alt']"></fa-icon>
          </a>
        </td>
      </tr>
    </ng-template>
  </p-table>
</p-card-->

<app-view #view [code]="'templates.list'" [model]="templatesFilters" (onEvent)="onEvent($event)"></app-view>


<!--<div id="bee-plugin-container" class="bee-plugin-container"></div>-->