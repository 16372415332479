import { Component, OnInit, ViewChild } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { CoreDialogService } from 'src/app/core/dialogs/core-dialog.service';
import { ViewComponent } from 'src/app/core/view/view.component';
import { ViewsService } from 'src/app/core/view/views.service';
import { OrganizationsTypesService } from 'src/app/organizations/organizations-types.service';
import { OrganizationsTypesDetailComponent } from '../organizations-types-detail/organizations-types-detail.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-organizations-types-list',
  templateUrl: './organizations-types-list.component.html',
  styleUrls: ['./organizations-types-list.component.scss']
})
export class OrganizationsTypesListComponent implements OnInit {
  @ViewChild("view") public view: ViewComponent;
  public organizationsFilters: any = {};
  public organizationsTypes: any[];
  private lastGridEvent: any;
  public totalRecords: number;
  public loading: boolean = false;

  constructor(
    private organizationsTypesService: OrganizationsTypesService,
    private coreDialogService: CoreDialogService,
    private dialogService: DialogService,
    private viewsService: ViewsService,
    private messageService: MessageService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
  }

  loadData($event) {
    this.lastGridEvent = $event;
    Promise.resolve(null).then(() => this.loading = true);
    var params = {
      _page: $event.first,
      _pageSize: $event.rows,
      _sortBy: $event.sortField ? $event.sortField : "",
      _sortDirection: $event.sortOrder > 0 ? "asc" : "desc"
    }
    //params = Object.assign(params, this.organizationsFilters);
    this.organizationsTypesService.all({}).subscribe({
      next: (data: any) => {
        this.totalRecords = data.totalRows;
        this.organizationsTypes = data.rows;
        Promise.resolve(null).then(() => this.loading = false);
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
        Promise.resolve(null).then(() => this.loading = false);
      }
    });
  }

  edit(organizationType){
    const ref = this.dialogService.open(OrganizationsTypesDetailComponent,{
      data:{
        id: organizationType!=null?organizationType.id:null
      },
      width: '30%'
    });
    ref.onClose.subscribe((data:any) =>{
      if (typeof (this.view) !== "undefined") this.viewsService.changeView(this.view.code);
      this.loadData(this.lastGridEvent);
    })
  }
  delete(organizationType){
    this.coreDialogService.confirm({
      message: this.translateService.instant("component.organizationType.delete_message", { name: organizationType.name }),
      header: this.translateService.instant("general.confirmation_delete_title"),
      icon: 'pi pi-info-circle',
      accept:()=>{
        this.organizationsTypesService.delete(organizationType.id).subscribe({
          next: (data: any) => {
            this.loadData(this.lastGridEvent);
            this.messageService.add({ closable: false, severity: "success", detail: 'Eliminado correctamente.' });

          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
          }
        });
      },
      reject:()=>{
        
      }
    })
  }
}
