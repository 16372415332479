import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { TrackingService } from 'src/app/tracking/tracking.service';
import { EntitiesFieldsService } from '../entities-fields.service';
import { EntitiesService } from '../entities.service';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { MessageService } from 'primeng/api';
import { ComponentService } from '../../core/view/component.service';
import { ReportsService } from '../../reports/reports.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-tracking-logs',
  templateUrl: './tracking-logs.component.html',
  styleUrls: ['./tracking-logs.component.scss']
})
export class TrackingLogsComponent implements OnInit {

  public entity_id: number;
  public entity: any;
  public entities = [{ name: "Ninguna", id: 0 }];
  public selectedEntitiesFields = [];
  public entitiesFields = [{ name: "Ninguna", id: 0 }];
  public element: any;
  public element_id: number;
  public elements = [{ name: "Ninguno", id: 0 }];
  public entitiesToTable: any[] = [];

  public logs: any;
  public columns: any;

  public totalRecords;
  public first;
  public last;
  public totalRecordsLogs;
  public loadingLogs = false;
  public temporalCombos = {};

  constructor(
    private entitiesService: EntitiesService,
    private entitiesFieldsService: EntitiesFieldsService,
    private trackingService: TrackingService,
    private messageService: MessageService,
    private componentService: ComponentService,
    private reportsService: ReportsService,
    private translateService: TranslateService

  ) { }

  ngOnInit(): void {
    this.loadEntities();

  }
  loadEntities() {
    this.entitiesService.all({ for_tracking: true }).subscribe({
      next: (data:any) => {
        this.entities = data.rows;
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
      }
    });
  }
  getSelectableEntityFields(filterIfAllreadyExist: boolean = false, onlyNumbers: boolean = false) {
    if (this.entity?.fields != null) {
      //obtener entidades
      let entities = [];
      let visibleEntityFields = [];
      this.entity.fields.forEach(field => {
        if (this.componentService.isVisibleFieldCustom({ entityField: field }, null)) {
          visibleEntityFields.push(field);
          if (entities.find(m => m.name == field.entity_name) == null) {
            entities.push({ code: field.entity_code, name: field.entity_name });
          }
        }
      });

      let entityFields = [];
      //entityFields = this.entity.fields.filter(m => m.use_for_report);
      entityFields = visibleEntityFields.filter(m => m.use_for_report);

      if (filterIfAllreadyExist) {
        //filtramos que no aparezcan si ya están añadidos a la tabla.
        /*if (typeof (this.report.metadata.report.columns) !== "undefined") {
          entityFields = visibleEntityFields.filter(m => m.use_for_report).filter(el => this.report.metadata?.report.columns.map(m => m.entity_field_id).indexOf(el.id) === -1);
        }*/
      }

      let data = [];
      entities.forEach((entity) => {
        data.push({
          entity: entity.name,
          fields: entityFields.filter(m => m.entity_code == entity.code)
        });
      });
      return data;
    } else {
      return [];
    }
  }


  checkOption(id) {
    if (this.selectedEntitiesFields != null && this.selectedEntitiesFields.length > 0 && this.selectedEntitiesFields.indexOf(id) > -1) {
      //lo quitamos
      this.selectedEntitiesFields = this.selectedEntitiesFields.filter(obj => obj !== id);
    } else {
      this.selectedEntitiesFields.push(id);
    }
  }


  loadEntitiesFields() {
    this.selectedEntitiesFields = [];
    this.entitiesFieldsService.all({ entity_id: this.entity_id, is_base_field: true }).subscribe({
      next: (data: any) => {
        this.entity.fields = data.rows;
        this.entitiesFields = data.rows;
        this.entitiesToTable = this.getSelectableEntityFields(true);

        this.loadTable({});
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
      }
    });
    this.trackingService.allElements({ entity_id: this.entity_id }).subscribe({
      next: (data: any) => {
        this.elements = data.values;
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
      }
    });
  }
  getComboOptions(field) {
    var _a;
    if (((_a = field.configuration.options) === null || _a === void 0 ? void 0 : _a.type) == "api") {
      var url = "/api/" + field.configuration.options.url;
      this.reportsService.customVoidCombos(url, {}).subscribe({
        next: (data: any) => {
          this.temporalCombos[field.id] = data.rows;
          if (field.configuration.options.url == "users") {
            //Añadimor el usuario actual
            this.temporalCombos[field.id].push({ id: -1, name: this.translateService.instant("general.actual_user") });
          }
          if (field.configuration.options.url == "transactionsStatus") {
            let transaction_type_id = (field.entity_code == "oportunity" ? 1 : 2);
            this.temporalCombos[field.id] = this.temporalCombos[field.id].filter(function (e) { return e.transaction_type_id == transaction_type_id; });
          }
          //
        },
        error: (error: any) => {
          this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
        }
      });
    }
    else {
      this.temporalCombos[field.id] = field.configuration.options.items;
    }
  }

  loadEntitiesFieldsCUSTOM() {
    this.reportsService.fields(this.entity_id).subscribe({
      next: (data: any) => {

        this.entity = data;
        this.entity.fields.forEach(field => {

          //cargamos en un temporal todos los datos de  los posibles combos
          if (field.control_type == "dropdown") {
            if (field.data_type == "list") {
              //los datos están aqui
              //this.temporalCombos[field.id] = field.configuration.options.items;
              this.getComboOptions(field);
            }
            else if (field.data_type == "number") {
              //tenemos que hacer petición para obtener la lista de datos.
              if (field.configuration.options?.type == "api") {
                var url = "/api/" + field.configuration.options.url;
                this.reportsService.customVoidCombos(url, {}).subscribe({
                  next: (data: any) => {
                    this.temporalCombos[field.id] = data.rows;
                    if (field.configuration.options.url == "users") {
                      //Añadimor el usuario actual
                      this.temporalCombos[field.id].push({ id: -1, name: this.translateService.instant("general.actual_user") });
                    }
                  },
                  error: (error: any) => {
                    this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
                  }
                });
              }
            }
          }
        });
        this.entitiesToTable = this.getSelectableEntityFields(true);

        this.trackingService.allElements({ entity_id: this.entity_id }).subscribe({
          next: (data: any) => {
            this.elements = data.values;
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        });

        this.loadTable({});

      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
      }

    });
  }
  getSelectedItems() {
    return "cilo";
  }

  loadTable(params: any) {
    if (this.entity_id != null) {
      this.loadingLogs = true;

      params.entity_id = this.entity_id
      if (this.element_id != null) params = Object.assign(params, { entity_pk_id: this.element_id });
      if (this.selectedEntitiesFields != null && this.selectedEntitiesFields.length > 0) params = Object.assign(params, { entitiesFieldsIds: this.selectedEntitiesFields });
      this.trackingService.all2(params).subscribe(
        data => {
          this.logs = data.values;
          this.columns = data.columns;
          this.loadingLogs = false;
          //this.first = params._page * params._pageSize;
          //this.last = params._pageSize;
          
          this.totalRecordsLogs = data.total[0].total;
        }
      );

    }
  }

  exportExcel() {
    var aux = [];
    //For que recorre cada row y column y devuelve un objeto que será exportado a excel:       
    this.logs.forEach(row => {
      var obj = {};
      this.columns.forEach(label => {
        obj[label.description] = row[label.model_property];
      });
      aux.push(obj);
    });
    //Promise.resolve(null).then(() => this.loading = false);
    const worksheet = XLSX.utils.json_to_sheet(aux);
    const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, "data");
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  customSort(event) {
    const field = (typeof event.sortField != "undefined" ? event.sortField : "date"); // Campo por el que se está ordenando
    const order = event.sortOrder == 1 ? 'ASC' : 'DESC'; // Dirección del orden

    this.loadTable({ _sortBy: field, _sortDirection: order, _page: (event.first / event.rows)+1, _pageSize: event.rows });
    /*
    // Copia del array original:
    const sortedLogs = [...this.logs];

    // Función de comparación para el orden ascendente:
    const ascSort = (a, b) => {
      const valueA = this.canBeParsedAsDate(a[field]) ? new Date(a[field]) : a[field];
      const valueB = this.canBeParsedAsDate(b[field]) ? new Date(b[field]) : b[field];

      if (valueA < valueB) return -1;
      if (valueA > valueB) return 1;
      return 0;
    };

    // Función de comparación para el orden descendente:
    const descSort = (a, b) => {
      const valueA = this.canBeParsedAsDate(a[field]) ? new Date(a[field]) : a[field];
      const valueB = this.canBeParsedAsDate(b[field]) ? new Date(b[field]) : b[field];

      if (valueA < valueB) return 1;
      if (valueA > valueB) return -1;
      return 0;
    };

    // Ordenar el array en función del campo y la dirección del orden:
    sortedLogs.sort(order === 1 ? ascSort : descSort);

    // Actualizar los logs ordenados
    this.logs = sortedLogs;*/
  }


  canBeParsedAsDate(value) {
    return !isNaN(Date.parse(value));
  }

}
