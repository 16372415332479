import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TransactionsDetailComponent } from './transactions-detail/transactions-detail.component';
import { TransactionsListComponent } from './transactions-list/transactions-list.component';

const routes: Routes = [
  { path: "transactions", component: TransactionsListComponent},
  { path: "transactions/:transaction_type_id", component: TransactionsListComponent },
  { path: "transactions/:transaction_type_id/:id", component: TransactionsDetailComponent },
  { path: "transactions/:transaction_type_id/:contact_id/:organization_id/:from_task_id/:id", component: TransactionsDetailComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TransactionsRoutingModule { }
