import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { UsersService } from 'src/app/users/users.service';
import { OrganizationsService } from '../organizations.service';
import { CoreDialogService } from 'src/app/core/dialogs/core-dialog.service';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';
import { CountriesService } from '../../configuration/countries/countries.service';

@Component({
  selector: 'app-organizations-detail',
  templateUrl: './organizations-detail.component.html',
  styleUrls: ['./organizations-detail.component.scss']
})
export class OrganizationsDetailComponent implements OnInit {
  public organization: any = {};
  public countries: any[];
  public responsibles: any[];
  

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private organizationsService: OrganizationsService,
    private countriesService: CountriesService,
    private messageService: MessageService,
    private usersService: UsersService,
    private coreDialogService: CoreDialogService,
    private config: DynamicDialogConfig,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {      
      this.loadData(params['id']);
    });
  }

  loadData(id: number) {
    this.loadCountries();
    this.loadResponsibles();
    if (id != 0) {
      this.organizationsService.get(id).subscribe({
        next: (data: any) => {
          this.organization = data;
        },
        error: (error: any) => {
          this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
        }
      });
    }
  }

  loadCountries() {
    this.countriesService.all({}).subscribe({
      next: (data: any) => {
        this.countries = data.rows;
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
      }
   });
  }

  loadResponsibles() {
    this.usersService.all({}).subscribe({
      next: (data: any) => {
        this.responsibles = data.rows;
        this.responsibles.unshift({ name: 'Ninguno', id: null });
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
      }
    });
  }

  cancel() {
    this.router.navigate(["/organizations", this.organization.id]);
  }

  save() {
    if (this.organization.email != null) this.organization.email = this.organization.email.trim();
    if (this.organization.email != null && this.organization.email.length > 0 && !this.validateEmail(this.organization.email)) {
      this.messageService.add({ closable: false, severity: "error", detail: this.translateService.instant("component.organizations.detail.invalid_mail") });
      return;
    }
    if(this.organization.vat_country_id == null) this.organization.vatCountry = null;
    if(this.organization.organization_type_id == null) this.organization.organizationType = null;
    if (this.organization.country_id == null) this.organization.country = null;
    if (this.organization.origin_id == null) this.organization.origin = null;
    if (this.organization.sector_id == null) this.organization.sector = null;
    if (this.organization.responsible_2_id == null) this.organization.responsible_2 = null;

    if (typeof this.organization.id === "undefined") {
      this.organizationsService.add(this.organization).subscribe({
        next: (data: any) => {
          this.messageService.add({ closable: false, severity: 'success', summary: this.translateService.instant("component.organizations.detail.add_organization_correct") });
          this.router.navigate(['/organizations']);
        },
        error: (error: any) => {
          this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
        }
      });
    } else {
      this.organizationsService.save(this.organization.id, this.organization).subscribe({
        next: (data: any) => {
          this.messageService.add({ closable: false, severity: "success", detail: this.translateService.instant("component.organizations.detail.save_organization_correct") });
          this.router.navigate(["/organizations"]);
        },
        error: (error: any) => {
          console.log(error);
          this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
        }
      });
    }    
  }

  delete() {
    this.coreDialogService.confirm({
      message: this.translateService.instant("component.organizations.delete_message", { name: this.organization.name }),
      header: this.translateService.instant("general.confirmation_delete_title"),
      icon: "pi pi-info-circle",
      accept: () => {
        this.organizationsService.delete(this.organization.id).subscribe({
          next: (data: any) => {
            this.messageService.add({ closable: false, severity: 'success', detail: this.translateService.instant("general.confirmation_delete") });
            this.router.navigate(['/organizations']);
          },
          error: (error:any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        })
      },
      reject:()=>{}
    })
  }

  validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }
}
