<div class="page-title">
  {{'component.mailMarketing.entity' | translate}}
</div>
<ul class="breadcrumb">
  <li>
    <a [routerLink]="['/home']">
      <span class="color-soft-grey-axial">
        {{'general.breadcrumb.you_are' | translate}}
      </span>{{'general.breadcrumb.home' | translate}}
    </a>
  </li>
  <li><i class="pi pi-angle-right"></i></li>
  <li><a [routerLink]="['/mailMarketing']">{{'component.mailMarketing.entity' | translate}}</a></li>
</ul>
<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000"></p-confirmDialog>

<!--<app-view #view [code]="'countries.list'" [model]="countriesFilters" (onEvent)="onEvent($event)"></app-view>-->

<div class="toolbar-panel">
  <a class="no-mobile p-button p-button-secondary filters" [class.p-button-badge-exclamation]="hasFilterValue" (click)="toggleFilter()" *ngIf="has_filter_panel">
    <i class="fas fa-filter" severity="warning"></i>&nbsp;{{'general.action.filters' | translate}}
  </a>
  <a class="mobile p-button p-button-secondary filters" (click)="toggleFilterMobile()" *ngIf="has_filter_panel">
    <i class="fas fa-filter" severity="warning"></i>
    &nbsp;{{'general.action.filters' | translate}}
  </a>
</div>

  <!--
  <div class="p-md-12">
    <p-card>
      <ng-template pTemplate="header">
        <div class="p-card-title">
          {{'general.filter.title' | translate}}
          <div class="p-card-title-actions">
            <a (click)="clearFilters()">{{'general.filter.clean' | translate}}</a>
            <a (click)="search()"><i class="fas fa-search"></i>&nbsp;{{'general.filter.search' | translate}}</a>
          </div>
        </div>
      </ng-template>
      <div class="p-grid">
        <div class="p-col p-field">
          <label for="subject">{{'notificationsEmails.entity_fields.subject' | translate}}</label>
          <input id="subject" type="text" pInputText [(ngModel)]="filters.subject" />
        </div>
        <div class="p-col-4 p-field">
          <label for="state">{{'notificationsEmails.entity_fields.status' | translate}}</label>
          <p-dropdown id="state" [(ngModel)]="filters.status" [options]="status" optionValue="value" optionLabel="label"
                      placeholder="{{'component.mailMarketing.detail.select_status' | translate}}"
                      [showClear]="true"></p-dropdown>
        </div>
      </div>
    </p-card>
  </div>
    -->
    <p-card styleClass="p-mt-4 p-card-content-fit">
      <!--<ng-template pTemplate="header" class="p-card-header">
        <div class="p-card-title">
          {{'component.mailMarketing.entity' | translate}}
        </div>
      </ng-template>-->
      <p-table #grid [lazy]="true" (onLazyLoad)="loadData($event)" [loading]="loading" [paginator]="true" [rows]="10"
               [totalRecords]="totalRecords" [showCurrentPageReport]="true"
               currentPageReportTemplate="Mostrando de {first} a {last} de un total de {totalRecords} entradas"
               [rowsPerPageOptions]="[5,10,25,50]" [value]="notificationsEmails" dataKey="name" responsiveLayout="scroll"
               paginatorDropdownAppendTo="body">
        <ng-template pTemplate="header">
          <tr>
            <th pSortableColumn="status" [width]="120">{{'notificationsEmails.entity_fields.status' | translate}}</th>
            <th pSortableColumn="type" [width]="120">{{'notificationsEmails.entity_fields.type' | translate}}</th>
            <th pSortableColumn="createdBy">{{'notificationsEmails.entity_fields.created_by' | translate}} </th>
            <th pSortableColumn="from">{{'notificationsEmails.entity_fields.from' | translate}} </th>
            <th pSortableColumn="date">{{'notificationsEmails.entity_fields.date' | translate}} </th>
            <th pSortableColumn="subject">{{'notificationsEmails.entity_fields.subject' | translate}}</th>
            <th>{{'notificationsEmails.entity_fields.sents_correct' | translate}}</th>
            <th>{{'notificationsEmails.entity_fields.errors' | translate}}</th>
            <th style="width: 280px"></th>
            <!--<th pSortableColumn="attachments">Asunto</th>-->
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-notificationsEmail let-expanded="expanded">
          <tr (dblclick)="viewMail(notificationsEmail)">
            <td [innerHTML]="getstatus(notificationsEmail.status)"></td>
            <td [innerHTML]="getType(notificationsEmail.type)" style="text-align: center;"></td>
            <td>{{notificationsEmail.createdBy?.name}}</td>
            <td>{{notificationsEmail.mailAccount?.name}}({{notificationsEmail.from}})</td>
            <td>{{notificationsEmail.date | date: 'dd/MM/yyyy HH:mm'}}</td>
            <td>{{notificationsEmail.subject}}</td>
            <td>{{notificationsEmail.sent_tos}}/{{notificationsEmail.totals_tos}}</td>
            <td *ngIf="notificationsEmail.error_tos > 0" style="color: red;">{{notificationsEmail.error_tos}}</td>
            <td *ngIf="notificationsEmail.error_tos == null || notificationsEmail.error_tos == 0">0</td>
            <td>
              <button pButton title="{{'general.action.delete' | translate}}" (click)="delete(notificationsEmail.id)"
                      type="button" icon="pi pi-times"
                      class="p-p-0 p-element p-button-danger p-button-text p-button p-component p-button-icon-only p-button-icon-only-sm "
                      style="height: 28px; margin-right: 5px;"></button>

              <p-button (click)="sendTest(notificationsEmail.id)"
                        styleClass="p-button-link p-button-secondary p-button-outlined"
                        label="{{'general.test' | translate}}"></p-button>

              <p-button *ngIf="notificationsEmail.status=='waiting'" styleClass="p-button-primary"
                        (click)="send(notificationsEmail.id)" class="p-ml-2" label="{{'general.send' | translate}}"></p-button>
              <p-button *ngIf="notificationsEmail.status=='sent'" (click)="duplicateAndSend(notificationsEmail.id)"
                        class="p-ml-2" styleClass="p-button-link p-button-secondary p-button-outlined">
                {{
'general.re_send' |
                translate
                }}
              </p-button>
              <p-button *ngIf="notificationsEmail.status=='programmed'" (click)="cancelMail(notificationsEmail.id)"
                        class="p-ml-2" styleClass="p-button-danger p-button-text">
                {{
'general.cancel_send' |
                translate
                }}
              </p-button>

              <button pButton *ngIf="notificationsEmail.type=='mailchimp' && notificationsEmail.external_id!=null"
                      (click)="mailchimpDashboard(notificationsEmail)"
                      class="p-ml-2 p-button-link p-button-secondary p-button-outlined p-button-icon-only">
                <fa-icon [icon]="['fas', 'chart-line']"></fa-icon>
              </button>
              <button pButton *ngIf="notificationsEmail.type=='mailjet' && notificationsEmail.external_id!=null"
                      (click)="mailjetDashboard(notificationsEmail)"
                      class="p-ml-2 p-button-link p-button-secondary p-button-outlined p-button-icon-only">
                <fa-icon [icon]="['fas', 'chart-line']"></fa-icon>
              </button>
            </td>
          </tr>
        </ng-template>

      </p-table>
    </p-card>


<div *ngIf="show_filter_panel || show_filter_panel_mobile">
  <p-sidebar [(visible)]="show_filter_panel" position="right" styleClass="p-sidebar-content-no-padding p-sidebar-content-no-footer">
    <ng-template pTemplate="header">{{'general.filter.title' | translate}}</ng-template>
    <div class="fixed-toolbar border-top border-bottom p-pb-3">
      <div class="p-d-flex p-pt-3 p-pl-3 p-pr-3">

        <a class="p-button p-button-primary p-button-text " label="Limpiar filtros" (click)="clearFilters()"><i class="pi pi-filter-slash"></i>&nbsp;{{'general.filter.clean' | translate}}</a>
        <div class="flex-grow-1"></div>
        <a class="p-button" (click)="loadFilterAndSearch()"> {{'general.filter.search' | translate}} </a>

      </div>
    </div>
    <div class="p-d-flex p-flex-column " style="scroll-behavior:auto">
      <div class="p-col p-field">
        <label for="subject">{{'notificationsEmails.entity_fields.subject' | translate}}</label>
        <input id="subject" type="text" pInputText [(ngModel)]="filters.subject" (keyup.enter)="loadFilterAndSearch()" />
      </div>
      <div class="p-col p-field">
        <label for="state">{{'notificationsEmails.entity_fields.status' | translate}}</label>
        <p-dropdown id="state" [(ngModel)]="filters.status" [options]="status" optionValue="value" optionLabel="label"
                    placeholder="{{'component.mailMarketing.detail.select_status' | translate}}"
                    [showClear]="true"></p-dropdown>
      </div>
    </div>
  </p-sidebar>
</div>
