import { Component, OnInit, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { TemplatesService } from '../templates.service';

@Component({
  selector: 'app-html-preview',
  templateUrl: './html-preview.component.html',
  styleUrls: ['./html-preview.component.scss']
})
export class HtmlPreviewComponent implements OnInit {

  public entity_id;
  public entity_pk_id;
  public temporal_template;
  public res = "";

  constructor(
    private config: DynamicDialogConfig,
    private templatesService: TemplatesService,
    private messageService: MessageService, public sanitizer: DomSanitizer,

  ) { }

  ngOnInit(): void {
    this.entity_id = this.config.data.entity_id;
    this.entity_pk_id = this.config.data.entity_pk_id;
    this.temporal_template = this.config.data.temporal_template;
    this.getHtml();
  }

  public getHtml() {
    this.templatesService.toHTML(this.temporal_template.id, this.entity_id, this.entity_pk_id, this.temporal_template).subscribe({
      next: (data: any) => {
        this.res = data.html;
        //this.res = this.sanitizer.sanitize(SecurityContext.HTML,data.html);
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
      }
    });
  };
}
