import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { CoreDialogService } from 'src/app/core/dialogs/core-dialog.service';
import { ViewComponentTableComponent } from 'src/app/core/view/component/table/view-component-table.component';
import { ViewComponent } from 'src/app/core/view/view.component';
import { ViewsService } from 'src/app/core/view/views.service';
import { OrganizationsDetailGeneralComponent } from 'src/app/organizations/organizations-detail/organizations-detail-general/organizations-detail-general.component';
import { TasksDetailComponent } from '../tasks-detail/tasks-detail.component';
import { TasksService } from '../tasks.service';
import { TranslateService } from '@ngx-translate/core';
import { UsersService } from '../../users/users.service';
import { LoginService } from '../../login/login.service';
import { SessionStorageService } from 'ngx-webstorage';

@Component({
  selector: 'app-tasks-list',
  templateUrl: './tasks-list.component.html',
  styleUrls: ['./tasks-list.component.scss']
})
export class TasksListComponent implements OnInit {
  @ViewChild("view") public view: ViewComponent;
  public tasksFilters: any = {
    //is_finished: false,
  };

  constructor(
    private tasksService: TasksService,
    private dialogService: DialogService,
    private messageService: MessageService,
    private coreDialogService: CoreDialogService,
    private router: Router,
    private viewsService: ViewsService,
    private translateService: TranslateService,
    private usersService: UsersService,
    private loginService: LoginService,
    private sessionStorage: SessionStorageService
  ) {

    this.tasksFilters.is_finished = false;
    if (localStorage.getItem("isSuperAdmin") === 'false') {//No es superadmin

      if (!this.loginService.hasPermission("TASKS_ALL_RELATED")) {
        this.tasksFilters.responsible_user_id = [parseInt(localStorage.getItem("userId"))];
      } else {
        //Si tiene este permiso se mostrará todas las tareas de las personas a las que tiene permiso de ver. (Subordinados/zona, etc)
        this.usersService.combo({}).subscribe({
          next: (data: any) => {

            this.tasksFilters.responsible_user_id = data.rows.map(m => m.id);
            this.tasksFilters._op_responsible_user_id = "in";
            this.view.fireEvent("tasks.list.results", "reload", this.tasksFilters);
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        });
      }
    }
    //TASKS_SUPERADMIN
  }

  ngOnInit(): void {
    //No funciona. Lo elimina del modelo el TODOS y no lo gaurdaros en la sesion, al entrar otra vez, lo pone en el modelo porque tiene valor por defecto arriba. Así
    //que en el caso de que selecionen de esta opción todos no consigo que lo haga
    this.tasksFilters._ignore_nullable_values = ["is_finished"];
   
    
  }

  loadPrevOrNext(id: number) {
    let table: ViewComponentTableComponent = this.view.getComponent("tasks.list.results");

    let prev: any = null;
    let next: any = null;
    let task: any = null;
    for (let i = 0; i < table.rows.length; i++) {
      if (table.rows[i].id == id) {
        task = table.rows[i];
        if ((i + 1) <= (table.rows.length - 1)) next = table.rows[i + 1];
        break;
      }
      prev = table.rows[i];
    }

    if (task != null) this.openTask(task, prev, next);
  }

  viewTask(task) {
    this.openTask(task, null, null);
  }

  openTask(task, prev, next) {
    var params2: any = {};

    let table: ViewComponentTableComponent = this.view.getComponent("tasks.list.results");
    var params = {
      _sortBy: (table != null && table.params._sortBy != "" ? table.params._sortBy : "task_action_scheduled_date"),
      _sortDirection: (table != null ? table.params._sortDirection : "asc") 
    };
    params = Object.assign(params, this.tasksFilters);
    if (params != null) {
      Object.keys(params).forEach(key => {
        if (typeof (params[key]) != "undefined" && params[key] != null) params2[key] = params[key];
      });
    }
    // Convierte el objeto params a una cadena JSON
    var paramsString = JSON.stringify(params);

    // Guarda la cadena JSON en el localStorage con la clave "tasks.list"
    localStorage.setItem("tasks.list", paramsString);
    localStorage.removeItem("organization.tasks");
    localStorage.removeItem("home.tasks");
    this.router.navigate(['/tasks/', (task?.organization_id ? task.organization_id : 0), (task?.id ? task.id : 0)]);
  }

  delete(task) {
    this.coreDialogService.confirm({
      message: this.translateService.instant("component.tasks.delete_message", { subject: task.subject }),
      header: this.translateService.instant("general.confirmation_delete_title"),
      icon: "pi pi-info-circle",
      accept: () => {
        this.tasksService.delete(task.id).subscribe({
          next: (data: any) => {
            this.messageService.add({ closable: false, severity: 'success', detail: this.translateService.instant("general.confirmation_delete") });
            this.router.navigate(['/tasks']);
            this.view.fireEvent("tasks.list.results", "reload");
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        });
      },
      reject: () => { }
    })
  }

  search() {
    this.view.fireEvent("tasks.list.results", "reload");
  }

  refreshAll() {
    this.view.view?.configuration?.components?.array?.forEach(component => {
      this.view.fireEvent(component.code, "reload");
    });
  }

  onEvent(event: any) {
    if (event.event == "refreshAll" && event.action.name == "refreshAll") this.refreshAll();
    if (event.event == "reload") {
      //this.tasksFilters = event.filters;//??
    }
    if (event.component == "tasks.list.filters") {
      if (event.event == "action" && event.action.name == "search") {
        this.search();
      }
    } else if (event.component == "tasks.list.results") {
      if (event.event == "dblclick") this.loadPrevOrNext(event.data.id);
      if (event.event == "action" && event.action.name == "add") this.viewTask(null);
      if (event.event == "action" && event.action.name == "edit") this.loadPrevOrNext(event.data.id);
      if (event.event == "action" && event.action.name == "delete") this.delete(event.data);
      if (event.event == "action" && event.action.name == "search") {
        this.view.fireEvent("tasks.list.results", "reload", event.data);
      }
    }
  }
}
