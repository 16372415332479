import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import  BeePlugin  from '@mailupinc/bee-plugin'
import { IBeeConfig } from '@mailupinc/bee-plugin/dist/types/bee';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})

export class BeeService {
  endpoint = "https://auth.getbee.io/apiauth";
  beeTest:BeePlugin = null;
  myPromise: Promise<any> | null = null;
  private resolve: Function | null = null;


  constructor(private http: HttpClient) {

   /* this.myPromise = new Promise<string>((resolve, reject) => {
      this.resolve = resolve;
    });*/
  }

   userInput (message, sample) {
    return function handler(resolve, reject) {
      var data = prompt(message, JSON.stringify(sample))
      return (data == null || data == '')
        ? reject()
        : resolve(JSON.parse(data))
    }
  }

  beeConfig:IBeeConfig = {
    uid: 'AxialCRM', //needed for identify resources of the that user and billing stuff
    container: 'bee-plugin-container', // Identifies the id of div element that contains the Beefree SDK builder
    language: 'es-ES',
    mergeTags: [],
    specialLinks: [],
    onSave: (jsonFile, htmlFile) => {     
      this.myPromise = new Promise((resolve, reject) => {
        this.resolve!({ jsonFile: jsonFile, htmlFile: htmlFile });
      }); 
    },
    onSaveAsTemplate: (jsonFile) => {
      console.log('onSaveAsTemplate', jsonFile)
    },
    onSend: (htmlFile) => {
      console.log('onSend', htmlFile)
    },
    onError: (errorMessage) => {
      console.log('onError ', errorMessage)
    }
  }
  start(template: any, mergeTag: any, especialLinks: any) {
    //Put your credentials in the .env file
    const clientId = environment.BEE_CLIENT_ID
    const clientSecret = environment.BEE_CLIENT_SECRET


    let config_html = {
      client_id: clientId, // Enter your client id
      client_secret: clientSecret, // Enter your secret key
      grant_type: "password" // Do not change
    };

    this.http.post<object>(this.endpoint, config_html).subscribe({
      next: (response: any) => {
        this.beeTest = new BeePlugin(response.data);
        if (mergeTag != null) {
          mergeTag.forEach(item => {
            this.beeConfig.mergeTags.push(item);
          });
        }
        if (especialLinks != null) {
          especialLinks.forEach(item => {
            this.beeConfig.specialLinks.push(item);
          });
        }
        this.beeTest.getToken(clientId, clientSecret)
          .then(() => this.beeTest.start(this.beeConfig, template));

      },
      error: (error: any) => {
        console.log(error);
      }
    });

  }

  save(): Promise<any> {
    this.myPromise = new Promise<string>((resolve, reject) => {
      this.resolve = resolve;
    });

     this.beeTest.save();
     return this.myPromise;
     
  }
  
}
