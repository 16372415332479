import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { CoreDialogService } from 'src/app/core/dialogs/core-dialog.service';
import { ViewComponent } from 'src/app/core/view/view.component';
import { ViewsService } from 'src/app/core/view/views.service';
import { ProductsCatalogueComponent } from '../products-catalogue/products-catalogue.component';
import { ProductsDetailComponent } from '../products-detail/products-detail.component';
import { ProductsService } from '../products.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-products-list',
  templateUrl: './products-list.component.html',
  styleUrls: ['./products-list.component.scss']
})
export class ProductsListComponent implements OnInit {
  @ViewChild("view") public view: ViewComponent;
  public products: any[];
  public lastGridEvent: any;
  public totalRecords: any;
  public loading: boolean;
  public productsFilters: any = {};

  constructor(
    private productsService: ProductsService,
    private dialogService: DialogService,
    private messageService: MessageService,
    private coreDialogService: CoreDialogService,
    private router: Router,
    private viewsService: ViewsService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
  }

  viewProduct(product) {
    const ref = this.dialogService.open(ProductsDetailComponent, {
      data: {
        id: product ? product.id : 0
      },
      //header: product ? "Detalles del producto" : "Nuevo producto",
      width: '70%',
    });
    ref.onClose.subscribe((data: any) => {
      if (typeof (this.view) !== "undefined") this.viewsService.changeView(this.view.code);
      this.view.fireEvent("products.list.results", "reload");
    })
  }
  delete(product) {
    this.coreDialogService.confirm({
      message: this.translateService.instant("component.products.delete_message", { name: product.name }),
      header: this.translateService.instant("general.confirmation_delete_title"),
      icon: "pi pi-info-circle",
      accept: () => {
        this.productsService.delete(product.id).subscribe({
          next: (data: any) => {
            this.messageService.add({ closable: false, severity: 'success', detail: this.translateService.instant("general.confirmation_delete") });
            this.router.navigate(['/products']);
            this.view.fireEvent("products.list.results", "reload");
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        });
      },
      reject: () => { }
    });
  }

  viewCatalogue() {
    var realFilters = {};
    for(var k in this.productsFilters) {
      if (this.productsFilters[k] != null && this.productsFilters[k] != 'undefined' && Object.keys(this.productsFilters[k]).length) realFilters[k] = this.productsFilters[k];
    }
    const ref = this.dialogService.open(ProductsCatalogueComponent, {
      data: {
        filters: realFilters
      },
      header: this.translateService.instant("component.products.catalogue"),
      width: '70%',
    });
    ref.onClose.subscribe((data: any) => {
      if (typeof (this.view) !== "undefined") this.viewsService.changeView(this.view.code);
      this.view.fireEvent("products.list.results", "reload");
    })
  }

  onReloadProducts(filters:any){
    //guardamos los ultimos filtros utilizados
    this.productsFilters = filters;
  }

  onEvent(event: any) {
    if (event.component == "products.list.filters") {
      if (event.event == "action" && event.action.name == "search") this.view.fireEvent("products.list.results", "reload");
    } else if (event.component == "products.list.results") {
      if (event.event == "reload") this.onReloadProducts(event.filters);
      if (event.event == "dblclick") this.viewProduct(event.data);
      if (event.event == "action" && event.action.name == "add") this.viewProduct(null);
      if (event.event == "action" && event.action.name == "edit") this.viewProduct(event.data);
      if (event.event == "action" && event.action.name == "delete") this.delete(event.data);
      if (event.event == "action" && event.action.name == "catalogue") this.viewCatalogue();
      if (event.event == "action" && event.action.name == "search") {
        this.view.fireEvent("products.list.results", "reload", event.data);
      }
    }

  }
}
