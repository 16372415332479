import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CoreDialogService } from 'src/app/core/dialogs/core-dialog.service';
import { LoginService } from 'src/app/login/login.service';
import { TasksHistoriesService } from '../tasks-histories.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-tasks-history-detail',
  templateUrl: './tasks-history-detail.component.html',
  styleUrls: ['./tasks-history-detail.component.scss']
})
export class TasksHistoryDetailComponent implements OnInit {
  public taskHistory: any = {};
  constructor(
    private tasksHistoriesService: TasksHistoriesService,
    private config: DynamicDialogConfig,
    private loginService: LoginService,
    private messageService: MessageService,
    private coreDialogService: CoreDialogService,
    private dynamicDialogRef: DynamicDialogRef,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {

    if(this.config.data.taskHistory)this.taskHistory = this.config.data.taskHistory;
  }
  save() {
    if (this.taskHistory.message) {
      this.taskHistory.changed_by = this.loginService.get("userId");
      this.taskHistory.changed_at = new Date();
      this.taskHistory.task_id = this.config.data.taskId;

      this.tasksHistoriesService.add(this.taskHistory).subscribe({
        next: (data: any) => {
          this.messageService.add({ closable: false, severity: "success", detail: this.translateService.instant("component.tasks.history_detail.add_history_correct") });
          this.cancel();
        },
        error: (error: any) => {
          this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
          this.cancel();
        }
      });
    }
  }
  delete() {
    this.coreDialogService.confirm({
      message: this.translateService.instant("component.tasks.history_detail.delete_history_message"),
      header: this.translateService.instant("general.confirmation_delete_title"),
      icon: "pi pi-info-circle",
      accept: () => {
        this.tasksHistoriesService.delete(this.taskHistory.id).subscribe({
          next: (data: any) => {
            this.messageService.add({ closable: false, severity: 'success', detail: this.translateService.instant("general.confirmation_delete") });
            this.cancel();
          },
          error: (error: any) => {
            this.messageService.add({closable: false, severity: 'error', detail: this.translateService.instant("general.delete_field_error") });
            this.cancel();
          }
        });
      },
      reject: () => {

      }
    })
  }
  cancel() {
    this.dynamicDialogRef.close();
  }
  isDuplied() {
    this.tasksHistoriesService.isDuplied(this.taskHistory).subscribe({
      next: (data: any) => {
        if (data == true) {
          this.coreDialogService.notify({
            message: this.translateService.instant("component.tasks.history_detail.history_duplicate"),
            header: 'Error',
            icon: 'far exclamation-circle',
            color: 'var(--orange-500)',
            accept: () => { }
          });
        } else {
          this.save();
        }
      }
    });
  }
}
