<ul class="breadcrumb">
  <li><a [routerLink]="['/home']"><span class="color-soft-grey-axial">{{'general.breadcrumb.you_are' | translate}} </span>{{'general.breadcrumb.home' | translate}}</a></li>
  <li><i class="pi pi-angle-right"></i></li>
    <li><a [routerLink]="['/configuration/entities']">{{ 'general.configuration' | translate}}</a></li>
  <li><i class="pi pi-angle-right"></i></li>
  <li><a [routerLink]="['/configuration/countries']">{{ 'country.entity.name_plural' | translate}}</a></li>
  <li><i class="pi pi-angle-right"></i></li>
  <li [hidden]="country.id==null"><a [routerLink]="['/configuration/countries', country.id]">{{country.name}}</a></li>
  <li [hidden]="country.id!=null">{{'component.country.detail.new_entity' | translate}}</li>
</ul>
<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000"></p-confirmDialog>
<form #formDetails="ngForm">
  <p-card header="{{'general.details' | translate}}">
    <div class="p-grid">
      <div class="p-col-3 p-field">
        <label for="iso_code_2">{{ 'country.entity_fields.iso_code_2' | translate}}</label>
          <input name="iso_code_2" type="text" pInputText [(ngModel)]="country.iso_code_2" required minlength="2" maxlength="2"/>
      </div>
      <div class="p-col-3 p-field">
        <label for="iso_code_3">{{ 'country.entity_fields.iso_code_3' | translate}}</label>
        <input name="iso_code_3" type="text" pInputText [(ngModel)]="country.iso_code_3" required minlength="3" maxlength="3"/>
      </div>
      <div class="p-col-6 p-field">
        <label for="name">{{ 'country.entity_fields.name' | translate}}</label>
          <input name="name" type="text" pInputText [(ngModel)]="country.name" required/>
      </div>
    </div>
    <ng-template pTemplate="footer">
        <div class="p-d-flex">
            <p-button label="{{'general.action.delete' | translate}}" icon="pi pi-trash" styleClass="p-button-danger" (click)="delete()" *ngIf="country.id!=null"></p-button>
            <div class="flex-grow-1"></div>
            <p-button label="{{'general.action.cancel' | translate}}" icon="pi pi-times" styleClass="p-button-secondary p-button-text" (click)="cancel()"></p-button>
            <p-button label="{{'general.action.save' | translate}}" icon="pi pi-check" styleClass="p-ml-2" (click)="isDuplied()"></p-button>
        </div>
    </ng-template>
  </p-card>
</form>
