import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { CoreDialogService } from 'src/app/core/dialogs/core-dialog.service';
import { CoreFormService } from 'src/app/core/forms/core-forms.service';
import { ProvincesService } from '../provinces.service';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { CountriesService } from '../../countries/countries.service';

@Component({
  selector: 'app-provinces-detail',
  templateUrl: './provinces-detail.component.html',
  styleUrls: ['./provinces-detail.component.scss']
})
export class ProvincesDetailComponent implements OnInit {
  @ViewChild("formDetails") public formDetails: NgForm;
  public province: any = {
    name: ""
  };
  public countries: any[] = [];

  constructor(
    private provincesService: ProvincesService,
    private countriesService: CountriesService,
    private route: ActivatedRoute,
    private router: Router,
    private messageService: MessageService,
    private coreFormService: CoreFormService,
    private coreDialogService: CoreDialogService,
    private _location: Location,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.loadCountries();
    this.route.params.subscribe(params => {
      this.loadData(params['id']);
    });
  }

  loadData(id: number) {
    if (id != 0) {
      this.provincesService.get(id).subscribe({
        next: (data: any) => {
          this.province = data;
        },
        error: (error: any) => {
          this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
        }
      });
    }
  }

  loadCountries() {
    this.countriesService.all({}).subscribe({
      next: (data: any) => {
        this.countries = data.rows;
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
      }
    });
  }
  cancel() {
    this.router.navigate(['/configuration/provinces']);
  }

  save() {
    if (this.coreFormService.validate(this.formDetails)) {
      this.provincesService.save(this.province.id, this.province).subscribe({
        next: (data: any) => {
          var _msg = (this.province.id != 0 && typeof this.province.id !== "undefined") ? "save_correct" : "add_correct";
          var aux = this.translateService.instant("component.province.detail." + _msg);
          this.messageService.add({ closable: false, severity: 'success', summary: aux });
          this.router.navigate(['/configuration/provinces']);
        },
        error: (error: any) => {
          this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
        }
      });
    }
  }

  delete() {
    this.coreDialogService.confirm({
      message: this.translateService.instant("component.province.delete_message", { name: this.province.name }),
      header: this.translateService.instant("general.confirmation_delete_title"),
      icon: 'pi pi-info-circle',
      accept: () => {
        this.provincesService.delete(this.province.id).subscribe({
          next: (res: any) => {
            this.messageService.add({ closable: false, severity: 'success', summary: this.translateService.instant("general.confirmation_delete") });
            this._location.back();
            //this.router.navigate(['/configuration/provinces']);
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
          }
        });
      },
      reject: () => {

      }
    });
  }

  isDuplied() {
    var errorMessage = "";
    if (this.province.name.length <= 0) {
      //errorMessage += "El nombre no puede estar vacío. \n";
      this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: this.translateService.instant("general.form_incomplete") });
    }
    /*if(errorMessage){
      this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: errorMessage });
    }*/
    this.provincesService.isDuplied(this.province).subscribe(
      data => {
        if (data == true) {
          this.coreDialogService.notify({
            message: this.translateService.instant("component.province.detail.types_duplicates"),
            header: 'Error',
            icon: 'far exclamation-circle',
            color: 'var(--orange-500)',
            accept: () => { }
          });
        } else {
          this.save();
        }
      }
    );
  }
}
