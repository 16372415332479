<div class="p-grid">
    <div class="p-col-3">
        <p-card styleClass="p-card-content-fit">
            <ul>
                <li *ngFor="let product of products" class="item {{product.id==selectedProduct.id?'item-selected':''}}" (click)="selectProduct(product)">
                    {{product.code}} <br />
                    {{product.name}}
                </li>
                <li *ngIf="products.length<1">No hay productos.</li>
            </ul>
        </p-card>
    </div>
    <div class="p-col-9">
        <p-card>
            <div class="p-grid" *ngIf="selectedProduct">
                <div class="p-col-7 p-mt-2">
                    <p-carousel [value]="imageUrls" styleClass="custom-carrousel">
                        <ng-template let-imageUrl pTemplate="item">
                            <div class="p-d-flex p-ai-center p-jc-center" style="height: 300px;">
                                <img src="{{imageUrl}}" style="max-width: 100%; max-height: 100%;">
                            </div>
                        </ng-template>
                    </p-carousel>
                </div>
                <div class="p-col-5">
                    <b>
                        <h3>{{selectedProduct.code}}</h3>
                        <h2>{{selectedProduct.name}}</h2>
                        <h4>{{selectedProduct.description}}</h4>
                        <h4 style="float: left;">{{selectedProduct.price}} €</h4>
                        <h4 style="float: right;">Stock: {{selectedProduct.current_stock}}</h4>
                    </b>
                </div>
                <div class="p-col-12">
                    <button type="button" pButton icon="pi pi-angle-left" iconPos="left" label="{{'component.products.prev' | translate}}" style="float: left;" (click)="previousProduct(selectedProduct)" [disabled]="selectedProduct.index-1==-1"></button>
                    <button type="button" pButton icon="pi pi-angle-right" iconPos="right" label="{{'component.products.next' | translate}}" style="float: right;" (click)="nextProduct(selectedProduct)" [disabled]="selectedProduct.index+1==products.length"></button>
                </div>
            </div>
        </p-card>
    </div>
</div>
