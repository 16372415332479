import { DatePipe } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { Fieldset } from 'primeng/fieldset';
import { OverlayPanel } from 'primeng/overlaypanel';
import { EntitiesService } from 'src/app/configuration/entities.service';
import { LoginService } from 'src/app/login/login.service';
import { LoaderService } from '../../../loader/loader.service';
import { ComponentService } from '../../component.service';
import { ViewsService } from '../../views.service';
import { ViewComponentConfigureComponent } from '../configure/view-component-configure.component';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { DebugService } from '../../../debug.service';
import { UtilsService } from '../../../utils.service';
import { Table } from 'primeng/table';

@Component({
  selector: 'app-view-component-card',
  templateUrl: 'view-component-card.component.html',
  styleUrls: ['view-component-card.component.scss'],
})
export class ViewComponentCardComponent implements OnInit {

  @Output() onEvent: EventEmitter<any> = new EventEmitter();

  @ViewChild('gridContainer') gridContainer: ElementRef;

  @ViewChild('opColumns') opColumns: OverlayPanel;
  @Input("view") public view: any = {};
  @Input("component") public component: any = {};
  @Input("index") public index: number = 0;
  @Input("model") public model: any = {};
  @Input("showConfigOptions") public showConfigOptions: boolean = false;
  public temporalModel: any = {};
  public entity: any = {};
  private lastGridEvent: any;
  public loading: boolean = false;
  public totalRows: number = 0;
  public paginator: boolean = true;
  public rows: any = Array<any>();
  public user_id: number;
  public today: string;
  public locked: boolean = false;

  public first;
  public last;
  public visibleByOptions: any[] = [
    { code: "all", label: this.translateService.instant("view.configure.all") },
    { code: "user", label: this.translateService.instant("view.configure.user_responsible") }
  ];

  public fieldType: string = "title";
  public allowedEntities: any[] = [];
  public allowedEntitiesFields: any[] = [];
  public rowsPerPage: number = 50;
  public params: any = {};
  public showPaginator: boolean=true;

  constructor(
    public debugService: DebugService,
    private loaderService: LoaderService,
    private entitiesService: EntitiesService,
    private messageService: MessageService,
    private viewsService: ViewsService,
    private datePipe: DatePipe,
    private dialogService: DialogService,
    private loginService: LoginService,
    public componentService: ComponentService,
    private translateService: TranslateService,
    private utilsService: UtilsService
  ) {
  }

  ngOnInit(): void {
    this.user_id = parseInt(this.loginService.get("userId"));
    this.today = new Date().toISOString();
    this.component.fields.forEach(field => {

      if (typeof field.model_property != "undefined") {
        this.temporalModel[field.model_property] = eval("this.model." + field.model_property);
      }

    });

    this.entitiesService.getByCode(this.component.entity).subscribe(
      data => {
        this.entity = data;
        this.component.fields.forEach(field => {
          field.entityField = this.entity.fields.find(m => m.id == field.entity_field_id);
        });

        var newFields: any = [];
        if (this.component.avatar != null) {
          if (this.component.avatar.type == "initials") {
            this.component.avatar.fields.forEach(field => {
              field.entityField = this.entity.fields.find(m => m.id == field.entity_field_id && !field.entityField.is_disabled);
              if (field.entityField != null && typeof (field.entityField) !== "undefined" && !field.entityField.is_disabled) newFields.push(field);
            });
            this.component.avatar.fields = newFields;
          }
        }
        //this.lazyLoad();
      },
      error => {
        this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
      }
    );
  }


  getModelValue(model, field) {
    var value = "";

    this.temporalModel[field.entityField?.model_property] = eval("model." + field.entityField?.model_property);
    value = this.temporalModel[field.entityField?.model_property];

    return value;
  }

  formatMail(value: any, args: any[]) {
    if (value == null || value.length == 0) return "";
    var formattedValue = "<a class='card-subtitle' href='mailto:" + value + "'>" + value + "</a>";
    return formattedValue;
  }
  
  preLazyLoad($event) {
    setTimeout(() => this.lazyLoad($event), 100);
  }

  lazyLoad($event) {

    this.lastGridEvent = $event;
    Promise.resolve(null).then(() => this.loading = true);
    console.log(this.lastGridEvent);

    this.params = {
      _page: $event.first / $event.rows,
      _pageSize: $event.rows,
      _sortBy: $event.sortField ? $event.sortField : "",
      _sortDirection: $event.sortOrder > 0 ? "asc" : "desc"
    };

    if (this.component.source != null) {
      if (this.component.source.params != null && Array.isArray(this.component.source.params)) {
        this.component.source.params.forEach(param => {
          let value = eval(param.value);
          if (typeof (value) != "undefined" && value != null) this.params[param.name] = value;
        });
      }
    }
    this.loaderService.skipRequest('/api/' + this.component.source.url, this.params);
    this.viewsService.getTableData(this.component.source.url, this.params).subscribe(
      data => {
        this.totalRows = data.totalRows;
        this.rows = data.rows;
        this.loading = false;
        this.showPaginator = this.totalRows > this.rowsPerPage;
        this.scrollHeightFunc();
      },
      error => {
        this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
        Promise.resolve(null).then(() => this.loading = false);
      }
    )
  }

  scrollHeightFunc() {
    let maxHeigth = this.component.maxHeight ? this.component.maxHeight : this.component.height;
    if (this.totalRows > this.rowsPerPage) {
      maxHeigth = parseInt(maxHeigth) - 50;
    }

    //ponemos maxheight
    if (this.gridContainer != null) {
      let datatableWrapper = this.gridContainer.nativeElement.querySelector(".p-datatable-wrapper");
      if (datatableWrapper != null) {
        datatableWrapper.style.maxHeight = maxHeigth + "px";
      }
    }

    return maxHeigth;
  }
  left() {
    this.onEvent.emit({ event: "component-left", index: this.index });
  }

  right() {
    this.onEvent.emit({ event: "component-right", index: this.index });
  }

  getAvatar(row: any) {
    let ret = "";
    if (this.component.avatar != null) {
      if (this.component.avatar.type == "initials") {
        this.component.avatar.fields.forEach(field => {
          var value = eval("row." + field.entityField?.model_property);
          if (value != null) ret += value.substring(0, 1).toUpperCase();
        });
      }
    }
    return ret;
  }


  getData(row: any, type) {

    let ret = "";
    var titleFields = this.component.fields.filter(m => m.type == type && m.entityField != null);

    if (titleFields.length > 0) {
      //if (type == "subtitle") console.log(titleFields);

      let i = 1;
      titleFields.forEach(field => {
        let property = field.entityField?.model_property;

        if (field.entityField.model_property_label != null && field.entityField.model_property_label != "") {
          property = field.entityField.model_property_label;
        }

        var value = "";
        try {
          if (field.entityField?.is_base_field) {
            value = eval("row." + property);
          } else {
            value = row.metadata[property]; // eval("row.metadata" + property);
            if (field.entityField?.control_type == "dropdown") {
              //obtener valor para el seleccionado          
              if (field.entityField.configuration.options?.type == "api") {
                //petición al servidor.
              } else {
                let temporalCombos = field.entityField.configuration.options.items;
                temporalCombos.forEach((item) => {
                  if (eval("item." + field.entityField?.configuration?.field_value) == value) {
                    value = eval("item." + field.entityField?.configuration?.field_text);
                  }
                });
              }
            } else if (field.entityField?.control_type == "dropdown-multiple") {
              //obtener valor para el seleccionado          
              if (field.entityField.configuration.options?.type == "api") {
                //petición al servidor.
              } else {
                let temporalCombos = field.entityField.configuration.options.items;
                let selectedValues: any = value;
                if (!(selectedValues instanceof Array)) selectedValues = [selectedValues];
                value = "";
                selectedValues.forEach(selectedValue => {
                  temporalCombos.forEach((item) => {
                    if (eval("item." + field.entityField?.configuration?.field_value) == selectedValue) {
                      if (value != "") value += ", ";
                      value += eval("item." + field.entityField?.configuration?.field_text);
                    }
                  });
                });
              }
            }
          }
        } catch (e) {
          //console.log("Eval for row." + property + " fail!");
        }

        if (field.type == "badge") {
          var css = field.badge.filter(m => m.id == eval("row." + field.entityField.model_property));
          if (css.length == 1) {
            let str = "<div class='p-badge p-badge-" + css[0].css + "'>" + value + "</div>";
            value = str;
          }
        } else {
          let formatFn = field.formatFn;
          if (formatFn == null && field.entityField.configuration != null && field.entityField.configuration.formatFn != null) {
            formatFn = field.entityField.configuration.formatFn;
          }
          if (formatFn != null) {
            var format = formatFn.split("|");
            if (format[0] == "date") value = this.utilsService.formatDate(value, format);
            if (format[0] == "suffix") value = this.utilsService.formatSuffix(value, format);
            if (format[0] == "phone") value = this.utilsService.formatPhone(value, format);
            if (format[0] == "mail") value = this.formatMail(value, format); //no se cambia, la clase que tiene es necesaria.
            if (format[0] == "www") value = this.utilsService.formatWWW(value, format);
            if (format[0] == "badge") value = this.utilsService.formatBadge(field, row, value, format);
            if (format[0] == "image") value = this.utilsService.formatImage(field, format);
            if (format[0] == "icon") value = this.utilsService.formatIcon(value, format);
            if (format[0] == "currency") value = this.utilsService.formatCurrency(value, format);
            if (format[0] == "number") value = this.utilsService.formatNumber(value, format);
          }
        }
        if (value != null && value.length > 0 && field.type != 'subtitle') ret += value + " ";
        if (value != null && value.length > 0 && field.type == 'subtitle') ret += value + (i != titleFields.length ? "<br>" : "");
        i++;
      });
    }
    return ret;
  }

  columns() {
    return this.component.fields.filter(m => m.type == "column");
  }


  configure() {
    const ref = this.dialogService.open(ViewComponentConfigureComponent, {
      data: {
        component: this.component,
        entity: this.entity
      },
      header: this.translateService.instant("component.configure.title"),
      width: '70%'
    });
    ref.onClose.subscribe((data: any) => {
      if (data) {
        this.component.fields = data.fields;
        this.component.title = data.title;
        this.component.class = data.class;
        this.component.height = data.height;
        this.onEvent.emit({ event: "view-save" });
      }
    })
  }

  onDblClick(item) {
    this.onEvent.emit({ event: "dblclick", component: this.component.code, data: item });
  }

  onActionClick(action, item, $event) {
    $event.preventDefault();
    $event.stopPropagation();
    if (action == 'edit') action = { name: "edit" };
    this.onEvent.emit({ event: "action", component: this.component.code, action: action, data: item });
  }

  fireEvent(component: string, event: string, args: any) {
    if (event == "reload") {
      this.preLazyLoad(this.lastGridEvent);
    }
  }

  isActionVisible(action: any, item: any) {
    let visible: boolean = true;
    if (action.ngIf != null) {
      visible = eval(action.ngIf);
    }
    return visible;
  }

  showColumnsSelector($event, fieldType) {
    this.allowedEntities = [];
    this.fieldType = fieldType;
    this.entity.fields.forEach(field => {
      if (this.allowedEntities.find(m => m.code == field.entity_code) == null) {
        this.allowedEntities.push({ code: field.entity_code, name: field.entity_name });
      }
    });
    this.allowedEntitiesFields = [];
    this.opColumns.toggle($event);
  }

  getAllowedEntityFields(entity_code: string) {
    let fields = this.entity.fields.filter(m => m.entity_code == entity_code);
    fields.forEach((field) => {
      var selected = this.component.fields.find(m => m.entity_field_id == field.id && m.type == this.fieldType);
      field.selected = selected != null;
    });
    return fields;
  }

  onFieldChange($event, field) {
    if ($event.checked) {
      //añadimos al final
      var viewField = {
        entity_field_id: field.id,
        entityField: field,
        model_property: field.model_property,
        type: this.fieldType
      };
      this.component.fields.push(viewField);
    } else {
      this.removeField(field.id);
    }
  }

  removeField(entity_field_id) {
    this.component.fields = this.component.fields.filter(m => (m.entity_field_id != entity_field_id && m.type == this.fieldType) || m.type != this.fieldType);
  }
  getRowClass(row: any) {
    let className = "";
    //console.log("getRowClass(row) 'row' -> ", row);
    if (this.component.rowFormatFn != null) {
      try {
        if (this.component.rowFormatFn.indexOf("eval|") == 0) {
          let command = this.component.rowFormatFn.replace("eval|", "");
          className = eval(command);
        }
      } catch (e) {
      }
    }
    return className;
  }

  getActionLabel(action: any) {
    //return this.componentService.getActionLabel(action, this.view.code + "-" +  this.component.code);
    return this.componentService.getActionLabel(action,this.component.code);
  }

}
