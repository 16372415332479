<div>
  <p-card>
    <div>
      <div class="p-field">
        <input id="username" type="username" pInputText [(ngModel)]="loginData.username" placeholder="nombre@email.com"
          (keyup.enter)="ok()" />
      </div>
      <p-message severity="error" text="{{'component.reports.detail.cant_undo' | translate}}" class="p-d-block"></p-message>
      <div class="p-mt-2" [hidden]="alertMessage==''">
        <p-message severity="info" text="{{alertMessage}}" class="p-d-block"></p-message>
      </div>
    </div>
    <div class="p-mt-4 ">

    </div>
    <div class="p-d-flex">
      <p-button label="{{'general.action.cancel' | translate}}" icon="pi pi-times" styleClass="p-button-link p-button-secondary p-ml-2 p-button "
        (click)="cancel()"></p-button>
      <div class="flex-grow-1"></div>
      <button pButton class="p-button-secondary" style="float: right;" (click)="ok()" icon="pi pi-save"
        label="{{'component.reports.detail.do_action' | translate}}"></button>
    </div>

    <div class="p-mt-2" [hidden]="errorMessage==''">
      <p-message severity="error" text="{{errorMessage}}" class="p-d-block"></p-message>
    </div>
  </p-card>
</div>
