-<div class="p-grid p-ai-center p-jc-center h-100">
  <div class="login" style=" background-color: white;" >
    <div class="login-logo p-mt-4">
      <img alt="logo" src="assets/img/logo.png">
    </div>
    <div class="p-m-4">
      <br />
      <h1>Página no encontrada.</h1><br /><br />

      Utilice el menú lateral para continuar con la aplicación

    </div>
  </div>
</div>
