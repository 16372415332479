<div class="p-grid p-grid-nogutter">
  <div class="p-col">
    <router-outlet></router-outlet>
  </div>
  <div class="p-col-2 layout-sidebar-right" *ngIf="organization.id > 0">
    <a [routerLink]="['/organizations', organization.id, 'general']" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact: true}">{{organization.name}}</a>
    <a [routerLink]="['/organizations', organization.id, 'contacts']" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact: true}">
      {{'contact.entity.name_plural' | translate}}
    </a>
    <a [routerLink]="['/organizations', organization.id, 'meetings']" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact: true}">
      {{'meeting.entity.name_plural' | translate}}
    </a>
  </div>
</div>
<p-confirmDialog [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '50vw'}">
  Content
</p-confirmDialog>
