<ul class="breadcrumb">
    <li><a [routerLink]="['/home']"><span class="color-soft-grey-axial">{{'general.breadcrumb.you_are' | translate}}
            </span>{{'general.breadcrumb.home' | translate}}</a></li>
    <li><i class="pi pi-angle-right"></i></li>
    <li><a [routerLink]="['/notifications-in']">{{'component.notifications.in.title'}}</a></li>
</ul>
<p-card header="{{'general.filter.title' | translate}}">
    <div class="p-grid">
        <div class="p-col p-field">
            <label for="subject">{{'notification.entity_fields.subject' | translate}}</label>
            <input id="subject" type="text" pInputText [(ngModel)]="notificationsFilters.subject" />
        </div>
        <div class="p-col-6 p-field">
            <label for="from">{{'notification.entity_fields.from' | translate}}</label>
            <input name="from" type="text" pInputText [(ngModel)]="notificationsFilters.from" />
        </div>
        <div class="p-col p-field">
            <label for="to">{{'notification.entity_fields.to' | translate}}</label>

            <p-dropdown [options]="mailAccounts" [(ngModel)]="notificationsFilters.to" optionLabel="mail_out_from"
                optionValue="mail_out_from"></p-dropdown>
        </div>
    </div>
    <div class="p-grid">
        <div class="p-col p-ai-right">
            <p-button label="Limpiar filtros" (click)="clearFilters()" styleClass="p-button-secondary"></p-button>
            <p-button label="Buscar" (click)="search()" class="p-ml-2"></p-button>
        </div>
    </div>
</p-card>
<p-card class="p-m-2" styleClass="p-card-content-fit">
    <ng-template pTemplate="header">
        <div class="p-card-title">
            {{'component.notifications.in.title'}}
            <!--div class="p-card-title-actions">
                <a (click)="edit({ id: 0 })"><fa-icon [icon]="['fas', 'plus-circle']"></fa-icon> Nuevo país</a>
            </div-->
        </div>
    </ng-template>
    <p-table #grid [value]="notifications" [lazy]="true" (onLazyLoad)="loadData($event)" [loading]="loading"
        [paginator]="true" [rows]="10" [totalRecords]="totalRecords" [showCurrentPageReport]="true"
        currentPageReportTemplate="{{'component.notifications.in.title' | translate}}"
        [rowsPerPageOptions]="[5,10,25,50]" paginatorDropdownAppendTo="body">
        <ng-template pTemplate="header">
            <tr>
                <!--th pSortableColumn="id" width="100">ID</th-->
                <th pSortableColumn="subject">{{'notification.entity_fields.subject' | translate}}</th>
                <th pSortableColumn="from">{{'notification.entity_fields.from' | translate}}</th>
                <th pSortableColumn="tos">{{'notification.entity_fields.to' | translate}}</th>
                <th pSortableColumn="date">{{'notification.entity_fields.date' | translate}}</th>
                <th width="100"></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-notification>
            <tr (dblclick)="viewMail(notification)">
                <!--td>{{notification.id}}</td-->
                <td>{{notification.subject}}</td>
                <td>{{notification.from}}</td>
                <td>{{notification.tos}}</td>
                <td>{{notification.date | date: 'dd/MM/yyyy HH:mm'}}</td>
                <td class="grid-actions">
                    <a (click)="viewMail(notification)"><fa-icon [icon]="['fas', 'eye']"></fa-icon></a>
                    <a (click)="delete(notification)" class="color-danger"><fa-icon
                            [icon]="['far', 'trash-alt']"></fa-icon></a>
                </td>
            </tr>
        </ng-template>
    </p-table>
</p-card>
