import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TasksDetailComponent } from '../tasks/tasks-detail/tasks-detail.component';
import { ReportsDetailComponent } from './reports-detail/reports-detail.component';
import { ReportsListComponent } from './reports-list/reports-list.component';

const routes: Routes = [
  { path: "reports", component: ReportsListComponent},
  { path: "reports/:id", component: ReportsDetailComponent },
  { path: "tasks/:organization_id/:id", component: TasksDetailComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReportsRoutingModule { }
