import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { EntitiesService } from '../../entities.service';
import { CompaniesIntegrationsService } from '../companiesintegrations.service';
import { MailjetService } from '../mailjet.service';

@Component({
  selector: 'app-integration-edit-mailjet',
  templateUrl: './integration-edit-mailjet.component.html',
  styleUrls: ['./integration-edit-mailjet.component.scss']
})
export class IntegrationEditMailjetComponent implements OnInit {

  public integration: any = {
    configuration: {
      auth: { enabled: false },
      mail: { enabled: false },
      contacts: { enabled: false },
      campaigns: { enabled: false },
    }
  };
  public connected: boolean = false;
  public connectionInfo: any = {};
  public lists: any[] = [];
  public errorMessage: string;
  public mergeFields: any[] = [];
  public bind: any = {};
  public entity: any = {};

  public contactsModes: any = [
    { name: "Axial <= Mailjet", value: "<" },
    { name: "Axial => Mailjet", value: ">" },
    { name: "Axial <=> Mailjet", value: "<>" }
  ];
  public campaignsModes: any = [
    { name: "Axial => Mailjet", value: ">" },
    { name: "Axial <=> Mailjet", value: "<>" }
  ];

  constructor(
    private dialogService: DialogService,
    private dynamicDialogRef: DynamicDialogRef,
    private companiesIntegrationsService: CompaniesIntegrationsService,
    private messageService: MessageService,
    private mailjetService: MailjetService,
    private entitiesService: EntitiesService
  ) { }

  ngOnInit(): void {
    this.companiesIntegrationsService.getByCode("mailjet").subscribe(
      data => {
        if (data != null) {
          this.integration = data;
          if (this.integration.configuration == null) this.initializeConfig();
          this.verify();
        } else {
          this.initializeConfig();
        }
      },
      error => {
        this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
      }
    );
  }

  initializeConfig() {
    console.log("initialize)");
    this.integration.configuration = {
      auth: { enabled: false },
      mail: { enabled: false },
      contacts: { enabled: false },
      campaigns: { enabled: false },
    };
  }


  verify() {
    if (this.integration?.configuration?.auth?.api_key == null || this.integration?.configuration?.auth?.api_key == "") return;
    if (this.integration?.configuration?.auth?.api_secret == null || this.integration?.configuration?.auth?.api_secret == "") return;
    this.mailjetService.verify(this.integration.configuration.auth.api_key, this.integration.configuration.auth.api_secret).subscribe(
      data => {
        this.errorMessage = null;
        this.connected = true;
        this.integration.configuration.auth.connected = true;

        //cargamos campos de entidad contactos
        this.loadEntityFields();

        //cargamos metadata de contactos
        this.loadContactMetadata();

        //cargamos listas de contactos disponibles
        this.laodLists();
      },
      error => {
        //this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
        this.errorMessage = error.error.title;
        this.connected = false;
        this.integration.configuration.auth.connected = false;
      }
    );
  }

  loadEntityFields() {
    this.entitiesService.getByCode("contact").subscribe({
      next: (data: any) => {
        this.entity = data;

        this.entity.fields.forEach(field => {
          field.description = field.entity_name + " / " + field.description;
        });
      },
      error: (error: any) => {
        //this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
        this.errorMessage = error.error.title;
      }
    });
  }


  laodLists() {
    if (this.integration?.configuration?.auth?.api_key == null || this.integration?.configuration?.auth?.api_key == "") return;
    if (this.integration?.configuration?.auth?.api_secret == null || this.integration?.configuration?.auth?.api_secret == "") return;
    this.mailjetService.getAllLists(this.integration.configuration.auth.api_key, this.integration.configuration.auth.api_secret).subscribe(
      (data: any) => {
        this.lists = data;
      },
      error => {
        //this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
        this.errorMessage = error.error.title;
      }
    );
  }

  loadContactMetadata() {
    if (this.integration?.configuration?.auth?.api_key == null || this.integration?.configuration?.auth?.api_key == "") return;
    if (this.integration?.configuration?.auth?.api_secret == null || this.integration?.configuration?.auth?.api_secret == "") return;
    this.mailjetService.getContactMetadata(this.integration.configuration.auth.api_key, this.integration.configuration.auth.api_secret).subscribe({
      next: (data: any) => {
        this.mergeFields = data;
      },
      error: (error: any) => {
        //this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
        this.errorMessage = error.error.title;
      }
    });
  }

  addBind() {
    if (this.bind.entity_field_id != null && this.bind.mailjet != null) {
      if (!this.integration.configuration.contacts.binds) this.integration.configuration.contacts.binds = [];
      this.integration.configuration.contacts.binds.push(this.bind);
      console.log(this.bind);
      this.bind = {};
    } else {
      this.messageService.add({ severity: "error", detail: "{{'component.integrations.error_select_both_properties'|translate}}" });
    }
  }

  removeBind(bind) {
    let idx = this.integration.configuration.contacts.binds.indexOf(bind);
    this.integration.configuration.contacts.binds.splice(idx, 1);
  }


  save() {
    this.companiesIntegrationsService.save(this.integration.id, this.integration).subscribe({
      next: (data: any) => {
        this.dynamicDialogRef.close(data);
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
      }
    });
  }

  cancel() {
    this.dynamicDialogRef.close();
  }

  connect() {
    this.verify();
  }

}
