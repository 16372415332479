<div class="p-grid">
  <div class="p-col-12 p-field">
    <label for="username">Cuenta</label>
    <input id="username" pInputText [(ngModel)]="loginData.code" placeholder="Cuenta proporcionada" />
  </div>

  <div class="p-col-12 p-field">
    <label for="username">Escriba su usuario o email. Le enviaremos una contraseña para acceder temporalmente.</label>
    <input id="username" pInputText [(ngModel)]="loginData.username" placeholder="nombre@email.com" />
  </div>
</div>
<div class="p-grid" *ngIf="errorMessage!=''">
    <div class="p-col">
        <p-message severity="error" text="{{errorMessage}}" class="p-d-block"></p-message>
    </div>
</div>
<div class="p-grid">
    <div class="p-col">
        <button class="p-ml-2" style="float: right;" pButton type="button" label="Restablecer contraseña" (click)="reset_password()"></button>
        <button pButton class="p-button-link p-button-secondary" style="float: right;" (click)="cancel()" icon="pi pi-times" label="{{'general.action.cancel' | translate}}"></button>
    </div>
</div>
