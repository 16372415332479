import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Md5 } from 'ts-md5';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  url = "/api/users";

  constructor(private http: HttpClient) { }

  all(params): Observable<any> {
    return this.http.get<any>(this.url, { params: params });
  }
  getComboUsers(params): Observable<any> {
    return this.http.get<any>(this.url + "/getComboUsers", { params: params });
  }

  combo(params): Observable<any> {
    return this.http.get<any>(this.url + "/Combo", { params: params });
  }

  get(id): Observable<object> {
    return this.http.get<object>(this.url + "/" + id);
  }

  save(id, obj): Observable<object> {
    return this.http.put<object>(this.url + "/" + id, obj);
  }

  add(obj): Observable<object> {
    const md5 = new Md5();
    obj.password = md5.appendStr(obj.password).end();
    return this.http.post<object>(this.url, obj);
  }

  delete(id): Observable<object> {
    return this.http.delete<object>(this.url + "/" + id);
  }

  changePassword(user) {
    const md5 = new Md5();
    user.password = md5.appendStr(user.password).end()
    return this.http.post<object>(this.url + "/ChangePassword", user);
  }

  setNewPassword() {
    return this.http.get<object>(this.url + "/setNewPassword");
  }

  setFavouriteReport(reportInfo, type): Observable<any> {
    return this.http.get<any>(this.url + "/favouriteReports/" + reportInfo + "/" + type)
  }

  setGraphHome(id): Observable<any> {
    return this.http.get<any>(this.url + "/graphHome/" + id)
  }

  reorderFavouriteReports(dragPos, dropPos): Observable<any> {
    return this.http.get<any>(this.url + "/reorderFavouriteReports/" + dragPos + "/" + dropPos)
  }

  isDuplied(obj): Observable<any> {
    return this.http.post<any>(this.url + "/isDuplied", obj);
  }

  getUserSubordinatesIdsAndZonesIds(id: number): Observable<any> {
    return this.http.get<any>(this.url + "/getUserSubordinatesIdsAndZonesIds/" + id);
  }

  changeCurrentView(user_id, new_view_id): Observable<object> {
    var obj = {
      user_id: user_id,
      view_id: new_view_id
    };
    return this.http.post<object>(this.url + "/changeCurrentView", obj);
  }
  visitedOrganizations(organizationId: number): Observable<any> {
    return this.http.get<any>(this.url + "/visitedOrganizations?id=" + organizationId);
  }

  getUsersSignatureEmail(id: number): Observable<any> {
    return this.http.get<any>(this.url + "/getUsersSignatureEmail/" + id);
  }

  allLanguages(params): Observable<any> {
    return this.http.get<any>("/api/companyLanguages", { params: params });
  }
}
