import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { CoreDialogService } from 'src/app/core/dialogs/core-dialog.service';
import { IntegrationEditApirestComponent } from '../integration-edit-apirest/integration-edit-apirest.component';
import { IntegrationEditOffice365Component } from '../integration-edit-office365/integration-edit-office365.component';
import { IntegrationEditSageComponent } from '../integration-edit-sage/integration-edit-sage.component';
import { IntegrationEditComponent } from '../integration-edit/integration-edit.component';
import { IntegrationService } from '../integration.service';
import { IntegrationEditMailchimpComponent } from '../integration-edit-mailchimp/integration-edit-mailchimp.component';
import { IntegrationEditMailjetComponent } from '../integration-edit-mailjet/integration-edit-mailjet.component';
import { IntegrationEditWebformComponent } from '../integration-edit-webform/integration-edit-webform.component';
import { TranslateService } from '@ngx-translate/core';
import { IntegrationEditGMailComponent } from '../integration-edit-gmail/integration-edit-gmail.component';

@Component({
  selector: 'app-integration-list',
  templateUrl: './integration-list.component.html',
  styleUrls: ['./integration-list.component.scss']
})
export class IntegrationListComponent implements OnInit {

  public integrations: any = Array<any>();

  constructor(
    private iService: IntegrationService,
    private messageService: MessageService,
    private coreDialogService: CoreDialogService,
    private dialogService: DialogService,
    private translateService: TranslateService,
  ) { }

  ngOnInit() {
    this.loadData();
  }
  loadData() {
    var params = {
      _page: 0,
      _pageSize: 100,
      _sortBy: "name",
      _sortDirection: "asc"
    };
    params = Object.assign(params, {});
    this.iService.all(params).subscribe(
      data => {
        this.integrations = data.rows;
      },
      error => {
        this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
      }
    );
  }

  open(code) {
    if (code == "apirest") {
      const ref = this.dialogService.open(IntegrationEditApirestComponent, {
        header: this.translateService.instant('component.integrations.integration_with_apirest'),
        width: '50%',
      });
      ref.onClose.subscribe((data: any) => {
      });
    } else if (code == "sagedespachos") {
      const ref = this.dialogService.open(IntegrationEditSageComponent, {
        header: this.translateService.instant('component.integrations.integration_with_sagedespachos'),
        width: '50%'
      });
      ref.onClose.subscribe((data: any) => {
      });

    } else if (code == "office365") {
      const ref = this.dialogService.open(IntegrationEditOffice365Component, {
        header: this.translateService.instant('component.integrations.integration_with_office365'),
        width: '900px'
      });
      ref.onClose.subscribe((data: any) => {
      });

    } else if (code == "gmail") {
      const ref = this.dialogService.open(IntegrationEditGMailComponent, {
        header: this.translateService.instant('component.integrations.integration_with_gmail'),
        width: '900px'
      });
      ref.onClose.subscribe((data: any) => {
      });

    } else if (code == "mailchimp") {
      const ref = this.dialogService.open(IntegrationEditMailchimpComponent, {
        header: this.translateService.instant('component.integrations.integration_with_mailchimp'),
        width: '800px'
      });
      ref.onClose.subscribe((data: any) => {
      });

    } else if (code == "mailjet") {
      const ref = this.dialogService.open(IntegrationEditMailjetComponent, {
        header: this.translateService.instant('component.integrations.integration_with_mailjet'),
        width: '500px'
      });
      ref.onClose.subscribe((data: any) => {
      });

    } else if (code == "webform") {
      const ref = this.dialogService.open(IntegrationEditWebformComponent, {
        header: this.translateService.instant('component.integrations.integration_with_webforms'),
        width: '800px'
      });
      ref.onClose.subscribe((data: any) => {
      });

    } else {
      const ref = this.dialogService.open(IntegrationEditComponent, {
        header: this.translateService.instant('component.integrations.generic_integration'),
        width: '50%'
      });
      ref.onClose.subscribe((data: any) => {
      });
    }
  }
}
