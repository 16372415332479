import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OrganizationsRoutingModule } from './organizations-routing.module';
import { OrganizationsListComponent } from './organizations-list/organizations-list.component';
import { OrganizationsDetailComponent } from './organizations-detail/organizations-detail.component';
import { CardModule } from 'primeng/card';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ButtonModule } from 'primeng/button';
import { TableModule } from 'primeng/table';
import { FormsModule } from '@angular/forms';
import {DropdownModule} from 'primeng/dropdown';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import { InputTextModule } from 'primeng/inputtext';
import { OrganizationsContactsDetailComponent } from './organizations-detail/organizations-contacts-detail/organizations-contacts-detail.component';
import { OrganizationsViewComponent } from './organizations-view/organizations-view.component';
import { OrganizationsContactsViewComponent } from './organizations-detail/organizations-contacts-view/organizations-contacts-view.component';
import { OrganizationsDetailGeneralComponent } from './organizations-detail/organizations-detail-general/organizations-detail-general.component';
import { OrganizationsMeetingsViewComponent } from './organizations-detail/organizations-meetings-view/organizations-meetings-view.component';
import { OrganizationsMeetingsDetailComponent } from './organizations-detail/organizations-meetings-detail/organizations-meetings-detail.component';
import { EditorModule } from '@tinymce/tinymce-angular';
import { CalendarModule } from 'primeng/calendar';
import { CoreModule } from '../core/core.module';
import { CheckboxModule } from 'primeng/checkbox';
import { UserCalendarModule } from '../user-calendar/user-calendar.module';
import { TasksWidgetComponent } from './organizations-detail/tasks-widget/tasks-widget.component';
import { OpportunitiesWidgetComponent } from './organizations-detail/opportunities-widget/opportunities-widget.component';
import { ChartModule } from 'primeng/chart';
import { OpportunitiesMonthsWidgetComponent } from './organizations-detail/opportunities-months-widget/opportunities-months-widget.component';
import { OpportunitiesTotalPipelineComponent } from './organizations-detail/opportunities-total-pipeline/opportunities-total-pipeline.component';
import { SalesTotalPipelineComponent } from './organizations-detail/sales-total-pipeline/sales-total-pipeline.component';
import { OrganizationsQuickComponent } from './organizations-quick/organizations-quick/organizations-quick.component';
import { MessagesModule } from 'primeng/messages';
import { FullCalendarModule } from '@fullcalendar/angular';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    OrganizationsListComponent,
    OrganizationsDetailComponent,
    OrganizationsContactsDetailComponent,
    OrganizationsViewComponent,
    OrganizationsContactsViewComponent,
    OrganizationsDetailGeneralComponent,
    OrganizationsMeetingsViewComponent,
    OrganizationsMeetingsDetailComponent,
    TasksWidgetComponent,
    OpportunitiesWidgetComponent,
    OpportunitiesMonthsWidgetComponent,
    OpportunitiesTotalPipelineComponent,
    SalesTotalPipelineComponent,
    OrganizationsQuickComponent
  ],
  imports: [
    CommonModule,
    OrganizationsRoutingModule,
    ButtonModule,
    CardModule,
    CoreModule,
    FontAwesomeModule,
    TableModule,
    FormsModule,
    DropdownModule,
    ConfirmDialogModule,
    InputTextModule,
    EditorModule,
    CalendarModule,
    CheckboxModule,
    UserCalendarModule,
    FullCalendarModule,
    ChartModule,
    MessagesModule,
    TranslateModule
  ],
  exports:[
    OrganizationsListComponent,
    OrganizationsDetailComponent,
    OrganizationsViewComponent,
    OrganizationsContactsDetailComponent,
    OrganizationsMeetingsDetailComponent,
    OrganizationsQuickComponent
  ],
})
export class OrganizationsModule { }
