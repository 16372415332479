import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { LoginService } from 'src/app/login/login.service';

@Component({
  selector: 'app-process-leads-message-error',
  templateUrl: './process-leads-message-error.component.html',
  styleUrls: ['./process-leads-message-error.component.scss']
})
export class ProcessLeadsMessageErrorComponent {
  public message_error: string = "";
  public already_processed_contacts = [];

  constructor(
    private messageService: MessageService,
    private route: ActivatedRoute,
    private dynamicDialogRef: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private loginService: LoginService,
    private translateService: TranslateService,
    private dialogService: DialogService,
  ) { }

  ngOnInit() {
    this.message_error = this.config.data.message_error;
    this.already_processed_contacts = this.config.data.processed_contacts;
  }
}
