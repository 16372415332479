<div class="{{isOpen?'layout-sidebar-backdrop':''}}" (click)="closeMenu()" style="z-index: 99"></div>
<div class="layout-sidebar {{isOpen?'layout-sidebar-open':''}}" style="z-index: 100">
  <div class="p-d-flex p-flex-column">
    <div class="logo" style="padding: 10px; text-align: center">
      <a href="home"><img src="assets/img/logo.jpg" style="height: 50px; width: auto; margin: 0 auto;" /></a>
    </div>
    <div class="logo_sm">
      <a href="home"><img src="assets/img/logo_sm.jpg" style="height: 50px; width: auto; margin: 0 auto;" /></a>
    </div>
    <div *ngIf="isMenuLoaded">
      <div *ngFor="let item of titleItemsMenu">
        <span class="titleMenu">{{item}}</span>
        <p-panelMenu [model]="getMenuGrouped(item)" [style]="{'width':'100%'}"></p-panelMenu>
      </div>
    </div>
  </div>

  <div class="version-info p-mt-4 p-mb-3">
    <div>Versión {{version.version}}</div>
  </div>
</div>
