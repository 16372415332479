import { Component, OnInit, ViewChild } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AttachmentsTypesService } from 'src/app/configuration/attachments-types/attachments-types.service';
import { TemplatesTypesService } from 'src/app/configuration/templates/templates-types.service';
import { TemplatesService } from 'src/app/configuration/templates/templates.service';
import { CoreDialogService } from 'src/app/core/dialogs/core-dialog.service';
import { ViewComponent } from 'src/app/core/view/view.component';
import { AttachmentsDataService } from '../attachments-data.service';
import { AttachmentsService } from '../attachments.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-attachments-detail',
  templateUrl: './attachments-detail.component.html',
  styleUrls: ['./attachments-detail.component.scss']
})
export class AttachmentsDetailComponent implements OnInit {
  @ViewChild("view") public view: ViewComponent;
  public attachment: any = {};
  public attachmentsTypes: any = [];
  public templates: any = [];
  public template: any = {
    name: "blank",
    html: "blank"
  };
  public selectedTemplateHtml: any;
  public html: any;
  tinyMceConfig: any;
  public showTinyMCE: boolean = false;
  public hideTabs: boolean = false;

  constructor(
    private attachmentsService: AttachmentsService,
    private attachmentDataService: AttachmentsDataService,
    private attachmentsTypesService: AttachmentsTypesService,
    private messageService: MessageService,
    public config: DynamicDialogConfig,
    private coreDialogService: CoreDialogService,
    private dynamicDialogRef: DynamicDialogRef,
    private templatesService: TemplatesService,
    private templatesTypesService: TemplatesTypesService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.hideTabs = this.config.data.hideTabs;
    this.configureTinyMce();
    this.loadAttachmentsTypes();
    this.loadTemplates();
    this.loadData(this.config.data.id);
    this.config.header = (typeof (this.config.data.id) !== "undefined" ? this.translateService.instant("component.attachments.detail.detail_entity", { entity_name: this.view.getEntityBaseName() }) : this.translateService.instant("component.attachments.detail.new_entity", { entity_name: this.view.getEntityBaseName() }));

  }
  configureTinyMce() {
    const that = this;
    this.tinyMceConfig =
    {
      height: 500,
      menubar: true,
      toolbar: [
        'undo redo | bold italic underline | fontselect fontsizeselect fontsize fontfamily  formatselect |' +
        'alignleft aligncenter alignright alignjustify | outdent indent | numlist bullist checklist |' +
        'forecolor casechange permanentpen formatpainter removeformat | fullscreen | insertfile image media pageembed link | table quickbars | variablesButton  variablesLinesButton | previewButton'
      ],
      toolbar_mode: 'wrap',
      language: 'es',
      relative_urls: false,
      remove_script_host: false,
      document_base_url: 'https://app.axialcrm.com/',
      paste_data_images: false,
      file_picker_types: 'image',
      file_picker_callback: function (cb, value, meta) {
        var input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.onchange = function (e) {
          var file = e.target["files"][0];
          var reader = new FileReader();
          reader.onload = (event: any) => {
            var datafile = {
              FileName: file.name,
              ContentType: file.type,
              Length: file.size,
              b64: event.target.result
            };
            that.templatesService.UploadImage(datafile).subscribe(
              (data: any) => {
                cb(data.location);
              },
              error => {
                that.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
              }
            );
          };
          reader.readAsDataURL(file);
        };

        input.click();
      }
    };
  }
  loadData(id) {

    if (id) {
      this.attachmentsService.get(id).subscribe(
        data => {
          this.attachment = data;
          if (this.attachment.mimetype == "application/pdf" && this.attachment.related_attachment_id) {
            //Si abres un PDF y tiene un related_attachment_id, vuelve a abrirse con los datos de su related_attachment_id:
            this.loadData(this.attachment.related_attachment_id);
          } else if (this.attachment.mimetype == "text/html") {
            //Si es un HTML, entonces abre el modal con la edición de HTML:
            this.html = "Aquí debería cargar el HTML.";
            this.loadHtmlData();
          }
        },
        error => {
          this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
        }
      );
    } else {
      this.attachment.entity_id = this.config.data.entity_id;
      this.attachment.entity_pk_id = this.config.data.entity_pk_id;
      if (this.config.data.entity_id != null) this.searchTypeId();
      //this.showTinyMCE = true;
    }
  }

  searchTypeId() {
    var code = "";
    if (this.config.data.entity_id == 8) code = "CATALOGUE_IMAGES";
    this.attachmentsTypesService.all({ code: code, is_generic: false }).subscribe(
      data => {
        if (data) {
          var attachment_type = data.rows[0];
          this.attachment.attachment_type_id = attachment_type.id;
        }
      },
      error => {
        this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
      }
    );
  }


  save(event: any) {
    if (!event.valid) {
      this.messageService.add({ closable: false, severity: "error", detail: this.translateService.instant("general.form_incomplete") });
      return;
    }
    this.attachment.organization_id = this.config.data.organization_id;
    if (typeof this.attachment.id === "undefined") {
      this.attachmentsService.add(this.attachment).subscribe({
        next: (data: any) => {
          this.dynamicDialogRef.close();
          this.messageService.add({ closable: false, severity: 'success', summary: this.translateService.instant("component.attachments.detail.add_attachment_correct") /*"Adjunto añadido correctamente."*/ });
        },
        error: (error: any) => {
          this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
        }
      });
    } else {
      this.attachmentsService.save(this.attachment.id, this.attachment).subscribe({
        next: (data: any) => {
          this.messageService.add({ closable: false, severity: "success", detail: this.translateService.instant("component.attachments.detail.save_attachment_correct") /*"Adjunto guardado correctamente"*/ });
          this.dynamicDialogRef.close();
        },
        error: (error: any) => {
          this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
        }
      });
    }
  }

  onBasicUpload(event) {
    if (event.originalEvent.body.off) {
      var guidNum = 0;
      event.originalEvent.body.files.forEach(file => {
        var datafile = {
          description: file.fileName,
          filename: file.fileName,
          mimetype: file.contentType,
          size: file.length,
          guid: event.originalEvent.body.guids[guidNum],
          entity_id: this.config.data.entity_id,
          entity_pk_id: this.config.data.entity_pk_id,
          organization_id: this.config.data.organization_id,
          attachment_type_id: this.attachment.attachment_type_id
        };
        this.attachmentsService.add(datafile).subscribe(
          data => {
            this.dynamicDialogRef.close();
          },
          error => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        );
        guidNum = guidNum + 1;
      });
    } else {
      this.attachment.description = event.originalEvent.body.filename;
      this.attachment.filename = event.originalEvent.body.filename;
      this.attachment.mimetype = event.originalEvent.body.mimetype;
      this.attachment.size = event.originalEvent.body.size;
      this.attachment.guid = event.originalEvent.body.guid;
    }
  }

  onErrorUpload(event) {
    this.messageService.add({ closable: false, severity: 'error', detail: this.translateService.instant('component.attachments.detail.error_updated_file') });
  }

  cancel() {
    this.dynamicDialogRef.close();
  }
  delete() {
    this.coreDialogService.confirm({
      message: this.translateService.instant("component.attachments.delete_message", { description: this.attachment.description }),//"¿Quieres borrar el adjunto " + this.attachment.filename + "?",
      header: this.translateService.instant("general.confirmation_delete_title"),
      icon: "pi pi-info-circle",
      accept: () => {
        this.attachmentsService.delete(this.attachment.id).subscribe(
          data => {
            this.messageService.add({ closable: false, severity: 'success', detail: this.translateService.instant("general.confirmation_delete") });
            this.dynamicDialogRef.close();
          },
          error => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        )
      },
      reject: () => {

      }
    })
  }

  loadTemplates() {
    this.templatesService.all({ entity_id: this.config.data.entity_id }).subscribe(
      data => {
        this.templates = data.rows;
      },
      error => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
      }
    );
  }
  loadAttachmentsTypes() {
    this.attachmentsTypesService.all({ is_generic: false }).subscribe(
      data => {
        this.attachmentsTypes = data.rows;
      },
      error => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
      }
    )
  }

  loadHtmlData() {
    this.attachmentDataService.getHtml(this.attachment.id).subscribe({
      next: (data: any) => {
        this.html = data["html"];
        this.showTinyMCE = true;
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
      }
    });
  }

  loadHtml() {
    if (this.selectedTemplateHtml == null) return;
    this.templatesService.toHTML(this.selectedTemplateHtml, this.config.data.entity_id, this.config.data.entity_pk_id, this.template).subscribe({
      next: (data: any) => {
        this.html = data.html;
        this.showTinyMCE = true;
        this.attachment.description = data.subject;
        if (this.attachment.description == null || this.attachment.description.trim() == "") this.attachment.description = data.description;
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
      }
    });
  }

  toPdf(duply) {
    //Crea un PDF nuevo. Si ya hay uno vinculado a un html, borra el anterior y asigna el nuevo.
    this.attachmentsService.GeneratePDF(this.attachment.id, this.html).subscribe({
      next: (data: any) => {
        this.messageService.add({ closable: false, severity: "success", detail: this.translateService.instant('component.generic_attachments.detail.pdf_generated_correct') });
        //Si es un duplicado, no cerraremos la pantalla de edición:
        if (!duply) this.dynamicDialogRef.close(data);
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
      }
    });
  }

  toDraft(duply) {
    if (this.attachment.description == null || this.attachment.description == "") {
      this.messageService.add({ closable: false, severity: "error", detail: this.translateService.instant('component.attachments.detail.error_empty_description') });
      return;
    }
    this.attachmentsService.htmlUpload({ html: this.html, attachment_guid: this.attachment.guid }).subscribe(
      data => {
        this.attachment.mimetype = "text/html";
        this.attachment.size = data["size"];
        if (!this.attachment.id || duply) {
          //El adjunto es nuevo o un duplicado.
          this.attachment.guid = data["guid"];
          this.attachment.filename = "Borrador_HTML.html";
          this.attachment.id = undefined;
          this.attachment.organization_id = this.config.data.organization_id;
          this.attachmentsService.add(this.attachment).subscribe(
            data => {
              this.messageService.add({ closable: false, severity: "success", detail: this.translateService.instant('component.attachments.detail.add_attachment_correct') });
              this.attachment = data;
              this.toPdf(duply);
            }
          );
        }
        if (this.attachment.id) {
          //Se modifica un adjunto existente.
          this.attachmentsService.save(this.attachment.id, this.attachment).subscribe(
            data => {
              this.toPdf(duply);
            },
            error => {
              this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
            }
          );
        }
      },
      error => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
      }
    );
  }

  uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  isDuplied(event) {
    delete this.attachment.createdBy;
    if (this.attachment.created_at == null) delete this.attachment.created_at;
    if (!event.valid) {
      this.messageService.add({ closable: false, severity: "error", detail: this.translateService.instant("general.form_incomplete") });
      return;
    }
    this.attachmentsService.isDuplied(this.attachment).subscribe(
      data => {
        if (data == true) {
          this.coreDialogService.notify({
            message: this.translateService.instant("component.attachments.detail.duplicate_attachment"),//"Ya existe un adjunto con estos datos proporcionados.",
            header: 'Error',
            icon: 'far exclamation-circle',
            color: 'var(--orange-500)',
            accept: () => { }
          });
        } else {
          this.save(event);
        }
      }
    );
  }

  refreshAll() {
    this.view.view?.configuration?.components?.array?.forEach(component => {
      this.view.fireEvent(component.code, "reload");
    });
  }

  onEvent(event) {
    if (event.event == "refreshAll" && event.action.name == "refreshAll") this.refreshAll();
    if (event.component == "attachments.edit.general") {
      if (event.event == "onChange") { };
      if (event.event == "action" && event.action.name == "delete") this.delete();
      if (event.event == "action" && event.action.name == "cancel") this.cancel();
      if (event.event == "action" && event.action.name == "save") this.isDuplied(event);
    }
    /* No tiene una vista
    if (event.component == "view" && event.event == "entity-loaded") {
      this.config.header = typeof (this.attachment.id) !== "undefined" ? "Detalles del " + this.view.getEntityBaseName() : "Nuevo " + this.view.getEntityBaseName();
    }*/
  }
}
