import { Component, OnInit } from '@angular/core';
import { Message, MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { PermissionsGroupService } from '../permissions-group.service';
import { PermissionsService } from '../permissions.service';
import { RolesService } from '../roles.service';
import { style } from '@angular/animations';
import { CoreDialogService } from 'src/app/core/dialogs/core-dialog.service';
import { FeaturesService } from 'src/app/features/features.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-roles-detail',
  templateUrl: './roles-detail.component.html',
  styleUrls: ['./roles-detail.component.scss']
})
export class RolesDetailComponent implements OnInit {
  public role: any = {
  };
  public permissionsGroup: any[];
  public permissions: any[];
  public allPermissions: any[];
  public checked_all: any;
  public selectedPermissions: string[] = [];
  public features: any[];

  constructor(
    private rolesService: RolesService,
    private featuresService: FeaturesService,
    private dynamicDialogRef: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private messageService: MessageService,
    private permissionsGroupService: PermissionsGroupService,
    private permissionsService: PermissionsService,
    private coreDialogService: CoreDialogService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.loadData(this.config.data.id);
  }

  loadData(id) {
    if (id != 0 && id != null) {
      this.rolesService.get(id).subscribe(
        data => {
          this.role = data;

          this.selectedPermissions = this.role.rolePermissions.map(m => m.permission_id + "");
          this.loadDataFeatures();
        },
        error => {
          this.messageService.add({ severity: "error", detail: error.error.title });
        }
      );
    } else {
      this.role = {
        rolePermissions: []
      }
      this.loadDataFeatures();
    }

  }

  loadDataPermissionsGroup() {
    this.permissionsGroupService.all({}).subscribe(
      data => {
        this.permissionsGroup = data.rows;
        this.loadDataPermissions();
      },
      error => {
        this.messageService.add({ severity: 'error', detail: error.error.title });
      }
    );
  }

  loadDataPermissions() {
    var ids = this.features.map(m => m.id);
    this.permissionsService.all({ featureIds: ids }).subscribe(
      data => {
        this.permissions = data.rows;
        this.allPermissions = this.permissions.map(permission => permission.id + "");
        this.updateChecked();
      },
      error => {
        this.messageService.add({ severity: 'error', detail: error.error.title });
      }
    );
  }

  getPermissions(group) {
    if (this.permissions == null) return [];
    return this.permissions.filter(m => m.permission_group_id == group.id);
  }

  cancel() {
    this.dynamicDialogRef.close();
  }

  delete() {
    this.coreDialogService.confirm({
      message: '¿Quieres borrar el rol ' + this.role.name + '?',
      header: this.translateService.instant("general.confirmation_delete_title"),
      icon: 'pi pi-info-circle',
      accept: () => {
        this.rolesService.delete(this.role.id).subscribe(
          data => {
            this.messageService.add({ severity: 'success', detail: this.translateService.instant("general.confirmation_delete") });
            this.dynamicDialogRef.close(data);
          }
        ),
          error => {
            this.messageService.add({ severity: 'error', detail: error.error.title });
          }
      },
      reject: () => {

      }
    })

  }

  save() {
    //añadir permisos al rol
    let finalRolePermissions = this.selectedPermissions.map(permission_id => {
      let existentPermissions = this.role.rolePermissions.filter(m => m.permission_id == permission_id && !m.deleted);
      if (existentPermissions.length > 0) {
        return existentPermissions[0];
      } else {
        return { role_id: this.role.id, permission_id: parseInt(permission_id) };
      }
    })
    this.role.rolePermissions = finalRolePermissions;

    if (typeof this.role.id === "undefined") {
      this.rolesService.add(this.role).subscribe(
        data => {
          this.role = data;
          this.loadData(this.role.id);
          this.messageService.add({ severity: "success", detail: "Guardado correctamente" });
        },
        error => {
          this.messageService.add({ severity: "error", detail: error.error.title });
        }
      );
    } else {
      this.rolesService.save(this.role.id, this.role).subscribe(
        data => {
          this.messageService.add({ severity: "success", detail: "Guardado correctamente" });
          this.dynamicDialogRef.close();
        },
        error => {
          this.messageService.add({ severity: "error", detail: error.error.title });
        }
      );
    }
  }
  loadDataFeatures() {
    this.featuresService.all({}).subscribe(
      data => {
        this.features = data.rows;
        this.loadDataPermissionsGroup();
      }, error => {
        this.messageService.add({ severity: 'error', detail: error.error.title });
      }
    )
  }
  isDuplied() {
    this.rolesService.isDuplied(this.role).subscribe(
      data => {
        if (data == true) {
          this.coreDialogService.notify({
            message: "Ya existe un rol con estos datos proporcionados.",
            header: 'Error',
            icon: 'far exclamation-circle',
            color: 'var(--orange-500)',
            accept: () => { }
          });
        } else {
          this.save();
        }
      }
    );
  }
  check_all() {
    this.allPermissions.sort();
    this.selectedPermissions.sort();

    //Si el check está marcado, añadirá todos los permisos posibles al array.
    if (this.checked_all) {
      this.selectedPermissions = this.allPermissions;
    } else {
      //Y si no, los borra.
      this.selectedPermissions = [];
    }
  }
  updateChecked() {
    if (this.selectedPermissions.length == this.allPermissions.length) {
      this.checked_all = true;
    } else {
      this.checked_all = false;
    }
  }
}
