<div class="p-grid">
    <!--Mensaje TextArea-->
    <div class="p-col-12">
        <label for="message">Mensaje</label>
        <textarea name="message" pInputTextarea [(ngModel)]="taskHistory.message" style="width: 100%;resize: vertical;" rows="20"></textarea>
    </div>

    <!--Opciones-->
    <div class="p-col-12">
        <button pButton class="p-button-danger" *ngIf="taskHistory.id>0" (click)="delete()" icon="pi pi-trash" label="{{'general.action.delete' | translate}}"></button>
        <button pButton class="p-ml-2" style="float: right;" (click)="isDuplied()" icon="pi pi-check" label="{{'general.action.save' | translate}}"></button>
        <button pButton class="p-button-secondary p-button-text" style="float: right;" (click)="cancel()" icon="pi pi-times" label="{{'general.action.cancel' | translate}}"></button>
    </div>
</div>
