import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ImportService {
  url = "/api/import";

constructor(private http: HttpClient) { }
  
  all(params): Observable<any> {
    return this.http.get<any>(this.url, { params: params });
  }
  get(id): Observable<object> {
    return this.http.get<object>(this.url + "/" + id);
  }  
  save(id, obj): Observable<object> {
    return this.http.put<object>(this.url + "/" + id, obj);
  }
  
  add(obj): Observable<object> {
      return this.http.post<object>(this.url, obj);
  }
  fields(id): Observable<object> {
    return this.http.get<object>(this.url + "/" + id + "/fields");
  }
  summarize(id): Observable<object> {
    return this.http.get<object>(this.url + "/" + id + "/summarize");
  }
  customVoidCombos(url, params): Observable<any> {
    return this.http.get<any>(url, { params: params });
  }
  getImportAllowedEntities(id): Observable<object> {
    return this.http.get<object>(this.url + "/" + id + "/GetImportAllowedEntities");
  }

  readColumns(uid, id): Observable<object>{
    return this.http.get<object>(this.url + "/readColumns/" + uid +"/"+ id);
  }

  loadDataFromCsv(save, params): Observable<any> {
    return this.http.post<object>(this.url + "/" + save +"/loadDataFromCsv", params);
  }
  delete(id): Observable<object> {
    return this.http.delete<object>(this.url + "/" + id);
  }
  checkIfProcessing(id): Observable<boolean> {
    return this.http.get<boolean>(this.url + "/" + id + "/checkIfProcessing");
  }

  download(id) {
    var url = "/api/PublicImport/" + localStorage.getItem("companyId") + "/" + id + "/download";
    window.open(url);
  }
  duplicate(id: number): Observable<boolean> {
    return this.http.get<boolean>(this.url + "/duplicate/" + id + "");
  }
}
