import { Component, OnInit, ViewChild } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CoreDialogService } from 'src/app/core/dialogs/core-dialog.service';
import { ViewComponent } from '../../../core/view/view.component';
import { AttachmentsTypesService } from '../attachments-types.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-attachments-types-detail',
  templateUrl: './attachments-types-detail.component.html',
  styleUrls: ['./attachments-types-detail.component.scss']
})
export class AttachmentsTypesDetailComponent implements OnInit {
  public attachmentType: any = {};
  @ViewChild("view") public view: ViewComponent;
  public attachment_type_type_id;
  constructor(
    private attachmentsTypesService: AttachmentsTypesService,
    private config: DynamicDialogConfig,
    private messageService: MessageService,
    private dynamicDialogRef: DynamicDialogRef,
    private coreDialogService: CoreDialogService,
    private translateService: TranslateService
  ) {
    this.attachment_type_type_id = this.config.data.attachment_type_type_id;
  }

  ngOnInit(): void {
    this.loadAttachmentsTypes(this.config.data.id);
  }

  loadAttachmentsTypes(id) {
    if (id) {
      this.attachmentsTypesService.get(id).subscribe({
        next: (data: any) => {
          this.attachmentType = data;
        },
        error: (error: any) => {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error.title });
        }
      });
    }
  }

  save(event: any) {
    if(this.attachment_type_type_id == 2){
      this.attachmentType.is_generic = true;
    }
    if (!event.valid) {
      this.messageService.add({ closable: false, severity: "error", detail: this.translateService.instant("general.form_incomplete") });
      return;
    }
    if (typeof this.attachmentType.id === "undefined") {
      this.attachmentsTypesService.add(this.attachmentType).subscribe({
        next: (data: any) => {
          this.messageService.add({ closable: false, severity: 'success', detail: this.translateService.instant("component.attachmentType.detail.add_correct") });
          this.dynamicDialogRef.close();
        },
        error: (error: any) => {
          this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
        }
      });
    } else {
      this.attachmentsTypesService.save(this.attachmentType.id, this.attachmentType).subscribe({
        next: (data: any) => {
          this.attachmentsTypesService.add({ closable: false, severity: "success", detail: this.translateService.instant("component.attachmentType.detail.save_correct") });
          this.dynamicDialogRef.close();
        },
        error: (error: any) => {
          this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
        }
      });
    }
  }

  cancel() {
    this.dynamicDialogRef.close();
  }

  delete() {
    this.coreDialogService.confirm({
      message: this.translateService.instant("component.attachmentType.delete_message", { name: this.attachmentType.name }),
      header: this.translateService.instant("general.confirmation_delete_title"),
      icon: "pi pi-info-circle",
      accept: () => {
        this.attachmentsTypesService.delete(this.attachmentType.id).subscribe({
          next: (data: any) => {
            this.messageService.add({ closable: false, severity: 'success', detail: this.translateService.instant("general.confirmation_delete") });
            this.dynamicDialogRef.close();
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        });
      },
      reject: () => {

      }
    })
  }

  isDuplied(event) {
    if (!event.valid) {
      this.messageService.add({ closable: false, severity: "error", detail: this.translateService.instant("general.form_incomplete") });
      return;
    }
    this.attachmentsTypesService.isDuplied(this.attachmentType).subscribe(
      data => {
        if (data == true) {
          this.coreDialogService.notify({
            message: this.translateService.instant("component.attachmentType.detail.types_duplicates"),
            header: 'Error',
            icon: 'far exclamation-circle',
            color: 'var(--orange-500)',
            accept: () => { }
          });
        } else {
          this.save(event);
        }
      }
    );
  }

  onEvent(event) {
    if (event.component == "attachmentType.edit.general") {
      if (event.event == "action" && event.action.name == "delete") this.delete();
      if (event.event == "action" && event.action.name == "cancel") this.cancel();
      if (event.event == "action" && event.action.name == "save") this.isDuplied(event);
    }

    if (event.component == "view" && event.event == "entity-loaded") {
      this.config.header = typeof (this.attachmentType.id) !== "undefined" ?
        this.translateService.instant("component.attachmentType.detail.detail_entity", { entity_name: this.view.getEntityBaseName() })
        :
        this.translateService.instant("component.attachmentType.detail.new_entity", { entity_name: this.view.getEntityBaseName() });
    }
  }
}
