import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DebugService {

  public enabled:boolean = false;

  constructor() { }
  
  enable(){
    this.enabled = true;
    console.log("Debug enabled");
  }

  disable(){
    this.enabled = false;
    console.log("Debug disabled");
  }

  log(source:any, message:string|object){
    if(!this.enabled) return;
    if(typeof(message)!="string") message = JSON.stringify(message);
    let constructorName = source?.constructor?.name;
    console.log("DEBUG " + (new Date()).toISOString() + " - " + constructorName + ": " + message);
  }

}
