<ng-container *ngFor="let groupAND of this.filters.filtersGroupAND; let i = index">
  <ng-container *ngIf="groupAND.ask_user">
    <p-fieldset class="fieldsetReports">
      <ng-template pTemplate="header" *ngIf="i==0">
        {{'component.reports.detail.condition' | translate}}
      </ng-template>
      <ng-template pTemplate="header" *ngIf="i!=0">

        {{'component.reports.detail.and_condition' | translate}}
      </ng-template>
      <div class="p-grid" *ngFor="let groupOR of groupAND.filtersGroupOR; let j = index">
        <div *ngIf="groupOR.ask_user" class="p-col p-col-3 p-field">
          {{getNameField(groupOR)}}
        </div>
        <div *ngIf="groupOR.ask_user" class="p-col p-col-3 p-field">
          <p-dropdown appendTo="body" [options]="filterConditionsValues[groupOR.entity_field_id]"
            [(ngModel)]="groupOR.condition_value" placeholder="{{'general.select' | translate}}" optionLabel="name"
            optionValue="value" [showClear]="true" [disabled]="!groupOR.ask_user" [ngModelOptions]="{standalone: true}">
          </p-dropdown>
        </div>
        <div class="p-col p-col p-field"
          *ngIf="groupOR.condition_value != 'null' && groupOR.condition_value != '!null' && groupOR.ask_user">
          <input *ngIf="getFilterOptionsOrValueType(groupOR, false) == 'input-text'"
            [ngModelOptions]="{standalone: true}" type="text" pInputText [(ngModel)]="groupOR.value" />

          <p-dropdown *ngIf="getFilterOptionsOrValueType(groupOR, false) == 'input-dropdown'"
            [options]="filterValuesOptions[groupOR.entity_field_id]" [(ngModel)]="groupOR.value"
            placeholder="{{'general.select' | translate}}" optionLabel="text" optionValue="code" [showClear]="true"
            appendTo="body" [filter]="true" (onFilter)="onFilter($event, groupOR)"
            [ngModelOptions]="{standalone: true}"></p-dropdown>

          <p-dropdown appendTo="body" *ngIf="(getFilterOptionsOrValueType(groupOR, false)=='input-datetime')"
            [options]="date_options" [(ngModel)]="groupOR.value_date" placeholder="{{'general.select' | translate}}"
            optionLabel="name" optionValue="value" [ngModelOptions]="{standalone: true}"></p-dropdown>

          <app-view-component-datetime
            *ngIf="getFilterOptionsOrValueType(groupOR, false) == 'input-datetime' && groupOR.value_date=='date'"
            [(ngModel)]="groupOR.value" [ngModelOptions]="{standalone: true}" [show-time]="false">
          </app-view-component-datetime>

          <p-multiSelect *ngIf="getFilterOptionsOrValueType(groupOR, false) == 'dropdown-multiple'"
            [options]="filterValuesOptions[groupOR.entity_field_id]" [(ngModel)]="groupOR.value_multiple"
            optionLabel="text" optionValue="code" placeholder="{{'general.select' | translate}}" [filter]="true"
            [showClear]="true" appendTo="body" [ngModelOptions]="{standalone: true}"></p-multiSelect>

          <input *ngIf="getFilterOptionsOrValueType(groupOR, false) == 'input-number'"
            [ngModelOptions]="{standalone: true}" type="number" pInputText [(ngModel)]="groupOR.value" />
        </div>
      </div>
    </p-fieldset>
  </ng-container>
</ng-container>

<div class="p-col p-field p-right">
  <label>&nbsp;</label>
  <p-button label="{{'component.reports.detail.generate' | translate}}" icon="pi pi-check" styleClass="p-ml-2"
    (click)="save()"></p-button>
</div>
