import { Component, OnInit, ViewChild } from '@angular/core';
import { MessageService } from 'primeng/api';
import { NotificationsService } from '../notifications.service';
import { DialogService, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { MailingDetailComponent } from 'src/app/mailing/mailing-detail/mailing-detail.component';
import { ViewComponent } from 'src/app/core/view/view.component';
import { ViewsService } from 'src/app/core/view/views.service';
import { CoreDialogService } from 'src/app/core/dialogs/core-dialog.service';
import { ActivatedRoute } from '@angular/router';
import { OrganizationsService } from 'src/app/organizations/organizations.service';
import { ContactsService } from 'src/app/organizations/organizations-detail/organizations-contacts-detail/contacts.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-mail-in-out-list',
  templateUrl: './mail-in-out-list.component.html',
  styleUrls: ['./mail-in-out-list.component.scss']
})
export class MailInOutListComponent implements OnInit {
  @ViewChild("view") public view: ViewComponent;
  organization_id: number;
  organization = null;
  contact_id: number;
  contact = null;
  public emailsFilters: any = {};

  constructor(
    private notificationsService: NotificationsService,
    private organizationsService: OrganizationsService,
    private contactsService: ContactsService,
    private dialogService: DialogService,
    private viewsService: ViewsService,
    private messageService: MessageService,
    private coreDialogService: CoreDialogService,
    private route: ActivatedRoute,
    private translateService: TranslateService
  ) {
    this.route.params.subscribe(params => {
      var organization_id = params["organization_id"];
      var contact_id = params["contact_id"];

      if (organization_id != null && organization_id > 0) {
        this.organization_id = organization_id;
        this.emailsFilters.organization_id = this.organization_id;
        this.getOrganization(this.organization_id);
      }
      if (contact_id != null && contact_id > 0) {
        this.contact_id = contact_id;
        this.emailsFilters.contact_id = this.contact_id;
        this.getContact(this.contact_id);
      }
    });
  }

  ngOnInit(): void {
  }

  search() {
    this.view.fireEvent("notificationEmails.list.results", "reload");
  }

  clearFilters() {
    this.emailsFilters = {};
    if (this.organization_id != null) {
      this.emailsFilters.organization_id = this.organization_id;
    }
    if (this.contact_id != null) {
      this.emailsFilters.contact_id = this.contact_id;
    }
    this.search();
  }

  viewMail(data) {
    const ref = this.dialogService.open(MailingDetailComponent, {
      data: {
        mail_id: data.id,
        //organization_id: this.organization.id,
        entity_id: data.entity_id,
        //template_type_id: 1,
        template_type_code: "MAIL",
        //notification_to_id: data.id,
        searchRelated: true
      },
      header: this.translateService.instant("general.new_mail_title"),
      width: '70%',
    });
  };

  getOrganization(id) {
    this.organizationsService.get(id).subscribe(
      data => {
        this.organization = data;
      },
      error => {
        console.log(error);
      }
    );
  }

  getContact(id) {
    this.contactsService.get(id).subscribe(
      data => {
        this.contact = data;
      },
      error => {
        console.log(error);
      }
    );
  }

  resendMail(data) {
    const ref = this.dialogService.open(MailingDetailComponent, {
      data: {
        resendMail: true,
        mail_id: data.id,
        to: null,
        //template_type_id: 1,
        template_type_code: "MAIL",
        is_generic: true,
        searchRelated: true
        /*entity_id: 2,
        entity_pk_id: this.contact.id,
        organization_id: this.contact.organization_id,
        contact_id: this.contact.id,
        template_type_id: 1*/
      },
      header: this.translateService.instant("general.resend_mail_title"),
      width: '70%',
    });
    ref.onClose.subscribe((data) => {

    });
  }

  onEvent(event: any) {
    if (event.component == "notificationEmails.list.filters") {
      if (event.event == "action" && event.action.name == "search") this.search();
      if (event.event == "action" && event.action.name == "clear") this.clearFilters();
    } else if (event.component == "notificationEmails.list.results") {
      if (event.event == "action" && event.action.name == "resend") this.resendMail(event.data);
      if (event.event == "action" && event.action.name == "view") this.viewMail(event.data);
    }
  }
}
