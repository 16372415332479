import { Component, OnInit, ViewChild } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { CoreDialogService } from 'src/app/core/dialogs/core-dialog.service';
import { ViewComponent } from 'src/app/core/view/view.component';
import { LoginService } from 'src/app/login/login.service';
import { TransactionTypesService } from '../../transaction-types/transaction-types.service';
import { TransactionStatusDetailComponent } from '../transaction-status-detail/transaction-status-detail.component';
import { TransactionStatusService } from '../transaction-status.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-transaction-status-list',
  templateUrl: './transaction-status-list.component.html',
  styleUrls: ['./transaction-status-list.component.scss']
})
export class TransactionStatusListComponent implements OnInit {
  @ViewChild("view") public view: ViewComponent;
  public transactionTypes: any[] = [];
  public transactionsStatus: any[] = [];
  public transactionsStatusFilters: any = {};
  public transaction_type_id: number = null;

  public lastGridEvent: any;
  public totalRecords: number;
  public loading: boolean = false;

  //Permisos:
  public pipeline_configuration;

  constructor(
    private transactionTypesService: TransactionTypesService,
    private transactionStatusService: TransactionStatusService,
    private coreDialogService: CoreDialogService,
    private messageService: MessageService,
    private dialogService: DialogService,
    private loginService: LoginService,
    private translateService: TranslateService

  ) { }

  ngOnInit(): void {
    this.pipeline_configuration = this.loginService.hasPermission("PIPELINE_CONFIGURATION");
    this.transactionsStatusFilters.transaction_type_id = 1;
  }

  search() {
    this.lastGridEvent.first = 0;
    this.loadData(this.lastGridEvent);
    this.view.fireEvent("transactionStatus.list.results", "reload");
  }
  loadData($event) {
    this.lastGridEvent = $event;
    Promise.resolve(null).then(() => this.loading = true);
    var params = {
      _page: $event.first,
      _pageSize: $event.rows,
      _sortBy: $event.sortField ? $event.sortField : "",
      _sortDirection: $event.sortOrder > 0 ? "asc" : "desc"
    };
    params = Object.assign(params, this.transactionsStatusFilters);
    this.transactionStatusService.all(params).subscribe({
      next: (data: any) => {
        this.totalRecords = data.totalRows;
        this.transactionsStatus = data.rows;
        Promise.resolve(null).then(() => this.loading = false);
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
        Promise.resolve(null).then(() => this.loading = false);
      }
    });
  }

  loadTransactionTypes() {
    this.loading = true;
    this.transactionTypesService.all({}).subscribe({
      next: (data: any) => {
        this.transactionTypes = data.rows;
        this.loading = false;
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
        this.loading = false;
      }
    });
  }

  delete(transactionStatus: any) {
    this.coreDialogService.confirm({
      message: this.translateService.instant("component.transactionsStatus.delete_message", { name: transactionStatus.name }),
      header: this.translateService.instant("general.confirmation_delete_title"),
      icon: 'pi pi-info-circle',
      accept: () => {
        this.transactionStatusService.delete(transactionStatus.id).subscribe({
          next: (data: any) => {
            this.messageService.add({ closable: false, severity: "success", detail: this.translateService.instant("general.confirmation_delete") });
            this.view.fireEvent("transactionStatus.list.results", "reload");
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
          }
        });
      },
      reject: () => {

      }
    });
  }
  edit(transactionStatus: any) {
    if (this.pipeline_configuration) {
      const ref = this.dialogService.open(TransactionStatusDetailComponent, {
        data: {
          id: transactionStatus != null ? transactionStatus.id : null,
          transaction_type_id: this.transaction_type_id
        },
        width: '70%'
      });
      ref.onClose.subscribe((data: any) => {
        this.view.fireEvent("transactionStatus.list.results", "reload");
      });
    }
  }
  clearFilters() {
    this.transactionsStatusFilters = {};
    this.search();
  }
  onEvent(event: any) {
    if (event.component == "transactionStatus.list.filters") {
      if (event.event == "onChange") this.transaction_type_id = event.data.transaction_type_id;
      if (event.event == "action" && event.action.name == "search") this.view.fireEvent("transactionStatus.list.results", "reload");
    } else if (event.component == "transactionStatus.list.results") {
      if (event.event == "dblclick") this.edit(event.data);
      if (event.event == "action" && event.action.name == "add") {
        if (this.transaction_type_id != null) this.edit(null); else this.messageService.add({ closable: false, severity: "error", detail: this.translateService.instant("component.attachmentType.first_select_type") });
      }
      if (event.event == "action" && event.action.name == "edit") this.edit(event.data);
      if (event.event == "action" && event.action.name == "delete") this.delete(event.data);
    }
  }
}
