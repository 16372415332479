<p-card>
  <ng-template pTemplate="header">
    <div class="p-card-title">
      {{'component.integrations.configuration.user'|translate}}
    </div>
  </ng-template>
  <div class="p-grid">
    <div class="p-col-12 p-field">
      <label for="user">Usuario</label>
      <input [(ngModel)]="integration.configuration.user" id="user" type="text" pInputText name="user" placeholder=""
        autocomplete="false" disabled />
    </div>
    <!--TODO: ¿Tiene sentido que se visualice aquí la clave?-->
    <div class="p-col-12 p-field">
      <label for="password">{{'component.integrations.configuration.password'|translate}}</label>
      <input [(ngModel)]="integration.configuration.password" id="password" name="password" type="text" pInputText
        placeholder="" autocomplete="false" disabled />
    </div>
  </div>
</p-card>
<p-card>
  <ng-template pTemplate="header">
    <div class="p-card-title">
      {{'component.integrations.apirest_permissions'|translate}}
    </div>
  </ng-template>
  <div class="p-grid">
    <div class="p-col-12 p-field">
      <label for="organizationPermission">{{'component.integrations.access_to_organizations'|translate}}</label>
      <p-dropdown name="organizationPermission" [options]="permissions"
        [(ngModel)]="integration.configuration.organizations" optionLabel="name" optionValue="value" appendTo="body">
      </p-dropdown>
    </div>
    <div class="p-col-12 p-field">
      <label for="contactsPermission">{{'component.integrations.access_to_contacts'|translate}}</label>
      <p-dropdown name="contactsPermission" [options]="permissions" [(ngModel)]="integration.configuration.contacts"
        optionLabel="name" optionValue="value" appendTo="body"></p-dropdown>
    </div>
    <div class="p-col-12 p-field">
      <label for="tasksPermission">{{'component.integrations.access_to_tasks'|translate}}</label>
      <p-dropdown name="tasksPermission" [options]="permissions" [(ngModel)]="integration.configuration.tasks"
        optionLabel="name" optionValue="value" appendTo="body"></p-dropdown>
    </div>
  </div>
</p-card>


<div class="p-col-12 p-d-flex">
  <div class="flex-grow-1"></div>
  <p-button label="{{'general.action.cancel' | translate}}" icon="pi pi-times"
    styleClass="p-button-secondary p-button-text" (click)="cancel()">
  </p-button>
  <button class="p-ml-2" style="float: right;" icon="pi pi-save" pButton type="button"
    label="{{'general.action.save' | translate}}" (click)="save()"></button>
</div>