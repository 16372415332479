import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LoaderService } from '../core/loader/loader.service';

@Injectable({
  providedIn: 'root'
})
export class OrganizationsService {
  url = "/api/organizations";

  constructor(private http: HttpClient, private loaderService: LoaderService) { }

  all(params): Observable<any> {
    return this.http.get<any>(this.url, { params: params });
  }

  get(id): Observable<object> {
    return this.http.get<object>(this.url + "/" + id);
  }

  save(id, obj): Observable<object> {
    return this.http.put<object>(this.url + "/" + id, obj);
  }

  add(obj): Observable<object> {
    return this.http.post<object>(this.url, obj);
  }

  delete(id): Observable<object> {
    return this.http.delete<object>(this.url + "/" + id);
  }

  statsActivities(id): Observable<object> {
    this.loaderService.skipRequest(this.url + "/" + id + "/stats/activities", null);
    return this.http.get<object>(this.url + "/" + id + "/stats/activities");
  }
  statsOpportunities(id): Observable<any> {
    this.loaderService.skipRequest(this.url + "/" + id + "/stats/opportunities", null);
    return this.http.get<any>(this.url + "/" + id + "/stats/opportunities");
  }
  pipelinesOpportunities(id): Observable<any> {
    this.loaderService.skipRequest(this.url + "/" + id + "/pipelines/opportunities", null);
    return this.http.get<any>(this.url + "/" + id + "/pipelines/opportunities");
  }
  pipelinesSales(id): Observable<any> {
    this.loaderService.skipRequest(this.url + "/" + id + "/pipelines/sales", null);
    return this.http.get<any>(this.url + "/" + id + "/pipelines/sales");
  }
  isDuplied(obj): Observable<any>{
    return this.http.post<any>(this.url+"/isDuplied",obj);
  }

  getCombo(params): Observable<any> {
    return this.http.get<any>(this.url + "/combo", { params: params });
  }
}
