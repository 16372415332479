import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CalendarComponent } from './calendar.component';
import { OrganizationsMeetingsDetailComponent } from '../organizations/organizations-detail/organizations-meetings-detail/organizations-meetings-detail.component';

const routes: Routes = [
  { path: "calendar/:id", component: CalendarComponent },
  { path: "calendar", component: CalendarComponent },
  { path: "meetings/:id", component: OrganizationsMeetingsDetailComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CalendarRoutingModule { }
