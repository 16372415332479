import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-dialogs-confirm',
  templateUrl: 'dialogs-confirm.component.html',
  styleUrls: ['dialogs-confirm.component.scss'],
})
export class DialogConfirmComponent implements OnInit {

  public configuration:any = {};
  public icon : [];
  public show_button_ok: boolean;
  public color: string;

  constructor(private ref: DynamicDialogRef,
    //public sanitizer: DomSanitizer,
    private config: DynamicDialogConfig) { 
    this.configuration = config;
  }

  ngOnInit(): void {
    this.icon = this.configuration.icon.split(" ");
    this.show_button_ok = this.configuration.show_button_ok;
    this.color = this.configuration.color;
  }
  myStyle(): object {
    if(this.color != ""){
      return {"color":this.color};
    }
  } 
  accept(){
    this.configuration.acceptFn();
    this.ref.close();
  }

  reject(){
    this.configuration.rejectFn();
    this.ref.close();
  }

  getHtml(){
    //no parece necesario
    //return this.sanitizer.bypassSecurityTrustHtml(this.configuration.message);
    return this.configuration.message;
  }

}
