<div *ngIf="organizationType==null" class="page-title">{{view.getEntityBaseName(true)}}</div>
<div *ngIf="organizationType!=null" class="page-title">{{organizationType.name}}</div>
<ul class="breadcrumb">
  <li><a [routerLink]="['/home']"><span class="color-soft-grey-axial">{{'general.breadcrumb.you_are' | translate}} </span>{{'general.breadcrumb.home' | translate}}</a></li>
  <li><i class="pi pi-angle-right"></i></li>
  <li><a [routerLink]="['/organizations']">{{view.getEntityBaseName(true)}}</a></li>
  <li *ngIf="organizationType!=null"><i class="pi pi-angle-right"></i></li>
  <li *ngIf="organizationType!=null"><span> {{organizationType.name}}</span></li>
</ul>
<app-view #view [code]="'organizations.list'" [model]="organizationsFilters" (onEvent)="onEvent($event)"></app-view>
