<p-card>
  <div>
    <!--<div class="p-col-12 p-field">
      <div [innerHTML]="getState(notificationTo.state)"></div>
    </div>
    <div class="p-col-12">
      <table style="width: 100%;">
        <tr>
          <td>Para:</td>
          <td>{{notificationTo.mails}}</td>
        </tr>
        <tr>
          <td>Nº de veces abierto:</td>
          <td>{{notificationTo.times_open? notificationTo.times_open: "0"}}</td>
        </tr>
        <tr>
          <td>Última fecha abierto:</td>
          <td>{{notificationTo.last_time_opened? notificationTo.last_time_opened: "Sin abrir."}}</td>
        </tr>
      </table>

    </div>-->
    <div class="header-info p-col-12  p-grid">
      <div class="p-col-3" [innerHTML]="getState(notificationTo.state)"></div>
      <div class="p-col-12 p-grid">
        <div class="p-col-4">{{'component.mailing_to.to'|translate}}: </div>
        <div class="p-col-8">{{notificationTo.mails}}</div>
        <div class="p-col-4">{{'component.mailing_to.openedMails'|translate}}: </div>
        <div class="p-col-8">{{notificationTo.times_open? notificationTo.times_open: "0"}}</div>
        <div class="p-col-4">{{'component.mailing_to.last_date_opened'|translate}}: </div>
        <div class="p-col-8">
          <div *ngIf="notificationTo.last_time_opened != null">
            {{notificationTo.last_time_opened|date:'dd/MM/yyyy'}}
          </div>
          <div *ngIf="notificationTo.last_time_opened == null">
            {{'component.mailing_to.not_opened'|translate}}
          </div>
        </div>
      </div>
    </div>
    <!--<div class="p-col-12 p-field" *ngIf="notificationTo.text != null && notificationTo.text != ''">
      <label for="error">Error</label>
      <textarea name="error" id="error" cols="30" rows="10" readonly>{{notificationTo.text}}</textarea>
    </div>-->
    <div class="p-col-12" *ngIf="notificationTo.text != null && notificationTo.text != ''">
      <!--<label for="error">Error</label>-->
      <p-message name="error" severity="error" text="{{notificationTo.text}}" class="p-d-block"></p-message>
    </div>
    <div class="p-col-12" *ngIf="notificationTo.text == null || notificationTo.text == ''">
      <p-table [value]="notificationStats">
        <ng-template pTemplate="header">
          <tr>
            <th width="90">{{'component.mailing_to.type'|translate}}</th>
            <th>{{'component.mailing_to.description'|translate}}</th>
            <th width="90" style="text-align: center">{{'component.mailing_to.last_date_opened'|translate}}</th>
            <th width="120" style="text-align: center">{{'component.mailing_to.first_opening'|translate}}</th>
            <th width="120" style="text-align: center">{{'component.mailing_to.last_opening'|translate}}</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-notificationStat>
          <tr>
            <td>{{(notificationStat.type=='attachment'?'Adjunto':'Enlace')}}</td>
            <td *ngIf="notificationStat.type=='attachment'">
              {{notificationStat.notificationAttachment.attachment.description}}</td>
            <td *ngIf="notificationStat.type=='link'">{{notificationStat.value}}</td>
            <td width="90" style="text-align: center">{{notificationStat.times_open}}</td>
            <td style="text-align: center">{{notificationStat.first_time_opened | date: 'dd/MM/yyyy HH:mm'}}</td>
            <td style="text-align: center">{{notificationStat.last_time_opened | date: 'dd/MM/yyyy HH:mm'}}</td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td [attr.colspan]="5">
              {{'general.not_data' | translate }}
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</p-card>
