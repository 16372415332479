<div class="p-grid">
  <div class="p-col">
    <p-message severity="info" text="{{'component.entity.detail_fields_fields.info_multiple' | translate}}" class="p-d-block"></p-message>
  </div>
  <div class="p-col-12">
    <label for="items">{{'component.entity.detail_fields_fields.items' | translate}}</label><br>
    <textarea name="items" pInputTextarea [(ngModel)]="text"
              style="width: 100%; height: 360px; resize: none;"></textarea>
  </div>
  <div class="p-col-12 p-d-flex">
    <p-button label="{{'general.action.cancel' | translate}}" icon="pi pi-times" styleClass="p-button-secondary p-button-text" (click)="cancel()">
    </p-button>
    <p-button label="{{'general.action.save' | translate}}" icon="pi pi-check" styleClass="p-ml-2" (click)="save()">
    </p-button>
  </div>
</div>
