import { Component, OnInit } from '@angular/core';
import { LoginService } from '../login.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss']
})

export class ForgetPasswordComponent implements OnInit {
  public loginData: any = {};
  public errorMessage: string = "";
  
  constructor(private loginService: LoginService,
    private messageService: MessageService,
    private dynamicDialogRef: DynamicDialogRef,
    private config: DynamicDialogConfig
  ) {

  }

  ngOnInit() {
    this.errorMessage = "";
    this.loginData.code = this.config.data.code
  }

  reset_password() {
    if (this.loginData.username != "" && this.loginData.username != null && typeof this.loginData.username !== "undefined" && this.loginData.code != "" && this.loginData.code != null) {
      // Ha introducido un usuario o email
      this.errorMessage = "";
      this.loginService.resetPassword(this.loginData).subscribe(
        data => { 
          //Al final de todas las operaciones, se cierra la pantalla?
          this.dynamicDialogRef.close();
        },
        error => { 
          this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
        }
      );
    } else {
      // No ha introducido datos
      this.errorMessage = "Debes indicar la cuenta y tu usuario o email para restablecer la contraseña.";
      return;
    }
  }

  cancel() {
    this.dynamicDialogRef.close();
  }


}
