import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContactsListComponent } from './contacts-list/contacts-list.component';
import { OrganizationsContactsDetailComponent } from '../organizations/organizations-detail/organizations-contacts-detail/organizations-contacts-detail.component';

const routes: Routes = [
  { path: "contacts", component: ContactsListComponent},
  { path: "contacts/:id", component: OrganizationsContactsDetailComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ContactsRoutingModule { }
