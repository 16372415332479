import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { MailingDetailComponent } from 'src/app/mailing/mailing-detail/mailing-detail.component';
import { NotificationsService } from '../notifications.service';
import { MailAccountsService } from 'src/app/configuration/mail-accounts/mail-accounts.service';
import { CoreDialogService } from '../../core/dialogs/core-dialog.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-notifications-in',
  templateUrl: './notifications-in.component.html',
  styleUrls: ['./notifications-in.component.scss']
})
export class NotificationsInComponent implements OnInit {

  public notificationsFilters: any = { to : "Todos"};
  public notifications: any[] = [];
  public mailAccounts: any[] = [];
  public mailTos: any[] = [];
  public totalRecords: number;
  public loading: boolean = false;
  private lastGridEvent: any;

  constructor(

    private notificationsService: NotificationsService,
    private mailAccountService: MailAccountsService,
    private dialogService: DialogService,
    private messageService: MessageService,
    private coreDialogService: CoreDialogService,
    private translateService: TranslateService

  ) { }

  ngOnInit(): void {
    this.loadMailAccounts();
  }

  loadData($event) {
    this.lastGridEvent = $event;
    Promise.resolve(null).then(() => this.loading = true);
    var params = {
      _page: $event.first,
      _pageSize: $event.rows,
      _sortBy: $event.sortField ? $event.sortField : "",
      _sortDirection: $event.sortOrder > 0 ? "asc" : "desc",
      direction: "in",
      status: "pending",
      type: "email",
      to:""
    };
    
    params = Object.assign(params, this.notificationsFilters);
    if (this.notificationsFilters.to == "Todos") params.to = "";
    this.notificationsService.all(params).subscribe(
      data => {
        this.totalRecords = data.totalRows;
        this.notifications = data.rows;
        Promise.resolve(null).then(() => this.loading = false);
      },
      error => {
        this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
        Promise.resolve(null).then(() => this.loading = false);
      }
    );
  }

  loadMailAccounts() {
    this.mailAccountService.all({}).subscribe(
      data => {
        this.mailAccounts = data.rows;
        this.mailAccounts.unshift({ mail_out_from: "Todos" });
        this.notificationsFilters.to = "Todos";
      },
      error => {

      }
    );
  }

  delete(not) {
    this.coreDialogService.confirm({
      message: '¿Está seguro de que desea eliminar este elemento?',
      header: this.translateService.instant("general.confirmation_delete_title"),
      icon: 'pi pi-info-circle',
      accept: () => {
        this.notificationsService.delete(not.id).subscribe(
          data => {
            this.search();
            this.messageService.add({ closable: false, severity: 'success', summary: 'Elemento eliminado correctamente' });
          },
          error => {

          }
        )
      },
      reject: () => {

      }
    });

  }

  search() {
    this.lastGridEvent.first = 0;
    this.loadData(this.lastGridEvent);
  }

  clearFilters() {
    this.notificationsFilters = {to: "Todos"};
    this.search();
  }

  viewMail(data) {
    const ref = this.dialogService.open(MailingDetailComponent, {
      data: {
        mail_id: data.id,
        //organization_id: this.organization.id,
        entity_id: data.entity_id,
        //template_type_id: 1,
        template_type_code: "MAIL",
        //notification_to_id: data.id,
        searchRelated: true
      },
      header: this.translateService.instant("general.new_mail_title"),
      width: '70%',
    });
    ref.onClose.subscribe((data: any) => {
      if (data !== "undefined") {
        this.loadData(this.lastGridEvent);
      }
    });
  };
}
