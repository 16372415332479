<ul class="breadcrumb">
    <li><a [routerLink]="['/home']"><span class="color-soft-grey-axial">{{'general.breadcrumb.you_are' | translate}} </span>{{'general.breadcrumb.home' | translate}}</a></li>
    <li><i class="pi pi-angle-right"></i></li>
    <li><a [routerLink]="['/configuration/entities']">Configuración</a></li>
    <li><i class="pi pi-angle-right"></i></li>
    <li *ngIf="view"><a [routerLink]="['/configuration/mailAccounts']">{{view.getEntityBaseName(true)}}</a></li>
</ul>
<div class="p-grid">
    <div class="p-md-12">
        <p-card>
            <ng-template pTemplate="header">
              <div class="p-card-title">
                {{ 'general.filter.title' | translate}}
                <div class="p-card-title-actions">
                  <a (click)="search()"><i class="fas fa-search"></i>&nbsp;{{ 'general.action.search' | translate}} </a>
                </div>
              </div>
            </ng-template>
            <div class="p-grid">
                <div class="p-col-5 p-field">
                  <label for="name">{{ 'mailAccount.entity_fields.name' | translate}}</label>
                    <input id="name" type="text" pInputText [(ngModel)]="mailAccountsFilters.name" />
                </div>
                <div class="p-col-4 p-field">
                  <label for="mail_out_from">{{ 'mailAccount.entity_fields.mail_out_from' | translate}}</label>
                    <input id="mail_out_from" type="text" pInputText [(ngModel)]="mailAccountsFilters.mail_out_from" />
                </div>
                <div class="p-col-3 p-field">
                  <label for="type">{{ 'mailAccount.entity_fields.type' | translate}}</label>
                    <p-dropdown name="type" [options]="mail_types" optionValue="value" appendTo="body"
                        optionLabel="label" [(ngModel)]="mailAccountsFilters.type" placeholder="{{ 'general.select' | translate}}"
                        [showClear]="true"></p-dropdown>
                </div>
            </div>
        </p-card>
    </div>
    <div class="p-md-12">
        <p-card styleClass="p-card-content-fit">
            <ng-template pTemplate="header">
              <div class="p-card-title">
                {{ 'mailAccount.entity.name_plural' | translate}}
                <div class="p-card-title-actions">
                  <a (click)="editMailAccount({ id: 0 })"><fa-icon [icon]="['fas', 'plus-circle']"></fa-icon>{{ 'general.action.new' | translate}}</a>
                </div>
              </div>
            </ng-template>
            <p-table #grid [value]="mailAccounts" [lazy]="true" (onLazyLoad)="loadData($event)" [loading]="loading"
                [paginator]="true" [rows]="10" [totalRecords]="totalRecords" [showCurrentPageReport]="true"
                currentPageReportTemplate="{{ 'general.resumen_table_records' | translate: { first: first, last: last, totalRecords: totalRecords } }}"
                [rowsPerPageOptions]="[5,10,25,50]" paginatorDropdownAppendTo="body">
                <ng-template pTemplate="header">
                  <tr>
                    <th pSortableColumn="type">
                      {{ 'mailAccount.entity_fields.type' | translate}}
                    </th>
                    <th pSortableColumn="name" width="">{{ 'mailAccount.entity_fields.name' | translate}}</th>
                    <th pSortableColumn="mail_out_server" width="">{{ 'mailAccount.entity_fields.mail_out_server' | translate}}</th>
                    <th pSortableColumn="mail_out_port" width="">{{ 'mailAccount.entity_fields.mail_out_port' | translate}}</th>
                    <th pSortableColumn="mail_out_from">{{ 'mailAccount.entity_fields.mail_out_from' | translate}}</th>
                    <th pSortableColumn="created_by">{{ 'mailAccount.entity_fields.created_by' | translate}}</th>
                    <th width="100"></th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-mailAccount>
                  <tr (dblclick)="editMailAccount(mailAccount)">
                    <td>{{getAccountType(mailAccount)}}</td>
                    <td>{{mailAccount.name}}</td>
                    <td>{{mailAccount.mail_out_server}}</td>
                    <td>{{mailAccount.mail_out_port}}</td>
                    <td>{{mailAccount.mail_out_from}}</td>
                    <td>{{mailAccount.createdBy?.name}}</td>
                    <td class="grid-actions">
                      <a (click)="editMailAccount(mailAccount)"><fa-icon [icon]="['far', 'edit']"></fa-icon></a>
                      <a (click)="delete(mailAccount)" class="color-danger">
                        <fa-icon [icon]="['far', 'trash-alt']"></fa-icon>
                      </a>
                    </td>
                  </tr>
                </ng-template>
            </p-table>
        </p-card>
    </div>
</div>

<!--app-view #view [code]="'mailAccounts.list'" [model]="mailAccountsFilters" (onEvent)="onEvent($event)"></app-view-->
