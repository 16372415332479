import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NotificationsListComponent } from './notifications-list/notifications-list.component';
import { MailInOutListComponent } from './mail-in-out-list/mail-in-out-list.component';
import { NotificationsInComponent } from './notifications-in/notifications-in.component';

const routes: Routes = [
  { path: "notifications", component: NotificationsListComponent },
  { path: "notifications-in", component: NotificationsInComponent },
  { path: "emails", component: MailInOutListComponent },
  { path: "emails/:organization_id", component: MailInOutListComponent },
  { path: "emails/:organization_id/:contact_id", component: MailInOutListComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class NotificationsRoutingModule { }
