<div style="" class="mailAttachments">
  <p-accordion>
    <p-accordionTab *ngFor="let attach of attachmentsTypes">

      <p-header>
        <label>{{attach.name}}</label>
      </p-header>
      <div class="p-grid" *ngFor="let item of getAttachments(attach.id)">
        <p-checkbox name="group-{{item.id}}" value="{{item.id}}" [(ngModel)]="selectedAttachments" class="checkbox"
          [inputId]="'group-' + item.id"></p-checkbox>
        <div class="p-col-fixed">
          <img src="/assets/img/mimes/{{getImage(item.mimetype)}}" class="p-mt-1" />
        </div>
        <div class="p-col">
          <div class="d-flex flex-column">
            <div class="user_name text-truncate">
              <span class="fw-bold card-title"><b>{{item.description}}</b></span>
            </div>
            <small class="emp_post text-muted">{{item.filename}}</small>
            <small class="emp_post text-muted">&nbsp;&nbsp;<b>{{'component.mailing_attachment.update'|translate}}:</b>
              {{item.updated_at | date: 'dd/MM/yyyy HH:mm':'GMT'}}</small>
            <!--<small class="emp_post text-muted">Creación: {{item.created_at | date: 'dd/MM/yyyy HH:mm'}}</small>-->
            <small
              class="emp_post text-muted">&nbsp;&nbsp;<b>{{'component.mailing_attachment.created_by'|translate}}:</b>
              {{item.createdBy?.name}}</small>

          </div>
        </div>
      </div>

    </p-accordionTab>
  </p-accordion>
</div>
<div class="p-grid">
  <div class="p-col">
    <button class="p-ml-2" style="float: right;" pButton type="button"
      label="{{'component.mailing_attachment.select_attachments'|translate}}" (click)="selectAttachemnts()"></button>
    <button pButton class="p-button-link p-button-secondary" style="float: right;" (click)="cancel()" icon="pi pi-times"
      label="{{'general.action.cancel' | translate}}"></button>
  </div>
</div>