import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CoreDialogService } from 'src/app/core/dialogs/core-dialog.service';
import { ViewComponent } from 'src/app/core/view/view.component';
import { ViewsService } from 'src/app/core/view/views.service';

@Component({
  selector: 'app-views-detail',
  templateUrl: './views-detail.component.html',
  styleUrls: ['./views-detail.component.scss']
})
export class ViewsDetailComponent implements OnInit {
  @ViewChild("view") public view: ViewComponent;
  public item: any = {};

  constructor(
    private viewsService: ViewsService,
    private coreDialogService: CoreDialogService,
    private dynamicDialogRef: DynamicDialogRef,
    private messageService: MessageService,
    private config: DynamicDialogConfig,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.loadData(this.config.data.id);
  }
  loadData(id) {
    if (id) {
      this.viewsService.get(id).subscribe(
        data => {
          this.item = data;
        },
        error => {
          this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
        }
      );
    }
  }
  save(event) {
    if (!event.valid) {
      this.messageService.add({ closable: false, severity: "error", detail: this.translateService.instant("general.form_incomplete") });
      return;
    }
    if (typeof this.item.id === "undefined") {
      this.viewsService.add(this.item).subscribe(
        data => {
          this.item = data;
          this.messageService.add({ closable: false, severity: 'success', summary: "Plantilla añadida correctamente." });
          this.dynamicDialogRef.close();
        },
        error => {
          this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
        }
      )
    } else {
      this.viewsService.save(this.item.id, this.item).subscribe(
        data => {
          this.messageService.add({ closable: false, severity: "success", detail: "Plantilla guardada correctamente" });
          this.dynamicDialogRef.close();
        },
        error => {
          this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
        }
      )
    }
  }
  cancel() {
    this.dynamicDialogRef.close();
  }
  delete() {
    this.coreDialogService.confirm({
      message: "¿Quieres borrar la vista " + this.item.name + "?",
      header: this.translateService.instant("general.confirmation_delete_title"),
      icon: "pi pi-info-circle",
      accept: () => {
        this.viewsService.delete(this.item.id).subscribe(
          data => {
            this.messageService.add({ closable: false, severity: 'success', detail: this.translateService.instant("general.confirmation_delete") });
            this.dynamicDialogRef.close();
          },
          error => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        )
      },
      reject: () => {

      }
    })
  }
  onEvent(event) {
    if (event.component == "views.edit.general") {
      if (event.event == "action" && event.action.name == "delete") this.delete();
      if (event.event == "action" && event.action.name == "cancel") this.cancel();
      if (event.event == "action" && event.action.name == "save") this.save(event);
    }
    if (event.component == "view" && event.event == "entity-loaded") {
      this.config.header = typeof (this.item.id) !== "undefined" ? this.translateService.instant("component.views.detail.new_entity", { entity_name: this.view.getEntityBaseName() }) : this.translateService.instant("component.views.detail.new_entity", { entity_name: this.view.getEntityBaseName() });
    }
  }
}
