import { Input } from '@angular/core';
import { Component, ComponentFactoryResolver, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OrganizationsService } from '../../organizations.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-opportunities-months-widget',
  templateUrl: './opportunities-months-widget.component.html',
  styleUrls: ['./opportunities-months-widget.component.scss']
})
export class OpportunitiesMonthsWidgetComponent implements OnInit {
  @Input() public data: any;
  @Input() public entity: any;

  public ret: any = {};
  public height = 0;
  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private organizationService: OrganizationsService,
    private route: ActivatedRoute, private translateService: TranslateService
  ) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.loadStatsOpportunities(params['id']);
    });
  }

  onEvent(component: string, event: string) {
    // console.log(component, event);
  }

  loadStatsOpportunities(id) {
    this.organizationService.statsOpportunities(id).subscribe({
      next: (data: any) => {
        this.ret = data;
        this.loadBasicDataMonths();
      },
      error: (error: any) => {
        //TODO: messageService aquí.
      }
    });
  }

  //Configuración de tablas horizontales para meses.
  public basicDataMonths: any;
  public horizontalOptionsMonths: any;
  public monthNames: any = ["", "Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];
  public labels: any[] = [];

  loadBasicDataMonths() {
    var anyo = new Date();
    for (let i = 0; i < this.ret.monthLabels.length; i++) {
      this.labels.push(this.monthNames[this.ret.monthLabels[i]] + " " + anyo.getFullYear());
      this.height = this.height + 25;
      anyo.setMonth(anyo.getMonth() - 1);
    }

    this.basicDataMonths = {
      labels: this.labels,
      datasets: [
        {
          label: this.translateService.instant("component.organizations.component.organizations.opportunities_month_widget.total_sales_per_month"),
          backgroundColor: '#0000F5',
          data: this.ret.sumMonths
        }
      ]
    };
    this.horizontalOptionsMonths = {
      indexAxis: 'y',
      tooltips: {
        callbacks: {
          label: function (tooltipItem, data) {
            var label = Intl.NumberFormat('de-DE').format(tooltipItem.value);
            return label+ " €";
          }
        }
      },
      plugins: {
        legend: {
          labels: {
            color: '#000057'
          }
        }
      },
      scales: {
        x: {
          ticks: {
            color: '#000057'
          },
          grid: {
            color: '#ebedef'
          }
        },
        y: {
          ticks: {
            color: '#000057'
          },
          grid: {
            color: '#ebedef'
          }
        }
      }
    };
  }
}
