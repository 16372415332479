import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { EntitiesFieldsService } from 'src/app/configuration/entities-fields.service';

@Component({
  selector: 'app-add-item-to-entity-field-list',
  templateUrl: './add-item-to-entity-field-list.component.html',
  styleUrls: ['./add-item-to-entity-field-list.component.scss']
})
export class AddItemToEntityFieldListComponent implements OnInit {

  public element: any;
  public is_create: any = false;
  public entityField: any;

  constructor(
    private config: DynamicDialogConfig,
    private messageService: MessageService,
    private dynamicDialogRef: DynamicDialogRef,
    private entitiesFieldsService: EntitiesFieldsService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.element = {};
    if (this.config.data.element != null) {
      this.element = JSON.parse(JSON.stringify(this.config.data.element));
      //Si llega aquí es que el usuario está editando un elemento.
      this.is_create = false;
    } else {
      //Si llega aquí, es que el usuario está creando un elemento nuevo.
      this.is_create = true;
    }
    this.entityField = this.config.data.entityField;
  }

  delete() {

  }

  cancel() {
    this.dynamicDialogRef.close(this.entityField);
  }

  save(saveAndContinue: boolean = false) {
    if (this.element.code && this.element.text) {
      //Si estamos creando un nuevo elemento, hay que comprobar que no está el código en la lista:
      var indice = this.entityField.configuration.options?.items?.findIndex(op => op.code == this.element.code.trim());
      if (this.is_create && indice >= 0) {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: this.translateService.instant("component.entity.detail_fields_fields.element_exist_code") });
        return;
      }

      if (this.isEmpty(this.entityField.configuration)) this.entityField.configuration = { options: { items: [] }, field_text: 'text', field_value: 'code' };
      if (this.entityField.configuration.field_text == null) this.entityField.configuration = { field_text: 'text', field_value: 'code' };
      if (this.entityField.configuration.options == null) this.entityField.configuration['options'] = {};
      if (this.entityField.configuration.options.items == null) this.entityField.configuration.options['items'] = [];

      if (indice >= 0) {
        //Si ya existe, sobreescribimos:
        this.entityField.configuration.options.items[indice] = { code: this.element.code.trim(), text: this.element.text.trim() };
      } else {
        //Si no lo encuentra, simplemente lo añade:
        this.entityField.configuration.options?.items?.push({ code: this.element.code.trim(), text: this.element.text.trim() });
      }

      //this.modelMonaco = JSON.stringify(this.entityField.configuration, null, "\t");

      if (this.entityField.id) {
        this.entitiesFieldsService.save(this.entityField.id, this.entityField).subscribe({
          next: ( data:any) => {
            if(this.is_create) {
              this.messageService.add({ closable: false, severity: 'success', detail: this.translateService.instant("component.entity.detail_fields_fields.add_correct") });
            } else {
              this.messageService.add({ closable: false, severity: 'success', detail: this.translateService.instant("component.entity.detail_fields_fields.save_correct") });
            }
            if (saveAndContinue) {
              this.element = {};
              this.is_create = true;
            } else {
              this.dynamicDialogRef.close(this.entityField);
            }
          },
          error: (error:any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        });
      } else {
        if (this.is_create) {
          this.messageService.add({ closable: false, severity: 'success', detail: this.translateService.instant("component.entity.detail_fields_fields.add_correct") });
        } else {
          this.messageService.add({ closable: false, severity: 'success', detail: this.translateService.instant("component.entity.detail_fields_fields.save_correct") });
        }
        if (saveAndContinue) {
          this.element = {};
          this.is_create = true;
        } else {
          this.dynamicDialogRef.close(this.entityField);
        }
      }
    } else {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: this.translateService.instant("component.entity.detail_fields_fields.error_no_data") });
    }
  }
  isEmpty(obj) {
    return Object.keys(obj).length === 0;
  }
}
