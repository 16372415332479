<p-card>
  <ng-template pTemplate="header">
    <div class="p-card-title">
      {{componentService.getComponentTitle(component)}}
      <small *ngIf="showConfigOptions|| debugService.enabled">Component: {{component.code}}, Entity: {{component.entity}}</small>
      <div *ngIf="!showConfigOptions" class="p-card-title-actions">
        <ng-container *ngFor="let action of component.headerActions">
          <a *ngIf="isActionVisible(action, item)" (click)="onActionClick(action, item)" class="{{action.class}}">
            <fa-icon *ngIf="action.icon!=null" [icon]="action.icon"></fa-icon>&nbsp;{{getActionLabel(action)}}
            <br *ngIf="debugService.enabled" /><small *ngIf="debugService.enabled">{{component.code}}.{{action.name}}</small>
          </a>
        </ng-container>
        <a (click)="showTracking()" *ngIf="component.show_tracking && configurationPermission">
          <fa-icon [icon]="['fas', 'info-circle']"></fa-icon>
        </a>
      </div>
      <div *ngIf="showConfigOptions" class="p-card-title-actions">
        <ng-content select="[toolbarStart]"></ng-content>
        <ng-content select="[toolbarEnd]"></ng-content>
      </div>
    </div>
  </ng-template>
  <ng-container #customComponentContainer></ng-container>
</p-card>
