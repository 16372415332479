<div class="p-grid" style="padding-inline: 80px;">
    <div class="p-col">
        <p-card style="text-align: center;">
            <h2>{{'component.mailMarketing.toStats.sentMails' | translate}}</h2>
            <h1>{{sentMails}}</h1>
        </p-card>
    </div>
    <div class="p-col">
        <p-card style="text-align: center;">
            <h2>{{'component.mailMarketing.toStats.openedMails'|translate}}</h2>
            <h1>{{openedMails}}</h1>
        </p-card>
    </div>
    <div class="p-col">
        <p-card style="text-align: center;">
          <h2>{{'notificationToStat.entity_fields.nClicks' | translate}}</h2>
            <h1>{{nClicks}}</h1>
        </p-card>
    </div>
</div>
<br>
<p-table #grid [value]="notificationToStats" [lazy]="true" (onLazyLoad)="loadData($event)" [loading]="loading"
    [paginator]="true" [rows]="10" [totalRecords]="totalRecords" [showCurrentPageReport]="true" [sortOrder]="-1"
    currentPageReportTemplate="{{'component.mailMarketing.toStats.resumen_table_records' | translate}}"
    [rowsPerPageOptions]="[5,10,25,50]" paginatorDropdownAppendTo="body">
    <ng-template pTemplate="header">
        <tr>
            <th pSortableColumn="notificationEmails.subject" width="350">Campaña</th>
            <th pSortableColumn="type" width="100">Tipo</th>
            <th pSortableColumn="value">Enlace</th>
            <th pSortableColumn="last_time_opened">{{'notificationToStat.entity_fields.last_time_opened' | translate}}
            </th>
            <!--th width="100"></th-->
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-notificationToStat>
        <tr (dblclick)="edit(notificationToStat)">
            <td>{{notificationToStat.notificationTo?.notificationEmails?.subject}}</td>
            <td>
                <div *ngIf="notificationToStat.type == 'attachment'">
                    {{'attachment.entity.name'|translate}}
                </div>
                <div *ngIf="notificationToStat.type != 'attachment'">
                    {{notificationToStat.type != 'attachment'?notificationToStat.type:''}}
                </div>
            </td>
            <td>
                <a (click)="openUrl(notificationToStat)"
                    *ngIf="notificationToStat.value!=null && notificationToStat.value != ''">{{notificationToStat.value}}</a>
                <!--a *ngIf="notificationToStat.notificationLink?.url != null && notificationToStat.notificationLink?.url != ''"
                    (click)="openUrl(notificationToStat)">{{notificationToStat.notificationLink.url}}</a-->
            </td>
            <td>{{notificationToStat.last_time_opened | date: 'dd/MM/yyyy hh:mm'}}</td>

            <!--td class="grid-actions">
                <a (click)="edit(notificationToStat)"><fa-icon [icon]="['far', 'edit']"></fa-icon></a>
                <a (click)="delete(notificationToStat)" class="color-danger"><fa-icon [icon]="['far', 'trash-alt']"></fa-icon></a>
            </td-->
        </tr>
    </ng-template>
</p-table>
