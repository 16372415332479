<p-card>
  <ng-template pTemplate="header">
    <div class="p-card-title p-d-flex">
      <div class="flex-grow-1">{{'lead.entity.name_plural'|translate}}</div>
      <div>
        <p-inputSwitch [(ngModel)]="integration.configuration.enabled"></p-inputSwitch>
      </div>
    </div>
  </ng-template>
  <div class="p-grid" *ngIf="integration.configuration.enabled">
    <div class="p-col-12 m-0 p-field">
      <label for="url">{{'component.integrations.endpoint'|translate}}</label>
      <textarea [(ngModel)]="integration.configuration.url" id="url" type="text" pInputText name="url" placeholder=""
        autocomplete="false" readonly></textarea>
    </div>
    <div class="p-col-12 m-0 p-field">
      <label for="url_ok">{{'component.integrations.thank_you_url'|translate}}</label>
      <input [(ngModel)]="integration.configuration.url_ok" id="url_ok" name="url_ok" type="text" pInputText
        placeholder="Ej: https://tudominio.com/gracias" autocomplete="false" />
      <small>{{'component.integrations.thank_you_url_description'|translate}}</small>
    </div>
    <div class="p-col-12 m-0 p-field">
      <label for="url_ok">{{'component.integrations.error_url'|translate}}</label>
      <input [(ngModel)]="integration.configuration.url_ko" id="url_ko" name="url_ko" type="text" pInputText
        placeholder="Ej: https://tudominio.com/error" autocomplete="false" />
      <small>{{'component.integrations.error_url_description'|translate}}</small>
    </div>
  </div>
</p-card>

<p-card styleClass="p-mt-4" *ngIf="integration.configuration.enabled">
  <ng-template pTemplate="header">
    <div class="p-card-title p-d-flex">
      <div class="flex-grow-1">{{'component.integrations.recaptcha_validation' | translate}}</div>
      <div>
        <p-inputSwitch [(ngModel)]="integration.configuration.recaptcha.enabled"></p-inputSwitch>
      </div>
    </div>
  </ng-template>
  <div class="p-grid" *ngIf="integration.configuration.recaptcha?.enabled">
    <div class="p-col-12 m-0 p-field">
      <label for="secret">{{'component.integrations.site_secret_key'|translate}}</label>
      <p-password [(ngModel)]="integration.configuration.recaptcha.secret" id="secret" name="secret" [toggleMask]="true"
        [feedback]="false" autocomplete="false"></p-password>
    </div>
    <div class="p-col-12 m-0 p-field">
      <label for="key">{{'component.integrations.site_key'|translate}}</label>
      <p-password [(ngModel)]="integration.configuration.recaptcha.key" id="key" name="key" [toggleMask]="true"
        [feedback]="false" autocomplete="false"></p-password>
    </div>
  </div>
</p-card>

<p-card styleClass="p-mt-4" *ngIf="integration.configuration.enabled">
  <ng-template pTemplate="header">
    <div class="p-card-title p-d-flex">
      <div class="flex-grow-1">{{'component.integrations.task_creation'|translate}}</div>
    </div>
  </ng-template>
  <div class="p-grid">
    <div class="p-col-12 m-0 p-field">
      <label for="url_ok">{{'component.integrations.task_default_action'|translate}}</label>
      <p-dropdown name="task_action" [options]="task_actions" [(ngModel)]="integration.configuration.task_action_id"
        optionLabel="name" optionValue="id" appendTo="body"></p-dropdown>
    </div>
    <div class="p-col-12 m-0 p-field">
      <label for="url_ok">{{'component.integrations.task_default_responsible'|translate}}</label>
      <p-dropdown name="responsible_id" [options]="responsibles" [(ngModel)]="integration.configuration.responsible_id"
        optionLabel="name" optionValue="id" appendTo="body"></p-dropdown>
    </div>
    <div class="p-col-12 m-0 p-field">
      <label for="url_ok">{{'component.integrations.task_default_status'|translate}}</label>
      <p-dropdown name="task_status" [options]="task_status" [(ngModel)]="integration.configuration.task_status_id"
        optionLabel="name" optionValue="id" appendTo="body"></p-dropdown>
    </div>
    <div class="p-col-12 m-0 p-field">
      <label for="url_ok">{{'component.integrations.task_default_subject'|translate}}</label>
      <input [(ngModel)]="integration.configuration.subject" id="subject" name="subject" type="text"
        autocomplete="false" />
    </div>
    <div class="p-col-12 m-0 p-field">
      <label for="url_ok">{{'component.integrations.lead_default_action'|translate}}</label>
      <p-dropdown name="organization_id" [options]="type_process_options"
        [(ngModel)]="integration.configuration.typeProcessOptions" optionLabel="label" optionValue="value"
        appendTo="body"></p-dropdown>
    </div>
    <div class="p-col-12 m-0 p-field">
      <label for="url_ok">{{'component.integrations.lead_default_organization'|translate}}</label>
      <p-dropdown name="organization_id" [options]="organizations"
        [(ngModel)]="integration.configuration.organization_id" optionLabel="name" optionValue="id" appendTo="body"
        [filter]="true" filterBy="name" [showClear]="true" (onFilter)="loadOrganizations($event)"></p-dropdown>
    </div>
  </div>
</p-card>

<div class="p-col-12 p-d-flex">
  <div class="flex-grow-1"></div>
  <p-button label="{{'general.action.cancel' | translate}}" icon="pi pi-times"
    styleClass="p-button-secondary p-button-text" (click)="cancel()">
  </p-button>
  <button class="p-ml-2" style="float: right;" icon="pi pi-save" pButton type="button"
    label="{{'general.action.save' | translate}}" (click)="save()"></button>
</div>