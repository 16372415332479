<ul class="breadcrumb">
  <li><a [routerLink]="['/home']"><span class="color-soft-grey-axial">{{'general.breadcrumb.you_are' | translate}} </span>{{'general.breadcrumb.home' | translate}}</a></li>
  <li><i class="pi pi-angle-right"></i></li>
  <li><a [routerLink]="['/configuration/entities']">{{ 'general.configuration' | translate}}</a></li>
  <li><i class="pi pi-angle-right"></i></li>
  <li *ngIf="view"><a [routerLink]="['/configuration/transactionsStatus']">{{view.getEntityBaseName(true)}}</a></li>
</ul>
<app-view #view [code]="'transactionStatus.list'" [model]="transactionsStatusFilters" (onEvent)="onEvent($event)"></app-view>
<!--
    *ngIf="selectedTransactionType!=null"
-->
