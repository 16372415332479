import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TaskStatusListComponent } from './task-status-list/task-status-list.component';
import { TaskStatusDetailComponent } from './task-status-detail/task-status-detail.component';

const routes: Routes = [
  { path: "taskStatus", component: TaskStatusListComponent },
  { path: "taskStatus/:id", component: TaskStatusDetailComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TaskStatusRoutingModule { }
