<ul class="breadcrumb">
    <li><a [routerLink]="['/home']"><span class="color-soft-grey-axial">{{'general.breadcrumb.you_are' | translate}}
            </span>{{'general.breadcrumb.home' | translate}}</a></li>
    <li><i class="pi pi-angle-right"></i></li>
    <li><a [routerLink]="['/configuration/entities']">{{'general.configuration' | translate}}</a></li>
    <li><i class="pi pi-angle-right"></i></li>
    <li><a [routerLink]="['/notifications']">{{'component.notifications.list.title' | translate}}</a></li>
</ul>
<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000"></p-confirmDialog>

<!--<app-view #view [code]="'countries.list'" [model]="countriesFilters" (onEvent)="onEvent($event)"></app-view>-->

<div class="p-grid">
    <div class="p-md-12">
        <p-card>
            <ng-template pTemplate="header">
                <div class="p-card-title">
                    {{'general.filter.title' | translate}}
                    <div class="p-card-title-actions">
                        <a (click)="clearFilters()">{{'general.filter.clean' | translate}}</a>
                        <a (click)="search()"><i class="fas fa-search"></i>&nbsp;{{'general.filter.search' | translate}}
                        </a>
                    </div>
                </div>
            </ng-template>
            <div class="p-grid">
                <div class="p-col p-field">
                    <label for="organization_id">{{'organization.entity.name' | translate}}</label>
                    <p-dropdown [options]="organizations" [(ngModel)]="filters.organization_id" optionLabel="name"
                        optionValue="id" [filter]="true" filterBy="name" [showClear]="true"
                        placeholder="{{'component.notifications.detail.select_organization' | translate}}"
                        (onChange)="changeOrganization()" (onFilter)="searchOrganization($event)">
                    </p-dropdown>
                </div>
                <div class="p-col-4 p-field" *ngIf="filters.organization_id != null">
                    <label for="contact_id">{{'contact.entity.name' | translate}}</label>
                    <p-dropdown [options]="contacts" [(ngModel)]="filters.contact_id" optionLabel="name"
                        optionValue="id" [filter]="true" filterBy="name" [showClear]="true"
                        placeholder="{{'component.notifications.detail.select_contact' | translate}}">
                    </p-dropdown>
                </div>
                <div class="p-col-12 p-field">
                    <label for="subject">{{'notificationTo.entity_fields.subject' | translate}}</label>
                    <input id="subject" type="text" pInputText [(ngModel)]="filters.subject" />
                </div>
                <div class="p-col-4 p-field">
                    <label for="email">{{'notificationTo.entity_fields.email' | translate}}</label>
                    <input id="email" type="text" pInputText [(ngModel)]="filters.email" />
                </div>
                <div class="p-col p-field">
                    <label for="entity_id">{{'notificationTo.entity_fields.origin' | translate}}</label>
                    <p-dropdown [options]="entities" [(ngModel)]="filters.entity_id" optionLabel="name" optionValue="id"
                        [filter]="true" filterBy="name" [showClear]="true"
                        placeholder="{{'component.notifications.detail.select_origin' | translate}}">
                    </p-dropdown>
                </div>
            </div>
        </p-card>

    </div>
    <div class="p-md-12">
        <p-card styleClass="p-card-content-fit">
            <ng-template pTemplate="header">
                <div class="p-card-title">
                    {{'component.notifications.list.title' | translate}}
                </div>
            </ng-template>
            <p-table #grid [lazy]="true" (onLazyLoad)="loadData($event)" [loading]="loading" [paginator]="true"
                [rows]="10" [totalRecords]="totalRecords" [showCurrentPageReport]="true"
                currentPageReportTemplate="{{'component.notifications.list.resumen_table_records' | translate}}"
                [rowsPerPageOptions]="[5,10,25,50]" [value]="notificationsEmails" dataKey="name"
                paginatorDropdownAppendTo="body" responsiveLayout="scroll">
                <ng-template pTemplate="header">
                    <tr>
                        <th pSortableColumn="date">{{'notification.entity_fields.date' | translate}}</th>
                        <th pSortableColumn="subject">{{'notification.entity_fields.subject' | translate}}</th>
                        <th pSortableColumn="from">{{'notification.entity_fields.from' | translate}}</th>
                        <th pSortableColumn="entity_id">{{'notification.entity_fields.entity_id' | translate}}</th>
                        <!--th pSortableColumn="status">Estado </th-->
                        <th width="60px"></th>
                        <!--<th pSortableColumn="attachments">Asunto </th>-->
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-notificationsEmail let-expanded="expanded">
                    <tr (dblclick)="viewMail(notificationsEmail)">
                        <td>
                            {{notificationsEmail.date | date: 'dd/MM/yyyy HH:mm'}}
                            {{notificationsEmail.date ? '':'-'}}
                        </td>
                        <td>{{notificationsEmail.subject}}</td>
                        <td>{{notificationsEmail.from}}</td>
                        <td>{{notificationsEmail.entity?.name}}</td>
                        <!--td [innerHTML]="getState(notificationsEmail.status)"></td-->
                        <td class="grid-actions">
                            <a (click)="resendMail(notificationsEmail)">
                                <fa-icon [icon]="['fas', 'share']"></fa-icon>
                            </a>
                            <a (click)="viewMail(notificationsEmail)">
                                <fa-icon [icon]="['fas', 'eye']"></fa-icon>
                            </a>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </p-card>
    </div>
</div>
