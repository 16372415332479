<div class="p-grid">
            <div class="p-col-12 p-field">
              <label for="name">{{'component.tasksActionsTypes.detail.name' | translate}}</label>
            <input pInputText type="text" name="name" [(ngModel)]="taskActionType.name">
        </div>
    <div class="p-col-12">
        <button pButton class="p-button-danger" *ngIf="taskActionType.id>0" (click)="delete()" icon="pi pi-trash"
            label="{{'general.action.delete' | translate}}"></button>
        <button pButton class="p-ml-2" style="float: right;" (click)="save()" icon="pi pi-check"
            label="{{'general.action.save' | translate}}"></button>
        <button pButton class="p-button-secondary p-button-text" style="float: right;" (click)="cancel()" icon="pi pi-times"
            label="{{'general.action.cancel' | translate}}"></button>
    </div>
</div>
