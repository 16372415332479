<div #container>
  <!--div *ngIf="have_tracking_notes">
    <form #formDetails="ngForm" style="text-align: center;">
      <textarea name="message" id="message" rows="2"
        style="width: 100%; resize: vertical; margin-left: -16px; margin-right: -16px;"></textarea>
    </form>
  </div-->
  <div *ngIf="mode=='table'" class="table-container" style="margin-left: -16px; margin-right: -16px;">
    <p-table styleClass="no-mobile" [lazy]="true" (onLazyLoad)="loadHistory($event)" [value]="history_data.values"
      [loading]="loading" [paginator]="true" [totalRecords]="totalRows" [showCurrentPageReport]="true"
      currentPageReportTemplate="{{ 'general.resumen_table_records' | translate: { first: first, last: last, totalRecords: totalRows } }}"
      [rowsPerPageOptions]="rowsPerPageOptions" [rows]="rowsPerPage" [scrollable]="true">
      <ng-template pTemplate="header">
        <tr>
          <th *ngFor="let column of getColumns()"><b>{{column.label}}</b></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-item let-index="rowIndex">
        <tr [class.no-border-bottom]="item.tracking_message != null && item.tracking_message != ''">
          <td *ngFor="let column of getColumns(); let c = index">{{getValueFor(item, column)}}</td>
        </tr>
        <tr *ngIf="item.tracking_message != null && item.tracking_message != ''">
          <td></td>
          <td [attr.colspan]="1+(getColumns().length)">
            <div class="message"><fa-icon [icon]="['fas', 'circle-info']"></fa-icon>&nbsp;<small>{{ item.tracking_message }}</small></div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>

  <div *ngIf="mode=='card'">
    <div *ngIf="loading" class="component-loader-top">
      <p-progressBar mode="indeterminate" [style]="{ height: '3px' }"></p-progressBar>
    </div>
    <div class="timeline">
      <div class="timeline-event" *ngFor="let event of history_data.values">
        <div class="p-d-flex p-ai-center p-mt-3 p-pt-2" (click)="toogleEvent(event)" style="cursor: pointer;">
          <div class="card-title flex-grow-1">
            <span *ngFor="let column of getColumns('header')" class="p-mr-2">{{getValueFor(event, column)}}</span>
            <span *ngIf="event.channel!=null && event.channel=='import'" class="p-mr-2">{{'general.tracking_import' | translate}}</span>
          </div>
          <a>
            <fa-icon *ngIf="event.is_opened" [icon]="['fas', 'chevron-up']"></fa-icon>
            <fa-icon *ngIf="!event.is_opened" [icon]="['fas', 'chevron-down']"></fa-icon>
          </a>
        </div>
        <div class="timeline-event-details p-d-flex p-flex-column" *ngIf="event.is_opened">
          <div *ngFor="let column of getColumns('detail')" class="item-data">
            <label>{{column.label}}</label>
            <div class="value">{{getValueFor(event, column)}}</div>
          </div>
          <div *ngIf="event.tracking_message!=null && event.tracking_message!=''" class="message">
            <fa-icon [icon]="['fas', 'circle-info']"></fa-icon>&nbsp;{{event.tracking_message}}
          </div>
        </div>
      </div>
    </div>

    <p-paginator (onPageChange)="onPageChange($event)" [first]="lastGridEvent.first" [rows]="lastGridEvent.rows"
      [totalRecords]="totalRows" [rowsPerPageOptions]="rowsPerPageOptions"></p-paginator>
  </div>

</div>
