import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AttachmentsRoutingModule } from './attachments-routing.module';
import { AttachmentsListComponent } from './attachments-list/attachments-list.component';
import { AttachmentsDetailComponent } from './attachments-detail/attachments-detail.component';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { SplitButtonModule } from 'primeng/splitbutton';
import { TableModule } from 'primeng/table';
import { TimelineModule } from 'primeng/timeline';
import { CoreModule } from '../core/core.module';
import { FileUploadModule } from 'primeng/fileupload';
import { HttpClientModule } from '@angular/common/http';
import { TabViewModule } from 'primeng/tabview';
import { EditorModule } from '@tinymce/tinymce-angular';
import { GenericAttachmentsDetailComponent } from './generic-attachments-detail/generic-attachments-detail.component';
import { GenericAttachmentsListComponent } from './generic-attachments-list/generic-attachments-list.component';
import { TranslateModule } from '@ngx-translate/core';
import { SidebarModule } from 'primeng/sidebar';


@NgModule({
  declarations: [AttachmentsListComponent, AttachmentsDetailComponent, GenericAttachmentsDetailComponent, GenericAttachmentsListComponent],
  imports: [
    CommonModule,
    AttachmentsRoutingModule,
    InputTextModule,
    ButtonModule,
    TableModule,
    ConfirmDialogModule,
    DialogModule,
    FormsModule,
    CheckboxModule,
    CoreModule,
    CardModule,
    FontAwesomeModule,
    InputTextareaModule,
    DropdownModule,
    CalendarModule,
    SplitButtonModule,
    TimelineModule,
    FileUploadModule,
    HttpClientModule,
    TabViewModule,
    EditorModule,
    TranslateModule,
    SidebarModule
  ],
  exports:[AttachmentsListComponent, AttachmentsDetailComponent]
})
export class AttachmentsModule { }
