import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CoreDialogService } from '../../../core/dialogs/core-dialog.service';
import { CompaniesIntegrationsService } from '../companiesintegrations.service';
import { IntegrationEditOffice365UserEditComponent } from './user-config/user-config.component';
import { UsersService } from 'src/app/users/users.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-integration-edit-office365',
  templateUrl: './integration-edit-office365.component.html',
  styleUrls: ['./integration-edit-office365.component.scss']
})
export class IntegrationEditOffice365Component implements OnInit {

  public integration: any = {
    configuration: {
      users: []
    }
  };

  public connected: boolean = false;
  public connectionInfo: any = {};
  public users: any[] = [];

  constructor(
    private coreDialogService: CoreDialogService,
    private dialogService: DialogService,
    private dynamicDialogRef: DynamicDialogRef,
    private companiesIntegrationsService: CompaniesIntegrationsService,
    private messageService: MessageService,
    private usersService: UsersService,
    private translateService: TranslateService,
  ) { }

  ngOnInit() {
    this.companiesIntegrationsService.getByCode("office365").subscribe({
      next: (data: any) => {
        if (data != null) {
          if (data.configuration == null) data.configuration = {};
          if (data.configuration.users == null) data.configuration.users = [];
          this.integration = data;
          this.loadUsers();
        }
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
      }
    });
  }

  save() {
    this.companiesIntegrationsService.save(this.integration.id, this.integration).subscribe({
      next: (data: any) => {
        this.dynamicDialogRef.close();
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
      }
    });
  }

  cancel() {
    this.dynamicDialogRef.close();
  }

  loadUsers() {
    let params: any = {
      _sortBy: "name"
    };
    this.usersService.all(params).subscribe({
      next: (users: any) => {
        this.users = users.rows;
        this.users.forEach(user => {
          let currentUserConfig = this.integration.configuration.users.find(m => m.user_id == user.id);
          if (currentUserConfig == null) {
            currentUserConfig = {
              user_id: user.id,
              name: user.name,
              contacts: { enabled: false },
              mail: { enabled: false },
              calendar: { enabled: false },
              connected: false,
              id: null
            };
            this.integration.configuration.users.push(currentUserConfig);
          } else {
            currentUserConfig.name = user.name;
          }
        });

      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
      }
    });
  }
  
  configUser(user: any) {
    const ref = this.dialogService.open(IntegrationEditOffice365UserEditComponent, {
      header: this.translateService.instant('component.integrations.user_configuration'),
      width: '500px',
      data: {
        user: user
      }
    });
    ref.onClose.subscribe((data: any) => {
      if (data) {
        user.contacts = data.contacts;
        user.mail = data.mail;
        user.calendar = data.calendar;
      }
    });
  }
}
