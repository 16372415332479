import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Md5 } from 'ts-md5/dist/md5';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  onLoginStateChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  private isLogged: boolean = false;
  public loadedUserId: string = null;
  public loadedCompanyId: string = null;

  url = "/api/auth";

  constructor(
    private http: HttpClient,
    private router: Router
  ) {
    this.checkLoadedSession();
  }

  setLoggedState(state: boolean) {
    this.isLogged = state;
    console.log(`Login state: ${state}`);
    //retrasamos la emision del evento
    setTimeout(() => {
      this.onLoginStateChange.emit(this.isLogged);
    }, 500);
  }

  checkLogged() {
    console.log("checkLogged",location.pathname);
    if (location.pathname != "/login") {
      if (localStorage.getItem("token") && !this.isLogged) {
        this.checkToken().subscribe(
          data => {
            if (data.success) {
              this.setLoggedState(true);
            } else {
              console.warn("Token not valid. Logging out.");
              localStorage.removeItem("token");
            }
          }
        );
      }
    } else {
      console.warn("Token removed!");
      localStorage.removeItem("token");
    }
  }

  isLoggedIn() {
    /*if(localStorage.getItem("token") && !this.isLogged){
        this.setLoggedState(true);
    }*/
    return this.isLogged;
  }

  login(obj): Observable<any> {
    var login = {
      email: obj.username,
      password: obj.password,
      code: obj.code,
      language_code: localStorage != null && localStorage.getItem("language") ? localStorage.getItem("language") : "es"
    };
    const md5 = new Md5();
    login.password = md5.appendStr(login.password).end();
    return this.http.post<object>(this.url + "/login", login);
  }

  loginByToken(auth_token): Observable<any> {
    return this.http.get<object>(this.url + "/login/" + auth_token);
  }

  checkToken(): Observable<any> {
    return this.http.get<object>(this.url + "/checktoken");
  }

  disableComercialComunications(company_id, contact_id): Observable<any> {
    return this.http.post<object>(this.url + "/disableComercialComunications/" + company_id + "/" + contact_id, { company_id: company_id, contact_id: contact_id });
  }
  get = function (key) {
    return localStorage.getItem(key)
  };

  hasPermission(permission) {
    const value = localStorage.getItem("permissions");
    if (value !== null) {
      const res = value.split(",");
      const index = res.indexOf(permission);
      if (index !== -1) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  hasFeature(feature) {
    const value = localStorage.getItem("features");
    if (value !== null) {
      const res = value.split(",");
      const index = res.indexOf("" + feature);
      if (index !== -1) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  resetPassword(email): Observable<any> {
    var login = {
      value: email
    };
    return this.http.post<object>(this.url + "/reset_password", login);
  }

  notificateChange(email): Observable<any> {
    var login = {
      value: email
    }
    return this.http.post<any>(this.url + "/notificateChange", login);
  }

  logout(for_clean_session = false) {
    console.warn("Logout!");
    this.isLogged = false;
    localStorage.removeItem("token");
    localStorage.removeItem("permissions");
    localStorage.removeItem("forgotPassword");
    localStorage.removeItem("roleId");
    localStorage.removeItem("userSubordinatesIds");
    localStorage.removeItem("userZonesIds");
    localStorage.removeItem("companyName");
    localStorage.removeItem("companyCode");
    //localStorage.removeItem("code");
    localStorage.removeItem("isSuperAdmin");
    localStorage.removeItem("companyId");
    sessionStorage.clear();

    if (window.parent) {
      let message: any = {
        action: "remove",
        properties: "code,username,password,remember"
      };
      window.parent.postMessage(message, '*');
    }

    this.setLoggedState(false);
    if (!for_clean_session) {
      location.replace("/");
    }
  }

  notFound() {

    this.router.navigateByUrl("/page-not-found");
  }

  canEditModel(model: any, componentEntity) {
    if (componentEntity == "transactions" && this.hasPermission("TRANSACTIONS_SUPERADMIN")) return true;
    if (componentEntity == "oportunity" && this.hasPermission("OPORTUNITIES_SUPERADMIN")) return true;
    if (componentEntity == "sale" && this.hasPermission("SALES_SUPERADMIN")) return true
    if (componentEntity == "task" && this.hasPermission("TASKS_SUPERADMIN")) return true;
    //Creo que estos dos no se utilizan, pero los mantengo por si acaso.
    if (componentEntity == "organization" && this.hasPermission("ORGANIZATIONS_SUPERADMIN")) return true;
    if (componentEntity == "contact" && this.hasPermission("ORGANIZATIONS_SUPERADMIN")) return true;
    if (componentEntity == "template" && this.hasPermission("TEMPLATES_SUPERADMIN")) return true;

    let editable = this.isUserResponsible(model);
    if (!editable && this.isUserSubordinate(model)) editable = true;
    if (!editable && this.isUserZone(model) && this.isUserZoneWritable(componentEntity)) editable = true;
    return editable;
  }

  isUserResponsible(model) {

    let user_id = localStorage.getItem("userId");
    let responsible_id = null;
    let responsible_2_id = null;
    if (model != null) {
      //responsable 1
      responsible_id = eval("model.responsible_id");
      if (responsible_id == null) responsible_id = eval("model.responsible_user_id");
      if (responsible_id == null) responsible_id = eval("model.transaction!=null?model.transaction.responsible_id:null"); //caso de una linea de transaccion, el resposnable esta en la transaccion
      if (responsible_id == null) responsible_id = eval("model.organization!=null?model.organization.responsible_id:null"); //caso de algo que cuelga de organizacion, por ejemplo contacto

      //responsable 2
      responsible_2_id = eval("model.responsible_2_id");
      if (responsible_2_id == null) responsible_2_id = eval("model.organization!=null?model.organization.responsible_2_id:null");
      if (responsible_2_id == null) responsible_2_id = eval("model.transaction!=null?model.transaction.responsible_2_id:null");
    }
    //TODO: falta comprobar que si es un contacto, está viendo la empresa porque es de su zona, debería poder modificar el contacto? ahora mismo no puede salvo que sea responsable1 o 2 de la organización o sea suya.

    let ret = responsible_id == null || user_id == responsible_id || (responsible_2_id != null && user_id == responsible_2_id);
    return ret;
  }

  isUserSubordinate(model) {
    let sUserSubordinatesIds = localStorage.getItem("userSubordinatesIds") ?? '';
    let userSubordinatesIds = sUserSubordinatesIds.split(",");
    let responsible_id = null;
    let responsible_2_id = null;
    if (model != null) {
      //responsable 1
      responsible_id = eval("model.responsible_id");
      if (responsible_id == null) responsible_id = eval("model.responsible_user_id");
      if (responsible_id == null) responsible_id = eval("model.transaction!=null?model.transaction.responsible_id:null"); //caso de una linea de transaccion, el resposnable esta en la transaccion
      if (responsible_id == null) responsible_id = eval("model.organization!=null?model.organization.responsible_id:null"); //caso de algo que cuelga de organizacion, por ejemplo contacto

      //responsable 2
      responsible_2_id = eval("model.responsible_2_id");
      if (responsible_2_id == null) responsible_2_id = eval("model.organization!=null?model.organization.responsible_2_id:null");
    }
    return responsible_id == null || userSubordinatesIds.indexOf('' + responsible_id) >= 0 || (responsible_2_id != null && userSubordinatesIds.indexOf('' + responsible_2_id) >= 0);
  }

  isUserZoneWritable(entity) {
    if (entity == "transactions" && this.hasPermission("TRANSACTIONS_WRITE_ZONES")) return true;
    if (entity == "oportunity" && this.hasPermission("OPORTUNITIES_WRITE_ZONES")) return true;
    if (entity == "sale" && this.hasPermission("SALES_WRITE_ZONES")) return true
    if (entity == "task" && this.hasPermission("TASKS_WRITE_ZONE")) return true;
    if (entity == "user" && this.hasPermission("USERS_WRITE_ZONES")) return true;
    if (entity == "organization" && this.hasPermission("ORGANIZATIONS_WRITE_ZONES")) return true;
    if (entity == "contact" && this.hasPermission("ORGANIZATIONS_WRITE_ZONES")) return true;
    if (entity == "template" && this.hasPermission("TEMPLATES_WRITE_ZONES")) return true;
    //return false;

    return this.hasPermission("ZONES_SUPERADMIN");
  }

  isUserZone(model) {
    let ret = true;
    if (!this.hasPermission("ZONES_SUPERADMIN")) {
      let sUserZonesIds = localStorage.getItem("userZonesIds") ?? '';
      let userZonesIds = sUserZonesIds.split(",");
      let zone_id = null;
      if (model != null) {
        zone_id = eval("model.zone_id");
        if (zone_id == null) zone_id = eval("model.organization!=null?model.organization.zone_id:null");
      }
      ret = zone_id == null || typeof (zone_id) === "undefined" || userZonesIds.indexOf('' + zone_id) >= 0;
    }
    return ret;
  }

  UserOnlyOneZone() {
    let sUserZonesIds = localStorage.getItem("userZonesIds") ?? '';
    let userZonesIds = sUserZonesIds.split(",") + "";
    if (userZonesIds.length == 1) {
      return parseInt(userZonesIds[0]);
    } else return null;
  }

  isUserInAnyZone(zones: any[], permission: string) {
    if (!this.hasPermission(permission)) return false;
    let sUserZonesIds = localStorage.getItem("userZonesIds") ?? '';
    let userZonesIds = sUserZonesIds.split(",") + "";
    const intersection = zones.filter(element => userZonesIds.includes(element));
    return intersection.length > 0;
  }

  isUserInAnyRole(roles: any[], permission: string) {
    if (!this.hasPermission(permission)) return false;
    let userRoleId = localStorage.getItem("roleId") ?? '';
    return roles.includes(parseInt(userRoleId));
  }
  isUserInUserList(users: any[], permission: string) {
    //if(!this.hasPermission(permission)) return false;
    let userId = localStorage.getItem("userId");
    return users.includes(parseInt(userId));
  }

  checkLoadedSession() {
    var logedCompanyId = localStorage.getItem("companyId");
    var logedUserId = localStorage.getItem("userId");
    if (location.pathname != "/preload" && location.pathname != "/login" && location.pathname != "/login-change" && ((this.loadedCompanyId != null && this.loadedCompanyId != logedCompanyId) || (this.loadedUserId != null && this.loadedUserId != logedUserId))) {
      //parace que se ha logado en otra pestaña con otra empresa, recargamos app para que obtenga datos nuevos de sesion
      //mostramos pantalla avisando que debe recargar
      this.router.navigateByUrl("/login-change");
    } else {
      if (this.loadedCompanyId == null) this.loadedCompanyId = localStorage.getItem("companyId");
      if (this.loadedUserId == null) this.loadedUserId = localStorage.getItem("userId");
      setTimeout(() => {
        this.checkLoadedSession();
      }, 1000);
    }
  }

}
