import { Component, OnInit, ViewChild } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CoreDialogService } from 'src/app/core/dialogs/core-dialog.service';
import { ViewComponent } from 'src/app/core/view/view.component';
import { TransactionTypesService } from '../transaction-types.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-transaction-types-detail',
  templateUrl: './transaction-types-detail.component.html',
  styleUrls: ['./transaction-types-detail.component.scss']
})
export class TransactionTypesDetailComponent implements OnInit {
  @ViewChild("view") public view: ViewComponent;
  public transactionType: any = {}
  public lastGridEvent: any;
  constructor(
    private transactionsTypesService: TransactionTypesService,
    private messageService: MessageService,
    private config: DynamicDialogConfig,
    private dialogService: DialogService,
    private coreDialogService: CoreDialogService,
    private dynamicDialogRef: DynamicDialogRef,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.loadData(this.config.data.id);
  }
  loadData(id){
    if(id){
      this.transactionsTypesService.get(id).subscribe({
        next: (data: any) => {
          this.transactionType = data;
        },
        error: (error: any) => {
          this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
        }
      });
    }
  }
  save(event:any) {
    if (typeof this.transactionType.id === "undefined") {
      this.transactionsTypesService.add(this.transactionType).subscribe({
        next: (data: any) => {
          this.transactionType = data;
          this.loadData(this.transactionType.id);
          this.messageService.add({ closable: false, severity: 'success', summary: this.translateService.instant("component.transactionType.detail.add_correct") });
          location.reload();
        },
        error: (error: any) => {
          this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
        }
      });
    } else {
      this.transactionsTypesService.save(this.transactionType.id, this.transactionType).subscribe({
        next: (data: any) => {
          this.messageService.add({ closable: false, severity: "success", detail: this.translateService.instant("component.transactionType.detail.save_correct") });
          location.reload();
        },
        error: (error: any) => {
          this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
        }
      });
    }
  }
  cancel() {
    this.dynamicDialogRef.close();
  }
  delete() {
    this.coreDialogService.confirm({
      message: this.translateService.instant("component.attachmentType.delete_message", { name: this.transactionType.name }),
      header: this.translateService.instant("general.confirmation_delete_title"),
      icon: "pi pi-info-circle",
      accept: () => {
        this.transactionsTypesService.delete(this.transactionType.id).subscribe({
          next: (data: any) => {
            this.messageService.add({ closable: false, severity: 'success', detail: this.translateService.instant("general.confirmation_delete") });
            this.dynamicDialogRef.close();
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        });
      },
      reject: () => {

      }
    })
  }
  isDuplied(event) {
    if(!event.valid){
      this.messageService.add({ closable: false, severity: "error", detail: this.translateService.instant("general.form_incomplete") });
      return;
    }
    this.transactionsTypesService.isDuplied(this.transactionType).subscribe(
      data => {
        if(data == true){
          this.coreDialogService.notify({
            message: this.translateService.instant("component.transactionType.detail.types_duplicates") ,
            header: 'Error',
            icon: 'far exclamation-circle',
            color: 'var(--orange-500)',
            accept: () => { }
          });          
        }else{
          this.save(event);
        }
      }
    );
  }
  onEvent(event) {
    if (event.component == "transactionTypes.edit.general") {
      if (event.event == "action" && event.action.name == "delete") this.delete();
      if (event.event == "action" && event.action.name == "cancel") this.cancel();
      if (event.event == "action" && event.action.name == "save") this.isDuplied(event);
    }
    if (event.component == "view" && event.event == "entity-loaded") {
      this.config.header = typeof (this.transactionType.id) !== "undefined" ?
        this.translateService.instant("component.transactionType.detail.detail_entity", { entity_name: this.view.getEntityBaseName() })
        :
        this.translateService.instant("component.transactionType.detail.new_entity", { entity_name: this.view.getEntityBaseName() });
    }
  }
}
