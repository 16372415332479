<div class="p-grid">
  <div *ngFor="let component of components" class="p-col-6">
    <p-card>
      <div class="p-d-flex p-flex-column p-jc-center">
        <h3>{{component.base_component.name}}</h3>
        <!--<small>{{component.base_component.code}}</small>-->
        <small class="p-mb-2">{{component.base_component.description}}</small>
        
      </div>
      <div class="p-col">
        <button pButton type="button" (click)="selectComponent(component)" style="float:right">
          <fa-icon [icon]="['fas','plus-circle']"></fa-icon>&nbsp;{{'general.filter.add' | translate }}
        </button>
      </div>
    </p-card>
  </div>
  <div class="p-col" *ngIf="components.length==0">
    {{'component.configure.all_components_in_view' | translate }}
  </div>
</div>
