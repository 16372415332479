import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OriginsDetailComponent } from './origins-detail/origins-detail.component';
import { OriginsListComponent } from './origins-list/origins-list.component';

const routes: Routes = [
  { path: "origins",component: OriginsListComponent },
  { path: "origins/:id",component: OriginsDetailComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OriginsRoutingModule { }
