import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { LoginService } from 'src/app/login/login.service';
import { EntitiesService } from '../entities.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-configuration-main-page',
  templateUrl: './configuration-main-page.component.html',
  styleUrls: ['./configuration-main-page.component.scss']
})
export class ConfigurationMainPageComponent implements OnInit {

  public configuration: boolean;
  public pipeline_configuration: boolean;
  public personalize_interface: boolean;
  public families: boolean;
  public roles_configuration: boolean;
  public create_templates: boolean;
  public create_clients: boolean;
  public mail_history: boolean;
  public zonesPermission: boolean;
  public business: boolean;
  public entities: any[];

  constructor(
    private loginService: LoginService,
    private entitiesService: EntitiesService,
    private messageService: MessageService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {

    this.configuration = this.loginService.hasPermission("CONFIGURATION");
    this.pipeline_configuration = this.loginService.hasPermission("PIPELINE_CONFIGURATION");
    this.personalize_interface = this.loginService.hasPermission("PERSONALIZE_INTERFACE");
    this.families = this.loginService.hasPermission("FAMILIES_READ");
    this.roles_configuration = (this.loginService.hasPermission("ROLES_CONFIGURATION") || localStorage.getItem("isSuperAdmin") === "true");
    this.create_templates = this.loginService.hasPermission("CREATE_TEMPLATES");
    this.create_clients = this.loginService.hasPermission("CREATE_CLIENTS");
    this.mail_history = this.loginService.hasPermission("MAIL_HISTORY");
    this.zonesPermission = this.loginService.hasPermission("ZONES_SUPERADMIN") || this.loginService.hasPermission("ZONES_READ");

    //this.sectorsPermission = this.loginService.hasPermission("CREATE_SECTORS");
    //this.originsPermission = this.loginService.hasPermission("CREATE_ORIGINS");
    this.business = this.loginService.hasFeature("31");

    this.entitiesService.all({}).subscribe(
      data => {
        this.entities = data.rows;
      },
      error => {
        this.messageService.add({ severity: 'error', detail: error.error.title });
      }
    );
  }

  getName(table, otherName) {
    if (typeof (this.entities) != "undefined" && this.entities != null && this.entities.length != 0) {
      let aux = this.entities.filter(m => m.code == table);
      let res = aux.length > 0 ? aux[0] : null;

      if (res != null) {
        //buscamos el nombre en el idioma
        var name = res.name_plural;
        var strPlural = "name_plural";
        var key = res.code + ".entity." + strPlural;
        if (this.translateService.instant(key) !== key) {
          name = this.translateService.instant(key);
        }
        return name;
      } else {
        var name = otherName;
        var strPlural = "name_plural";
        var key = table + ".entity." + strPlural;
        if (this.translateService.instant(key) !== key) {
          name = this.translateService.instant(key);
        }
        return name;
      }
      /*if (res != null) {
        return res.name_plural;
      } else {
        return otherName;
      }*/
    }
    return otherName;
  }
}
