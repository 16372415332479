<ul class="breadcrumb">
    <li><a [routerLink]="['/home']"><span class="color-soft-grey-axial">{{'general.breadcrumb.you_are' | translate}}
            </span>{{'general.breadcrumb.home' | translate}}</a></li>
    <li><i class="pi pi-angle-right"></i></li>
    <li><a [routerLink]="['/configuration/entities']">{{ 'general.configuration' | translate}}</a></li>
    <li><i class="pi pi-angle-right"></i></li>
    <li><a [routerLink]="['/configuration/taskStatus']">{{ 'taskStatus.entity.name_plural' | translate}}</a></li>
    <li><i class="pi pi-angle-right"></i></li>
    <li [hidden]="taskStatus.id==null"><a
            [routerLink]="['/configuration/taskStatus', taskStatus.id]">{{taskStatus.name}}</a>
    </li>
    <li [hidden]="taskStatus.id!=null">{{'component.taskStatus.detail.new_entity' | translate}}</li>
</ul>
<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000"></p-confirmDialog>
<form #formDetails="ngForm">
    <p-card header="{{'component.taskStatus.detail.detail_entity' | translate}}">
        <div class="p-grid">
            <div class="p-col-6 p-field">
                <label for="name">{{ 'taskStatus.entity_fields.name' | translate}}</label>
                <input name="name" type="text" pInputText [(ngModel)]="taskStatus.name" required />
            </div>
            <div class="p-col-2 p-field">
                <label for="color">{{'taskStatus.entity_fields.color'|translate}} {{taskStatus.color}}</label>
                <p-colorPicker name="color" [(ngModel)]="taskStatus.color" ngControlDefault
                    appendTo="body"></p-colorPicker>
            </div>
            <div class="p-col-2 p-field">
                <label for="is_default">{{'taskStatus.entity_fields.is_default' | translate}}</label>
                <p-checkbox binary="true" name="is_default" [(ngModel)]="taskStatus.is_default"
                    [disabled]="init_default" [ngModelOptions]="{standalone: true}"></p-checkbox>
            </div>
            <div class="p-col-2 p-field">
                <label for="is_finished">{{'taskStatus.entity_fields.is_finished' | translate}}</label>
                <p-checkbox binary="true" name="is_finished" [(ngModel)]="taskStatus.is_finished"
                    [ngModelOptions]="{standalone: true}"></p-checkbox>
            </div>
            <div class="p-col-2 p-field">
                <label for="order">{{ 'taskStatus.entity_fields.order' | translate}}</label>
                <input name="order" type="number" min="0" pInputText [(ngModel)]="taskStatus.order" required />
            </div>

        </div>
        <ng-template pTemplate="footer">
            <div class="p-d-flex">
                <p-button label="{{'general.action.delete' | translate}}" icon="pi pi-trash"
                    styleClass="p-button-danger" (click)="delete()" *ngIf="taskStatus.id!=null"></p-button>
                <div class="flex-grow-1"></div>
                <p-button label="{{'general.action.cancel' | translate}}" icon="pi pi-times"
                    styleClass="p-button-secondary p-button-text" (click)="cancel()"></p-button>
                <p-button label="{{'general.action.save' | translate}}" icon="pi pi-check" styleClass="p-ml-2"
                    (click)="save()"></p-button>
            </div>
        </ng-template>
    </p-card>
</form>