import { EventEmitter, Injectable, Output } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CustomizerService {

  @Output() enabled: EventEmitter<boolean> = new EventEmitter();
  @Output() disabled: EventEmitter<boolean> = new EventEmitter();
  @Output() moved: EventEmitter<any> = new EventEmitter();
  @Output() resized: EventEmitter<any> = new EventEmitter();
  
  constructor() { }

  enable() {
    this.enabled.emit();
  }
  disable() {
    this.disabled.emit();
  }
  move(customizer: any, from: number, to: number) {
    if (from != to) this.moved.emit({ customizer: customizer, from: from, to: to });
  }
  resize(customizer:any , idx: number, size: number) {
    this.resized.emit({ customizer: customizer, idx: idx, size: size });
  }

}
