<ul class="breadcrumb" *ngIf="dialogRef == null">
  <li><a [routerLink]="['/home']"><span class="color-soft-grey-axial">{{'general.breadcrumb.you_are' | translate}} </span>{{'general.breadcrumb.home' | translate}}</a></li>
  <li *ngIf="transaction.organization_id != null && transaction.organization_id != 0"><i class="pi pi-angle-right"></i>
  </li>
  <li *ngIf="transaction.organization_id != null && transaction.organization_id != 0"><a [routerLink]="['/organizations']">{{'organization.entity.name_plural' | translate}}</a></li>
  <li *ngIf="transaction.organization_id != null && transaction.organization_id != 0"><i class="pi pi-angle-right"></i>
  </li>
  <li *ngIf="transaction.organization_id != null && transaction.organization_id != 0"><a
      [routerLink]="['/organizations', 0, transaction.organization_id]">{{transaction.organization?.name}}</a></li>
  <li><i class="pi pi-angle-right"></i></li>
  <li><a [routerLink]="['/transactions', transaction_type_id]">{{'transaction.entity.name_plural' | translate}}</a></li>
  <li><i class="pi pi-angle-right"></i></li>
  <li [hidden]="transaction.id==null"><a [routerLink]="['/transactions',transaction.transaction_type_id, transaction.id]">{{transaction.description}}</a></li>
</ul>

<ul class="step-line p-mb-4">
  <li (click)="status_selected(status.id)" *ngFor="let status of transactions_status"
    [ngClass]="transaction.transaction_status_id == status.id?'transaction-status-'+transaction.transaction_status_id:''">
    {{status.name}}<span class="separator"></span>
  </li>
</ul>

<div *ngIf="showMessageBlockData" class="p-col" style="text-align: center">
  <p-message severity="info" text="{{'component.transaction.detail.not_change_state' | translate}}"></p-message>
</div>

<div style="{{disableForm}}">
  <app-view *ngIf="transaction.transaction_type_id != null" #view
    [code]="'transactions.edit'+transaction.transaction_type_id" [model]="transaction" (onEvent)="onEvent($event)">
  </app-view>
</div>
<!--<app-view *ngIf="transaction.transaction_type_id == null" #view
    [code]="'transactions.edit'" [model]="transaction" (onEvent)="onEvent($event)">
</app-view>-->
