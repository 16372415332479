<ul class="breadcrumb">
    <li><a [routerLink]="['/home']"><span class="color-soft-grey-axial">{{'general.breadcrumb.you_are' | translate}} </span>{{'general.breadcrumb.home' | translate}}</a></li>
    <li><i class="pi pi-angle-right"></i></li>
    <li><a [routerLink]="['/configuration/entities']">{{ 'general.configuration' | translate}}</a></li>
    <li><i class="pi pi-angle-right"></i></li>
    <li><a [routerLink]="['/configuration/taxRates']">{{'taxRate.entity.name' | translate}}</a></li>
</ul>
<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000"></p-confirmDialog>
<p-card styleClass="p-card-content-fit">
    <ng-template pTemplate="header">
      <div class="p-card-title">
        {{'taxRate.entity.name_plural' | translate}}
        <div class="p-card-title-actions">
          <a (click)="edit({ id: 0 })">
            <fa-icon [icon]="['fas', 'plus-circle']"></fa-icon> {{'component.taxRates.action.new' | translate}}
          </a>
        </div>
      </div>
    </ng-template>
    <p-table #grid [value]="taxRates" [lazy]="true" (onLazyLoad)="loadData($event)" [loading]="loading"
        [paginator]="true" [rows]="10" [totalRecords]="totalRecords" [showCurrentPageReport]="true"
        currentPageReportTemplate="{{resumen_table_records}}"
        [rowsPerPageOptions]="[5,10,25,50]" paginatorDropdownAppendTo="body">
        <ng-template pTemplate="header">
            <tr>
              <th pSortableColumn="id" width="100">{{'taxRate.entity_fields.id' | translate}}</th>
              <th pSortableColumn="name">{{'taxRate.entity_fields.name' | translate}}</th>
              <th pSortableColumn="percent">{{'taxRate.entity_fields.percent' | translate}}</th>
                <th width="100"></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-taxRate>
            <tr (dblclick)="edit(taxRate)">
                <td>{{taxRate.id}}</td>
                <td>{{taxRate.name}}</td>
                <td>{{taxRate.percent}}</td>
                <td class="grid-actions">
                    <a (click)="edit(taxRate)">
                        <fa-icon [icon]="['far', 'edit']"></fa-icon>
                    </a>
                    <a (click)="delete(taxRate)" class="color-danger">
                        <fa-icon [icon]="['far', 'trash-alt']"></fa-icon>
                    </a>
                </td>
            </tr>
        </ng-template>
    </p-table>
</p-card>
