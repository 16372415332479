import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { NotificationStatsService } from './notification-stats.service';

@Component({
  selector: 'app-mail-marketing-notification-to-stats',
  templateUrl: './mail-marketing-notification-to-stats.component.html',
  styleUrls: ['./mail-marketing-notification-to-stats.component.scss']
})
export class MailMarketingNotificationToStatsComponent implements OnInit {

  public notificationToStatsFilters: any = {};
  public notificationToStats: any[];
  public totalRecords: number;
  public loading: boolean = false;
  public sentMails: number = 0;
  public openedMails: number = 0;
  public nClicks: number = 0;
  private lastGridEvent: any;

  constructor(
    //private notificationStatsService: notificationstatsserv
    private router: Router,
    private notificationStatsService: NotificationStatsService,
    private messageService: MessageService,
    private config: DynamicDialogConfig
  ) { }

  ngOnInit(): void { }

  search() {
    this.lastGridEvent.first = 0;
    this.loadData(this.lastGridEvent);
  }

  clearFilters() {
    this.notificationToStatsFilters = {};
    this.search();
  }

  loadData($event) {
    this.lastGridEvent = $event;
    Promise.resolve(null).then(() => this.loading = true);
    var params = {
      _page: $event.first,
      _pageSize: $event.rows,
      _sortBy: $event.sortField ? $event.sortField : "last_time_opened",
      _sortDirection: $event.sortOrder > 0 ? "asc" : "desc"
    };
    if (this.config.data.organization_id != null) params['organization_id'] = this.config.data.organization_id;
    if (this.config.data.contact_id != null) params['contact_id'] = this.config.data.contact_id;
    params = Object.assign(params, this.notificationToStatsFilters);
    this.notificationStatsService.all(params).subscribe(
      data => {
        this.totalRecords = data.totalRows;
        this.notificationToStats = data.rows;
        this.sentMails = this.notificationToStats.length;
        this.openedMails = this.notificationToStats.filter(m => m.times_open > 0).length;
        this.nClicks = this.notificationToStats.filter(m => m.notificationLink != null && m.notificationLink.times_open > 0).length;
        //this.nClicks = this.notificationToStats.reduce((acumulador, valorActual) => acumulador + valorActual.notificationLink.times_open, 0);
        Promise.resolve(null).then(() => this.loading = false);
      },
      error => {
        this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
        Promise.resolve(null).then(() => this.loading = false);
      }
    );
  }
  openUrl(notificationToStat) {
    //var url = notificationToStat.notificationLink?.url;
    var url = notificationToStat.value;
    window.open(url, "_blank");
  }
}
