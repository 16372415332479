<div class="p-grid">
  <div class="p-col-12" *ngIf="organization.errorMessage">
    <p-message severity="error" text="{{organization.errorMessage}}" class="p-d-block"></p-message>
  </div>
  <div class="p-col-12">
    <!--<p-gmap #gmap [options]="options" [overlays]="overlays" (onMapReady)="setMap($event)" (onOverlayDragEnd)="markerDrag($event)" [style]="{ width: '100%', height: '400px' }"></p-gmap>-->
    <google-map height="400px"
                width="100%"
                [center]="center"
                [zoom]="zoom"
                >
      <map-marker 
                  *ngFor="let marker of markerPositions"
                  [position]="marker.position"
                  [title]="marker.title"
                  
                  [options]="marker.options"
                  (mapDragend)="onDragend(marker, $event)"></map-marker>
      <map-info-window> <div [innerHTML]="infoContent"></div></map-info-window>

    </google-map>
  </div>
  <div class="p-col p-field p-right">
    <p-button label="{{'component.maps.save_position' | translate}}" icon="pi pi-check" styleClass="p-ml-2" (click)="save()"></p-button>
  </div>
</div>

