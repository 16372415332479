<p-card>
    <div class="p-grid p-grid-nogutter">
        <div class="p-col-12 p-field p-mb-0 p-mt-3" *ngIf="entity == 'organization'">
            <label for="organization_name">{{'organization.entity_fields.name'|translate}}</label>
            <input name="organization_name" type="text" pInputText [(ngModel)]="filters.name"
                (ngModelChange)="onInputChange()" (keydown.enter)="loadElements()" />
        </div>
        <div class="p-col-12 p-field p-mb-0 p-mt-3" *ngIf="entity == 'contact'">
            <label for="contact_fullname">{{'contact.entity_fields.fullname'|translate}}</label>
            <input name="contact_fullname" type="text" pInputText [(ngModel)]="filters.fullname"
                (ngModelChange)="onInputChange()" (keydown.enter)="loadElements()" />
        </div>
        <div class="p-col-12 p-field p-mb-0 p-mt-3" *ngIf="entity == 'meeting'">
            <label for="meeting_subject">{{'meeting.entity_fields.subject'|translate}}</label>
            <input name="meeting_subject" type="text" pInputText [(ngModel)]="filters.subject"
                (ngModelChange)="onInputChange()" (keydown.enter)="loadElements()" />
        </div>
        <ng-container *ngIf="entity == 'task'">
            <div class="p-col-6 p-field p-mb-0 p-mt-3">
                <label for="task_subject">{{'task.entity_fields.subject'|translate}}</label>
                <input name="task_subject" type="text" pInputText [(ngModel)]="filters.subject"
                    (ngModelChange)="onInputChange()" (keydown.enter)="loadElements()" />
            </div>
            <div class="p-col-6 p-field p-mb-0 p-mt-3">
                <label for="task_organization">{{'task.entity_fields.organization_id'|translate}}</label>
                <input name="task_organization" type="text" pInputText [(ngModel)]="filters.organization_name"
                    (ngModelChange)="onInputChange()" (keydown.enter)="loadElements()" />
            </div>
        </ng-container>
        <div class="p-col-12 p-field p-mb-0 p-mt-3" *ngIf="entity == 'transaction'">
            <label for="transaction_description">{{'transaction.entity_fields.description'|translate}}</label>
            <input name="transaction_description" type="text" pInputText [(ngModel)]="filters.description"
                (ngModelChange)="onInputChange()" (keydown.enter)="loadElements()" />
        </div>
        <div class="p-col-12 p-field p-mb-0 p-mt-3" *ngIf="entity == 'oportunity'">
            <label for="transaction_description">{{'oportunity.entity_fields.description'|translate}}</label>
            <input name="transaction_description" type="text" pInputText [(ngModel)]="filters.description"
                (ngModelChange)="onInputChange()" (keydown.enter)="loadElements()" />
        </div>
        <div class="p-col-12 p-field p-mb-0 p-mt-3" *ngIf="entity == 'sale'">
            <label for="transaction_description">{{'sale.entity_fields.description'|translate}}</label>
            <input name="transaction_description" type="text" pInputText [(ngModel)]="filters.description"
                (ngModelChange)="onInputChange()" (keydown.enter)="loadElements()" />
        </div>
        <div class="p-col-12" style="text-align: right;">
            <p-button label="Buscar" (click)="loadElements()" [disabled]="!is_valid" />
        </div>
        <div class="p-col-12 p-field">
            <label for="name"> {{'component.reports.detail.records_to_add_campaign' | translate}}</label>
            <p-table #grid [lazy]="true" [value]="rows" [reorderableColumns]="false" styleClass="p-datatable-sm"
                [scrollable]="true" scrollHeight="150px" class="p-mt-3">
                <ng-template pTemplate="header">
                    <tr>
                        <th></th>
                        <ng-container *ngFor="let label of labels">
                            <th>{{label | translate}}</th>
                        </ng-container>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-item let-index="rowIndex" let-rowData>
                    <tr [pReorderableRow]="index">
                        <td width="25"><p-checkbox name="groupname" value="{{item.id}}" [inputId]="item.id"
                                [(ngModel)]="selected"></p-checkbox></td>
                        <td>{{rowData.id}}</td>
                        <ng-container *ngIf="entity == 'organization'">
                            <td>{{rowData.name}}</td>
                        </ng-container>
                        <ng-container *ngIf="entity == 'contact'">
                            <td>{{rowData.fullname}}</td>
                            <td>{{rowData.organization?.name}}</td>
                        </ng-container>
                        <ng-container *ngIf="entity == 'task'">
                            <td>{{rowData.subject}}</td>
                            <td>{{rowData.date | date}}</td>
                            <td>{{rowData.organization?.name}}</td>
                            <td>{{rowData.contact?.fullname}}</td>
                        </ng-container>
                        <ng-container *ngIf="entity == 'meeting'">
                            <td>{{rowData.subject}}</td>
                            <td>{{rowData.from | date}}</td>
                            <td>{{rowData.organization?.name}}</td>
                            <td>{{rowData.contact?.fullname}}</td>
                        </ng-container>
                        <ng-container *ngIf="entity == 'oportunity' || entity == 'sale' || entity == 'transaction'">
                            <td>{{rowData.description}}</td>
                            <td>{{rowData.date | date}}</td>
                            <td>{{rowData.organization?.name}}</td>
                            <td>{{rowData.contact?.fullname}}</td>
                        </ng-container>
                        <ng-container *ngIf="entity == 'notificationEmails'">
                            <td>{{rowData.subject}}</td>
                            <td>{{rowData.from}}</td>
                            <td>{{rowData.date | date}}</td>
                        </ng-container>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td [attr.colspan]="labels.length +1">
                            {{'general.not_data' | translate }}
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
    <div class="p-card">
        <div class="p-grid p-col-12">
            <div class="p-col-12 p-field" *ngIf="visible_organizations_checkbox">
                <label>{{'component.campaigns.reports.add_organizations_checkbox' | translate}}</label>
                <p-checkbox [(ngModel)]="add_organizations_checkbox" binary="true"
                    [ngModelOptions]="{standalone: true}"></p-checkbox>
            </div>
            <div class="p-col-12 p-field" *ngIf="visible_contacts_checkbox">
                <label>{{'component.campaigns.reports.add_contacts_checkbox' | translate}}</label>
                <p-checkbox [(ngModel)]="add_contacts_checkbox" binary="true"
                    [ngModelOptions]="{standalone: true}"></p-checkbox>
            </div>
        </div>
    </div>
    <div class="p-d-flex p-mt-3">
        <div class="flex-grow-1"></div>
        <p-button label="{{'general.action.cancel' | translate}}" icon="pi pi-times"
            styleClass="p-button-secondary p-button-text" (click)="cancel()"></p-button>
        <p-button label="{{'component.reports.detail.save_campaign' | translate}}" icon="pi pi-check"
            [disabled]="campaign_id == null" styleClass="p-ml-2" (click)="saveCampaign()"></p-button>
    </div>
</p-card>