import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { LoginService } from 'src/app/login/login.service';
import { ViewsCustomService } from '../../views-custom.service';
import { ViewsService } from '../../views.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-new-view',
  templateUrl: './new-view.component.html',
  styleUrls: ['./new-view.component.scss']
})
export class NewViewComponent implements OnInit {
  public selectedOption = "onlyme";
  public viewTemplate: any;
  public created_by: any;

  public view: any = {
    code: "",
    metadata: { to: this.selectedOption, default: false }
  };

  public options = [
    { label: this.translateService.instant("view.configure.for_all"), value: "all" },
    { label: this.translateService.instant("view.configure.onlyme"), value: "onlyme" },
    { label: this.translateService.instant("view.configure.subordinate"), value: "subordinate" }
  ];
  constructor(
    private viewsService: ViewsService,
    private viewsCustomService: ViewsCustomService,
    private loginService: LoginService,
    private config: DynamicDialogConfig,
    private dynamicDialogRef: DynamicDialogRef,
    private messageService: MessageService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    //Recojo el identificador del usuario logeado:
    this.created_by = this.loginService.get("userId");
    //Recojo la vista que se visualiza actualmente para copiar su contenido a la nueva:
    this.viewTemplate = this.config.data.view;
    //Le ponemos el mismo code que la que estamos modificando (porque es una variación):
    this.view.code = this.viewTemplate.code;
    //Su creador será el usuario logeado:
    this.view.user_id = parseInt(this.loginService.get("userId"));
    //Copiamos el contenido del view que visualizamos actualmente:
    this.view.configuration = this.viewTemplate.configuration;
    //Colocamos el ID de la view que se usó como plantilla:
    this.view.view_id = this.viewTemplate.view_id;
    if (this.config.data.edit) {
      this.view.id = this.viewTemplate.id;
      this.view.name = this.viewTemplate.name;
      this.view.metadata = (this.viewTemplate.metadata != null ? this.viewTemplate.metadata : { to: "all" });
    }
  }

  loadView() {

  }

  cancel() {
    this.dynamicDialogRef.close();
  }

  save() {
    if (this.view.name == null || this.view.name.trim() == "") {
      this.messageService.add({ severity: "error", detail: this.translateService.instant("general.filter.name_cant_be_empty") });
      return;
    }
    this.viewsCustomService.add(this.view).subscribe(
      data => {
        this.messageService.add({ severity: "success", detail: this.translateService.instant("general.filter.save_filters") });
        this.dynamicDialogRef.close(data);
      },
      error => {
        this.messageService.add({ severity: "error", detail: error.error.title });
      }
    );
  }

  delete() {
    /*
    this.confirmationService.confirm({
      message: '¿Quieres borrar la vista ' + this.view.name + '? Se procederá a asignar la vista base para todos los usuarios que la tuviesen seleccionada.',
      header: 'Confirmación de borrado',
      icon: 'pi pi-info-circle',
      accept: () => {

      }
    });
    */
    this.viewsCustomService.delete(this.view.id).subscribe(
      data => {
        this.messageService.add({severity: "success", detail: this.translateService.instant("general.filter.delete_view_correct") });
        this.dynamicDialogRef.close(data);
      },
      error => {
        this.messageService.add({ severity: "error", detail: error.error.title });
      }
    );
  }
}
