import { Component, OnInit, ViewChild } from '@angular/core';
import { GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { UIChart } from 'primeng/chart';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TRISTATECHECKBOX_VALUE_ACCESSOR } from 'primeng/tristatecheckbox';
import { ContactsRoutingModule } from 'src/app/contacts/contacts-routing.module';

declare var google: any;
var geocoder: any;

@Component({
  selector: 'app-maps-detail',
  templateUrl: './maps-detail.component.html',
  styleUrls: ['./maps-detail.component.scss']
})
export class MapsDetailComponent implements OnInit {

  //public map: any;
  public options: any;
  public overlays: any[];
  public organization: any = {};
  //public infoWindow: any;
  public errorMessage: string;
  public infoContent: string;

  @ViewChild(GoogleMap, { static: false }) map: GoogleMap;
  @ViewChild(MapInfoWindow, { static: false }) infoWindow: MapInfoWindow;

  constructor(
    private config: DynamicDialogConfig,
    private dynamicDialogRef: DynamicDialogRef,
    private messageService: MessageService,
    private translateService: TranslateService
  ) { }
  public markerPositions: google.maps.LatLngLiteral[] = [];
  center: google.maps.LatLngLiteral = { lat: 40.4166400, lng: -3.7032700 };
  zoom = 6;
 /* display: google.maps.LatLngLiteral;

  moveMap(event: google.maps.MapMouseEvent) {
    this.center = (event.latLng.toJSON());
  }
  move(event: google.maps.MapMouseEvent) {
    this.display = event.latLng.toJSON();
  }
  
  setMap(event) {
    this.map = event.map;
    this.loadMap();
  }
  */
  ngOnInit(): void {
    
    this.options = {
      center: { lat: 40.4166400, lng: -3.7032700 },
      zoom: 4
    };
    this.organization = this.config.data.organization;
    this.organization.errorMessage = "";
    //this.loadMap();

    setTimeout(() => { // map will need some time to load            
      this.loadMap();
    }, 10);
    this.errorMessage = "";

  }


  markerDrag(event) {
    this.organization.lat = event.overlay.position.lat();
    this.organization.lng = event.overlay.position.lng();
  }

  geocodePosition(organization: any) {
    geocoder = new google.maps.Geocoder();
    const that = this;
    return new Promise(function (resolve, reject) {
      geocoder.geocode({ 'address': organization.address + " " + organization.postal_code + " " + organization.city }, function (results, status) {
        if (status == 'OK') {
          organization.lat = results[0].geometry.location.lat();
          organization.lng = results[0].geometry.location.lng()
          resolve(organization);
        } else {
          //this.messageService.add({ severity: 'error', summary: 'Error', detail: "No se pudo reconocer la dirección proporcionada." });
          //throw Error("No se pudo reconocer la dirección proporcionada");
          organization.errorMessage = that.translateService.instant("component.maps.cant_add_marker_in_position") + organization.address + " " + organization.postal_code + " " + organization.city;
          resolve(organization);
        }
      });
    });

  }

  loadMap() {
    /*let bounds = new google.maps.LatLngBounds();
    geocoder = new google.maps.Geocoder();
    this.overlays = [];*/
    if (this.organization.lat == null || this.organization.lat == 0) {
      if (this.organization.address) {
        var org = this.geocodePosition(this.organization).then((data) => {
          this.organization = data;
          let lng = this.organization.lng;
          let lat = this.organization.lat;
          if(this.organization.errorMessage != "" && this.organization.lng== 0 && this.organization.lat == 0){
              lat = 40.42898477277719;
              lng = -3.6987698698315654
          }

          const marker = new google.maps.Marker({
            position: { lat: lat, lng: lng },
            title: this.organization.name,
            label: {
              color: "black",
              text: this.organization.name
            },
            draggable: true,
            options: {
              draggable: true
            }
          });
          this.markerPositions.push(marker);
          this.zoom = 16;
          this.center  = { lat: lat, lng: lng };

         /* this.overlays.push(marker);
          this.overlays.forEach(marker => {
            bounds.extend(marker.getPosition());
          });*/
          /*
          setTimeout(() => { // map will need some time to load            
            this.map.fitBounds(bounds); // Map object used directly
            this.map.setZoom(16);
          }, 3000);*/
        });
      }
    } else {


      const marker = new google.maps.Marker({
        position: { lat: this.organization.lat, lng: this.organization.lng },
        title: this.organization.name,
        label: {
          color: "black",
          text: this.organization.name
        },
        draggable: true,
        options: {
          //icon: image,
          draggable: true,
          animation: google.maps.Animation.DROP
        }
      });
      marker.setDraggable(true);

      this.markerPositions.push(marker);
      this.zoom = 16;
      this.center = { lat: this.organization.lat, lng: this.organization.lng };

     /* marker.addListener("click", () => {
        this.generateInfoWindow(marker);
      });*/

      /*
      marker.addListener("mouseout",()=>{
        this.closeInfoWindow();
      });
      */

     /* this.overlays.push(marker);
      this.overlays.forEach(marker => {
        bounds.extend(marker.getPosition());
      });

      setTimeout(() => { // map will need some time to load            
        this.map.fitBounds(bounds); // Map object used directly
        this.map.setZoom(16);

      }, 1000);*/
    }
  }
  onDragend(m, event) {
    //this.markers[this.determineIndex(m)].position = event.latLng;
  }

  generateInfoWindow(marker: MapMarker) {

   // this.closeInfoWindow();
    //Las cosas a mostrar en la ventana del marcador, se ponen en la siguiente línea como String:
    let infoWindowContent = "<b>" + this.organization.name + "</b>";
    if (this.organization.address) infoWindowContent += "<br/>" + this.organization.address + "";
    if (this.organization.city) infoWindowContent += "<br/>" + this.organization.city + "";
    if (this.organization.city && this.organization.postal_code) infoWindowContent += ", " + this.organization.postal_code;
    if (this.organization.webpage) infoWindowContent += "<br/>" + this.organization.webpage + "";
    if (this.organization.phone_1) infoWindowContent += "<br/>" + this.organization.phone_1 + "";
    if (this.organization.phone_2) infoWindowContent += "<br/>" + this.organization.phone_2 + "";
    if (this.organization.email) infoWindowContent += "<br/>" + this.organization.email + "";

    this.infoContent = infoWindowContent;
    this.infoWindow.open(marker);
  }

  closeInfoWindow() {
    if (this.infoWindow) {
      this.infoWindow.close();
    };
  }

  save() {
    this.dynamicDialogRef.close(this.organization);
  }
  cancel() {
    this.dynamicDialogRef.close();
  }
}
