
<!--<span class="font-semibold">¡Hola! ¿Qué deseas hacer ahora?</span>-->

<ul class="breadcrumb p-mt-3 p-mb-2">
  <li><a [routerLink]="['/home']"><span class="color-soft-grey-axial">{{'general.breadcrumb.you_are' | translate}} </span>{{'general.breadcrumb.home' | translate}}</a></li>
</ul>

<app-view #view [code]="'home.preview'" [model]="home" (onEvent)="onEvent($event)"></app-view>

<div class="p-grid">
  <div class="p-col-12 p-lg-6" *ngFor="let report of reports">
    <p-card header="{{report.name}}">
      <p-chart #chartGraph type="{{report.chart_type}}" [data]="report.dataGraph" [options]="basicOptions" height="300"></p-chart>
    </p-card>
  </div>
</div>
