<div class="p-grid p-ai-center p-jc-center h-100">
  <div class="login">
    <p-card>
      <div class="login-logo p-mt-4">
        <img alt="logo" src="assets/img/logo.png">
      </div>
      <div class="login-title">
        <h2>{{'component.login.welcome_title' | translate }}</h2>
        {{'component.login.welcome_subtitle' | translate }}
      </div>
      <div class="p-m-4">
        <div class="p-field p-mb-3">
          <label for="code">{{'component.login.account' | translate }}</label>
          <input id="code" pInputText [(ngModel)]="loginData.code" placeholder="cuenta proporcionada"
            autocomplete="new-password" />
        </div>
        <div class="p-field p-mb-3">
          <label for="username">{{'component.login.email' | translate }}</label>
          <input id="username" pInputText [(ngModel)]="loginData.username" placeholder="nombre@email.com"
            (keyup.enter)="login()" />
        </div>
        <div class="p-field p-mb-3">
          <label for="password">{{'component.login.password' | translate }}</label>
          <p-password id="password" [(ngModel)]="loginData.password" [toggleMask]="true" [feedback]="false"
            placeholder="Contraseña" (keyup.enter)="login()"></p-password>
        </div>
        <div class="p-mt-4">
          <p-checkbox id="remember" name="remember" [(ngModel)]="loginData.remember" binary="true"
            inputId="binary"></p-checkbox>
          <label for="remember"> {{'component.login.remember' | translate }}</label>
        </div>
        <div class="p-mt-4">
          <p-button label="{{'component.login.access' | translate }}" (click)="login()"></p-button>
        </div>
        <div class="p-mt-4">
          <a (click)="forget_password()"> {{'component.login.forgoten_password' | translate }} </a>
        </div>
        <div class="p-mt-4" [hidden]="errorMessage==''">
          <p-message severity="error" text="{{errorMessage }}" class="p-d-block"></p-message>
      </div>

      </div>
    </p-card>
    <div class="version-info-login p-m-2 p-d-flex p-flex-column p-ai-center">
      <div>Versión {{version.version}}</div>
    </div>
  </div>

</div>
