<p-timeline [value]="trackings" styleClass="hide-oposite tracking-timeline">
  <ng-template pTemplate="content" let-event>
    <b>{{getActionDescription(event.action)}}</b><br />
    <small *ngIf="event.message"><i class="pi pi-exclamation-triangle" style="color: #808080"></i>&nbsp;{{event.message}}<br></small>
    <small *ngIf="event.user">{{event.user?.name}}<br></small>
    <small *ngIf="event.channel=='import'">{{'general.tracking_from_import' | translate}}<br></small>
    <small>{{event.date | date:'dd/MM/yy HH:mm':'GMT'}}</small>
  </ng-template>
</p-timeline>
<div *ngIf="trackings.length==0">
  {{'general.tracking_empty' | translate }}
</div>
