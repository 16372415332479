import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UsersRoutingModule } from './users-routing.module';
import { UsersListComponent } from './users-list/users-list.component';
import { UsersDetailComponent } from './users-detail/users-detail.component';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { TableModule } from 'primeng/table';
import { AppRoutingModule } from '../app-routing.module';
import { CoreModule } from '../core/core.module';
import {MultiSelectModule} from 'primeng/multiselect';
import { UserZonesDetailComponent } from './user-zones-detail/user-zones-detail.component';
import { DropdownModule } from 'primeng/dropdown';
import { UserChangePasswordComponent } from './user-change-password/user-change-password.component';
import { UserRolesComponent } from './users-detail/user-roles/user-roles.component';
import { UsersMailAccountsDetailComponent } from './users-mail-accounts-detail/users-mail-accounts-detail.component';
import { FileUploadModule } from 'primeng/fileupload';
import { AccordionModule } from 'primeng/accordion';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ColorPickerModule } from 'primeng/colorpicker';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { EditorModule } from '@tinymce/tinymce-angular';
import { TranslateModule } from '@ngx-translate/core';
import { TooltipModule } from 'primeng/tooltip';

@NgModule({
  declarations: [UsersListComponent, UsersDetailComponent, UserZonesDetailComponent, UserChangePasswordComponent, UserRolesComponent, UsersMailAccountsDetailComponent],
  imports: [
    CommonModule,
    UsersRoutingModule,
    InputTextModule,
    ButtonModule,
    TableModule,
    ConfirmDialogModule,
    DialogModule,
    FormsModule,
    CheckboxModule,
    CoreModule,
    CardModule,
    FontAwesomeModule,
    AppRoutingModule,
    MultiSelectModule,
    DropdownModule,
    FileUploadModule,
    AccordionModule,
    InputSwitchModule,
    ColorPickerModule,
    AutoCompleteModule,
    EditorModule,
    TranslateModule,
    TooltipModule
  ],
  exports:[
    UsersListComponent, UsersDetailComponent, UserRolesComponent
  ]
})
export class UsersModule { }
