<p-tabView *ngIf="attachment.guid == null || attachment.mimetype == 'text/html' && attachment.id != null">
    <div class="p-field" *ngIf="attachment.entity_id != 8">
        <label for="attachmentType">{{'attachment.entity_fields.attachment_type' | translate}}</label>
        <p-dropdown name="attachmentType" [options]="attachmentsTypes" optionValue="id"
            placeholder="{{'component.attachments.detail.select_attachment_type'|translate}}" [showClear]="true"
            appendTo="body" optionLabel="name" [(ngModel)]="attachment.attachment_type_id">
        </p-dropdown>
    </div>

    <p-tabPanel header="{{'attachment.entity.name_plural' | translate}}"
        *ngIf="attachment.mimetype != 'text/html' && !hideTabs">
        <p-fileUpload name="myfile[]" url="/api/attachments/upload" (onError)="onErrorUpload($event)"
            (onUpload)="onBasicUpload($event)" multiple="multiple"></p-fileUpload>
    </p-tabPanel>
    <p-tabPanel header="{{'component.attachments.detail.combine_template' | translate}}"
        *ngIf="attachment.entity_id != 12 && attachment.entity_id != 8">
        <div class="p-field" *ngIf="attachment.id == null">
            <label for="template">{{'template.entity.name' | translate}}</label>
            <p-dropdown name="template" [options]="templates" optionValue="id" placeholder="{{'component.attachments.detail.select_template' | translate}}"
                [showClear]="true" appendTo="body" optionLabel="name" [(ngModel)]="selectedTemplateHtml"
                (change)="loadHtml()" (onChange)="loadHtml()"></p-dropdown>
        </div>
        <div class="p-field">
            <label for="description">{{'attachment.entity_fields.description' | translate}}</label>
            <input name="description" type="text" pInputText [(ngModel)]="attachment.description" required />
        </div>
        <editor apiKey="uojbvej6pesw9jdh1l7l7csugjyu7cop2zrbv09dz71r7acy" [ngModelOptions]="{standalone: true}"
            [(ngModel)]="html" [init]="tinyMceConfig" *ngIf="showTinyMCE"
            plugins="link media image fullscreen code table quickbars"></editor>
        <button pButton class="p-button-danger p-mt-3" icon="pi pi-file-pdf"
            label="{{'general.action.save_as_new' | translate}}" (click)="toDraft(true)" *ngIf="attachment.id"></button>
        <button pButton class="p-ml-2 p-mt-3" style="float: right;" icon="pi pi-check"
            label="{{'general.action.save' | translate}}" (click)="toDraft(false)"></button>
    </p-tabPanel>
</p-tabView>


<app-view #view [code]="'attachments.edit'" [model]="attachment" (onEvent)="onEvent($event)"
    *ngIf="attachment.guid != null && attachment.mimetype != 'text/html' || attachment.guid!=null && attachment.id != null && attachment.mimetype != 'text/html' || attachment.mimetype == 'text/html' && attachment.id == null"></app-view>
