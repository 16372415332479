import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'isoToDate'
  })
  export class IsoToDatePipe implements PipeTransform {
  
    transform(value: string, ...args: unknown[]): Date {
      let dt:Date = null;
      if(value!=null) dt = new Date(value);
      return dt;
  
    }
  
  }