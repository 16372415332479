import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OrganizationsContactsDetailComponent } from './organizations-detail/organizations-contacts-detail/organizations-contacts-detail.component';
import { OrganizationsContactsViewComponent } from './organizations-detail/organizations-contacts-view/organizations-contacts-view.component';
import { OrganizationsDetailGeneralComponent } from './organizations-detail/organizations-detail-general/organizations-detail-general.component';
import { OrganizationsDetailComponent } from './organizations-detail/organizations-detail.component';
import { OrganizationsMeetingsDetailComponent } from './organizations-detail/organizations-meetings-detail/organizations-meetings-detail.component';
import { OrganizationsMeetingsViewComponent } from './organizations-detail/organizations-meetings-view/organizations-meetings-view.component';
import { OrganizationsListComponent } from './organizations-list/organizations-list.component';
import { OrganizationsViewComponent } from './organizations-view/organizations-view.component';

const routes: Routes = [
  { path: "organizations", component: OrganizationsListComponent },
  { path: "organizations/clients", component: OrganizationsListComponent },
  { path: "organizations/:id/view", component: OrganizationsViewComponent },
  { path: "organizations/:organizationTypeId", component: OrganizationsListComponent},
  { path: "organizations/:organizationTypeId/:id", component:OrganizationsViewComponent},
  { path: "organizations/:id/contacts/:contactId", component: OrganizationsContactsDetailComponent},
  { path: "contacts/:organizationId/:id", component: OrganizationsContactsDetailComponent},
  { path: "organizations/:id/contacts/:meetingId", component: OrganizationsMeetingsDetailComponent },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OrganizationsRoutingModule { }
