import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CoreDialogService } from 'src/app/core/dialogs/core-dialog.service';
import { LoaderService } from '../../../core/loader/loader.service';
import { ImportService } from '../../import.service';


@Component({
  selector: 'app-import-progressbar',
  templateUrl: './import-progressbar.component.html',
  styleUrls: ['./import-progressbar.component.scss']
})
export class ImportProgressbarComponent implements OnInit {

  public loadingPercent: number;
  private timeoutID: any;
  constructor(
    private route: ActivatedRoute,
    private messageService: MessageService,
    private config: DynamicDialogConfig,
    private dynamicDialogRef: DynamicDialogRef,
    private coreDialogService: CoreDialogService,
    private importService: ImportService, private loaderService: LoaderService
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.checkIfProcessing(this.config.data.id);
    });
  }

  checkIfProcessing(id) {
    this.importService.checkIfProcessing(id).subscribe((response: any) => {
      if (response.success == true) {
        this.loadingPercent = response.status_total;
        this.loaderService.onPendingRequest.emit({ pendingRequests: 0, loadingPercent:0 });
        this.timeoutID = setTimeout(() => {
           this.checkIfProcessing(id);
        }, 10000);
      } else {
        this.cancel();
      }
    });
  }
  cancel() {
    clearTimeout(this.timeoutID);
    this.dynamicDialogRef.close();
  }
}
