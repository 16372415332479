<form #formDetails="ngForm">
  <div class="p-grid  p-grid-nogutter">
    <div class="p-col-12 p-field">
      <label for="name">{{'component.reports.detail.select_column_to_update' | translate}}</label>

      <p-dropdown placeholder="{{'general.select' | translate}}" (onChange)="changeColumn($event, selectedColumn)"
        [(ngModel)]="selectedColumn.entity_field_id" [options]="entitiesToFilter" [ngModelOptions]="{standalone: true}"
        [showClear]="true" name="selectedColumns" appendTo="body" [filter]="true" optionLabel="description"
        optionValue="id"></p-dropdown>
      <p-messages [(value)]="messages" [enableService]="false" [closable]="false"></p-messages>
    </div>
    <div class="p-col p-field" *ngIf="selectedColumn.entity_field_id != null">
      <label for="name">{{'component.reports.detail.select_field_value' | translate}}</label>

      <input *ngIf="getFilterOptionsOrValueType(selectedColumn) == 'input-text'" [ngModelOptions]="{standalone: true}"
        type="text" pInputText [(ngModel)]="selectedColumn.value" />

      <p-dropdown *ngIf="getFilterOptionsOrValueType(selectedColumn) == 'input-dropdown'"
        [options]="filterValuesOptions[selectedColumn.entity_field_id]" [(ngModel)]="selectedColumn.value"
        placeholder="{{'general.select' | translate}}" [optionLabel]="getFilterComboOptionsText(selectedColumn, true)"
        [optionValue]="getFilterComboOptionsText(selectedColumn, false)" [showClear]="true" appendTo="body"
        [ngModelOptions]="{standalone: true}"></p-dropdown>
    
      <app-view-component-datetime *ngIf="getFilterOptionsOrValueType(selectedColumn) == 'input-datetime'"
        [(ngModel)]="selectedColumn.value" [ngModelOptions]="{standalone: true}" [show-time]="false">
      </app-view-component-datetime>
      <input *ngIf="getFilterOptionsOrValueType(selectedColumn) == 'input-number'" [ngModelOptions]="{standalone: true}"
        type="number" pInputText [(ngModel)]="selectedColumn.value" />


      <p-dropdown *ngIf="getFilterOptionsOrValueType(selectedColumn) == 'boolean'" [options]="yes_no"
        [(ngModel)]="selectedColumn.value" placeholder="{{'general.select' | translate}}" optionLabel="name" optionValue="value"
        [showClear]="true" appendTo="body" [ngModelOptions]="{standalone: true}"></p-dropdown>

      <p-multiSelect *ngIf="getFilterOptionsOrValueType(selectedColumn, false) == 'dropdown-multiple'"
        [options]="filterValuesOptions[selectedColumn.entity_field_id]" [(ngModel)]="selectedColumn.value"
        optionLabel="text" optionValue="code" placeholder="{{'general.select' | translate}}" [filter]="true" [showClear]="true"
        appendTo="body" [ngModelOptions]="{standalone: true}"></p-multiSelect>
    </div>

  </div>
  <div class="p-d-flex">
    <div class="flex-grow-1"></div>
    <p-button label="{{'general.action.cancel' | translate}}" icon="pi pi-times" styleClass="p-button-secondary p-button-text" (click)="cancel()"></p-button>
    <p-button label="{{'component.reports.detail.update' | translate}}" icon="pi pi-check" styleClass="p-ml-2" (click)="updateColumns()"></p-button>
  </div>
</form>
