<div class="p-grid">
    <div class="p-col p-field">
        <label for="password">{{'user.entity_fields.password'|translate}}</label>
        <input id="password" type="password" pInputText [(ngModel)]="changePswModal.password" placeholder=""
            autocomplete="new-password" />
    </div>
    <div class="p-col p-field">
        <label for="repeatPassword">{{'component.users.repeat_password'|translate}}</label>
        <input id="repeatPassword" type="password" pInputText [(ngModel)]="changePswModal.password2" placeholder=""
            autocomplete="new-password" />
    </div>
</div>
<div class="p-grid" *ngIf="changePswModal.password!=changePswModal.password2">
    <div class="p-col">
        <p-message severity="error" text="{{'no_match_passwords'|translate}}" class="p-d-block"></p-message>
    </div>
</div>
<div class="p-grid">
    <div class="p-col">
        <button class="p-ml-2" style="float: right;" pButton type="button" label="{{'general.restore_password'|translate}}"
            (click)="changePassword()"></button>
        <button pButton class="p-button-link p-button-secondary" style="float: right;" (click)="cancel()"
            icon="pi pi-times" label="{{'general.action.cancel' | translate}}"></button>
    </div>
</div>