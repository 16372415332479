import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AttachmentsService {

  url = "/api/attachments";

  constructor(private http: HttpClient) { }

  all(params): Observable<any> {
    return this.http.get<any>(this.url, { params: params });
  }

  get(id): Observable<object> {
    return this.http.get<object>(this.url + "/" + id);
  }

  download(guid: string) {
    var url = "/api/PublicAttachments/" + localStorage.getItem("companyId") + "/" + guid + "/download";
    window.open(url);
  }

  save(id, obj): Observable<object> {
    return this.http.put<object>(this.url + "/" + id, obj);
  }

  add(obj): Observable<object> {
    return this.http.post<object>(this.url, obj);
  }
  addGeneric(obj): Observable<object> {
    return this.http.post<object>(this.url+"/generic", obj);
  }

  htmlUpload(obj): Observable<object> {
    return this.http.post<object>(this.url + "/htmlUpload", obj);
  }
  upload(obj): Observable<object> {
    return this.http.post<object>(this.url + "/upload", obj);
  }
  delete(id): Observable<object> {
    return this.http.delete<object>(this.url + "/" + id);
  }
  GeneratePDF(attachment_id, data): Observable<any> {
    return this.http.post<any>(this.url + "/" + attachment_id + "/GeneratePDF", { html: data });
  }
  isDuplied(obj): Observable<any> {
    return this.http.post<any>(this.url + "/isDuplied", obj);
  }
  customupload(obj): Observable<object> {
    return this.http.post<object>(this.url + "/customUpload2", obj);

  }
  deleteByGuid(guid): Observable<object> {
    return this.http.get<object>(this.url + "/delete" + guid);
  }
}
