import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppSettingsService } from 'src/app/app-settings.service';

@Injectable({
  providedIn: 'root'
})
export class CompanyLicenseService {
  url = "licenses";

  constructor(private http: HttpClient, private appSetting: AppSettingsService) {
    this.url = appSetting.Api(this.url);
  }

  all(params): Observable<any> {
    return this.http.get<any>(this.url, { params: params });
  }
  getLastLicense(): Observable<any> {
    return this.http.get<any>(this.url + "/getLastLicense", { params: {} });
  }
  canCreateUser(): Observable<any> {
    return this.http.get<any>(this.url + "/canCreateUser", { params: {} });
  }
}
