import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { TableModule } from 'primeng/table';
import { CoreModule } from 'src/app/core/core.module';
import { IntegrationRoutingModule } from './integration-routing.module';
import { IntegrationListComponent } from './integration-list/integration-list.component';
import { IntegrationEditComponent } from './integration-edit/integration-edit.component';
import { PanelModule } from 'primeng/panel';
import { DropdownModule } from 'primeng/dropdown';
import { HttpClientModule } from '@angular/common/http';
import { IntegrationEditApirestComponent } from './integration-edit-apirest/integration-edit-apirest.component';
import { IntegrationEditSageComponent } from './integration-edit-sage/integration-edit-sage.component';
import { IntegrationEditWebcrmComponent } from './integration-edit-webcrm/integration-edit-webcrm.component';
import { IntegrationEditOffice365Component } from './integration-edit-office365/integration-edit-office365.component';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { InputSwitchModule } from 'primeng/inputswitch';
import { IntegrationEditOffice365UserEditComponent } from './integration-edit-office365/user-config/user-config.component';
import { IntegrationEditMailchimpComponent } from './integration-edit-mailchimp/integration-edit-mailchimp.component';
import { IntegrationEditMailjetComponent } from './integration-edit-mailjet/integration-edit-mailjet.component';
import { IntegrationEditWebformComponent } from './integration-edit-webform/integration-edit-webform.component';
import { TooltipModule } from 'primeng/tooltip';
import { AccordionModule } from 'primeng/accordion';
import { BadgeModule } from 'primeng/badge';
import { TranslateModule } from '@ngx-translate/core';
import { IntegrationEditGMailComponent } from './integration-edit-gmail/integration-edit-gmail.component';
import { IntegrationEditGMailUserEditComponent } from './integration-edit-gmail/user-config/user-config.component';


@NgModule({
  declarations: [
    IntegrationListComponent,
    IntegrationEditComponent,
    IntegrationEditApirestComponent,
    IntegrationEditSageComponent,
    IntegrationEditWebcrmComponent,
    IntegrationEditOffice365Component,
    IntegrationEditOffice365UserEditComponent,
    IntegrationEditGMailComponent,
    IntegrationEditGMailUserEditComponent,
    IntegrationEditMailchimpComponent,
    IntegrationEditMailjetComponent,
    IntegrationEditWebformComponent
  ],
  imports: [
    CommonModule,
    IntegrationRoutingModule,
    InputTextModule,
    InputSwitchModule,
    ButtonModule,
    TableModule,
    ConfirmDialogModule,
    DialogModule,
    FormsModule,
    DropdownModule,
    CheckboxModule,
    CoreModule,
    CardModule,
    FontAwesomeModule,
    PanelModule,
    HttpClientModule,
    MessageModule,
    MessagesModule,
    TooltipModule,
    AccordionModule,
    BadgeModule,
    TranslateModule
  ],
  exports: [IntegrationListComponent, IntegrationEditComponent]
})
export class IntegrationModule { }
