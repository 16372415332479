import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LeadsListComponent } from './leads-list/leads-list.component';
import { LeadsDetailComponent } from './leads-detail/leads-detail.component';

const routes: Routes = [
  { path: "leads", component: LeadsListComponent },
  { path: "leads/:id", component: LeadsDetailComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LeadsRoutingModule { }
