import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImportListComponent } from './import-list/import-list.component';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CoreModule } from '../core/core.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MessageModule } from 'primeng/message';
import { ImportRoutingModule } from './import-routing.module';
import { CardModule } from 'primeng/card';
import { FileUploadModule } from 'primeng/fileupload';
import { ImportDetailComponent } from './import-detail/import-detail.component';
import { TableModule } from 'primeng/table';
import {StepsModule} from 'primeng/steps';
import { DropdownModule } from 'primeng/dropdown';
import { CheckboxModule } from 'primeng/checkbox';
import { AccordionModule } from 'primeng/accordion';
import { ProgressBarModule } from 'primeng/progressbar';
import { ImportProgressbarComponent } from './import-detail/import-progressbar/import-progressbar.component';
import { MultiSelectModule } from 'primeng/multiselect';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    ConfirmDialogModule,
    ImportRoutingModule,
    DialogModule,
    FormsModule,
    CoreModule,
    FontAwesomeModule,
    MessageModule,
    CardModule,
    FileUploadModule,
    TableModule,
    DropdownModule,
    StepsModule,
    AccordionModule,
    CheckboxModule,
    ProgressBarModule,
    MultiSelectModule,
    TranslateModule
  ],
  declarations: [ImportListComponent, ImportDetailComponent, ImportProgressbarComponent]
})
export class ImportModule { }
