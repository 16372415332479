import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CoreDialogService } from 'src/app/core/dialogs/core-dialog.service';
import { CoreFormService } from 'src/app/core/forms/core-forms.service';
import { ViewComponent } from 'src/app/core/view/view.component';
import { LoginService } from 'src/app/login/login.service';
import { MailAccountsService } from '../mail-accounts.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-mail-accounts-detail',
  templateUrl: './mail-accounts-detail.component.html',
  styleUrls: ['./mail-accounts-detail.component.scss']
})
export class MailAccountsDetailComponent implements OnInit {
  @ViewChild("view") public view: ViewComponent;
  @ViewChild("formDetails") public formDetails: NgForm;
  public mailAccount: any = {}
  public status: any = "";
  public mail_types: any = [
    { name: this.translateService.instant("component.mailAccount.list.server_smtp"), code: "smtp" }
  ];
  public lastGridEvent: any;
  public yesno_options: any = [{ label: "Sí", value: true }, { label: "No", value: false }];
  public mode_options: any = [
    { label: this.translateService.instant("component.mailAccount.detail.download_office365"), value: "<" },
    { label: this.translateService.instant("component.mailAccount.detail.send_office365"), value: ">" },
    { label: this.translateService.instant("component.mailAccount.detail.sincronize_office365"), value: "<>" }
  ];

  constructor(
    private mailAccountsService: MailAccountsService,
    private messageService: MessageService,
    private config: DynamicDialogConfig,
    private dialogService: DialogService,
    private coreDialogService: CoreDialogService,
    private dynamicDialogRef: DynamicDialogRef,
    private loginService: LoginService,
    private coreFormService: CoreFormService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.checkPossiblesTypes();
    this.loadData(this.config.data.id);
  }

  checkPossiblesTypes() {
    //Si tiene el feature 34 (Integración Correo/Calendarios office), mete el office365:
    if (this.loginService.hasFeature("34")) this.mail_types.push({ name: this.translateService.instant("component.mailAccount.list.office365"), code: "office365" });

    //Si tiene el feature 42, mete el mailchimp:
    if (this.loginService.hasFeature("42")) this.mail_types.push({ name: this.translateService.instant("component.mailAccount.list.mailchimp"), code: "mailchimp" });

    //si tiene el feature 43, metemos mailjet
    if (this.loginService.hasFeature("43")) this.mail_types.push({ name: this.translateService.instant("component.mailAccount.list.mailjet"), code: "mailjet" });

    //Si tiene el feature 46 (Integración Correo/Calendarios gmail), mete el gmail:
    if (this.loginService.hasFeature("46")) this.mail_types.push({ name: this.translateService.instant("component.mailAccount.list.gmail"), code: "gmail" });

  }

  loadData(id) {
    if (id) {
      this.mailAccountsService.get(id).subscribe({
        next: (data: any) => {
          this.mailAccount = data;
          if (this.mailAccount.connected) { this.status = this.translateService.instant("component.mailAccount.detail.account_connected") } else { this.status = this.translateService.instant("component.mailAccount.detail.account_disconnected") }
          var date_last_connection = new Date();
          if (this.mailAccount.last_connection) {
            date_last_connection = new Date(this.mailAccount.last_connection);
            var date_formatted = this.getFormattedDate(date_last_connection);
            //this.status += " - Última conexión " + date_formatted;
            this.status += this.translateService.instant("component.mailAccount.detail", { date: date_formatted });
          };
        },
        error: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
      }
      });
    } else {
      this.mailAccount.type = 'smtp';
      //this.mailAccount.mail_out_server = "smtp.gmail.com";
      //this.mailAccount.mail_out_port = 587;
      //this.mailAccount.mail_out_ssl = true;
      this.mailAccount.configuration = {
        mail: {
          enabled: false,
          mode: "<"
        },
        contacts: {
          enabled: false,
          mode: "<"
        },
        calendar: {
          enabled: false,
          mode: "<"
        }
      };
    };
  };
  save(cb:Function=null) {
    if (!this.coreFormService.validate(this.formDetails)) {
      this.messageService.add({ closable: false, severity: "error", detail: this.translateService.instant("general.form_incomplete") });
      return;
    }
    if (typeof this.mailAccount.id === "undefined") {
      this.mailAccountsService.add(this.mailAccount).subscribe({
        next: (data: any) => {
          this.mailAccount = data;
          this.loadData(this.mailAccount.id);
          this.messageService.add({ closable: false, severity: 'success', summary: this.translateService.instant("component.mailAccount.detail.add_correct") });
          if (cb != null) {
            cb();
          } else {
            this.dynamicDialogRef.close();
          }
        },
        error: (error: any) => {
          this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
        }
      });
    } else {
      this.mailAccountsService.save(this.mailAccount.id, this.mailAccount).subscribe({
        next: (data: any) => {
          this.messageService.add({ closable: false, severity: "success", detail: this.translateService.instant("component.mailAccount.detail.save_correct") });
          if (cb != null) {
            cb();
          } else {
            this.dynamicDialogRef.close();
          }
        },
        error: (error: any) => {
          this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
        }
      });
    }
  }
  cancel() {
    this.dynamicDialogRef.close();
  }
  delete() {
    this.coreDialogService.confirm({
      message: this.translateService.instant("component.mailAccount.delete_message", { name: this.mailAccount.name }),
      header: this.translateService.instant("general.confirmation_delete_title"),
      icon: "pi pi-info-circle",
      accept: () => {
        this.mailAccountsService.delete(this.mailAccount.id).subscribe({
          next: (data:any) => {
          this.messageService.add({ closable: false, severity: 'success', detail: this.translateService.instant("general.confirmation_delete") });
          this.dynamicDialogRef.close();
        },
          error: (error:any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        });
      },
      reject: () => {

      }
    })
  }
  isDuplied() {
    if (!this.coreFormService.validate(this.formDetails)) {
      this.messageService.add({ closable: false, severity: "error", detail: this.translateService.instant("general.form_incomplete") });
      return;
    }
    this.mailAccountsService.isDuplied(this.mailAccount).subscribe({
      next: (data: any) => {
        if (data == true) {
          this.coreDialogService.notify({
            message: this.translateService.instant("component.mailAccount.detail.types_duplicates"),
            header: 'Error',
            icon: 'far exclamation-circle',
            color: 'var(--orange-500)',
            accept: () => { }
          });
        } else {
          this.save();
        }
      },
      error: (error: any) => {
        console.log(error.error.errors);
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
      }
    });
  }

  getFormattedDate(date) {
    var date_formatted = this.padDigits(date.getDate())
      + "/"
      + this.padDigits(date.getMonth())
      + "/"
      + date.getFullYear()
      + " "
      + this.padDigits(date.getHours())
      + ":"
      + this.padDigits(date.getMinutes());
    return date_formatted;
  }
  padDigits(num, padding = 2) {
    return num.toString().padStart(padding, '0');
  }

  testAccount() {
    //TODO:
  }

}
