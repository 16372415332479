import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable, Type } from '@angular/core';
import { Observable, of } from 'rxjs';
import { LoaderService } from '../loader/loader.service';
import { CustomComponent } from './component/custom/custom-component.interface';

@Injectable({
  providedIn: 'root'
})
export class ViewsService {
  url = "/api/";

  onViewChange: EventEmitter<string> = new EventEmitter<string>();  
  onEditViewChange: EventEmitter<string> = new EventEmitter<string>();
  onCreateViewChange: EventEmitter<string> = new EventEmitter<string>();
  onFilterChange: EventEmitter<string> = new EventEmitter<string>();

  private viewCache: any[] = [];

  private registeredComponents: any[] = [];

  constructor(private http: HttpClient, private loaderService:LoaderService) { }

  removeViewConfigurationFromCache(code:string) {
    this.viewCache = this.viewCache.filter(m => m.code != code);
  }

  getViewConfiguration(code: string): Observable<any> {
    let observable = Observable.create(observer => {

      let view = this.viewCache.find(m => m.code == code);
      if (view == null) {
        this.http.get<any>(this.url + "views/bycode/" + code).subscribe(data => {
          this.viewCache.push({ code: code, view: data });
          observer.next(data);
          observer.complete();
        });
      } else {
        observer.next(view.view);
        observer.complete();
      }
    });

    return observable;
  }
  getViewComponents(code: string): Observable<any> {
    return this.http.get<any>(this.url + "views/bycode/" + code + "/components");
  }

  getComponentConfiguration(code: string): Observable<any> {
    return this.http.get<any>(this.url + "components/bycode/" + code);
  }

  getComboOptions(path: string): Observable<any> {
    return this.http.get<any>(this.url + path);
  }

  getTableData(path: string, params: any): Observable<any> {
    return this.http.get<any>(this.url + path, { params: params });
  }

  saveView(id, obj): Observable<object> {
    return this.http.put<object>(this.url + "views/" + id, obj);
  }


  all(params): Observable<any> {
    return this.http.get<any>(this.url + "views/", { params: params });
  }

  get(id): Observable<object> {
    return this.http.get<object>(this.url + "views/" + id);
  }


  save(id, obj): Observable<object> {
    return this.http.put<object>(this.url + "views/" + id, obj);
  }

  add(obj): Observable<object> {
    return this.http.post<object>(this.url + "views/", obj);
  }

  delete(id): Observable<object> {
    return this.http.delete<object>(this.url + "views/" + id);
  }

  RegisterCustomComponent(name: string, component: Type<CustomComponent>) {
    this.registeredComponents.push({ name: name, component: component });
  }

  GetComponentByName(name: string): Type<CustomComponent> {
    let founds = this.registeredComponents.filter(m => m.name == name);
    let found = null;
    if (founds.length > 0) found = founds[0].component;
    return found;
  }

  viewsCombo(params): Observable<any> {
    this.loaderService.skipRequest(this.url + "views/viewsCombo/", params);
    return this.http.get<any>(this.url + "views/viewsCombo/", { params: params });
  }

  changeView(code: string) {
    this.onViewChange.emit(code);
  }
  
  editView(code: string) {
    this.onEditViewChange.emit(code);
  }

  createView(filters: any = null) {
    this.onCreateViewChange.emit(filters);
  }

  getPagesListenerEditView() {
    return this.onEditViewChange;
  }
  
  getIfHasFiltersValues(code: any) {
    return this.onFilterChange;
  }
}
