import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { MessageService } from 'primeng/api';
import { UsersService } from 'src/app/users/users.service';
import { CoreDialogService } from 'src/app/core/dialogs/core-dialog.service';
import { OrganizationsService } from '../../organizations.service';
import { NgForm } from '@angular/forms';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { OrganizationsTypesService } from '../../organizations-types.service';
import { EntitiesService } from 'src/app/configuration/entities.service';
import { ViewComponent } from '../../../core/view/view.component';
import { ViewsService } from '../../../core/view/views.service';
import { MailingDetailComponent } from '../../../mailing/mailing-detail/mailing-detail.component';
import { Location } from '@angular/common';
import { LoginService } from '../../../login/login.service';
import { TranslateService } from '@ngx-translate/core';
import { CountriesService } from '../../../configuration/countries/countries.service';

var geocoder: any;
declare var google: any;

@Component({
  selector: 'app-organizations-detail-general',
  templateUrl: './organizations-detail-general.component.html',
  styleUrls: ['./organizations-detail-general.component.scss']
})
export class OrganizationsDetailGeneralComponent implements OnInit {

  @ViewChild("view") public view: ViewComponent;
  @ViewChild("formDetails") public formDetails: NgForm;
  public organization: any = {};
  public countries: any[];
  public responsibles: any[];
  public organizations_types: any[];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private config: DynamicDialogConfig,
    private organizationsService: OrganizationsService,
    private organizationsTypesService: OrganizationsTypesService,
    private entitiesService: EntitiesService,
    private countriesService: CountriesService,
    private messageService: MessageService,
    private usersService: UsersService,
    private coreDialogService: CoreDialogService,
    private dynamicDialogRef: DynamicDialogRef,
    private dialogService: DialogService,
    private viewsService: ViewsService,
    private _location: Location, private loginService: LoginService,
    private translateService: TranslateService,
    private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.loadCountries();
    this.loadResponsibles();
    this.loadorganizationsTypes();
    this.loadData(this.config.data.id);
  }
  loadData(id: number) {
    if (id > 0) {
      this.organizationsService.get(id).subscribe({
        next: (data: any) => {
          this.organization = data;
        },
        error: (error: any) => {
          this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
        }
      });
    } else {
      this.organization.responsible_id = parseInt(localStorage.getItem('userId'));
      if (typeof (this.config.data.organization_type_id) !== "undefined" && this.config.data.organization_type_id != null) {
        this.organization.organization_type_id = parseInt(this.config.data.organization_type_id);
      }
      //Seleccionamos la zona si solo tiene una
      this.organization.zone_id = this.loginService.UserOnlyOneZone();  
    }
  }

  loadorganizationsTypes() {
    this.organizationsTypesService.all({}).subscribe({
      next: (data: any) => {
        this.organizations_types = data.rows;
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
      }
    });
  }

  loadCountries() {
    this.countriesService.all({}).subscribe({
      next: (data: any) => {
        this.countries = data.rows;
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
      }
    });
  }

  loadResponsibles() {
    this.usersService.all({}).subscribe({
      next: (data: any) => {
        this.responsibles = data.rows;
        this.responsibles.unshift({ name: 'Ninguno', id: null });
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
      }
    });
  }
  cancel() {
    this.dynamicDialogRef.close();
  }


  geocodePosition(organization: any) {
    try {
      geocoder = new google.maps.Geocoder();
      return new Promise(function (resolve, reject) {
        geocoder.geocode({ 'address': organization.address + " " + organization.postal_code + " " + organization.city }, function (results, status) {
          if (status == 'OK') {
            organization.lat = results[0].geometry.location.lat();
            organization.lng = results[0].geometry.location.lng()
            resolve(organization);
          } else {
            organization.lat = 0;
            organization.lng = 0;
            resolve(organization);
            //alert('Geocode was not successful for the following reason: ' + status);
          }
        });
      });
    } catch (e) {
      //si no esta disponible la geolocalización o no ha podido geolocalizar, continuamos normal
      return new Promise(function (resolve, reject) {
        resolve(organization);
      });
    }

  }

  saveDB(event: any) {

    if (typeof (this.organization.email) === "undefined" || this.organization.email == null) this.organization.email = null;
    if (!event.valid) {
      this.messageService.add({ closable: false, severity: "error", detail: this.translateService.instant("general.form_incomplete") });
      return;
    }
    if (this.organization.email != null) this.organization.email = this.organization.email.trim();
    if (this.organization.email != null && this.organization.email.length > 0 && !this.validateEmail(this.organization.email)) {
      this.messageService.add({ closable: false, severity: "error", detail: this.translateService.instant("component.organizations.detail.invalid_mail")  });
      return;
    }

    if (typeof this.organization.id === "undefined") {
      this.organizationsService.add(this.organization).subscribe({
        next: (data: any) => {
          this.messageService.add({ closable: false, severity: 'success', summary: this.translateService.instant("component.organizations.detail.add_organization_correct") });
          this.dynamicDialogRef.close(data);
        },
        error: (error: any) => {
          this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
        }
      });
    } else {
      this.organizationsService.save(this.organization.id, this.organization).subscribe({
        next: (data: any) => {
          this.messageService.add({ closable: false, severity: "success", detail: this.translateService.instant("component.organizations.detail.save_organization_correct") });
          this.dynamicDialogRef.close(data);
        },
        error: (error: any) => {
          this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
        }
      });
    }
  }

  save(event: any) {
    if (this.organization.lat == null || this.organization.lat == "") this.organization.lat = 0;
    if (this.organization.lng == null || this.organization.lng == "") this.organization.lng = 0;
    if (
      this.organization.lat == null || (String(this.organization.lat) === '0') &&
      this.organization.lng == null || (String(this.organization.lng) === '0') &&
      this.organization.address
    ) {
      //Obtenemos la position desde la dirección.
      this.geocodePosition(this.organization).then((data) => {
        this.organization = data;
        this.isDuplied(this.organization, event);
      });
    } else {
      this.isDuplied(this.organization, event);
    }
  }

  delete() {
    this.coreDialogService.confirm({
      message: this.translateService.instant("component.organizations.detail.remember_associated_info_will_be_deleted"),
      header: this.translateService.instant("general.confirmation_delete_title"),
      icon: "pi pi-info-circle",
      accept: () => {
        
       this.organizationsService.delete(this.organization.id).subscribe({
          next: (data: any) => {
           
           var url = window.location.pathname;
           var organizationType = url.split("/")[url.split("/").length - 2];
           if (organizationType != "0") {
             this.router.navigate(['/organizations/' + organizationType]);
           } else {
             this.router.navigate(['/organizations']);
           }
           this.dynamicDialogRef.close();
           this.messageService.add({ closable: false, severity: 'success', detail: this.translateService.instant("general.confirmation_delete") });

          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        });
      },
      reject: () => { }
    })
  }

  validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  isDuplied(organization, event) {
    this.organizationsService.isDuplied(this.organization).subscribe({
      next: (data: any) => {
        if (data == true) {
          this.coreDialogService.notify({
            message: this.translateService.instant("component.organizations.detail.existing_organization"),
            header: 'Error',
            icon: 'far exclamation-circle',
            color: 'var(--orange-500)',
            accept: () => { }
          });
        } else {
          this.saveDB(event);
        }
      },
      error: (error: any) => {
        console.log(error);
        this.messageService.add({ closable: false, severity: 'error', detail: (error.error.title != null ? error.error.title : error.error.title) });
      }
    });
  }
  newMail(emailAcc, entity_id, entity_pk_id, template_type_code, searchRelated) {

    const ref = this.dialogService.open(MailingDetailComponent, {
      data: {
        to: emailAcc,
        entity_id: entity_id,
        entity_pk_id: entity_pk_id,
        organization_id: this.organization.id,
        template_type_code: template_type_code,
        searchRelated: searchRelated
      },
      header: this.translateService.instant("general.new_mail_title"),
      width: '70%',
    });
    ref.onClose.subscribe((data: any) => {
      this.view.fireEvent("organizations.preview.mails", "reload");
      if (typeof (this.view) !== "undefined") this.viewsService.changeView(this.view.code);
    });
  }

  onEvent(event: any) {
    if (event.component == "organization.edit.general") {
      if (event.event == "action" && event.action.name == "save") this.save(event);
      if (event.event == "action" && event.action.name == "cancel") this.cancel();
      if (event.event == "action" && event.action.name == "delete") this.delete();

      if (event.event == "fieldAction" && event.action.name == "new-mail") {
        var toInfo = { name: "", email: event.value }
        if (event.action.param == "INTERNAL") {
          //Cargamos los datos del usuario (o responsible_id o responsible_user_id)
          this.usersService.get(event.value).subscribe({
            next: (data: any) => {
              toInfo = { name: data.name, email: data.email }
              this.newMail(toInfo, 1, this.organization.id, "MAIL_INTERNAL", false);
            },
            error: (error: any) => {
              this.messageService.add({ severity: "error", detail: error.error.title });
            }
          });
        } else {
          this.newMail(toInfo, 1, this.organization.id, "MAIL", true);
        }
      }
    }
    if (event.component == "view" && event.event == "entity-loaded") {
      this.config.header = this.translateService.instant("component.organizations.detail.detail_entity", {entity_name: this.view.getEntityBaseName() });
    }
  }
}
