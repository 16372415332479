import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { LoginService } from '../../login/login.service';
import { UsersService } from '../users.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-user-change-password',
  templateUrl: './user-change-password.component.html',
  styleUrls: ['./user-change-password.component.scss']
})
export class UserChangePasswordComponent implements OnInit {

  public changePswModal: any = {
  };
  public user: any;
  public userId: number;

  constructor(
    private messageService: MessageService,
    private dynamicDialogRef: DynamicDialogRef,
    private userService: UsersService,
    private config: DynamicDialogConfig,
    private loginService: LoginService,
    private usersService: UsersService,
    private translateService: TranslateService,
  ) { }

  ngOnInit(): void {
    this.userId = this.config.data.id;
    if (this.config.data.user) {
      this.user = this.config.data.user;
    } else {
      this.loadUser();
    }
  };

  loadUser() {
    if (this.userId) {
      this.usersService.get(this.userId).subscribe(
        data => {
          this.user = data;
        },
        error => {
          this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
        }
      );
    }
  }

  changePassword() {
    if (this.changePswModal.password != "") {
      if (this.changePswModal.password == this.changePswModal.password2) {
        this.user.password = this.changePswModal.password;
        this.userService.changePassword(this.user).subscribe({
          next: (data: any) => {
            //Al final de todas las operaciones, se cierra la pantalla?
            this.dynamicDialogRef.close(data);
            this.messageService.add({ closable: false, severity: 'success', summary: this.translateService.instant('component.users.save_password_correct') });
            //Mandar correo aquí:
            this.notificateChange();
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: "error", detail: error.error.Message });
          }
        });
      } else {
        this.messageService.add({ closable: false, severity: "error", detail: this.translateService.instant('component.users.no_match_passwords') });
      }
    } else {
      this.messageService.add({ closable: false, severity: "error", detail: this.translateService.instant('component.users.empty_password_error') });
    }
  }

  notificateChange() {
    this.loginService.notificateChange(this.user.email).subscribe({
      next: (data: any) => {
        if (data.success) {
          this.messageService.add({ closable: false, severity: 'success', summary: this.translateService.instant('component.users.sent_email') });
        } else {
          this.messageService.add({ closable: false, severity: "error", detail: data.message });
        }

      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: "error", detail: error.error.Message });
      }
    });
  }

  cancel() {
    this.dynamicDialogRef.close();
  }
}
