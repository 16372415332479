import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { TransactionStatusService } from '../../transaction-status/transaction-status.service';
import { TransactionTypesService } from '../../transaction-types/transaction-types.service';
import { CompaniesIntegrationsService } from '../companiesintegrations.service';
import { OrganizationsTypesService } from 'src/app/organizations/organizations-types.service';
import { BusinessService } from '../../business/business.service';

@Component({
  selector: 'app-integration-edit-sage',
  templateUrl: './integration-edit-sage.component.html',
  styleUrls: ['./integration-edit-sage.component.scss']
})
export class IntegrationEditSageComponent implements OnInit {
  public integration: any = {
    configuration: {
      business: []
    }
  };
  @ViewChild("formDetails") public formDetails: NgForm;

  public organizationsTypes: any[] = [];
  public transactionTypes: any[] = [];
  public transactionStatus: any[] = [];
  public business: any[] = [];

  constructor(
    private dynamicDialogRef: DynamicDialogRef,
    private companiesIntegrationsService: CompaniesIntegrationsService,
    private transactionTypesService: TransactionTypesService,
    private transactionStatusService: TransactionStatusService,
    private messageService: MessageService,
    private organizationsTypesService: OrganizationsTypesService,
    private businessService: BusinessService
  ) { }

  ngOnInit() {
    this.loadTypes();
  }

  loadData() {
    this.companiesIntegrationsService.getByCode("sagedespachos").subscribe(
      data => {
        if (data != null) {
          if (data.configuration == null) data.configuration = {};
          if (data.configuration.business == null) data.configuration.business = [];

          //generamos array de business
          this.business.forEach(b => {
            let item = data.configuration.business.find(m => m.business_id == b.id);
            if (item == null) {
              //si no esta la empresa en la lista, la incluimos
              data.configuration.business.push({
                business_id: b.id,
                business_name: b.name,
                business_code: b.erp_id
              });
              item = data.configuration.business.find(m => m.business_id == b.id);
            } else {
              item.business_name = b.name;
              item.business_code = b.erp_id;
            }

            //generamos datos por defecto para ventas
            if (!item.sales) {
              item.sales = {
                export: item.export_sales || false,
                use_axial_prices: false
              };
              //quitamos clave de configuración vieja
              delete item.export_sales;
            }
          });

          this.integration = data;
        } else {
          this.integration = {
            integration_id: 1,
            configuration: {}
          };
        }
        this.loadStatus();
      },
      error => {
        this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
      }
    );
  }

  loadTypes() {
    //sociedad
    this.businessService.all({}).subscribe(
      data => {
        this.business = data.rows;
        this.loadData();
      },
      error => {
        this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
      }
    );
    //tipos de transaccion
    this.transactionTypesService.all({}).subscribe(
      data => {
        this.transactionTypes = data.rows;
      },
      error => {
        this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
      }
    );
    //tipos de organizacion
    this.organizationsTypesService.all({}).subscribe(
      data => {
        this.organizationsTypes = data.rows;
      },
      error => {
        this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
      }
    );
  }

  loadStatus() {
    if (this.integration.configuration.invoices_transaction_type_id) {
      this.transactionStatusService.all({ transaction_type_id: this.integration.configuration.invoices_transaction_type_id }).subscribe(
        data => {
          this.transactionStatus = data.rows;
        },
        error => {
          this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
        }
      );
    }
  }

  onTransactionTypeChange() {
    this.loadStatus();
  }

  save() {
    this.companiesIntegrationsService.save(this.integration.id, this.integration).subscribe({
      next: (data: any) => {
        this.dynamicDialogRef.close();
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
      }
    });
  }

  cancel() {
    this.dynamicDialogRef.close();
  }
}
