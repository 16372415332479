import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MapsDetailComponent } from './maps-detail/maps-detail.component';
//import { GMapModule } from 'primeng/gmap';
import { CoreModule } from '../core/core.module';
import { CardModule } from 'primeng/card';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { GoogleMapsModule } from '@angular/google-maps';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [MapsDetailComponent],
  imports: [
    CommonModule,
    CoreModule,
    CardModule,
    FontAwesomeModule,
    //GMapModule,
    MessageModule,
    MessagesModule,

    GoogleMapsModule,
    TranslateModule
  ],
  exports:[
    MapsDetailComponent
  ]
})
export class MapsModule { }
