import { Component, OnInit } from '@angular/core';
import { LoginService } from '../login.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogService } from 'primeng/dynamicdialog';
import { ForgetPasswordComponent } from '../forget-password/forget-password.component';
import { UsersService } from 'src/app/users/users.service';
import { UpdateService } from '../../update/update.service';

@Component({
  selector: 'app-login-change',
  templateUrl: './login-change.component.html',
  styleUrls: ['./login-change.component.scss']
})

export class LoginChangeComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
    
  }

  reload() {
    location.replace("/home");
  }

}
