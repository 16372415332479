import { Component, OnInit, ViewChild } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { CoreDialogService } from 'src/app/core/dialogs/core-dialog.service';
import { ViewComponent } from 'src/app/core/view/view.component';
import { ViewsService } from 'src/app/core/view/views.service';
import { OriginsDetailComponent } from '../origins-detail/origins-detail.component';
import { OriginsService } from '../origins.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-origins-list',
  templateUrl: './origins-list.component.html',
  styleUrls: ['./origins-list.component.scss']
})
export class OriginsListComponent implements OnInit {

  @ViewChild("view") public view: ViewComponent;
  public originsFilters: any = {};
  public origins: any[];
  private lastGridEvent: any;
  public totalRecords: number;
  public loading: boolean = false;

  constructor(
    private originsService: OriginsService,
    private coreDialogService: CoreDialogService,
    private dialogService: DialogService,
    private messageService: MessageService,
    private viewsService: ViewsService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
  }

  loadData($event) {
    this.lastGridEvent = $event;
    Promise.resolve(null).then(() => this.loading = true);
    var params = {
      _page: $event.first,
      _pageSize: $event.rows,
      _sortBy: $event.sortField ? $event.sortField : "",
      _sortDirection: $event.sortOrder > 0 ? "asc" : "desc"
    }
    params = Object.assign(params, this.originsFilters);
    this.originsService.all(params).subscribe({
      next: (data: any) => {
        this.totalRecords = data.totalRows;
        this.origins = data.rows;
        Promise.resolve(null).then(() => this.loading = false);
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
        Promise.resolve(null).then(() => this.loading = false);
      }
    });
  }
  editOrigin(origin) {
    const ref = this.dialogService.open(OriginsDetailComponent, {
      data: {
        id: origin != null ? origin.id : null
      },
      header: origin != null ? this.translateService.instant("component.origin.edit_origin") : this.translateService.instant("component.origin.add_origin"),
      width: '45%'
    });
    ref.onClose.subscribe((data: any) => {
      if (typeof (this.view) !== "undefined") this.viewsService.changeView(this.view.code);
      this.view.fireEvent("origins.list.results", "reload");
    });
  }
  delete(origin) {
    this.coreDialogService.confirm({
      message: this.translateService.instant("component.origin.delete_message", { name: origin.name }),
      header: this.translateService.instant("general.confirmation_delete_title"),
      icon: 'pi pi-info-circle',
      accept: () => {
        this.originsService.delete(origin.id).subscribe({
          next: (data: any) => {
            this.messageService.add({ closable: false, severity: "success", detail: this.translateService.instant("general.confirmation_delete") });
            this.view.fireEvent("origins.list.results", "reload");
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
          }
        });
      },
      reject: () => {

      }
    })
  }

  search() {
    this.lastGridEvent.first = 0;
    this.loadData(this.lastGridEvent);
  }

  onEvent(event: any) {
    if (event.component == "origins.list.filters") {
      if (event.event == "action" && event.action.name == "search") this.view.fireEvent("origins.list.results", "reload");
    } else if (event.component == "origins.list.results") {
      if (event.event == "dblclick") this.editOrigin(event.data);
      if (event.event == "action" && event.action.name == "add") this.editOrigin(null);
      if (event.event == "action" && event.action.name == "edit") this.editOrigin(event.data);
      if (event.event == "action" && event.action.name == "delete") this.delete(event.data);
    }
  }
}
