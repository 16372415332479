import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TransactionsRoutingModule } from './transactions-routing.module';
import { TransactionsListComponent } from './transactions-list/transactions-list.component';
import { TransactionsDetailComponent } from './transactions-detail/transactions-detail.component';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { TableModule } from 'primeng/table';
import { CoreModule } from '../core/core.module';
import { UserCalendarModule } from '../user-calendar/user-calendar.module';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { FullCalendarModule } from '@fullcalendar/angular';
import { EditorModule } from '@tinymce/tinymce-angular';
import { TimelineModule } from 'primeng/timeline';
import { TranslateModule } from '@ngx-translate/core';
import { TransactionSAGEInfoCustomComponent } from './transactions-detail/transactios-sage-info-custom/transactios-sage-info-custom.component';


@NgModule({
  declarations: [TransactionsListComponent, TransactionsDetailComponent, TransactionSAGEInfoCustomComponent],
  imports: [
    CommonModule,
    TransactionsRoutingModule,
    ButtonModule,
    CardModule,
    CoreModule,
    FontAwesomeModule,
    TableModule,
    FormsModule,
    DropdownModule,
    ConfirmDialogModule,
    InputTextModule,
    EditorModule,
    CalendarModule,
    CheckboxModule,
    UserCalendarModule,
    FullCalendarModule,
    MessagesModule,
    MessageModule,
    ButtonModule,
    InputTextModule,
    TimelineModule,
    TranslateModule
    
  ],
})
export class TransactionsModule { }
