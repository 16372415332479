import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MailchimpService {

  url = "/api/mailchimp";

  constructor(private http: HttpClient) { }

  verify(api_key): Observable<object> {
    return this.http.get<object>(this.url + "/verify/" + api_key);
  }

  getAllLists(api_key): Observable<object> {
    return this.http.get<object>(this.url + "/getAllLists/" + api_key);
  }

  getMergeFields(api_key, list_id): Observable<any> {
    return this.http.get<object>(this.url + "/mergesfields/" + api_key + "/" + list_id);
  }

  dashboard(id): Observable<object> {
    return this.http.get<object>(this.url + "/dashboard/" + id);
  }

  sendCampaignTest(id:number, email:string): Observable<object> {
    return this.http.get<object>(this.url + "/campaign/" + id + "/send-test?email=" + email);
  }
}
