import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ZonesService } from 'src/app/configuration/zones/zones.service';
import { UserZonesService } from '../user-zones.service';
import { UsersService } from '../users.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-user-zones-detail',
  templateUrl: './user-zones-detail.component.html',
  styleUrls: ['./user-zones-detail.component.scss']
})
export class UserZonesDetailComponent implements OnInit {
  public zones: any[] = [];
  public selectedZones: any[] = [];
  public userZone: any = {};

  constructor(
    private userZonesService: UserZonesService,
    private zonesService: ZonesService,
    private usersService: UsersService,

    private messageService: MessageService,
    private config: DynamicDialogConfig,
    private dynamicDialogRef: DynamicDialogRef,
    private translateService: TranslateService,
  ) { }

  ngOnInit(): void {
    this.userZone.user_id = this.config.data.user.id;
    this.selectedZones = this.config.data.selectedZones;
    this.loadZones();
  }
  cancel() {
    this.dynamicDialogRef.close();
  }
  save() {
    if (this.userZone != null && this.userZone.zone_id > 0) {
      this.userZonesService.add(this.userZone).subscribe({
        next: (data: any) => {
          this.messageService.add({ closable: false, severity: "success", detail: this.translateService.instant('component.users.detail.zone_assign_correct') });
          this.dynamicDialogRef.close(data);
        },
        error: (error: any) => {
          this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
        }
      });
    } else {
      this.messageService.add({ closable: false, severity: "error", detail: this.translateService.instant('component.users.detail.invalid_zone_error_message') })
    }
    /*
    this.addZones();
    this.usersService.save(this.user.id, this.user).subscribe(
      data => {
        this.messageService.add({ closable: false, severity: "success", detail: "Usuario guardado correctamente" });
        this.dynamicDialogRef.close(data);
      },
      error => {
        this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
      }
    )*/
  }

  loadZones() {
    this.zonesService.all({ ids: this.selectedZones }).subscribe(
      data => {
        this.zones = data.rows;
      },
      error => {
        this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
      }
    )
  }
  /*  
  addZones() {
    //Añadir zonas al usuario:
    let finalUserZones = this.selectedZones.map(zone_id => {
      let existentZones = this.user.userZones.filter(m => m.zone_id == zone_id && !m.deleted);
      if (existentZones.length > 0) {
        return existentZones[0];
      } else {
        return { user_id: this.user.id, zone_id: parseInt(zone_id) };
      }
    });
    this.user.userZones = finalUserZones;
  }
  */
}
