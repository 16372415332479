import { Component, OnInit, ViewChild } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { CoreDialogService } from 'src/app/core/dialogs/core-dialog.service';
import { ViewComponent } from 'src/app/core/view/view.component';
import { ViewsService } from 'src/app/core/view/views.service';
import { LoginService } from 'src/app/login/login.service';
import { ViewsDetailComponent } from '../view-detail/views-detail.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-views-list',
  templateUrl: './views-list.component.html',
  styleUrls: ['./views-list.component.scss']
})
export class ViewsListComponent implements OnInit {
  @ViewChild("view") public view: ViewComponent;
  public viewsFilters: any = {};
  public personalize_interface: boolean;
  constructor(
    private viewsService: ViewsService,
    private coreDialogService: CoreDialogService,
    private messageService: MessageService,
    private dialogService: DialogService,
    private loginService: LoginService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.personalize_interface = this.loginService.hasPermission("PERSONALIZE_INTERFACE"); //Se hace uso de este permiso en código.
  }

  search() {
    this.view.fireEvent("views.list.results", "reload");
  }

  delete(item) {
    this.coreDialogService.confirm({
      message: '¿Quieres borrar la vista ' + item.name + '?',
      header: this.translateService.instant("general.confirmation_delete_title"),
      icon: 'pi pi-info-circle',
      accept: () => {
        this.viewsService.delete(item.id).subscribe(
          data => {
            this.messageService.add({ closable: false, severity: "success", detail: 'Eliminada correctamente.' });
            this.view.fireEvent("views.list.results", "reload");
          },
          error => {
            this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
          }
        );
      },
      reject: () => {

      }
    });
  }
  edit(template) {
    const ref = this.dialogService.open(ViewsDetailComponent, {
      data: {
        id: template != null ? template.id : null
      },
      //header: template != null ? 'Modificar vista' : 'Añadir vista',
      width: '70%'
    });
    ref.onClose.subscribe((data: any) => {
      this.view.fireEvent("views.list.results", "reload");
    });
  }
  onEvent(event: any) {
    if (event.component == "views.list.filters" && this.personalize_interface) {
      if (event.event == "action" && event.action.name == "search") this.view.fireEvent("views.list.results", "reload");
    } else if (event.component == "views.list.results" && this.personalize_interface) {
      if (event.event == "dblclick") this.edit(event.data);
      if (event.event == "action" && event.action.name == "add") this.edit(null);
      if (event.event == "action" && event.action.name == "edit") this.edit(event.data);
      if (event.event == "action" && event.action.name == "delete") this.delete(event.data);
    }
  }
}
