<h2 *ngIf="is_create">{{'component.entity.detail_fields_fields.add_new_title' | translate}}</h2>
<h2 *ngIf="!is_create">{{'component.entity.detail_fields_fields.modify_title' | translate}}</h2>
<div class="p-grid">
    <div class="p-col-6 p-field">
      <label for="code">{{'component.entity.detail_fields.code' | translate}}</label>
        <input name="code" type="text" pInputText [(ngModel)]="element.code" [disabled]="!is_create"/>
    </div>
    <div class="p-col-6 p-field">
      <label for="text">{{'component.entity.detail_fields.value' | translate}}</label>
        <input name="text" type="text" pInputText [(ngModel)]="element.text"/>
    </div>
    <div class="p-col-12 p-d-flex">
        <p-button label="{{'general.action.delete' | translate}}" *ngIf="false" icon="pi pi-trash"
            styleClass="p-button-danger" (click)="delete()"></p-button>
        <div class="flex-grow-1"></div>
        <p-button label="{{'general.action.cancel' | translate}}" icon="pi pi-times" styleClass="p-button-secondary p-button-text" (click)="cancel()">
        </p-button>
        <p-button label="{{'general.action.save' | translate}}" icon="pi pi-check" styleClass="p-ml-2" (click)="save()"></p-button>
        <p-button label="{{'general.action.save_continue' | translate}}" icon="pi pi-check" styleClass="p-ml-2" (click)="save(true)"></p-button>
    </div>
</div>
