import { Component, OnInit, ViewChild } from '@angular/core';
import { ReportsService } from '../reports.service';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { CoreDialogService } from 'src/app/core/dialogs/core-dialog.service';
import { ViewComponent } from 'src/app/core/view/view.component';
import { UsersService } from 'src/app/users/users.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-reports-list',
  templateUrl: './reports-list.component.html',
  styleUrls: ['./reports-list.component.scss']
})
export class ReportsListComponent implements OnInit {
  @ViewChild("view") public view: ViewComponent;
  public reportsFilters: any = {};


  constructor(
    private reportsService: ReportsService,
    private usersService: UsersService,
    private dialogService: DialogService,
    private messageService: MessageService,
    private coreDialogService: CoreDialogService,
    private router: Router,
    private translateService: TranslateService
  ) { 
    
  }

  ngOnInit(): void {
  }

  edit(report){
    this.router.navigate(['/reports/',report?report.id:0]);
  }

  delete(report) {
    this.coreDialogService.confirm({
      message: this.translateService.instant("component.reports.delete_report_message", {name: report.name}),
      header: this.translateService.instant("general.confirmation_delete_title"),
      icon: "pi pi-info-circle",
      accept: () => {
        this.reportsService.delete(report.id).subscribe({
          next: (data: any) => {
            this.messageService.add({ closable: false, severity: 'success', detail: this.translateService.instant("general.confirmation_delete") });
            this.router.navigate(['/reports']);
            this.view.fireEvent("reports.list.results", "reload");
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        })
      },
      reject: () => { }
    });
  }

  onEvent(event: any) {
    if (event.component == "reports.list.filters") {
      if (event.event == "action" && event.action.name == "search") this.view.fireEvent("reports.list.results", "reload");
    } else if (event.component == "reports.list.results") {
      if (event.event == "dblclick") this.edit(event.data);
      if (event.event == "action" && event.action.name == "add") this.edit(null);
      if (event.event == "action" && event.action.name == "edit") this.edit(event.data);
      if (event.event == "action" && event.action.name == "delete") this.delete(event.data);
    } if (event.event == "action" && event.action.name == "search") {
      this.view.fireEvent("reports.list.results", "reload", event.data);
    }
  }
  

}
