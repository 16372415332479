import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faSmileBeam } from '@fortawesome/free-solid-svg-icons';
import { Message, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { Table } from 'primeng/table';
import { RolesDetailComponent } from '../roles-detail/roles-detail.component';
import { RolesService } from "../roles.service";
import { CoreDialogService } from 'src/app/core/dialogs/core-dialog.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-roles-list',
  templateUrl: './roles-list.component.html',
  styleUrls: ['./roles-list.component.scss']
})
export class RolesListComponent implements OnInit {
  @ViewChild("grid") public grid: Table;
  public rolesFilters: any = {};
  public roles: any[];
  private lastGridEvent: any;
  public totalRecords: number;
  public loading: boolean = false;

  public first;
  public last;
  constructor(
    private rolesService: RolesService,
    private route: ActivatedRoute,
    private coreDialogService: CoreDialogService,
    private messageService: MessageService,
    private dialogService: DialogService,
    private router: Router, private translateService: TranslateService) { }

  ngOnInit(): void {
  }
  search() {
    this.lastGridEvent.first = 0;
    this.loadData(this.lastGridEvent);
  }

  clearFilters() {
    this.rolesFilters = {};
    this.search();
  }
  loadData($event) {
    this.lastGridEvent = $event;
    Promise.resolve(null).then(() => this.loading = true);
    var params = {
      _page: $event.first,
      _pageSize: $event.rows,
      _sortBy: $event.sortField ? $event.sortField : "",
      _sortDirection: $event.sortOrder > 0 ? "asc" : "desc"
    };
    params = Object.assign(params, this.rolesFilters);
    this.rolesService.all(params).subscribe(
      data => {
        this.totalRecords = data.totalRows;
        this.roles = data.rows;
        Promise.resolve(null).then(() => this.loading = false);
      },
      error => {
        this.messageService.add({ severity: 'error', detail: error.error.title });
        Promise.resolve(null).then(() => this.loading = false);
      }
    );
  }

  delete(role: any) {
    this.coreDialogService.confirm({
      message: '¿Quieres borrar el rol ' + role.name + '?',
      header: this.translateService.instant("general.confirmation_delete_title"),
      icon: 'pi pi-info-circle',
      accept: () => {
        this.rolesService.delete(role.id).subscribe(
          data => {
            this.loadData(this.lastGridEvent);
            this.messageService.add({ severity: "success", detail: 'Eliminado correctamente.' });

          },
          error => {
            this.messageService.add({ severity: "error", detail: error.error.title });
          }
        );
      },
      reject: () => {

      }
    });
  }

  edit(role: any) {
    const ref = this.dialogService.open(RolesDetailComponent, {
      data: {
        id: role != null ? role.id : null
      },
      //header: role!=null?'Modificar rol':'Añadir rol',
      width: '70%'
    });
    ref.onClose.subscribe((data: any) => {
      if (data) {
        this.loadData(this.lastGridEvent);
      }
      this.loadData(this.lastGridEvent);
    });
  }
}
