import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { TaxRatesService } from '../tax-rates.service';
import { CoreDialogService } from 'src/app/core/dialogs/core-dialog.service';
import { TranslateService } from '@ngx-translate/core';
import { ViewComponent } from '../../../core/view/view.component';

@Component({
  selector: 'app-tax-rates-list',
  templateUrl: './tax-rates-list.component.html',
  styleUrls: ['./tax-rates-list.component.scss']
})
export class TaxRatesListComponent implements OnInit {
  @ViewChild("grid") public grid: Table;
  public taxRatesFilters: any = {};
  public taxRates: any[];
  public countries: any[];
  public totalRecords: number;
  public loading: boolean = false;
  private lastGridEvent: any;
  public resumen_table_records: string;

  constructor(
    private taxRatesService: TaxRatesService,
    private router: Router,
    private coreDialogService: CoreDialogService,
    private messageService: MessageService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
  }

  search() {
    this.lastGridEvent.first = 0;
    this.loadData(this.lastGridEvent);
  }

  clearFilters() {
    this.taxRatesFilters = {};
    this.search();
  }

  loadData($event) {
    this.lastGridEvent = $event;
    Promise.resolve(null).then(() => this.loading = true);
    var params = {
      _page: $event.first,
      _pageSize: $event.rows,
      _sortBy: $event.sortField ? $event.sortField : "",
      _sortDirection: $event.sortOrder > 0 ? "asc" : "desc"
    };
    params = Object.assign(params, this.taxRatesFilters);
    this.taxRatesService.all(params).subscribe({
      next: (data: any) => {
        this.totalRecords = data.totalRows;
        this.taxRates = data.rows;
        this.resumen_table_records = this.translateService.instant("component.taxRates.resumen_table_records", { first: $event.first, last: $event.last, totalRecords: this.totalRecords });

        Promise.resolve(null).then(() => this.loading = false);
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
        Promise.resolve(null).then(() => this.loading = false);
      }
    });
  }

  edit(taxRate) {
    this.router.navigate(['/configuration/taxRates/', taxRate.id]);
  }

  delete(taxRate) {
    this.coreDialogService.confirm({
      message: this.translateService.instant("component.taxRates.delete_message", { name: taxRate.name }),
      header: this.translateService.instant("general.confirmation_delete_title"),
      icon: 'pi pi-info-circle',
      accept: () => {
        this.taxRatesService.delete(taxRate.id).subscribe({
          next: (data: any) => {
            this.messageService.add({ closable: false, severity: "success", detail: this.translateService.instant("general.confirmation_delete") });

            this.loadData(this.lastGridEvent);
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
          }
        });
      },
      reject: () => {

      }
    });
  }
}
