import { Injectable } from "@angular/core";
import { UntypedFormArray, UntypedFormGroup, NgForm } from "@angular/forms";

@Injectable({
    providedIn: 'root'
})
export class CoreFormService {

    constructor() {

    }

    validate(group: NgForm | UntypedFormGroup | UntypedFormArray) {
        Object.keys(group.controls).forEach((key: string) => {
            const control = group.controls[key];
            if (control instanceof UntypedFormGroup || control instanceof UntypedFormArray) { 
                control.markAsTouched(); this.validate(control);
            }
            else {
                control.markAsTouched();
            };
        });
        return group.valid;
    };

}