import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Components
import { CoreModule } from '../core/core.module';
import { EntityHistoryLineComponent } from './entity-history-line.component';
import { TableModule } from 'primeng/table';
import { PaginatorModule } from 'primeng/paginator';
import { ProgressBarModule } from 'primeng/progressbar';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
    imports: [
      CommonModule,
      CoreModule,
      TableModule,
      PaginatorModule,
    ProgressBarModule,
    TranslateModule
    ],
    declarations: [
      EntityHistoryLineComponent
    ]
  })
export class EntityHistoryLineModule {
  }
