import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { CoreDialogService } from 'src/app/core/dialogs/core-dialog.service';
import { ViewComponent } from 'src/app/core/view/view.component';
import { ViewsService } from 'src/app/core/view/views.service';
import { ContactsService } from 'src/app/organizations/organizations-detail/organizations-contacts-detail/contacts.service';

@Component({
  selector: 'app-contacts-list',
  templateUrl: './contacts-list.component.html',
  styleUrls: ['./contacts-list.component.scss']
})
export class ContactsListComponent implements OnInit {
  @ViewChild("view") public view: ViewComponent;
  public contactsFilters: any = {};

  constructor(
    private contactsService: ContactsService,
    private viewsService: ViewsService,
    private coreDialogService: CoreDialogService,
    private messageService: MessageService,
    private dialogService: DialogService,
    private router: Router,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
  }

  viewContact(contact) {
    this.router.navigate(["/contacts/" + (contact ? contact.id : 0)]);
  }

  deleteContact(contact) {
    this.coreDialogService.confirm({
      message: this.translateService.instant("component.contacts.delete_message", { name: contact.name }),
      header: this.translateService.instant("general.confirmation_delete_title"), // this.translateService.instant("general.confirmation_delete_title"),
      icon: "pi pi-info-circle",
      accept: () => {
        this.contactsService.delete(contact.id).subscribe({
          next: (data:any) => {
            this.messageService.add({ closable: false, severity: 'success', detail: this.translateService.instant("general.confirmation_delete") });
            this.router.navigate(['/contacts']);
            this.view.fireEvent("contacts.list.results", "reload");
          },
          error: (error:any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        })
      },
      reject: () => { }
    });
  }
  onEvent(event: any) {
    if (event.component == "contacts.list.filters") {
      if (event.event == "action" && event.action.name == "search") this.view.fireEvent("contacts.list.results", "reload");
    } else if (event.component == "contacts.list.results") {
      if (event.event == "dblclick") this.viewContact(event.data);
      if (event.event == "action" && event.action.name == "add") this.viewContact(null);
      if (event.event == "action" && event.action.name == "edit") this.viewContact(event.data);
      if (event.event == "action" && event.action.name == "delete") this.deleteContact(event.data);
      if (event.event == "action" && event.action.name == "search") {
        this.view.fireEvent("contacts.list.results", "reload", event.data);
      }
    }
  }
}
