<ul class="breadcrumb">
  <li><a [routerLink]="['/home']"><span class="color-soft-grey-axial">{{'general.breadcrumb.you_are' | translate}} </span>{{'general.breadcrumb.home' | translate}}</a></li>
  <li><i class="pi pi-angle-right"></i></li>
  <li><a [routerLink]="['/configuration/entities']">{{ 'general.configuration' | translate}}</a></li>
  <li><i class="pi pi-angle-right"></i></li>
  <!--li *ngIf="view"><a [routerLink]="['/configuration/attachmentsTypes']">{{view.getEntityBaseName(true)}}</a></li-->
  <li *ngIf="view"><a [routerLink]="['/configuration/attachmentsTypes'+attachment_type_id]">{{view.getEntityBaseName(true)}}</a></li>
</ul>
<!--app-view *ngIf="attachment_type_id == null" #view [code]="'attachmentsTypes.list'" [model]="attachmentsTypesFilters" (onEvent)="onEvent($event)"></app-view-->
<app-view *ngIf="attachment_type_id != null" #view [code]="'attachmentsTypes.list'+attachment_type_id" [model]="attachmentsTypesFilters" (onEvent)="onEvent($event)"></app-view>
