import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { CompaniesIntegrationsService } from '../companiesintegrations.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-integration-edit-webcrm',
  templateUrl: './integration-edit-webcrm.component.html',
  styleUrls: ['./integration-edit-webcrm.component.scss']
})
export class IntegrationEditWebcrmComponent implements OnInit {

  /*
    - Organizaciones: sin acceso / lectura / lectura y escritura
    - Contactos: sin acceso / lectura / lectura y escritura
    - Actividades: sin acceso / lectura / lectura y escritura
  */

  public permissions: any = [
    { name: this.translateService.instant('no_permission'), value: "" },
    { name: this.translateService.instant('read_permission'), value: "read" },
    { name: this.translateService.instant('readwrite_permission'), value: "readwrite" }
  ];

  public integration: any = {
    configuration: {}
  };

  constructor(
    private dynamicDialogRef: DynamicDialogRef,
    private companiesIntegrationsService: CompaniesIntegrationsService,
    private messageService: MessageService,
    private translateService: TranslateService,
  ) { }

  ngOnInit() {
    this.companiesIntegrationsService.getByCode("webcrm").subscribe({
      next: (data: any) => {
        if (data != null) {
          if (data.configuration == null) data.configuration = {};
          this.integration = data;
        }
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
      }
    });
  }

  save() {
    //const md5 = new Md5();
    //obj.configuration.password = md5.appendStr(obj.configuration.password).end();
    this.companiesIntegrationsService.save(this.integration.id, this.integration).subscribe({
      next: (data: any) => {
        this.dynamicDialogRef.close();
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
      }
    });
  }

  cancel() {
    this.dynamicDialogRef.close();
  }
}
