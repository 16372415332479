import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TransactionStatusRoutingModule } from './transaction-status-routing.module';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { TableModule } from 'primeng/table';
import { CoreModule } from 'src/app/core/core.module';
import { TransactionStatusListComponent } from './transaction-status-list/transaction-status-list.component';
import { TransactionStatusDetailComponent } from './transaction-status-detail/transaction-status-detail.component';
import { DropdownModule } from 'primeng/dropdown';
import { TransactionStatusBlockingRolesComponent } from './transaction-status-blocking-roles/transaction-status-blocking-roles.component';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
  declarations: [TransactionStatusListComponent,TransactionStatusDetailComponent, TransactionStatusBlockingRolesComponent],
  imports: [
    CommonModule,
    TransactionStatusRoutingModule,
    InputTextModule,
    ButtonModule,
    TableModule,
    ConfirmDialogModule,
    DialogModule,
    FormsModule,
    CheckboxModule,
    CoreModule,
    CardModule,
    FontAwesomeModule,
    DropdownModule,
    TranslateModule
  ],
  exports: [TransactionStatusListComponent,TransactionStatusDetailComponent]
})
export class TransactionStatusModule { }
