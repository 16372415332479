import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OrganizationsTypesRoutingModule } from './organizations-types-routing.module';
import { OrganizationsTypesListComponent } from './organizations-types-list/organizations-types-list.component';
import { CoreModule } from '../../core/core.module';
import { CardModule } from 'primeng/card';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { FormsModule } from '@angular/forms';
import { OrganizationsTypesService } from '../../organizations/organizations-types.service';
import { OrganizationsTypesDetailComponent } from './organizations-types-detail/organizations-types-detail.component';
import { ColorPickerModule } from 'primeng/colorpicker';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
  declarations: [OrganizationsTypesListComponent, OrganizationsTypesDetailComponent],
  imports: [
    CommonModule,
    OrganizationsTypesRoutingModule,
    CoreModule,
    CardModule,
    TableModule,
    ButtonModule,
    FormsModule,
    ConfirmDialogModule,
    ColorPickerModule,
    TranslateModule
  ],
  exports:[
    OrganizationsTypesListComponent
  ],
  providers:[
    OrganizationsTypesService
  ]
})
export class OrganizationsTypesModule { }
