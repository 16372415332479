import { Input, ViewChild } from '@angular/core';
import { Component, ComponentFactoryResolver, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UIChart } from 'primeng/chart';
import { TransactionsService } from 'src/app/transactions/transactions.service';
import { OrganizationsService } from '../../organizations.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-opportunities-total-pipeline',
  templateUrl: './opportunities-total-pipeline.component.html',
  styleUrls: ['./opportunities-total-pipeline.component.scss']
})
export class OpportunitiesTotalPipelineComponent implements OnInit {
  @ViewChild('chart') chart: UIChart;

  @Input() public data: any;
  @Input() public entity: any;

  public id: any;
  public ret: any = {};
  public height = 0;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private organizationService: OrganizationsService,
    private transactionService: TransactionsService,
    private route: ActivatedRoute, private translateService: TranslateService
  ) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.id = params['id'];
      this.loadStatsOpportunities(this.id);
    });
  }

  onEvent(event: string) {
    this.refresh();
  }

  refresh(){
    this.loadStatsOpportunities(this.id);
    this.chart.reinit();
    this.chart.refresh();
  }
  loadStatsOpportunities(id) {
    this.organizationService.pipelinesOpportunities(id).subscribe({
      next: (data: any) => {
        this.ret = data;
        this.loadBasicData();
      },
      error: (error: any) => {
        //TODO: messageService aquí.
      }
    });
  }

  //Configuración de tablas horizontales para meses.
  public basicData: any;
  public horizontalOptions: any;
  public labels: any[] = [];
  public backgroundColors: any[] = [];
  public dataFormated: any[] = [];



  loadBasicData() {
    this.labels = [];
    this.backgroundColors = [];
    this.height = 0;

    for (let i = 0; i < this.ret.labels.length; i++) {
      this.dataFormated[i] = new Intl.NumberFormat('de-DE').format(this.ret.sumByStatus[i]);
      this.labels.push(this.ret.labels[i]);
      this.backgroundColors.push(this.ret.backgroundColors[i]);
      this.height = this.height + 25;
    }
    this.basicData = {
      labels: this.labels,
      datasets: [
        {
          label: this.translateService.instant("component.organizations.sales_total_pipeline.total"),
          backgroundColor: this.backgroundColors,//'#FFCE56',
          data: this.ret.sumByStatus
        }
      ]
    };
    this.horizontalOptions = {
      indexAxis: 'y',
      tooltips: {
        callbacks: {
          label: function (tooltipItem, data) {
            var label = new Intl.NumberFormat('de-DE').format(tooltipItem.value);
            return label+ " €";
          },
        }
      },
    };
  }
}
