import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppSettingsService } from '../../../../app-settings.service';
import { MessageService } from 'primeng/api';


@Injectable()
export class CustomTranslateLoader implements TranslateLoader {
  contentHeader = new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  });

  constructor(
    private httpClient: HttpClient,
    private appSetting: AppSettingsService,
    private messageService: MessageService
  ) { }

  getTranslation(lang: string): Observable<any> {

    const apiAddress = this.appSetting.Api("i18n/" + lang);

    return this.httpClient.get(apiAddress, { headers: this.contentHeader })
      .pipe(
         catchError((error: any, caught: Observable<any>): Observable<any> => {
           console.error('Error loading translations', error);
           //this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
           //alert(error.error.title);

           // after handling error, return a new observable 
           // that doesn't emit any values and completes
           return this.httpClient.get("/assets/i18n/es.json")
         })
      );
  }
}
