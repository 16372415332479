import {  Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AppSettingsService {
  versionNumber = "1.0.13";
  versionDate = "11/06/2021";

  baseUrl = "/api/";

  
  constructor(private http: HttpClient) {

  }

  Api(relativeUrl:string){
    return this.baseUrl + relativeUrl;
  }

  Version():any{
    return {
      version: this.versionNumber,
      date: this.versionDate
    };
  }

  getTime(): any {
    return new Date().toLocaleDateString();
  }

}
