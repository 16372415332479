import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ImportListComponent } from '../import/import-list/import-list.component';
import { NotificationsListComponent } from '../notifications/notifications-list/notifications-list.component';
import { UsersDetailComponent } from '../users/users-detail/users-detail.component';
import { UsersListComponent } from '../users/users-list/users-list.component';
import { AttachmentsTypesDetailComponent } from './attachments-types/attachments-types-detail/attachments-types-detail.component';
import { AttachmentsTypesListComponent } from './attachments-types/attachments-types-list/attachments-types-list.component';
import { BusinessListComponent } from './business/business-list/business-list.component';
import { BusinessDetailComponent } from './business/business-detail/business-detail.component';
import { CompanyLicenseComponent } from './company-license/company-license.component';
import { ConfigurationMainPageComponent } from './configuration-main-page/configuration-main-page.component';
import { CountriesDetailComponent } from './countries/countries-detail/countries-detail.component';
import { CountriesListComponent } from './countries/countries-list/countries-list.component';
import { EntitiesDetailComponent } from './entities-detail/entities-detail.component';
import { EntitiesListComponent } from './entities-list/entities-list.component';
import { FamiliesDetailComponent } from './families/families-detail/families-detail.component';
import { FamiliesListComponent } from './families/families-list/families-list.component';
import { IntegrationListComponent } from './integration/integration-list/integration-list.component';
import { MailAccountsListComponent } from './mail-accounts/mail-accounts-list/mail-accounts-list.component';
import { OrganizationsTypesDetailComponent } from './organizations-types/organizations-types-detail/organizations-types-detail.component';
import { OrganizationsTypesListComponent } from './organizations-types/organizations-types-list/organizations-types-list.component';
import { OriginsListComponent } from './origins/origins-list/origins-list.component';
import { ProvincesDetailComponent } from './provinces/provinces-detail/provinces-detail.component';
import { ProvincesListComponent } from './provinces/provinces-list/provinces-list.component';
import { RolesDetailComponent } from './roles/roles-detail/roles-detail.component';
import { RolesListComponent } from './roles/roles-list/roles-list.component';
import { SectorsListComponent } from './sectors/sectors-list/sectors-list.component';
import { TasksActionsTypesListComponent } from './tasks-actions-types/tasks-actions-types-list/tasks-actions-types-list.component';
import { TaxRatesDetailComponent } from './tax-rates/tax-rates-detail/tax-rates-detail.component';
import { TaxRatesListComponent } from './tax-rates/tax-rates-list/tax-rates-list.component';
import { TemplatesListComponent } from './templates/templates-list/templates-list.component';
import { TrackingLogsComponent } from './tracking-logs/tracking-logs.component';
import { TransactionStatusListComponent } from './transaction-status/transaction-status-list/transaction-status-list.component';
import { TransactionTypesListComponent } from './transaction-types/transaction-types-list/transaction-types-list.component';
import { ViewsListComponent } from './views/view-list/views-list.component';
import { ZonesDetailComponent } from './zones/zones-detail/zones-detail.component';
import { ZonesListComponent } from './zones/zones-list/zones-list.component';
import { MailInOutListComponent } from '../notifications/mail-in-out-list/mail-in-out-list.component';
import { TaskStatusListComponent } from './task-status/task-status-list/task-status-list.component';
import { TaskStatusDetailComponent } from './task-status/task-status-detail/task-status-detail.component';


const routes: Routes = [
  {
    path: "configuration", component: ConfigurationMainPageComponent, children: [
      { path: '', component: EntitiesListComponent },
      { path: 'entities', component: EntitiesListComponent },
      { path: "entities/:id", component: EntitiesDetailComponent },
      { path: "organizationsTypes", component: OrganizationsTypesListComponent },
      { path: "organizationsTypes/:id", component: OrganizationsTypesDetailComponent },
      { path: "countries", component: CountriesListComponent },
      { path: "countries/:id", component: CountriesDetailComponent },
      { path: "roles", component: RolesListComponent },
      { path: "roles/:id", component: RolesDetailComponent },
      { path: "transactionsTypes", component: TransactionTypesListComponent },
      { path: "transactionsStatus", component: TransactionStatusListComponent },
      { path: "templates", component: TemplatesListComponent },
      { path: "mailAccounts", component: MailAccountsListComponent },
      { path: "views", component: ViewsListComponent },
      { path: "zones", component: ZonesListComponent },
      { path: "zones/:id", component: ZonesDetailComponent },
      { path: "users", component: UsersListComponent },
      { path: "users/:id", component: UsersDetailComponent },
      { path: "attachmentsTypes", component: AttachmentsTypesListComponent },
      { path: "attachmentsTypes/:attachment_type_id", component: AttachmentsTypesListComponent },
      { path: "attachmentsTypes/:attachment_type_id/:id", component: AttachmentsTypesDetailComponent },
      { path: "business", component: BusinessListComponent },
      { path: "business/:id", component: BusinessDetailComponent },
      { path: "families", component: FamiliesListComponent },
      { path: "families/:id", component: FamiliesDetailComponent },
      { path: "logs", component: TrackingLogsComponent },
      { path: "import", component: ImportListComponent },
      { path: "integrations", component: IntegrationListComponent },
      { path: "notifications", component: NotificationsListComponent },
      { path: "emails", component: MailInOutListComponent },
      { path: 'companyLicense', component: CompanyLicenseComponent },
      { path: "tasksActionsTypes", component: TasksActionsTypesListComponent },
      { path: "sectors", component: SectorsListComponent },
      { path: "origins", component: OriginsListComponent },
      { path: "provinces", component: ProvincesListComponent },
      { path: "provinces/:id", component: ProvincesDetailComponent },
      { path: "taskStatus", component: TaskStatusListComponent },
      { path: "taskStatus/:id", component: TaskStatusDetailComponent },
      { path: "taxRates", component: TaxRatesListComponent },
      { path: "taxRates/:id", component: TaxRatesDetailComponent },
    ]
  },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ConfigurationRoutingModule { }
