import { Directive, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CustomizerService } from './customizer.service';

@Directive({
  selector: '[customizer]'
})
export class CustomizerDirective implements OnInit {
  @Input() customizer: any = {};
  private mode: string = null;
  private placeStartIdx: number = null;
  private placeEndIdx: number = null;
  private selectedElement: any = null;
  private size: number = null;
  private lastColClass: string = null;

  constructor(
    private el: ElementRef,
    private customizerService:CustomizerService
  ) {
    this.customizerService.enabled.subscribe(() => {
      this.enable();
    });
    this.customizerService.disabled.subscribe(() => {
      this.disable();
    });
  }

  ngOnInit(): void {
  }

  enable() {
    let container = this.el.nativeElement;

    //adding listener
    container.addEventListener("dragover", (event) => {
      this.onDragOver(event);
    });
    container.addEventListener("drop", (event) => {
      this.onDrop(event);
    });

    //adding draggable to children
    for (let children of container.children) {
      if (!children.classList.contains("flex-break")) {
        this.addResizableHandler(children);
        children.setAttribute("draggable", "true");
        children.addEventListener("dragstart", (event) => {
          this.onDragStart(event);
        });
      }
    }
  }

  disable() {
    for (let children of this.el.nativeElement.children) {
      children.setAttribute("draggable", "false");
      this.removeResizableHandler(children);
    }
  }

  onDragStart(event) {
    this.placeStartIdx = this.findElementIndex(event.target);
    this.selectedElement = event.target;
    var elementRect = event.target.getBoundingClientRect();
    //si estamos arrastrando en la zona de resizado
    if (event.offsetX > (elementRect.width - 20)) {
      this.mode = "resize";
      this.lastColClass = null;
      //var crt = event.target.cloneNode(true);
      /*crt.style.display = "none";
      document.body.appendChild(crt);
      event.dataTransfer.setDragImage(crt, 0, 0);*/
      var img = document.createElement("img");   
      img.src = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkeAsAAPMA72PD3bsAAAAASUVORK5CYII=";
      //document.body.appendChild(img);
      event.dataTransfer.setDragImage(img, 0, 0);
    } else {
      this.mode = "drag";
    }
  }
  
  onDragOver(event) {
    if (this.mode == "drag") {
      this.addPlaceIndicator(event);
    } else if(this.mode == "resize") {
      var rect = this.selectedElement.getBoundingClientRect();
      let newWidth = event.clientX - rect.x;
      if (newWidth < 0) newWidth = 1;

      //calculamos clase de ancho
      var baseCols = 12;
      var containerRect = this.el.nativeElement.getBoundingClientRect();
      var colWidth = containerRect.width / baseCols;
      var col = Math.round(newWidth / colWidth);
      if (col < 1) col = 1;
      if (col > 12) col = 12;
      let colClass = "p-md-" + col;
      this.size = col;
      if (colClass != this.lastColClass) {
        for (var col = 1; col <= 12; col++) this.selectedElement.classList.remove("p-md-" + col);
        this.selectedElement.classList.add(colClass);
        this.lastColClass = colClass;
        this.customizerService.resize(this.customizer, this.placeStartIdx, this.size);
      }
    }
    event.preventDefault();
  }
  onDrop(event) {
    if (this.mode == "drag") {
      if (this.placeStartIdx != this.placeEndIdx) this.moveDOMElement(this.placeStartIdx, this.placeEndIdx);
      this.removePlaceIndicator();
    } else if(this.mode == "resize") {
      this.customizerService.resize(this.customizer, this.placeStartIdx, this.size);
    }
    this.mode = null;
    this.selectedElement = null;
    event.preventDefault();
    event.stopPropagation();
  }

  findItemUnder(x, y) {
    var element = Array.from(this.el.nativeElement.children).find((m: any) => {
      var rect = m.getBoundingClientRect();
      return rect.x < x && (rect.x + rect.width) > x && rect.y < y && (rect.y + rect.height) > y;
    });
    return element;
  }

  findElementByIndex(index) {
    return this.el.nativeElement.children[index]
  }

  findElementIndex(element) {
    let ret: number = element.getAttribute("idx");
    return ret;
  }

  moveDOMElement(from, to) {
    if (from != null && to != null) this.customizerService.move(this.customizer, from, to);
  }

  removePlaceIndicator() {
    let elementsWithIndicator = this.el.nativeElement.getElementsByClassName("customizer-item-placeholder-start");
    Array.from(elementsWithIndicator).forEach((oldElement: any) => {
      oldElement.classList.remove("customizer-item-placeholder-start");
    });
    elementsWithIndicator = this.el.nativeElement.getElementsByClassName("customizer-item-placeholder-end");
    Array.from(elementsWithIndicator).forEach((oldElement: any) => {
      oldElement.classList.remove("customizer-item-placeholder-end");
    });
    this.placeEndIdx = null;
  }

  addPlaceIndicator(event) {
    //buscamos elemento en el que estamos
    let element:any = this.findItemUnder(event.clientX, event.clientY);

    //poniendo marca al elemento actual
    var position = "";
    if (element) {
      //calculamos si esta en la derecha o izquierda
      let offset = element.getBoundingClientRect();
      position = event.clientX < (offset.left + offset.width / 2) ? "start" : "end";

      //añadimos clase de indicador
      element.classList.add("customizer-item-placeholder-" + position);

      //calculamos posicion de la plaza
      this.placeEndIdx = this.findElementIndex(element);
      if (position == "end") this.placeEndIdx++;
    }

    //quitando over de elemento anterior si estaba, salvo que sea el mismo o haya cambiado de lugar
    let elementsWithIndicator = this.el.nativeElement.getElementsByClassName("customizer-item-placeholder-start");
    Array.from(elementsWithIndicator).forEach((oldElement:any) => {
      if (element != oldElement || position!="start") oldElement.classList.remove("customizer-item-placeholder-start");
    });
    elementsWithIndicator = this.el.nativeElement.getElementsByClassName("customizer-item-placeholder-end");
    Array.from(elementsWithIndicator).forEach((oldElement: any) => {
      if (element != oldElement || position != "end") oldElement.classList.remove("customizer-item-placeholder-end");
    });

  }

  addResizableHandler(element:any) {
    element.classList.add("customizer-item-resizable");
  }
  removeResizableHandler(element: any) {
    element.classList.remove("customizer-item-resizable");
  }

}
