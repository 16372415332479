import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProductsRoutingModule } from './products-routing.module';
import { ProductsListComponent } from './products-list/products-list.component';
import { TableModule } from 'primeng/table';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { SplitButtonModule } from 'primeng/splitbutton';
import { TimelineModule } from 'primeng/timeline';
import { CoreModule } from '../core/core.module';
import { ProductsDetailComponent } from './products-detail/products-detail.component';
import { ProductsCatalogueComponent } from './products-catalogue/products-catalogue.component';
import {CarouselModule} from 'primeng/carousel';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [ProductsListComponent, ProductsDetailComponent, ProductsCatalogueComponent],
  imports: [
    CommonModule,
    ProductsRoutingModule,
    ButtonModule,
    TableModule,
    ConfirmDialogModule,
    DialogModule,
    FormsModule,
    CheckboxModule,
    CoreModule,
    CardModule,
    FontAwesomeModule,
    InputTextareaModule,
    DropdownModule,
    CalendarModule,
    SplitButtonModule,
    TimelineModule,
    CarouselModule,
    TranslateModule
  ],
  exports:[ProductsListComponent]
})
export class ProductsModule { }
