import { NgModule } from '@angular/core';
import { SidebarComponent } from './sidebar/sidebar.component';
import { SidebarModule } from 'primeng/sidebar';
import { TopbarComponent } from './topbar/topbar.component';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ToastModule } from 'primeng/toast';
//import { DialogConfirmComponent } from './dialogs/dialogs-confirm/dialogs-confirm.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
import { ButtonModule } from 'primeng/button';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DialogConfirmComponent } from './dialogs/dialogs-confirm/dialogs-confirm.component';
import { ViewComponent } from './view/view.component';
import { CommonModule, DatePipe } from '@angular/common';
import { CardModule } from 'primeng/card';
import { InputText, InputTextModule } from 'primeng/inputtext';
import { InputTextarea, InputTextareaModule } from 'primeng/inputtextarea';
import { DropdownModule } from 'primeng/dropdown';
import { CheckboxModule } from 'primeng/checkbox';
import { TableModule } from 'primeng/table';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ViewComponentFormComponent } from './view/component/form/view-component-form.component';
import { ViewComponentTableComponent } from './view/component/table/view-component-table.component';
import { ViewComponentConfigureComponent } from './view/component/configure/view-component-configure.component';
import { GridsterModule } from 'angular-gridster2';
import { LoaderComponent } from './loader/loader.component';
import { LoaderService } from './loader/loader.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpConfigInterceptor } from './loader/httpconfig.interceptor';
import { TooltipModule } from 'primeng/tooltip';
import { ViewComponentCardComponent } from './view/component/card/view-component-card.component';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { PanelMenuModule } from 'primeng/panelmenu';
import { CalendarModule } from 'primeng/calendar';
import {BadgeModule} from 'primeng/badge';
import { EditorModule } from '@tinymce/tinymce-angular';
import { ViewComponentGalleryComponent } from './view/component/gallery/view-component-gallery.component';
import { ViewComponentFilesComponent } from './view/component/files/view-component-files.component';
import { MonacoEditorModule, MONACO_PATH } from '@materia-ui/ngx-monaco-editor';
import { PasswordModule } from 'primeng/password';
import { ViewComponentCustomComponent } from './view/component/custom/view-component-custom.component';
import {SlideMenuModule} from 'primeng/slidemenu';
import {SplitButtonModule} from 'primeng/splitbutton';
import {ColorPickerModule} from 'primeng/colorpicker';
import { AccordionModule } from 'primeng/accordion';
import { CascadeSelectModule } from 'primeng/cascadeselect';
import { InputSwitchModule} from 'primeng/inputswitch';
import { FileUploadModule } from 'primeng/fileupload';
import { CustomizerDirective } from './view/customizer.directive';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PanelModule } from 'primeng/panel';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { ViewComponentAddComponent } from './view/component/add/view-component-add.component';
import { MultiSelectModule } from 'primeng/multiselect';
import { NewViewComponent } from './view/component/new-view/new-view.component';
import { ProgressBarModule } from 'primeng/progressbar';
import { HighlightModule } from '../../lib/highlight/highlight.module';
import { IsoToDatePipe } from './core.pipes';
import { InputMaskModule } from 'primeng/inputmask';
import { ViewComponentDateTimeComponent } from './view/component/datetime/datetime.component';
import { InputNumberModule } from 'primeng/inputnumber';
import { ViewComponentFilterComponent } from './view/component/filter/view-component-filter.component';
import { FilterModalComponent } from './view/component/filter/modal/filter-modal.component';


@NgModule({
  declarations: [
    IsoToDatePipe,
    SidebarComponent,
    TopbarComponent,
    DialogConfirmComponent,
    LoaderComponent,
    ViewComponent,
    ViewComponentFormComponent,
    ViewComponentTableComponent,
    ViewComponentCardComponent,
    ViewComponentGalleryComponent,
    ViewComponentConfigureComponent,
    ViewComponentFilesComponent,
    ViewComponentCustomComponent,
    CustomizerDirective,
    ViewComponentAddComponent,
    NewViewComponent,
    ViewComponentDateTimeComponent,
    ViewComponentFilterComponent,
    FilterModalComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SidebarModule,
    RouterModule,
    ToastModule,
    DynamicDialogModule,
    ButtonModule,
    FontAwesomeModule,
    CardModule,
    InputTextModule,
    InputTextareaModule,
    DropdownModule,
    CheckboxModule,
    TableModule,
    TooltipModule,
    EditorModule,
    DragDropModule,
    GridsterModule,
    AutoCompleteModule,
    PanelMenuModule,
    CalendarModule,
    BadgeModule,
    MonacoEditorModule,
    SlideMenuModule,
    SplitButtonModule,
    ColorPickerModule,
    CascadeSelectModule,
    AccordionModule,
    InputSwitchModule,
    FileUploadModule,
    OverlayPanelModule,
    PanelModule,
    ScrollPanelModule,
    MultiSelectModule,
    ProgressBarModule,
    HighlightModule,
    InputMaskModule,
    InputNumberModule,
    TranslateModule
  ],
  exports: [
    SidebarComponent,
    TopbarComponent,
    LoaderComponent,
    ViewComponent,
    ViewComponentFormComponent,
    ViewComponentTableComponent,
    ViewComponentCardComponent,
    ViewComponentGalleryComponent,
    ViewComponentConfigureComponent,
    ViewComponentFilesComponent,
    ViewComponentCustomComponent,
    ToastModule,
    DynamicDialogModule,
    ButtonModule,
    FontAwesomeModule,
    AutoCompleteModule,
    PasswordModule,
    IsoToDatePipe,
    ViewComponentDateTimeComponent,
    ViewComponentFilterComponent,
    FilterModalComponent
  ],
  providers: [
    ConfirmationService,
    DialogService,
    MessageService,
    LoaderService,
    DatePipe,
    { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true },
    {
      provide: MONACO_PATH,
      useValue: 'https://unpkg.com/monaco-editor@0.24.0/min/vs'
    }
  ]
})
export class CoreModule { }
