import { AfterViewInit, Component, OnInit, ViewChild, ViewChildren } from '@angular/core';
import {  MessageService } from 'primeng/api';
import { ViewComponent } from 'src/app/core/view/view.component';
import { TasksStatusService } from 'src/app/tasks/tasks-status.service';
import { TasksService } from 'src/app/tasks/tasks.service';
import { UIChart } from 'primeng/chart';
import { OrganizationsTypesService } from 'src/app/organizations/organizations-types.service';
import { OrganizationsService } from 'src/app/organizations/organizations.service';
import { LoginService } from 'src/app/login/login.service';
import { DialogService } from 'primeng/dynamicdialog';
import { Router } from '@angular/router';
import { ReportsService } from 'src/app/reports/reports.service';
import { UsersService } from 'src/app/users/users.service';
import { ViewsService } from 'src/app/core/view/views.service';
import { ViewComponentTableComponent } from 'src/app/core/view/component/table/view-component-table.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-home-list',
  templateUrl: './home-list.component.html',
  styleUrls: ['./home-list.component.scss']
})

export class HomeListComponent implements OnInit, AfterViewInit {

  @ViewChild("view") public view: ViewComponent;
  @ViewChild("chartTasks", { static: false }) public chartTasks: UIChart;
  @ViewChild("chartOrganizations", { static: false }) public chartOrganizations: UIChart;
  @ViewChildren("chartGraph") public chartGraph: UIChart;

  public entity: any = {};
  public home: any;
  public user_id: number;
  public selectedTimeModeTask: string = "day";
  public selectedTimeModeMeeting: string = "day";
  public dataOrganizations: any = {
    labels: [],
    datasets: [
      {
        data: []
      }
    ]
  };
  public dataTasks: any = {
    labels: [],
    datasets: [
      {
        data: []
      }
    ]
  };
  /*
    public colorsStates: any = ["#E7E9ED", "#FFCE56","#4BC0C0", "#36A2EB", "#FF6384"];
    public colorsOrganizations: any = ["#FF6384", "#4BC0C0", "#FFCE56", "#E7E9ED", "#36A2EB"];*/
  //public colors1: any = ["#90cdc4", "#E7E9ED", "#FFCE56", "#FF6384", "#36A2EB", "#93dd9c", "#9d9d9d", "#745914", "#103a3a", "#87132c"];
  public colors1: any = ["#BFB48F", "#E89B01", "#E2660E", "#AF2734", "#D687B8", "#904C83", "#8282BF", "#434488", "#267EAB", "#85CDD7", "#26B4AD", "#7FB780", "#2C7130", "#1B505A", "#5E3030"];//ma han pasado la lista de coloresg2pm el 14/11/2022

  public reports: any[];
  public basicOptions: any = {
    plugins: {
      legend: {
        display: true,
        position: 'bottom'
      }
    },
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true
        }
      }]
    }

  };

  constructor(
    private tasksService: TasksService,
    private tasksStatusService: TasksStatusService,
    private organizationsTypesService: OrganizationsTypesService,
    private organizationsService: OrganizationsService,
    private messageService: MessageService,
    private loginService: LoginService,
    private dialogService: DialogService,
    private reportService: ReportsService,
    private router: Router,
    private usersService: UsersService,
    private viewsService: ViewsService,
    private translateService: TranslateService
  ) {
  }

  ngOnInit() {
    this.loadDataGraph();
  }

  ngAfterViewInit() {
    //this.chartGraph.reinit();
  }

  loadData() {
    this.user_id = parseInt(this.loginService.get("userId"));
  }

  loadDataGraph() {

    this.reportService.listGraphsHome().subscribe(data => {
      this.reports = data;

      this.reports.forEach(element => {

        //clear chart
        element.dataGraph = {
          labels: [],
          datasets: [
            {
              data: [],
              label: "",
            }
          ]
        }
        if (element.chart_type == "bar") {
          element.dataGraph.datasets[0].backgroundColor = this.colors1[0];
          element.dataGraph.datasets[0].hoverBackgroundColor = this.colors1[0];
        } else if (element.chart_type == "pie") {
          element.dataGraph.datasets[0].backgroundColor = this.colors1[0];
          element.dataGraph.datasets[0].hoverBackgroundColor = this.colors1[0];
        } else {
          element.dataGraph.datasets[0].borderColor = this.colors1[0];
          element.dataGraph.datasets[0].fill = false;
        }

        element.dataGraph.labels = element.data.map(m => m.label).filter(function (item, pos, self) {
          return self.indexOf(item) == pos;
        });
        let grouped2 = element.data.map(m => m.label2).filter(function (item, pos, self) {
          return self.indexOf(item) == pos;
        });
        element.dataGraph.labels.forEach(label => {
          let i = 0;
          grouped2.forEach(g2 => {
            let valres = element.data.filter(m => m.label == label && m.label2 == g2);
            var randomColor = this.generateColor();
            if (element.dataGraph.datasets.length - 1 < i) {
              var aux = {
                data: [],
                label: "",
                backgroundColor: (this.colors1[i] ? this.colors1[i] : randomColor), hoverBackgroundColor: (this.colors1[i] ? this.colors1[i] : randomColor)
              };
              element.dataGraph.datasets.push(aux);
            }
            element.dataGraph.datasets[i].data.push(valres.length > 0 ? valres[0].value : 0);
            element.dataGraph.datasets[i].label = g2;
            element.dataGraph.datasets[i].label = (typeof (g2) === "undefined" ? element.entity_field_description : g2);
            if (typeof (g2) === "undefined") {
              //Pinta todos del mismo color y pone el label con el texto del field correspondiente.
              element.dataGraph.datasets[i].backgroundColor = this.colors1[0];
              element.dataGraph.datasets[i].hoverBackgroundColor = this.colors1[0];
            }
            i++;
          });

        });

      });

    },
      error => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
      })
  }

  loadPrevOrNext(id: number) {
    let task = null;
    let table: ViewComponentTableComponent = this.view.getComponent("home.tasks");
    let prev: any = null;
    let next: any = null;
    for (let i = 0; i < table.rows.length; i++) {
      if (table.rows[i].id == id) {
        task = table.rows[i];
        if ((i + 1) <= (table.rows.length - 1)) next = table.rows[i + 1];
        break;
      }
      prev = table.rows[i];
    }
    if (task != null) this.viewTask(task, prev, next);
  }

  viewTask(task, prev = null, next = null) {
    var params = { status_pending: true, home: true, _sortBy: "", timeMode: this.selectedTimeModeTask };

    // Convierte el objeto params a una cadena JSON
    var paramsString = JSON.stringify(params);

    // Guarda la cadena JSON en el localStorage con la clave "organization.tasks"
    localStorage.setItem("home.tasks", paramsString);
    localStorage.removeItem("tasks.list");
    localStorage.removeItem("organization.tasks");

    this.router.navigate(['/tasks/', (task.organization_id ? task.organization_id : 0), (task?.id ? task.id : 0)]);
    
  }

  viewMeeting(meeting: any) {
    var params = {
      id: meeting ? meeting.company_id : 0,
      contactId: meeting ? meeting.contact_id : 0,
      meetingId: meeting ? meeting.id : 0,
      deactivated: true,
      timeMode: this.selectedTimeModeMeeting
    }
    // Utiliza router.navigate y pasa el objeto de parámetros
    this.router.navigate(['/meetings/', (meeting ? meeting.id : 0)], { queryParams: params });
  }

  viewOrganization(organization: any) {
    this.router.navigate(['/organizations/', organization.organization_type_id ? organization.organization_type_id : 0, organization.id]);
  }

  viewReport(event: any) {
    this.router.navigateByUrl('/reports/' + event.id + "#" + event.url);
  }
  viewTransactions(event: any) {
    this.router.navigate(['/transactions', event.data.transaction_type_id, event.data.id]);
    /*
    var code = event.data.transactionType.code;
    const ref = this.dialogService.open(TransactionsDetailComponent, {
      data: {
        id: event ? event.data.id : null,
        transaction_type_id: event.data.transaction_type_id
      },
      width: '70%',
    });
    ref.onClose.subscribe((data: any) => {
      if (typeof (this.view) !== "undefined") this.viewsService.changeView(this.view.code);
      this.view.fireEvent("home.opportunities", "reload");
      this.view.fireEvent("home.sales", "reload");
      this.view.fireEvent(event.component, "reload");
      this.view.fireEvent("home.follow_up_opportunities", "reload");
      this.view.fireEvent("home.follow_up_sales", "reload");
    });*/
  }

  reorderReport(event: any) {
    if (event.dragIndex != event.dropIndex) {
      this.usersService.reorderFavouriteReports(event.dragIndex, event.dropIndex).subscribe(data => {
      },
        error => {
          this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
        })
    }
  }

  filterTasksByTime(timeMode, event) {
    this.selectedTimeModeTask = timeMode;
    this.view.fireEvent("home.tasks", "reload", { timeMode: timeMode });
  }
  filterMeetingsByTime(timeMode, event) {
    this.selectedTimeModeMeeting = timeMode;
    this.view.fireEvent("home.meetings", "reload", { timeMode: timeMode, _sortBy: "from" });
  }

  onEvent(event: any) {
    if (event.component == "home.tasks") {
      if (event.event == "dblclick") this.loadPrevOrNext(event.data.id);
      if (event.event == "action" && event.action.name == "edit") this.loadPrevOrNext(event.data.id);
      if (event.event == "action" && event.action.name == "filterTasksByDay") {
        this.filterTasksByTime("day", event);
        const boxes = document.getElementsByClassName("filterTasksByDay");
        boxes[0].classList.add("active");
        const boxes2 = document.getElementsByClassName("filterTasksByMonth");
        boxes2[0].classList.remove("active");
        const boxes3 = document.getElementsByClassName("filterTasksByWeek");
        boxes3[0].classList.remove("active");
      }
      if (event.event == "action" && event.action.name == "filterTasksByWeek") {
        this.filterTasksByTime("week", event);
        const boxes = document.getElementsByClassName("filterTasksByWeek");
        boxes[0].classList.add("active");
        const boxes2 = document.getElementsByClassName("filterTasksByMonth");
        boxes2[0].classList.remove("active");
        const boxes3 = document.getElementsByClassName("filterTasksByDay");
        boxes3[0].classList.remove("active");
      }
      if (event.event == "action" && event.action.name == "filterTasksByMonth") {
        this.filterTasksByTime("month", event);
        const boxes = document.getElementsByClassName("filterTasksByMonth");
        boxes[0].classList.add("active");
        const boxes2 = document.getElementsByClassName("filterTasksByWeek");
        boxes2[0].classList.remove("active");
        const boxes3 = document.getElementsByClassName("filterTasksByDay");
        boxes3[0].classList.remove("active");
      }
    } else if (event.component == "home.meetings") {
      if (event.event == "dblclick") this.viewMeeting(event.data);
      if (event.event == "action" && event.action.name == "edit") this.viewMeeting(event.data);
      //filterMeetingsBy time.
      if (event.event == "action" && event.action.name == "filterMeetingsByDay") {
        this.filterMeetingsByTime("day", event);
        const boxes = document.getElementsByClassName("filterMeetingsByDay");
        boxes[0].classList.add("active");
        const boxes2 = document.getElementsByClassName("filterMeetingsByMonth");
        boxes2[0].classList.remove("active");
        const boxes3 = document.getElementsByClassName("filterMeetingsByWeek");
        boxes3[0].classList.remove("active");
      }
      if (event.event == "action" && event.action.name == "filterMeetingsByWeek") {
        this.filterMeetingsByTime("week", event);
        const boxes = document.getElementsByClassName("filterMeetingsByWeek");
        boxes[0].classList.add("active");
        const boxes2 = document.getElementsByClassName("filterMeetingsByMonth");
        boxes2[0].classList.remove("active");
        const boxes3 = document.getElementsByClassName("filterMeetingsByDay");
        boxes3[0].classList.remove("active");
      }
      if (event.event == "action" && event.action.name == "filterMeetingsByMonth") {
        this.filterMeetingsByTime("month", event);
        const boxes = document.getElementsByClassName("filterMeetingsByMonth");
        boxes[0].classList.add("active");
        const boxes2 = document.getElementsByClassName("filterMeetingsByWeek");
        boxes2[0].classList.remove("active");
        const boxes3 = document.getElementsByClassName("filterMeetingsByDay");
        boxes3[0].classList.remove("active");
      }
    } else if (event.component == "home.organization") {
      if (event.event == "dblclick") this.viewOrganization(event.data);
      if (event.event == "action" && event.action.name == "edit") this.viewOrganization(event.data);
    } else if (event.component == "home.reports") {
      if (event.event == "dblclick") this.viewReport(event.data);
      if (event.event == "action" && event.action.name == "edit") this.viewReport(event.data);
      if (event.event == "reorder") this.reorderReport(event.data);
    } else if (event.component == "home.opportunities") {
      if (event.event == "dblclick") this.viewTransactions(event);
      if (event.event == "action" && event.action.name == "edit") this.viewTransactions(event);
    } else if (event.component == "home.sales") {
      if (event.event == "dblclick") this.viewTransactions(event);
      if (event.event == "action" && event.action.name == "edit") this.viewTransactions(event);
    } else if (event.component != "" && event.event == "dblclick" && typeof (event.data.transaction_type_id) != "undefined") {
      this.viewTransactions(event);
    }
  }

  generateColor() {
    var randomColor = Math.floor(Math.random() * 16777215).toString(16);
    return "#" + randomColor;
  }
}


