import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MailMarketingRoutingModule } from './mail-marketing-routing.module';
import { MailMarketingListComponent } from './mail-marketing-list/mail-marketing-list.component';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { TableModule } from 'primeng/table';
import { CardModule } from 'primeng/card';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DropdownModule } from 'primeng/dropdown';
import { MailMarketingTestComponent } from './mail-marketing-test/mail-marketing-test.component';
import { MailMarketingSendComponent } from './mail-marketing-send/mail-marketing-send.component';
import { CalendarModule } from 'primeng/calendar';
import { MailMarketingNotificationToStatsComponent } from './mail-marketing-notification-to-stats/mail-marketing-notification-to-stats.component';
import { MessageModule } from 'primeng/message';
import { TranslateModule } from '@ngx-translate/core';
import { SidebarModule } from 'primeng/sidebar';


@NgModule({
  declarations: [MailMarketingListComponent, MailMarketingTestComponent, MailMarketingSendComponent, MailMarketingNotificationToStatsComponent],
  imports: [
    CommonModule,
    MailMarketingRoutingModule,
    FormsModule,
    HttpClientModule,
    ButtonModule,
    InputTextModule,
    TableModule,
    CardModule,
    FontAwesomeModule,
    ConfirmDialogModule,
    DropdownModule,
    FontAwesomeModule,
    CalendarModule,
    MessageModule,
    TranslateModule,
    SidebarModule
  ]
})
export class MailMarketingModule { }
