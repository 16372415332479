<ul class="breadcrumb">
  <li><a [routerLink]="['/home']"><span class="color-soft-grey-axial">{{'general.breadcrumb.you_are' | translate}}
      </span>{{'general.breadcrumb.home' | translate}}</a></li>
  <li><i class="pi pi-angle-right"></i></li>
  <li><a [routerLink]="['/configuration/entities']">{{'general.configuration' | translate}}</a></li>
  <li><i class="pi pi-angle-right"></i></li>
  <li><a [routerLink]="['/configuration/companyLicense']">{{'component.companyLicense.license_data' | translate}}</a>
  </li>
</ul>
<p-card class="p-mt-3">
  <ng-template pTemplate="header">
    <div class="p-card-title">
      {{'component.companyLicense.license_data' | translate}}
    </div>
  </ng-template>
  <div class="p-grid">
    <div class="p-col-12">
      <label for="number_of_users">{{'component.companyLicense.date_renovation' | translate}}: </label>
      <span>{{license.renew_date?license.renew_date:' Sin fecha de renovación '}}</span>
    </div>
    <div class="p-col-12">
      <label for="number_of_users">{{'component.companyLicense.num_users' | translate}}: </label>
      <span>{{license.number_of_users}}</span>
      <!--<input type="text" disabled name="number_of_users" value="{{license.number_of_users}}">-->
    </div>
  </div>
  <div class="p-grid">
    <div class="p-col-12">
      <h3>{{'component.companyLicense.features_contracted' | translate}}</h3>
    </div>
    <div class="p-col-12 p-grid p-ml-2">
      <div class="p-col-4" *ngFor="let feature of getFeaturesInGroup('Funcionalidades')">
        {{feature.name}}
      </div>
    </div>
    <div class="p-col-12">
      <h3>{{'component.companyLicense.integrations_contracted' | translate}}</h3>
    </div>
    <div class="p-col-12 p-grid p-ml-2">

      <div class="p-col-4" *ngFor="let feature of getFeaturesInGroup('Integraciones')">
        {{feature.name}}
      </div>
    </div>
  </div>
</p-card>