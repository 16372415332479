<ul class="breadcrumb">
    <li><a [routerLink]="['/home']"><span class="color-soft-grey-axial">{{'general.breadcrumb.you_are' | translate}} </span>{{'general.breadcrumb.home' | translate}}</a></li>
    <li><i class="pi pi-angle-right"></i></li>
    <li *ngIf="organization != null"><a [routerLink]="['/organizations']">Empresas</a></li>
    <li *ngIf="contact != null"><a [routerLink]="['/contacts']">Contactos</a></li>
    <li *ngIf="organization == null && contact == null"><a [routerLink]="['/configuration/entities']">{{'general.configuration' | translate}}</a></li>
    <li *ngIf="contact != null || organization != null"><i class="pi pi-angle-right"></i></li>
    <li *ngIf="organization != null"><a [routerLink]="['/organizations/0', organization.id]">{{organization.name}}</a></li>
    <li *ngIf="contact != null"><a [routerLink]="['/contacts']">{{contact.fullname}}</a></li>
    <li><i class="pi pi-angle-right"></i></li>
    <li><a [routerLink]="['/emails']">Lista de emails</a></li>
</ul>
<app-view #view [code]="'notificationsEmails.list'" [model]="emailsFilters" (onEvent)="onEvent($event)"></app-view>
