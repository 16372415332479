import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TemplatesService {
  url = "/api/templates";

  constructor(private http: HttpClient) { }

  all(params): Observable<any> {
    return this.http.get<any>(this.url, { params: params });
  }

  get(id): Observable<any> {
    return this.http.get<any>(this.url + "/" + id);
  }

  getByCode(code): Observable<any> {
    return this.http.get<any>(this.url + "/bycode/" + code);
  }

  save(id, obj): Observable<any> {
    return this.http.put<any>(this.url + "/" + id, obj);
  }
  savePermissions(id, obj): Observable<any> {
    return this.http.put<any>(this.url + "/savePermissions/" + id, obj);
  }

  add(obj): Observable<any> {
    return this.http.post<any>(this.url, obj);
  }

  delete(id): Observable<any> {
    return this.http.delete<any>(this.url + "/" + id);
  }

  toPDF(id, data): Observable<any> {
    return this.http.post<any>(this.url + "/" + id + "/topdf", data);
  }

  toHTML(id: number, entity_id: number, entity_pk_id: number, temporal_template): Observable<any> {
    return this.http.post<any>(this.url + "/" + id + "/" + entity_id + "/" + entity_pk_id + "/tohtml", temporal_template);
  }

  duplicateTemplate(id): Observable<any> {
    return this.http.get<any>(this.url + "/duplicateTemplate?id=" + id);
  }

  /*
  toHTML2(id:number, entity_id:number, entity_pk_id:number, temporal_template): Observable<any> {
    var params = {temporal_template: temporal_template};
      return this.http.post<any>(this.url + "/" + id + "/" + entity_id + "/" + entity_pk_id + "/tohtml2",temporal_template);
  }
  */

  isDuplied(obj): Observable<any> {
    return this.http.post<any>(this.url + "/isDuplied", obj);
  }
  UploadImage(obj): Observable<object> {
    return this.http.post<object>(this.url + "/UploadImage", obj);

  }

}
