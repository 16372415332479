import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  url = "/api/configuration";

  constructor(private http: HttpClient) { }

  company(): Observable<any> {
    return this.http.get<any>(this.url + "/company");
  }

  css(): Observable<any> {
    return this.http.get<string>(this.url + "/css", { responseType: 'text' as 'json' });
  }

  configuration(): Observable<any> {
    return this.http.get<any>(this.url + "/configuration");
  }

}
