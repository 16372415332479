import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CompaniesIntegrationsService } from '../companiesintegrations.service';
import { Office365Service } from '../office365.service';
import { MailchimpService } from '../mailchimp.service';
import { EntitiesService } from '../../entities.service';

@Component({
  selector: 'app-integration-edit-mailchimp',
  templateUrl: './integration-edit-mailchimp.component.html',
  styleUrls: ['./integration-edit-mailchimp.component.scss']
})
export class IntegrationEditMailchimpComponent implements OnInit {
  public integration: any = {
    id: null,
    configuration: {
      auth: { enabled: false },
      mail: { enabled: false },
      contacts: { enabled: false },
      campaigns: { enabled: false },
    }
  };
  public connected: boolean = false;
  public connectionInfo: any = {};
  public auth: any = {};
  public mail: any = {};
  public contacts: any = {};
  public campaigns: any = {};
  public lists: any;
  public errorMessage: string;
  public mergeFields: any[] = [];
  public bind: any = {};
  public entity: any = {};

  public contactsModes: any = [
    { name: "Axial <= Mailchimp", value: "<" },
    { name: "Axial => Mailchimp", value: ">" },
    { name: "Axial <=> Mailchimp", value: "<>" }
  ];
  public campaignsModes: any = [
    { name: "Axial => Mailchimp", value: ">" },
    { name: "Axial <=> Mailchimp", value: "<=>" }
  ];

  constructor(
    private dialogService: DialogService,
    private dynamicDialogRef: DynamicDialogRef,
    private companiesIntegrationsService: CompaniesIntegrationsService,
    private office365Service: Office365Service,
    private messageService: MessageService,
    private mailchimpService: MailchimpService,
    private entitiesService: EntitiesService,
  ) { }

  ngOnInit() {
    this.companiesIntegrationsService.getByCode("mailchimp").subscribe({
      next: (data: any) => {
        if (data != null) {
          this.integration = data;
          if (this.integration.configuration == null) this.initializeConfig();
          this.verify();
        } else {
          this.initializeConfig();
        }
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
      }
    });
  }

  initializeConfig() {
    this.integration.configuration = {
      auth: { enabled: false },
      mail: { enabled: false },
      contacts: { enabled: false },
      campaigns: { enabled: false },
    };
  }

  verify() {
    if (this.integration?.configuration?.auth?.api_key == null || this.integration?.configuration?.auth?.api_key == "") return;
    this.mailchimpService.verify(this.integration.configuration.auth.api_key).subscribe(
      data => {
        this.errorMessage = null;
        this.connected = true;
        this.integration.configuration.auth.connected = true;

        //cargamos listas
        this.getAllLists();

        //cargamos campos de entidad contactos
        this.loadEntityFields();

        //cargamos merges
        if (this.integration.configuration.contacts?.list_id != null) {
          this.loadMergeFields();
        }
      },
      error => {
        //this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
        this.errorMessage = error.error.title;
        this.connected = false;
        this.integration.configuration.auth.connected = false;
      }
    );
  }

  getAllLists() {
    if (this.integration?.configuration?.auth?.api_key == null || this.integration?.configuration?.auth?.api_key == "") return;
    this.mailchimpService.getAllLists(this.integration.configuration.auth.api_key).subscribe({
      next: (data: any) => {
        this.lists = data;
      },
      error: (error: any) => {
        //this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
        this.errorMessage = error.error.title;
      }
    });
  }

  loadEntityFields() {
    this.entitiesService.getByCode("contact").subscribe({
      next: (data: any) => {
        this.entity = data;

        this.entity.fields.forEach(field => {
          field.description = field.entity_name + " / " + field.description;
        });
      },
      error: (error: any) => {
        //this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
        this.errorMessage = error.error.title;
      }
    });
  }

  loadMergeFields() {
    if (this.integration?.configuration?.auth?.api_key == null || this.integration?.configuration?.auth?.api_key == "") return;
    if (this.integration?.configuration?.contacts?.list_id == null || this.integration?.configuration?.contacts?.list_id == "") return;
    this.mailchimpService.getMergeFields(this.integration.configuration.auth.api_key, this.integration.configuration.contacts.list_id).subscribe({
      next: (data: any) => {
        this.mergeFields = data;
      },
      error: (error: any) => {
        //this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
        this.errorMessage = error.error.title;
      }
    });
  }

  addBind() {
    if (this.bind.entity_field_id != null && this.bind.mailchimp != null) {
      if (!this.integration.configuration.contacts.binds) this.integration.configuration.contacts.binds = [];
      this.integration.configuration.contacts.binds.push(this.bind);
      this.bind = {};
    } else {
      this.messageService.add({ severity: "error"/* summary: "Enlace no creado"*/, detail: "Debes seleccionar ambas propiedades para poder añadir el enlace de datos" });
    }
  }

  removeBind(bind) {
    let idx = this.integration.configuration.contacts.binds.indexOf(bind);
    this.integration.configuration.contacts.binds.splice(idx, 1);
  }

  save() {
    this.companiesIntegrationsService.save(this.integration.id, this.integration).subscribe(
      data => {
        this.dynamicDialogRef.close(data);
      },
      error => {
        this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
      }
    );
  }

  cancel() {
    this.dynamicDialogRef.close();
  }

  connect() {
    this.verify();
  }

}
