<p-card styleClass="p-card-content-fit">
    <ng-template pTemplate="header">
      <div class="p-card-title">
        {{componentService.getComponentTitle(component)}}
        <small *ngIf="showConfigOptions">Component: {{component.code}}, Entity: {{component.entity}}</small>
        <div *ngIf="!showConfigOptions" class="p-card-title-actions">
          <ng-container *ngFor="let action of component.headerActions">
            <a *ngIf="isActionVisible(action, item)" (click)="onActionClick(action, item)" class="{{action.class}}">
              <fa-icon *ngIf="action.icon!=null" [icon]="action.icon"></fa-icon>&nbsp;{{getActionLabel(action)}}
              <br *ngIf="debugService.enabled" /><small *ngIf="debugService.enabled">{{component.code}}.{{action.name}}</small>
            </a>
          </ng-container>
        </div>
        <div *ngIf="showConfigOptions" class="p-card-title-actions">
          <ng-content select="[toolbarStart]"></ng-content>
          <a *ngIf="showConfigOptions" (click)="configure()" class="internal-action">
            <fa-icon [icon]="['fas', 'cogs']"></fa-icon>
          </a>
          <ng-content select="[toolbarEnd]"></ng-content>
        </div>
      </div>
    </ng-template>        
    <div style="{{component.height!=null?'height: ' + component.height:''}}">
        <p-table #grid
            
            [value]="rows"
            [loading]="loading" [paginator]="false" [rows]="10" [totalRecords]="totalRows"
            [showCurrentPageReport]="true" currentPageReportTemplate="{{ 'general.resumen_table_records' | translate: { first: first, last: last, totalRecords: totalRows } }}"
            [rowsPerPageOptions]="[5,10,25,50]"
            [scrollable]="true" scrollHeight="flex"
            >                        
            <ng-template pTemplate="body" let-item>
               
                <tr (dblclick)="onDblClick(item)">
                    <td>
                        <img src="/api/PublicAttachments/{{companyId}}/{{item.guid}}/download" style="max-width: 100%; max-height: 40px;" />
                    </td>
                    <td>
                        <div class="d-flex flex-column" (click)="onDblClick(item)">
                            <div class="user_name text-truncate">
                                <span class="fw-bold card-title">{{getData(item, "title")}}</span>
                            </div>
                            <small class="emp_post text-muted">{{getData(item, "subtitle")}}</small>                                                                        
                            <div [innerHtml]="getData(item, 'badge')">
                            </div>                                                                        
                        </div>
                    </td>
                    <td class="grid-actions">
                        <a *ngFor="let action of component.actions" (click)="onActionClick(action, item)" class="{{action.class}}">
                            <fa-icon [icon]="action.icon" *ngIf="isActionVisible(action, item)"></fa-icon>
                        </a>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage" let-columns>
                <tr>
                    <td [attr.colspan]="2">
                        {{'general.no_data' | translate }}
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</p-card>
