import { Component, Input, OnInit } from '@angular/core';
import { ViewComponent } from 'src/app/core/view/view.component';
import { CustomComponent } from '../../../core/view/component/custom/custom-component.interface';
import { CompaniesIntegrationsService } from '../../../configuration/integration/companiesintegrations.service';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-transactios-sage-info-custom',
  templateUrl: './transactios-sage-info-custom.component.html',
  styleUrls: ['./transactios-sage-info-custom.component.scss']
})
export class TransactionSAGEInfoCustomComponent implements OnInit, CustomComponent {
  @Input() public data: any
  @Input() public entity: any;

  public info: any = {};

  constructor(
    private companiesIntegrationsService: CompaniesIntegrationsService,
    private messageService: MessageService,
    private translateService: TranslateService) {
 }

  ngOnInit(): void {
    this.loadData();    
  }

  loadData() {
    if (this.data.transaction_type_id == 2) {
      this.privateObtainInfo(this.data);
    } else {
      this.privateObtainInfo(this.data.offer);
    }
  }

  privateObtainInfo(data: any) {

    console.log("privateObtainInfo", data);
    if (typeof data == "undefined") {
      this.info = {
        color: "#ca8a04",
        icon: "pi pi-info-circle",
        message: this.translateService.instant("component.transactionSAGEInfoCustom.no_offer_to_sincronize")
      }
    } else {
      if (!data.id || data.id == 0) {
        this.info = {
          color: "#ca8a04",
          icon: "pi pi-fw pi-exclamation-circle",
          message: this.translateService.instant("component.transactionSAGEInfoCustom.sincronization_pending"),
        }
      } else {
        //1)comprobamos si tiene sincronización activada.
        if (data.erp_id != null) {
          //TODO CORRECTO
          this.info = {
            color: "#40ab44",
            icon: "pi pi-fw pi-check-circle",
            message: this.translateService.instant("component.transactionSAGEInfoCustom.sincronization_ok"),
            // smallMsg: data.erp_id
          }
        } else {
          if (data.business_id != null || data.organization?.business_id != null) {
            this.companiesIntegrationsService.getByCode("sagedespachos").subscribe({
              next: (integration: any) => {
                let business_id = (data.business_id != null ? data.business_id : data.organization?.business_id);
                let item = integration.configuration.business.find(m => m.business_id == business_id);

                if (item.sales?.export) {
                  if (data.erp_id != null) {
                    //TODO CORRECTO
                    this.info = {
                      color: "#40ab44",
                      icon: "pi pi-fw pi-check-circle",
                      message: this.translateService.instant("component.transactionSAGEInfoCustom.sincronization_ok"),
                      //smallMsg: data.erp_id
                    }
                  } else {
                    //Miramos si es porque organización no tiene definido cif.
                    if (data.organization?.vat_number) {
                      this.info = {
                        color: "#ca8a04",
                        icon: "pi pi-fw pi-sync",
                        message: this.translateService.instant("component.transactionSAGEInfoCustom.sincronization_pending"),
                      }
                    } else {
                      this.info = {
                        color: "#B71C1C",
                        icon: "pi pi-fw pi-exclamation-circle",
                        message: "No se puede enviar a SAGE porque existen errores.",
                        smallMsg: this.translateService.instant("component.transactionSAGEInfoCustom.sincronization_no_vat"),
                      }
                    }
                  }
                } else {
                  this.info = {
                    color: "#B71C1C",
                    icon: "pi pi-fw pi-exclamation-circle",
                    message: this.translateService.instant("component.transactionSAGEInfoCustom.sincronization_no_config"),
                  }
                }
              },
              error: (error: any) => {
                this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
              }
            });
          } else {
            this.info = {
              color: "#B71C1C",
              icon: "pi pi-fw pi-exclamation-circle",
              message: this.translateService.instant("component.transactionSAGEInfoCustom.sincronization_no_bussiness")
            }
          }
        }
      }
    }
    
   
   
  }

  onEvent(component: string, event: string, args: any) {
    
    if (component == "transactionSAGE.custom" && event == "reload") {
      this.loadData();
    }
  }
}
