import { DatePipe } from '@angular/common';
import { Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';

import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { EntitiesService } from 'src/app/configuration/entities.service';
import { ViewsService } from '../../views.service';
import { ViewComponentConfigureComponent } from '../configure/view-component-configure.component';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { DomSanitizer } from '@angular/platform-browser';
import { LoginService } from 'src/app/login/login.service';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { OverlayPanel } from 'primeng/overlaypanel';
import { ComponentService } from '../../component.service';
import { LoaderService } from '../../../loader/loader.service';
import { environment } from 'src/environments/environment';
import { DebugService } from '../../../debug.service';
import { SessionStorageService } from 'ngx-webstorage';
import { TranslateService } from '@ngx-translate/core';
import { UtilsService } from '../../../utils.service';

@Component({
  selector: 'app-view-component-table',
  templateUrl: 'view-component-table.component.html',
  styleUrls: ['view-component-table.component.scss'],
})
export class ViewComponentTableComponent implements OnInit {

  @ViewChild("grid") private grid: any;

  @ViewChild('opColumns') opColumns: OverlayPanel;

  @ViewChild('opField') opField: OverlayPanel;
  public selectedField: any = {};
  public visibleByOptions: any[] = [
    { code: "all", label: this.translateService.instant("view.configure.all") },
    { code: "user", label: this.translateService.instant("view.configure.user_responsible") }
  ];

  @Output() onEvent: EventEmitter<any> = new EventEmitter();


  @Input("view") public view: any = {};
  @Input("component") public component: any = {};
  @Input("index") public index: number = 0;
  @Input("model") public model: any = {};
  @Input("config") public config: any = {};
  @Input("showConfigOptions") public showConfigOptions: boolean = false;
  public temporalModel: any = {};
  public entity: any = {};
  private lastGridEvent: any;
  public loading: boolean = false;
  public totalRows: number = 0;
  public rows: any = Array<any>();
  private touchMoved: boolean = false;
  public params: any = {};
  public first;
  public last;

  public columnResizeMode: string = 'fit';

  public allowedEntities: any[] = [];
  public allowedEntitiesFields: any = {};

  public rowsPerPageOptions: number[] = [5, 10, 25, 50, 100, 250, 500];
  public rowsPerPage: number = 50;
  public selectedRow: any[] = [];

  public show_filter_panel: boolean = false;

  constructor(
    private loaderService: LoaderService,
    private entitiesService: EntitiesService,
    private messageService: MessageService,
    private viewsService: ViewsService,
    private datePipe: DatePipe,
    private dialogService: DialogService,
    public sanitizer: DomSanitizer,
    private loginService: LoginService,
    public componentService: ComponentService,
    private debugService: DebugService,
    private sessionStorage: SessionStorageService,
    private translateService: TranslateService,
    private utilsService: UtilsService
  ) {
  }

  public create_reports: boolean;
  public create_templates: boolean;
  public catalogue_button: boolean;
  public locked: boolean = false;
  public colspanIfEmpty: number;

  ngOnInit(): void {

    if (this.component.rowsPerPageOptions) this.rowsPerPageOptions = this.component.rowsPerPageOptions;

    if (this.component.rowsPerPage) {
      this.rowsPerPage = this.component.rowsPerPage;
    }
    //Si no tiene datos que mostrar, se mostrará el mensaje de sin datos con las columnas correspondientes:
    this.colspanIfEmpty = this.component.fields.length + (this.component.allow_reorder_rows ? 1 : 0) + (this.component.actions.length > 0 ? 1 : 0);

    this.create_reports = this.loginService.hasPermission("REPORTS_CREATE");
    this.create_templates = this.loginService.hasPermission("CREATE_TEMPLATES");
    this.catalogue_button = this.loginService.hasPermission("CATALOGUE_BUTTON");
    this.entitiesService.getByCode(this.component.entity).subscribe(
      data => {
        var newFields: any = [];
        var user_role_id = localStorage.getItem("roleId") + "";

        this.component.fields.forEach(field => {
          var hasRole = true;
          this.entity = data;
          field.entityField = this.entity.fields.find(m => m.id == field.entity_field_id);
          if (field.entityField != null && field.entityField.configuration != null) {
            var viewRoles = field.entityField?.configuration['viewRoles'];
            if (viewRoles != null && viewRoles.length > 0) {
              if (!viewRoles.find(role => role == user_role_id)) hasRole = false;
            }
          }
          if (field.entityField != null && hasRole) newFields.push(field);
        });
        this.component.fields = newFields;
        setTimeout(() => {
          this.adjustCols();
        });
      },
      error => {
        this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
      }
    );

  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.adjustCols();
  }

  adjustCols() {

    const minColWidth = 60;

    //calcumos ancho de contenedor menos padding y borders
    let container = this.grid.el.nativeElement.parentElement;
    const styles = window.getComputedStyle(container);
    let fullWidth = container.clientWidth - parseFloat(styles.paddingLeft) - parseFloat(styles.paddingRight);
    let allColWidth = 0;
    this.component.fields.forEach(field => {
      if (field.width) {
        allColWidth += parseFloat(field.width);
      } else {
        allColWidth += minColWidth;
      }
    });

    //añadimos columna de ancla de move
    if (this.component.allow_reorder_rows) {
      //allColWidth += 35;
      fullWidth -= 35;
    }

    //añadimos columna de acciones
    if (this.component.actions.length > 0) {
      //allColWidth += 100;
      fullWidth -= 100;
    }
    //ajustamos cada campo proporcionalmente al ancho que tenia
    let usedWidth = 0;
    let totalFieldNoWidth = 0;
    this.component.fields.forEach(field => {
      if (field.width) {
        let adjustedSize = Math.floor(fullWidth * field.width / allColWidth);
        //verificamos que no este por debajo del permitido
        if (adjustedSize < 40) {
          adjustedSize = 40;
        }

        field.width = adjustedSize;
        usedWidth += adjustedSize;

      } else {
        totalFieldNoWidth++;
      }
    });
    if (this.component.actions.length > 0) usedWidth += 100;

    //ajustamos cada campo con el ancho proporcional sobrante
    let defaultFieldSize = (fullWidth - usedWidth) / totalFieldNoWidth;
    this.component.fields.forEach(field => {
      if (!field.width) {
        let adjustedSize = defaultFieldSize;

        if (adjustedSize < minColWidth) adjustedSize = minColWidth;

        field.width = adjustedSize;
        usedWidth += adjustedSize;
      }
    });

    //si habiamos requerido mas ancho del previsto, es porque habia columnas que estaban por debajo del minColWidth
    //if (fullWidth < usedWidth) this.adjustCols();

  }

  autoSizeCols() {
    //calcumos ancho de contenedor menos padding y borders
    let container = this.grid.el.nativeElement.parentElement;
    const styles = window.getComputedStyle(container);
    let fullWidth = container.clientWidth - parseFloat(styles.paddingLeft) - parseFloat(styles.paddingRight);


    //añadimos columna de ancla de move
    if (this.component.allow_reorder_rows) fullWidth -= 35;

    //columna de acciones
    if (this.component.actions.length > 0) {
      fullWidth -= 100;
    }

    //ajustamos cada campo con el ancho proporcional sobrante
    let defaultFieldSize = Math.floor(fullWidth / this.component.fields.length);
    this.component.fields.forEach(field => {
      let adjustedSize = defaultFieldSize;
      field.width = adjustedSize;
    });
  }

  getFieldDescription(field: any, entityfieldObject: boolean = true) {
    return this.componentService.getFieldDescription(field, entityfieldObject);
    /*
    let label = (entityfieldObject ? field.entityField?.description : field.description);
    if (field.label && field.label != null && field.label != "") {
      label = this.translateService.instant(field.label);
    } else {
      
      //No es parsear la descripción sino componer la clave. EJEM: contact.entity_fields.name
      let full_model_property = (entityfieldObject ? field.entityField?.model_property : field.model_property);
      let model_property = full_model_property.split("."); //Porque si son de otra entidad a la pintada el model property llega concatenado.
      let keyTranslate = (entityfieldObject ? field.entityField?.entity_code : field.entity_code) + ".entity_fields." + model_property[model_property.length-1];
      if (!entityfieldObject) console.log(keyTranslate, field);

      let label2 = this.translateService.instant(keyTranslate);
      if (keyTranslate != label2) {
        //No ha encontrado la clave, ponemos la descripción del campo.
        label = label2;
      }
      
    }
    return label;*/
  }
  getEntityName(entity: any) {
    return this.componentService.getEntityName(entity);
    /*
    let label = null;
    
    //Buscamos si está en la traducción key = "contact.entity"
    let key = entity.code + ".entity.name";
    label = this.translateService.instant(key);
    if (key == label) {
      //No tiene traduccion devolvemos la base.
      label = entity.name;
    }
    return label;*/
  }

  getRowClass(row: any) {
    let className = "";
    if (this.component.rowFormatFn != null) {
      try {
        if (this.component.rowFormatFn.indexOf("eval|") == 0) {
          let command = this.component.rowFormatFn.replace("eval|", "");
          className = eval(command);
        }
      } catch (e) {
      }
    }
    return className;
  }

  getRowValue(row, field, excel = false, onlyText = false) {
    if (field.entityField == null) return;

    //verificamos si puede ver el valor
    if (!this.showConfigOptions && !this.componentService.isVisibleField(field, row)) {
      if (!this.componentService.isVisibleFieldCustom(field, row)) return "";
      return "<small>(locked)</small>";
    }

    let property = field.entityField.model_property;
    if (field.entityField.model_property_label != null && field.entityField.model_property_label != "") {
      property = field.entityField.model_property_label;

      //si tiene mas de un path, hay que añadirlo por delante de la propiedad
      let path = "";
      if (field.entityField.model_property_path != null && field.entityField.model_property_path.length >= 2) path = (path != "" ? "." : "") + field.entityField.model_property_path[1].property;
      if (field.entityField.model_property_path != null && field.entityField.model_property_path.length >= 3) path = (path != "" ? "." : "") + field.entityField.model_property_path[2].property;

      //añadimos path
      property = path + (path != "" ? "." : "") + property;
    }

    var value = "";
    try {
      if (field.entityField.is_base_field == true) {
        //console.log(property);
        value = eval("row." + property);
      } else {
        //Si no es base, y contiene metadata ya es que es una propuiedad de navegación de un field customizado (no de la entidad que estamos mostrando).
        if ((property).includes("metadata")) {

          value = eval("row." + property);
        } else {
          value = eval("row.metadata." + property);
        }


        if (field.entityField?.control_type == "dropdown") {
          //obtener valor para el seleccionado          
          if (field.entityField.configuration.options?.type == "api") {
            //petición al servidor.
          } else {
            let temporalCombos = field.entityField.configuration.options.items;
            temporalCombos.forEach((item) => {
              if (eval("item." + field.entityField?.configuration?.field_value) == value) {
                value = eval("item." + field.entityField?.configuration?.field_text);
              }
            });
          }
        } else if (field.entityField?.control_type == "dropdown-multiple") {
          //obtener valor para el seleccionado          
          if (field.entityField.configuration.options?.type == "api") {
            //petición al servidor.
          } else {
            let temporalCombos = field.entityField.configuration.options.items;
            let selectedValues: any = value;
            if (!(selectedValues instanceof Array)) selectedValues = [selectedValues];
            value = "";
            selectedValues.forEach(selectedValue => {
              temporalCombos.forEach((item) => {
                if (eval("item." + field.entityField?.configuration?.field_value) == selectedValue) {
                  if (value != "") value += ", ";
                  value += eval("item." + field.entityField?.configuration?.field_text);
                }
              });
            });
          }
        }
      }
    } catch (e) {
      //console.error("Eval for row." + property + " fail!");
    }
    let formatFn = field.formatFn;
    if (formatFn == null && field.entityField.configuration != null && field.entityField.configuration.formatFn != null) {
      formatFn = field.entityField.configuration.formatFn;
    }
    if (formatFn != null && !excel) {
      var format = formatFn.split("|");
      if (format[0] == "date") value = this.utilsService.formatDate(value, format);
      if (!onlyText) { //esto es porque hay un tooltip con el texto y solo queremos que formatee el date
        if (format[0] == "suffix") value = this.utilsService.formatSuffix(value, format);
        if (format[0] == "phone") value = this.utilsService.formatPhone(value, format);
        if (format[0] == "mail") value = this.utilsService.formatMail(value, format);
        if (format[0] == "www") value = this.utilsService.formatWWW(value, format);
        if (format[0] == "badge") value = this.utilsService.formatBadge(field, row, value, format);
        if (format[0] == "badgeColor") value = this.utilsService.formatBadgeColor(field, row, value, format);
        if (format[0] == "image") value = this.utilsService.formatImage(field, format);
        if (format[0] == "icon") value = this.utilsService.formatIcon(value, format);
        if (format[0] == "currency") value = this.utilsService.formatCurrency(value, format);
        if (format[0] == "number") value = this.utilsService.formatNumber(value, format);
      }
    }
    if (value == null || value == "") value = " ";
    return value;
  }

  preLazyLoad($event) {
    setTimeout(() => this.lazyLoad($event), 100);
  }

  lazyLoad($event, args = null) {
    this.lastGridEvent = $event;
    Promise.resolve(null).then(() => this.loading = true);
    this.params = {
      _page: $event.first / $event.rows,
      _pageSize: $event.rows,
      _sortBy: $event.sortField ? $event.sortField : "",
      _sortDirection: $event.sortOrder > 0 ? "asc" : "desc"
    };

    if (args != null) {
      this.params = Object.assign(this.params, args);
    }

    //filtros viejos de tablas
    if (this.component.source != null) {
      if (this.component.source.params != null && Array.isArray(this.component.source.params)) {
        this.component.source.params.forEach(param => {
          let value = eval(param.value);
          if (typeof (value) != "undefined" && value != null) this.params[param.name] = value;
        });
      }
      if (this.component.source.paramsFromComponent != null) {
        var filterComponent = this.view.configuration.components.find(m => m.code == this.component.source.paramsFromComponent);
        filterComponent.fields.forEach(field => {
          if (field.entityField) {
            let isMetadata = false;
            if (field.entityField.model_property.indexOf("custom_") >= 0) isMetadata = true;
            let modelProperty = "this.model" + (isMetadata ? ".metadata" : "") + "['" + field.entityField.model_property + "']";
            let modelValue = eval(modelProperty);

            if (typeof (modelValue) != "undefined" && modelValue != null) {

              //añadimos valor
              if (field.entityField.data_type == "date") {
                if (modelValue instanceof Date) {
                  this.params[field.entityField.model_property] = modelValue.toISOString();
                } else {
                  this.params[field.entityField.model_property] = modelValue;
                }
              } else {
                this.params[field.entityField.model_property] = modelValue;
              }

              //añadimos operador si hay
              if (field.filter_operator) {
                this.params["_op_" + field.entityField.model_property] = field.filter_operator;
              } else {
                if (isMetadata) {
                  this.params["_op_" + field.entityField.model_property] = "like";
                } else {
                  this.params["_op_" + field.entityField.model_property] = "=";
                }
              }
            }
          }
        });
      }

    }//end component.source

    //si tiene datos en sesion, rellenar modelo con los valores de los filtros en sesion
    let sessionInfo = this.sessionStorage.retrieve(this.view.code);
    if (sessionInfo != null) {
      sessionInfo.filters.forEach((field: any) => {
        if (field.value != null && field.value != "") {
          var entityField = this.entity.fields.find(m => m.id == field.entity_field_id);
          if (entityField) {
            //si no esta excluido, cargamos el valor de la session
            let ignore_session_value = this.model._ignore_session_fields?.findIndex(m => m == entityField.model_property) >= 0;
            if (!ignore_session_value) {
              this.params[entityField.model_property] = field.value;
              this.params["_op_" + entityField.model_property] = field.operator;
            }
          }
        }
      });
    }

    //si no hemos cambiado la ordenación, la asignamos
    if ($event.sortField) {
      this.params._sortBy = $event.sortField;
      this.params._sortDirection = $event.sortOrder > 0 ? "asc" : "desc";
    }
    this.loaderService.skipRequest('/api/' + this.component.source.url, this.params);
    this.viewsService.getTableData(this.component.source.url, this.params).subscribe(
      data => {
        this.totalRows = data.totalRows;
        this.rows = data.rows;
        this.onEvent.emit({ event: "reload", filters: this.params });
        Promise.resolve(null).then(() => this.loading = false);
      },
      error => {
        this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
        Promise.resolve(null).then(() => this.loading = false);
      }
    );
  }
  left() {
    this.onEvent.emit({ event: "component-left", index: this.index });
  }

  right() {
    this.onEvent.emit({ event: "component-right", index: this.index });
  }

  configure() {
    const ref = this.dialogService.open(ViewComponentConfigureComponent, {
      data: {
        component: this.component,
        entity: this.entity
      },
      header: this.translateService.instant("component.configure.title"), //"Configuración de componente",
      width: '70%'
    });
    ref.onClose.subscribe((data: any) => {
      if (data) {

        this.component.fields = data.fields;
        this.component.title = data.title;
        this.component.class = data.class;
        this.component.height = data.height;
        this.component.allow_export_xls = data.allow_export_xls && this.loginService.hasPermission("EXPORT_EXCEL");
        this.component.show_header = data.show_header;
        this.component.allow_reorder_rows = data.allow_reorder_rows;
        this.onEvent.emit({ event: "view-save" });
      }
    })
  }
  onTouchStart(event) {
    this.touchMoved = false;
  }
  onTouchMove(event) {
    this.touchMoved = true;
  }

  onTouchEnd(item, $event) {
    if (this.touchMoved) {
      this.touchMoved = false;
      return;
    }
    this.onDblClick(item, $event);
  }

  onRowReorder(event) {
    let ids = [];
    for (const key of this.rows) {
      ids.push(key.id);
    }
    if (!this.locked) {
      this.onEvent.emit({ event: "reorder", component: this.component.code, data: event, ids: ids, params: this.params });
    }
  }

  onDblClick(item, $event) {
    $event.preventDefault();
    $event.stopPropagation();
    if (!this.locked) {
      this.onEvent.emit({ event: "dblclick", component: this.component.code, data: item });
    }
  }
  getActionLabel(action, item) {

    //return this.componentService.getActionLabel(action, this.view.code + "-" + this.component.code);
    //console.log(this.component.code + ".action." + action.name);
    return this.componentService.getActionLabel(action, this.component.code);
    /*let label = action.label;
    //1 buscamos en el generico general.action.add
    var key = "general.action." + action.name;
    if (this.translateService.instant(key) !== key) {
      label = this.translateService.instant(key);
    }
    //2 buscamos en el especifico
    var keySpecific = "component."+this.view.code+"." + action.name;
    if (this.translateService.instant(keySpecific) !== keySpecific) {
      label = this.translateService.instant(keySpecific);
    }
    return label;*/
  }

  onActionClick(action, item, $event) {
    $event.preventDefault();
    $event.stopPropagation();
    let auxSelectedRow = null;
    if (this.component.checked_multiple) auxSelectedRow = this.selectedRow.map(m => m[this.component.checked_multiple.value]);
    this.onEvent.emit({ event: "action", component: this.component.code, action: action, data: item, selectedRow: auxSelectedRow });
  }

  fireEvent(component: string, event: string, args: any) {
    if (event == "reload") {
      if (args != null) this.model = args; //Cuando esta en reload, guardamos el modelo con los datos de la peticion.
      this.lazyLoad(this.lastGridEvent, args);
    }
  }

  isActionVisible(action: any, item: any) {
    let visible: boolean = true;
    if (action.ngIf != null) {
      visible = eval(action.ngIf);
    }

    return visible;
  }

  getExcelPrams(): any {

    var page = this.params["_page"];
    var pageSize = this.params["_pageSize"];
    let excelParams = JSON.parse(JSON.stringify(this.params));

    delete excelParams["_page"];
    delete excelParams["_pageSize"];

    return excelParams;
  }

  exportExcel() {
    var excelParams = this.getExcelPrams();
    this.viewsService.getTableData(this.component.source.url, excelParams).subscribe({
      next: (data: any) => {
        this.totalRows = data.totalRows;
        this.rows = data.rows;

        var aux = [];

        //For que recorre cada row y column y devuelve un objeto que será exportado a excel:
        var i = 0;
        this.rows.forEach(row => {
          var obj = {};
          this.component.fields.forEach(column => {
            var fieldValue = this.getRowValue(row, column, true);


            if (fieldValue != null && fieldValue != "" && fieldValue != " " && column.entityField.data_type == "date") {
              var fDate = new Date(fieldValue);
              var month = fDate.getMonth();
              fieldValue = fDate.getDate() + "/" + (month + 1) + "/" + fDate.getFullYear() + " " + fDate.getHours() + ":" + fDate.getMinutes()/* + ":" + fDate.getSeconds()*/;

            }
            if ((fieldValue == null || fieldValue.length == 0 || fieldValue == " ") && column.entityField.data_type == "number" && column.entityField.control_type != "dropdown") fieldValue = "0";
            if ((fieldValue == null || fieldValue.length == 0 || fieldValue == " ") && column.entityField.data_type == "number" && column.entityField.control_type == "dropdown") fieldValue = "";
            if (column.entityField.data_type == "number" && column.entityField.control_type != "dropdown") {
              fieldValue = new Intl.NumberFormat(environment.companyConfiguration.locale, { useGrouping: true }).format(parseFloat(fieldValue));
            }

            obj[this.getFieldDescription(column)] = "" + fieldValue;
          });
          aux.push(obj);
        });

        this.onEvent.emit({ event: "reload", filters: {} });
        Promise.resolve(null).then(() => this.loading = false);
        const worksheet = XLSX.utils.json_to_sheet(aux);
        const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, "data");
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
        Promise.resolve(null).then(() => this.loading = false);
      }
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  onColReorder($event) {
    moveItemInArray(this.component.fields, $event.dragIndex, $event.dropIndex);
  }

  onColResize($event) {

    //mapeamos anchos de cabeceras a campos
    let headers = $event.element.parentElement.getElementsByTagName('th');
    for (let header of headers) {
      let idx = header.getAttribute("data-index");
      let width = header.offsetWidth;
      if (idx != null) {
        this.component.fields[idx].width = width;
      }
    }
  }


  showColumnsSelector($event) {
    this.allowedEntities = [];
    this.allowedEntitiesFields = {};
    this.entity.fields.forEach(field => {
      if (this.allowedEntities.find(m => m.code == field.entity_code) == null) {
        this.allowedEntities.push({ code: field.entity_code, name: field.entity_name });
        this.prepareAllowedEntityFields(field.entity_code);
      }
    });
    this.opColumns.toggle($event);
  }

  prepareAllowedEntityFields(entity_code: string) {
    let fields = this.entity.fields.filter(m => m.entity_code == entity_code);
    fields.forEach((field) => {
      var selected = this.component.fields.find(m => m.entity_field_id == field.id);
      field.selected = selected != null;
    });
    this.allowedEntitiesFields[entity_code] = fields;
  }

  removeField(entity_field_id) {
    this.component.fields = this.component.fields.filter(m => m.entity_field_id != entity_field_id);
  }

  onFieldChange($event, field) {
    if ($event.checked) {
      //añadimos al final
      var viewField = {
        entity_field_id: field.id,
        entityField: field,
        class: "p-md-4",
        model_property: field.model_property,
        readonly: false,
        width: 100
      };
      this.component.fields.push(viewField);
      setTimeout(() => {
        this.adjustCols();
      });
    } else {
      this.removeField(field.id);
    }
  }

  configField($event, field: any) {
    this.selectedField = field;
    this.opField.toggle($event);
  }

  getHTMLIfEmpty() {

    var aux = (this.component.messageIfEMpty != null ? this.component.messageIfEMpty : this.translateService.instant("general.not_data"));
    if (this.component.messageIfEmptyData != null) {
      var title = this.translateService.instant(this.component.messageIfEmptyData.title);
      var subtitle = this.translateService.instant(this.component.messageIfEmptyData.subtitle);
      aux = "<div class='cardHome'> <a href='" + this.component.messageIfEmptyData.link + "'> <img alt='knowledge-base-image' class='card-img-top' src='" + this.component.messageIfEmptyData.img + "'> <div class='card-body text-center'> <h3>" + title + " </h3><p class='text-body mt-1 mb-0'>" + subtitle + "</p> </div></a> </div>";
    }
    return aux;

    return aux;
  }

  selectedRowTable(item, $event) {
    $event.preventDefault();
    $event.stopPropagation();
  }
  onComponentEvent(event: any) {
    this.model = event.data;
    this.lazyLoad(this.lastGridEvent, null);
    //this.onEvent.emit(event);
  }

  toggleFilter() {
    this.show_filter_panel = !this.show_filter_panel;
    //calcumos ancho de contenedor menos padding y borders
    let container = this.grid.el.nativeElement.parentElement;
    const styles = window.getComputedStyle(container);
    let fullWidth = container.clientWidth - parseFloat(styles.paddingLeft) - parseFloat(styles.paddingRight);

  }

}
