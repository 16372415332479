import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { CoreDialogService } from 'src/app/core/dialogs/core-dialog.service';
import { TasksActionTypesService } from 'src/app/tasks/tasks-action-types.service';

@Component({
  selector: 'app-tasks-actions-types-detail',
  templateUrl: './tasks-actions-types-detail.component.html',
  styleUrls: ['./tasks-actions-types-detail.component.scss']
})
export class TasksActionsTypesDetailComponent implements OnInit {

  public taskActionType: any = {}

  constructor(
    private dynamicDialogRef: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private messageService: MessageService,
    private taskActionTypesService: TasksActionTypesService,
    private coreDialogService: CoreDialogService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.loadData(this.config.data.id);
  }
  loadData(id) {
    if (id != 0 && id != null) {
      this.taskActionTypesService.get(id).subscribe({
        next: (data: any) => {
          this.taskActionType = data;
        },
        error: (error: any) => {
          this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
        }
      });
    }
  }
  cancel() {
    this.dynamicDialogRef.close();
  }
  delete() {
    this.coreDialogService.confirm({
      message: this.translateService.instant("component.tasksActionsTypes.delete_message", { name: this.taskActionType.name }),
      header: this.translateService.instant("general.confirmation_delete_title"),
      icon: 'pi pi-info-circle',
      accept: () => {
        this.taskActionTypesService.delete(this.taskActionType.id).subscribe(
          data => {
            this.messageService.add({ closable: false, severity: 'success', detail: this.translateService.instant("general.confirmation_delete") });
            this.dynamicDialogRef.close(data);
          }
        ),
          error => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
      },
      reject: () => {

      }
    });
  }

  save() {
    if (typeof this.taskActionType.id === "undefined") {
      this.taskActionTypesService.add(this.taskActionType).subscribe({
        next: (data: any) => {
          this.taskActionType = data;
          this.loadData(this.taskActionType.id);
          this.messageService.add({ closable: false, severity: "success", detail: this.translateService.instant("component.tasksActionsTypes.detail.save_correct") });
          this.dynamicDialogRef.close();
        },
        error: (error: any) => {
          this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
        }
      });
    } else {
      this.taskActionTypesService.save(this.taskActionType.id, this.taskActionType).subscribe({
        next: (data: any) => {
          this.messageService.add({ closable: false, severity: "success", detail: this.translateService.instant("component.tasksActionsTypes.detail.add_correct") });
          this.dynamicDialogRef.close();
        },
        error: (error: any) => {
          this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
        }
      });
    }
  }

}
