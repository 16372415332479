import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CoreDialogService } from 'src/app/core/dialogs/core-dialog.service';
import { LoginService } from 'src/app/login/login.service';

@Component({
  selector: 'app-reports-revalidation-action',
  templateUrl: './reports-revalidation-action.component.html',
  styleUrls: ['./reports-revalidation-action.component.scss']
})
export class ReportsRevalidationActionComponent implements OnInit {

  public errorMessage: string = "";
  public alertMessage: string = "";
  public loginData: any = {};

  constructor(private route: ActivatedRoute,
    private messageService: MessageService,
    private config: DynamicDialogConfig,
    private dynamicDialogRef: DynamicDialogRef,
    private coreDialogService: CoreDialogService,
    private loginService: LoginService,
    private translateService: TranslateService) { }

  ngOnInit() {
    var entity = this.config.data.entity;
    if (entity.code == "sale") {
      this.alertMessage = this.translateService.instant("component.reports.detail.remember_gestion_transactions_related");
    }
  }

  ok() {
    if (this.loginService.get("username") == this.loginData.username) {
      this.dynamicDialogRef.close(true);
    } else {
      this.errorMessage = this.translateService.instant("component.reports.detail.user_incorrect");
    }
  }
  cancel() {
    this.dynamicDialogRef.close(false);
  }
}
