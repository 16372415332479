import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TasksActionsTypesDetailComponent } from './tasks-actions-types-detail/tasks-actions-types-detail.component';
import { TasksActionsTypesListComponent } from './tasks-actions-types-list/tasks-actions-types-list.component';

const routes: Routes = [
  {path:'tasksActionTypes', component:TasksActionsTypesListComponent},
  { path: "tasksActionTypes/:id", component: TasksActionsTypesDetailComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TasksActionsTypesRoutingModule { }
