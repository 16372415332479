import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { CoreDialogService } from 'src/app/core/dialogs/core-dialog.service';
import { ViewComponent } from 'src/app/core/view/view.component';
import { ViewsService } from 'src/app/core/view/views.service';
import { BusinessDetailComponent } from '../business-detail/business-detail.component';
import { BusinessService } from '../business.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-business-list',
  templateUrl: './business-list.component.html',
  styleUrls: ['./business-list.component.scss']
})
export class BusinessListComponent implements OnInit {

  @ViewChild("view") public view: ViewComponent;
  public businessFilters: any = {};

  constructor(
    private businessService: BusinessService,
    private dialogService: DialogService,
    private coreDialogService: CoreDialogService,
    private messageService: MessageService,
    private viewsService: ViewsService,
    private activatedRoute: ActivatedRoute,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    //this.attachment_type_id = this.activatedRoute.snapshot.params.attachment_type_id;
  }

  viewBusiness(business) {
    const ref = this.dialogService.open(BusinessDetailComponent, {
      data: {
        id: business ? business.id : 0,
      },
      width: '70%',
    });
    ref.onClose.subscribe((data: any) => {
      if (typeof (this.view) !== "undefined") this.viewsService.changeView(this.view.code);
      this.view.fireEvent("business.list.results", "reload");
    });
  }

  deleteAttachmentType(business) {
    this.coreDialogService.confirm({
      message: this.translateService.instant("component.business.delete_message", { name: business.name }),
      header: this.translateService.instant("general.confirmation_delete_title"),
      icon: "pi pi-info-circle",
      accept: () => {
        this.businessService.delete(business.id).subscribe({
          next: (data: any) => {
            this.messageService.add({ closable: false, severity: 'success', detail: this.translateService.instant("general.confirmation_delete") });
            this.view.fireEvent("business.list.results", "reload");
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        });
      },
      reject: () => { }
    });
  }

  onEvent(event: any) {
    if (event.component == "business.list.filters") {
      if (event.event == "action" && event.action.name == "search") this.view.fireEvent("business.list.results", "reload");
    } else if (event.component == "business.list.results") {
      if (event.event == "dblclick") this.viewBusiness(event.data);
      if (event.event == "action" && event.action.name == "add") this.viewBusiness(null);
      if (event.event == "action" && event.action.name == "edit") this.viewBusiness(event.data);
      if (event.event == "action" && event.action.name == "delete") this.deleteAttachmentType(event.data);
    }
  }
}
