<ul class="breadcrumb">
    <li><a [routerLink]="['/home']"><span class="color-soft-grey-axial">{{'general.breadcrumb.you_are' | translate}}
            </span>{{'general.breadcrumb.home' | translate}}</a></li>
    <li><i class="pi pi-angle-right"></i></li>
    <li><a [routerLink]="['/configuration/entities']">{{ 'general.configuration' | translate}}</a></li>
    <li><i class="pi pi-angle-right"></i></li>
    <li><a [routerLink]="['/configuration/taskStatus']">{{ 'taskStatus.entity.name_plural' | translate}}</a></li>
    <!--<li *ngIf="view"><a [routerLink]="['/configuration/taskStatus']">{{view.getEntityBaseName(true)}}</a></li>-->
</ul>
<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000"></p-confirmDialog>

<!--<app-view #view [code]="'taskStatus.list'" [model]="taskStatusFilters" (onEvent)="onEvent($event)"></app-view>-->

<p-card>
    <ng-template pTemplate="header">
        <div class="p-card-title">
            {{ 'general.filter.title' | translate}}
            <div class="p-card-title-actions">
                <a (click)="search()"><i class="fas fa-search"></i>&nbsp;{{ 'general.action.search' | translate}} </a>
            </div>
        </div>
    </ng-template>
    <div class="p-grid">
        <div class="p-col p-field">
            <label for="name">{{ 'taskStatus.entity_fields.name' | translate}}</label>
            <input id="name" type="text" pInputText [(ngModel)]="taskStatusFilters.name" />
        </div>
    </div>
    <!--div class="p-grid">
          <div class="p-col p-ai-right">
              <p-button label="Limpiar filtros" (click)="clearFilters()" styleClass="p-button-secondary"></p-button>
              <p-button label="Buscar" (click)="search()" class="p-ml-2"></p-button>
          </div>
      </div-->
</p-card>
<br>
<p-card styleClass="p-card-content-fit">
    <ng-template pTemplate="header">
        <div class="p-card-title">
            {{ 'taskStatus.entity.name_plural' | translate}}
            <div class="p-card-title-actions">
                <a (click)="edit({ id: 0 })">
                    <fa-icon [icon]="['fas', 'plus-circle']"></fa-icon> {{ 'general.action.new' | translate}}
                </a>
            </div>
        </div>
    </ng-template>
    <p-table #grid [value]="taskStatus" [lazy]="true" (onLazyLoad)="loadData($event)" [loading]="loading"
        [paginator]="true" [rows]="10" [totalRecords]="totalRecords" [showCurrentPageReport]="true"
        currentPageReportTemplate="{{ 'general.resumen_table_records' | translate: { first: first, last: last, totalRecords: totalRecords } }}"
        [rowsPerPageOptions]="[5,10,25,50]" paginatorDropdownAppendTo="body">
        <ng-template pTemplate="header">
            <tr>
                <th pSortableColumn="id" width="100">{{ 'taskStatus.entity_fields.id' | translate}}</th>
                <th pSortableColumn="name">{{ 'taskStatus.entity_fields.name' | translate}}</th>
                <th width="100"></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-taskStatus>
            <tr (dblclick)="edit(taskStatus)">
                <td>{{taskStatus.id}}</td>
                <td>{{taskStatus.name}}</td>
                <td class="grid-actions">
                    <a (click)="edit(taskStatus)">
                        <fa-icon [icon]="['far', 'edit']"></fa-icon>
                    </a>
                    <a (click)="delete(taskStatus)" class="color-danger">
                        <fa-icon [icon]="['far', 'trash-alt']"></fa-icon>
                    </a>
                </td>
            </tr>
        </ng-template>
    </p-table>
</p-card>