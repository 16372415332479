<div class="p-grid">
    <div class="p-col-7" style="text-align: center;">
        <div class="p-grid">
            <div class="p-col-4">
                <p-card [style]="{ height: '128px' }">
                    <p><small>{{'organization.entity.name_plural' | translate}}</small></p>
                    <h2>{{ret?.n_organizations}}</h2>
                </p-card>
            </div>
            <div class="p-col-4">
                <p-card [style]="{ height: '128px' }">
                    <p><small>{{'contact.entity.name_plural' | translate}}</small></p>
                    <h2>{{ret?.n_contacts}}</h2>
                </p-card>
            </div>
            <div class="p-col-4">
                <p-card [style]="{ height: '128px' }">
                    <p><small>{{'task.entity.name_plural' | translate}}</small></p>
                    <h2>{{ret?.n_tasks}}</h2>
                </p-card>
            </div>
            <div class="p-col-4">
                <p-card [style]="{ height: '128px' }">
                    <p><small>{{'meeting.entity.name_plural' | translate}}</small></p>
                    <h2>{{ret?.n_meetings}}</h2>
                </p-card>
            </div>
            <div class="p-col-4">
                <p-card [style]="{ height: '128px' }">
                    <p><small>{{'transaction.entity.name_plural' | translate}}</small></p>
                    <h2>{{ret?.n_transactions}}</h2>
                </p-card>
            </div>
            <div class="p-col-4">
                <p-card [style]="{ height: '128px' }">
                    <p><small>{{'component.campaigns.detail.transaction_volume' | translate}}</small></p>
                    <h2>{{ret?.transaction_volume}}€</h2>
                </p-card>
            </div>
        </div>
    </div>
    <div class="p-col-5" style="text-align: center;">
        <p-chart #chart type="bar" [data]="basicData" [options]="options" height="100%"></p-chart>
    </div>
</div>