<ul class="breadcrumb">
  <li><a [routerLink]="['/home']"><span class="color-soft-grey-axial">{{'general.breadcrumb.you_are' | translate}}
      </span>{{'general.breadcrumb.home' | translate}}</a></li>
  <li><i class="pi pi-angle-right"></i></li>
  <li><a [routerLink]="['/configuration/entities']">{{ 'general.configuration' | translate}}</a></li>
  <li><i class="pi pi-angle-right"></i></li>
  <li><a [routerLink]="['/configuration/logs']">{{ 'component.tranckingLogs.title' | translate}}</a></li>
</ul>

<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000"></p-confirmDialog>

<p-card>
  <ng-template pTemplate="header">
    <div class="p-card-title">
      {{ 'general.filter.title' | translate}}
      <div class="p-card-title-actions">
        <a (click)="loadTable()">
          <i class="fas fa-search"></i>&nbsp;{{ 'general.action.search' | translate}}
        </a>
      </div>
    </div>
  </ng-template>
  <div class="p-grid">
    <div class="p-col-3 p-field">
      <label for="entity">{{ 'component.tranckingLogs.entity' | translate}}</label>
      <p-dropdown name="entity" [options]="entities" [(ngModel)]="entity_id" optionLabel="name" optionValue="id"
        placeholder="{{ 'general.select' | translate}}" [filter]="true" [showClear]="true" appendTo="body"
        (onChange)="loadEntitiesFieldsCUSTOM()"></p-dropdown>
    </div>
    <div class="p-col p-field">
      <label for="element">{{ 'component.tranckingLogs.element' | translate}}</label>
      <p-dropdown name="element" [options]="elements" [(ngModel)]="element_id" optionLabel="name" optionValue="id"
        placeholder="{{ 'general.select' | translate}}" [filter]="true" [showClear]="true" appendTo="body"
        [disabled]="entity?.id == null || elements == null"></p-dropdown>
    </div>
  </div>
</p-card>
<br>
<p-card styleClass="p-card-content-fit" *ngIf="entity?.id != null">
  <ng-template pTemplate="header">
    <div class="p-card-title">
      {{ 'component.tranckingLogs.title' | translate}}
      <div class="p-card-title-actions">
        <!--<span style="margin-top: 6px;margin-right: 10px;">Seleccione campos:</span>
    <p-cascadeSelect class="cascadeTracking"
                     [(ngModel)]="selectedColumns"
                     [options]="entitiesToTable"
                     optionLabel="{{selectedEntitiesFields.length}}"
                     optionGroupLabel="entity"
                     [ngModelOptions]="{standalone: true}"
                     [optionGroupChildren]="['fields']" placeholder="{{'general.select'|translate}}">
      <ng-template pTemplate="option" let-option>
        <div class="country-item">
          <p-checkbox name="group-{{option.id}}" value="{{option.id}}"  [(ngModel)]="selectedEntitiesFields" (onChange)="checkOption(option.id)" *ngIf="!option.entity"></p-checkbox>

          <span>{{option.description || option.entity }}</span>
        </div>
      </ng-template>
    </p-cascadeSelect>-->

        <a (click)="exportExcel()">
          <fa-icon [icon]="['fas', 'file-excel']"></fa-icon> {{ 'view.configure.export_excel' | translate}}
        </a>
      </div>
    </div>
  </ng-template>
  <p-table #grid [value]="logs" [loading]="loadingLogs" [paginator]="true" [rows]="100" [lazy]="true"
    (onLazyLoad)="customSort($event)" [customSort]="true" [totalRecords]="totalRecordsLogs"
    [showCurrentPageReport]="true" responsiveLayout="scroll"
    currentPageReportTemplate="{{ 'general.resumen_table_records' | translate: { first: first, last: last, totalRecords: totalRecords } }}"
    [rowsPerPageOptions]="[100,500,1000]">
    <ng-template pTemplate="header">
      <tr>
        <th *ngFor="let column of columns" pSortableColumn="{{column.model_property}}" width="200">
          {{column.description}}</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-log>
      <tr>
        <td *ngFor="let column of columns" [innerHTML]="log[column.model_property]"></td>

      </tr>
    </ng-template>
  </p-table>
</p-card>