<p-tabView *ngIf="attachment.guid == null || attachment.mimetype == 'text/html' && attachment.id != null">
    <!--div class="p-field" *ngIf="attachment.entity_id != 8">
        <label for="attachmentType">Tipo de Adjunto</label>
        <p-dropdown name="attachmentType" [options]="attachmentsTypes" optionValue="id" placeholder="{{'general.select'|translate}}"
            [showClear]="true" appendTo="body" optionLabel="name" [(ngModel)]="attachment.attachment_type_id">
        </p-dropdown>
    </div-->

    <p-tabPanel header="{{'generic_attachment.entity.name_plural' | translate}}" *ngIf="attachment.mimetype != 'text/html' && !hideTabs">
        <p-fileUpload name="myfile[]" url="/api/attachments/upload" (onError)="onErrorUpload($event)"
            (onUpload)="onBasicUpload($event)" multiple="multiple"></p-fileUpload>
    </p-tabPanel>
    <p-tabPanel header="{{'component.generic_attachments.detail.combine_template' | translate}}" *ngIf="attachment.entity_id != 12 && attachment.entity_id != 8">
        <div class="p-field" *ngIf="attachment.id == null">
            <label for="template">{{'template.entity.name' | translate}}</label>
            <p-dropdown name="template" [options]="templates" optionValue="id" placeholder="{{'component.generic_attachments.detail.select_template' | template}}"
                [showClear]="true" appendTo="body" optionLabel="name" [(ngModel)]="selectedTemplateHtml"
                (change)="loadHtml()" (onChange)="loadHtml()"></p-dropdown>
        </div>
        <div class="p-field">
            <label for="description">{{'generic_attachment.entity_fields.description' | translate}}</label>
            <input name="description" type="text" pInputText [(ngModel)]="attachment.description" required />
        </div>
        <editor apiKey="uojbvej6pesw9jdh1l7l7csugjyu7cop2zrbv09dz71r7acy" [ngModelOptions]="{standalone: true}"
            [(ngModel)]="html" [init]="tinyMceConfig" plugins="link media image fullscreen code table quickbars">
        </editor>
        <button pButton class="p-button-danger p-mt-3" icon="pi pi-file-pdf" label="{{general.action_save | translate}}"
            (click)="toDraft(true)" *ngIf="attachment.id"></button>
        <button pButton class="p-ml-2 p-mt-3" style="float: right;" icon="pi pi-check"
            label="{{'general.action.save' | translate}}" (click)="toDraft(false)"></button>
    </p-tabPanel>
</p-tabView>
<p-card
    *ngIf="attachment.guid != null && attachment.mimetype != 'text/html' || attachment.guid!=null && attachment.id != null && attachment.mimetype != 'text/html' || attachment.mimetype == 'text/html' && attachment.id == null">
    <form #formDetails="ngForm">
        <div class="p-grid">
            <div class="p-field p-col-12">
                <label for="attachmentType2">{{'generic_attachment.entity_fields.description' | translate}}</label>
                <input type="text" name="description" pInputText [(ngModel)]="attachment.description" required />
            </div>
            <div class="p-field p-col-12">
                <label for="filename">{{'generic_attachment.entity_fields.filename' | translate}}</label>
                <input type="text" name="filename" pInputText [(ngModel)]="attachment.filename" readonly disabled />
            </div>
            <div class="p-col-4 p-field">
                <label for="attachment_type_id">{{'generic_attachment.entity_fields.attachment_type' |
                    translate}}</label>
                <p-dropdown name="attachment_type_id" [options]="attachmentsTypes"
                    [(ngModel)]="attachment.attachment_type_id" optionValue="id" optionLabel="name"
                    placeholder="{{'component.generic_attachments.detail.select_attachment_type' | translate}}"
                    [required]="true" appendTo="body"></p-dropdown>
            </div>
            <div class="p-field p-col-4">
                <label for="mimetype">{{'generic_attachment.entity_fields.mimetype' | translate}}</label>
                <input type="text" name="mimetype" pInputText [(ngModel)]="attachment.mimetype" readonly disabled />
            </div>

            <div class="p-field p-col-4">
                <label for="guid">{{'generic_attachment.entity_fields.guid' | translate}}</label>
                <input type="text" name="guid" pInputText [(ngModel)]="attachment.guid" readonly disabled />
            </div>
            <div *ngIf="attachment.created_by" class="p-field p-col-4">
                <label for="created_by">{{'generic_attachment.entity_fields.created_by' | translate}}</label>
                <input type="text" name="created_by" pInputText [ngModel]="attachment.createdBy.name" readonly
                    disabled />
            </div>
        </div>
    </form>
    <ng-template pTemplate="footer">
        <div class="p-d-flex">
            <p-button label="{{'general.action.delete' | translate}}" icon="pi pi-trash" styleClass="p-button-danger"
                (click)="delete()"></p-button>
            <div class="flex-grow-1"></div>
            <p-button label="{{'general.action.cancel' | translate}}" icon="pi pi-times" styleClass="p-button-secondary"
                (click)="cancel()"></p-button>
            <p-button label="{{'general.action.save' | translate}}" icon="pi pi-check" styleClass="p-ml-2"
                (click)="isDuplied()"></p-button>
        </div>
    </ng-template>
</p-card>
