import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EntitiesFieldsService {

  url = "/api/entitiesFields";

  constructor(private http: HttpClient) { }

  all(params): Observable<any> {
    return this.http.get<any>(this.url, { params: params });
  }

  get(id): Observable<object> {
    return this.http.get<object>("/api/entitiesFieldsCustom/" + id);
  }

  save(id, obj): Observable<object> {
    return this.http.put<object>("/api/entitiesFieldsCustom/" + id, obj);
  }

  add(obj): Observable<object> {
    return this.http.post<object>("/api/entitiesFieldsCustom/", obj);
  }

  delete(id): Observable<object> {
    return this.http.delete<object>("/api/entitiesFieldsCustom/" + id);
  }
  reorderFields(dragPos, dropPos, entity_id): Observable<any> {
    return this.http.get<any>(this.url + "/reorderFields/" + dragPos + "/" + dropPos + "/" + entity_id)
  }
  GetEntityFieldDescription(entity_id, entity_field_model_property): Observable<any> {
    let params = {
      entity_id: entity_id,
      entity_field_model_property: entity_field_model_property
    }
    return this.http.get<any>("api/entitiesFieldsCustom/GetEntityFieldDescription/", { params: params });
  }
}
