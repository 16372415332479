import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CustomHistoryService {

  public history: string [] = [];

  constructor(private http: HttpClient) { }

  getAll() {
    return this.history;
  }
  getBackHistory() {
    if (this.history.length > 1) {
      return this.history[this.history.length - 2];
    } else {
      return null;
    }
  }
  checkIfExistPrev(url : string) {
    //Busca si justo antes se ha insertado (esto es porque se carga, llama al preload, que no guardamos y se vuelve a cargar, entonces aparece dos veces);
    if (this.history[this.history.length - 1] == url) return true;
    return false;
  }
  add(url: string) {
    if (this.history.length >= 10) {
      this.history.splice(0, 1);
    }
    this.history.push(url);
  }
  getTotal() {
    return this.history.length;
  }
}
