import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { MailAccountsService } from 'src/app/configuration/mail-accounts/mail-accounts.service';
import { UserMailAccountsService } from './user-mail-accounts.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-users-mail-accounts-detail',
  templateUrl: './users-mail-accounts-detail.component.html',
  styleUrls: ['./users-mail-accounts-detail.component.scss']
})
export class UsersMailAccountsDetailComponent implements OnInit {
  public mailAccounts: any[] = [];
  public selectedMailAccounts: any[] = [];
  public userMailAccount: any = {};

  constructor(
    private userMailAccountsService: UserMailAccountsService,
    private mailAccountsService: MailAccountsService,

    private messageService: MessageService,
    private config: DynamicDialogConfig,
    private dynamicDialogRef: DynamicDialogRef,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.userMailAccount.user_id = this.config.data.user.id;
    this.selectedMailAccounts = this.config.data.selectedMailAccounts;
    this.loadMailAccounts();
  }
  cancel() {
    this.dynamicDialogRef.close();
  }
  save() {
    if (this.userMailAccount != null && this.userMailAccount.mailAccount_id > 0) {
      this.userMailAccountsService.add(this.userMailAccount).subscribe({
        next: (data: any) => {
          this.messageService.add({ closable: false, severity: "success", detail: this.translateService.instant('component.users.detail.assign_mail_account_correct') });
          this.dynamicDialogRef.close(data);
        },
        error: (error: any) => {
          this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
        }
      });
    } else {
      this.messageService.add({ closable: false, severity: "error", detail: this.translateService.instant('invalid_language_error_message') })
    }
  }

  loadMailAccounts() {
    this.mailAccountsService.all({ ids: this.selectedMailAccounts }).subscribe({
      next: (data: any) => {
        this.mailAccounts = data.rows;
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
      }
    })
  }
}
