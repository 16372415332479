<form #formDetails="ngForm" id="reportMailMarketing">
    <p-card styleClass="p-card p-component">

        <div class="p-grid  p-grid-nogutter">
            <div class="p-col-12 p-field p-mt-3">
                <label for="name">{{this.selected.length}} {{'component.reports.detail.records_to_add_campaign' |
                    translate}}</label>
                <p-table #grid [lazy]="true" [value]="rows" [columns]="results.labels" [paginator]="false"
                    [totalRecords]="results.length" [reorderableColumns]="false" styleClass="p-datatable-sm"
                    [scrollable]="true" scrollHeight="150px" class="p-mt-3">

                    <ng-template pTemplate="body" let-item let-index="rowIndex" let-rowData>
                        <tr [pReorderableRow]="index">
                            <td width="25"><p-checkbox name="groupname" value="{{item.base_id}}"
                                    [inputId]="item.base_id" [(ngModel)]="selected"></p-checkbox></td>
                            <td *ngFor="let header of results.labels"
                                [innerHTML]="getRowValue(header, item[header.column_key], item )">
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td [attr.colspan]="results.values.length + 1">
                                {{'general.not_data' | translate }}
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
        <div class="p-card">
            <div class="p-grid p-col-12">
                <div class="p-col-12 p-field">
                    <label for="template">{{'campaign.entity.name' | translate}}</label>
                    <p-dropdown name="template" [(ngModel)]="selected_campaign_id" [options]="campaigns"
                        optionLabel="name" optionValue="id" [showClear]="true" appendTo="body"></p-dropdown>
                </div>
                <div class="p-col-12 p-field" *ngIf="visible_organizations_checkbox">
                    <label>{{'component.campaigns.reports.add_organizations_checkbox' | translate}}</label>
                    <p-checkbox [(ngModel)]="add_organizations_checkbox" binary="true"
                        [ngModelOptions]="{standalone: true}"></p-checkbox>
                </div>
                <div class="p-col-12 p-field" *ngIf="visible_contacts_checkbox">
                    <label>{{'component.campaigns.reports.add_contacts_checkbox' | translate}}</label>
                    <p-checkbox [(ngModel)]="add_contacts_checkbox" binary="true"
                        [ngModelOptions]="{standalone: true}"></p-checkbox>
                </div>
            </div>
        </div>
    </p-card>

    <div class="p-d-flex p-mt-3">
        <div class="flex-grow-1"></div>
        <p-button label="{{'general.action.cancel' | translate}}" icon="pi pi-times"
            styleClass="p-button-secondary p-button-text" (click)="cancel()"></p-button>
        <p-button label="{{'component.reports.detail.save_campaign' | translate}}" icon="pi pi-check"
            [disabled]="selected_campaign_id == null" styleClass="p-ml-2" (click)="saveCampaign()"></p-button>
    </div>
</form>