import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { CoreDialogService } from 'src/app/core/dialogs/core-dialog.service';
import { CoreFormService } from 'src/app/core/forms/core-forms.service';
import { TaxRatesService } from '../tax-rates.service';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-tax-rates-detail',
  templateUrl: './tax-rates-detail.component.html',
  styleUrls: ['./tax-rates-detail.component.scss']
})
export class TaxRatesDetailComponent implements OnInit {

  @ViewChild("formDetails") public formDetails: NgForm;
  public taxRate: any = {
    name: ""
  };
  public countries: any[] = [];

  constructor(
    private taxRatesService: TaxRatesService,
    private route: ActivatedRoute,
    private router: Router,
    private messageService: MessageService,
    private coreFormService: CoreFormService,
    private coreDialogService: CoreDialogService,
    private _location: Location,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.loadData(params['id']);
    });
  }

  loadData(id: number) {
    if (id != 0) {
      this.taxRatesService.get(id).subscribe({
        next: (data: any) => {
          this.taxRate = data;
        },
        error: (error: any) => {
          this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
        }
      });
    }
  }

  cancel() {
    this.router.navigate(['/configuration/taxRates']);
  }

  save() {
    if (this.taxRate.id != null && this.taxRate.id > 0) {
      this.taxRatesService.save(this.taxRate.id, this.taxRate).subscribe({
        next: (data: any) => {
          this.messageService.add({ closable: false, severity: 'success', summary: this.translateService.instant("component.taxRates.detail.save_correct") });
          this.router.navigate(['/configuration/taxRates']);
        },
        error: (error: any) => {
          this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
        }
      });
    } else {
      this.taxRatesService.add(this.taxRate).subscribe({
        next: (data: any) => {
          this.messageService.add({ closable: false, severity: 'success', summary: this.translateService.instant("component.taxRates.detail.add_correct") });
          this.router.navigate(['/configuration/taxRates']);
        },
        error: (error: any) => {
          this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
        }
      });
    }
  }

  delete() {
    this.coreDialogService.confirm({
      message: this.translateService.instant("component.taxRates.delete_message", { name: this.taxRate.name }),
      header: this.translateService.instant("general.confirmation_delete_title"),
      icon: 'pi pi-info-circle',
      accept: () => {
        this.taxRatesService.delete(this.taxRate.id).subscribe({
          next: (data: any) => {

            this.messageService.add({ closable: false, severity: "success", detail: this.translateService.instant("general.confirmation_delete") });
            this._location.back();
            //this.router.navigate(['/configuration/taxRates']);
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
          }
        });
      },
      reject: () => {

      }
    });
  }

  isDuplied() {
    var errorMessage = "";
    if (this.taxRate.name.length <= 0) {
      //errorMessage += "El nombre no puede estar vacío. \n";
      this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: this.translateService.instant("component.taxRates.detail.no_name") });
    }
    /*if(errorMessage){
      this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: errorMessage });
    }*/
    this.taxRatesService.isDuplied(this.taxRate).subscribe(
      data => {
        if (data == true) {
          this.coreDialogService.notify({
            message: this.translateService.instant("component.taxRates.detail.taxRates_duplicate"),
            header: 'Error',
            icon: 'far exclamation-circle',
            color: 'var(--orange-500)',
            accept: () => { }
          });
        } else {
          this.save();
        }
      }
    );
  }
}
