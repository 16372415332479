import { Input } from '@angular/core';
import { ComponentFactoryResolver } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { CustomComponent } from 'src/app/core/view/component/custom/custom-component.interface';
import { OrganizationsService } from '../../organizations.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-tasks-widget',
  templateUrl: './tasks-widget.component.html',
  styleUrls: ['./tasks-widget.component.scss']
})
export class TasksWidgetComponent implements OnInit,CustomComponent {
  @Input() public data: any;
  @Input() public entity: any;

  public ret: any = {};
  private id:number = null;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private organizationService: OrganizationsService,
    private route: ActivatedRoute,
    private messageService: MessageService
    ) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.id = params['id'];
      this.loadStatsActivities(this.id);
    });
  }
  loadStatsActivities(id){
    this.organizationService.statsActivities(id).subscribe({
      next: (data: any) => {
        this.ret = data;
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
      }
    });
  }

  onEvent(component:string, event:string){
    if(event=="reload"){
      this.loadStatsActivities(this.id);
    }
  }
}
