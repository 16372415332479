import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { AttachmentsDataService } from 'src/app/attachments/attachments-data.service';
import { AttachmentsService } from 'src/app/attachments/attachments.service';
import { ProductsService } from '../products.service';

@Component({
  selector: 'app-products-catalogue',
  templateUrl: './products-catalogue.component.html',
  styleUrls: ['./products-catalogue.component.scss']
})
export class ProductsCatalogueComponent implements OnInit {

  //Atributos:
  public selectedProduct: any;
  public products: any[] = [];
  public attachmentsData: any[] = [];
  public attachments: any[] = [];
  public imageUrls: any[] = [];

  constructor(
    private attachmentsDataService: AttachmentsDataService,
    private attachmentsService: AttachmentsService,
    private productsService: ProductsService,
    private config: DynamicDialogConfig,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.loadProducts();
  }

  loadProducts() {
    let params = {};
    if (this.config.data.filters != null) params = this.config.data.filters;
    this.productsService.all(params).subscribe(
      data => {
        this.products = data.rows;
        this.assignIndex(this.products);

        if(this.products){
          //Si hay algún producto en la lista, cargamos el attachmentData del primero en el array:
          this.selectedProduct = this.products[0];
          this.loadAttachmentsData(this.selectedProduct);
        }
      },
      error => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
      }
    );
  }

  assignIndex(arrayObject:any){
    var index = 0;
    arrayObject.forEach(element => {
      element.index = index;
      index++;
    });
  }

  loadAttachmentsData(product) {
    if(product==null || product.id==null) return;
    this.attachmentsDataService.all({entity_id: 8, entity_pk_id: product.id}).subscribe(
      data => {
        this.attachmentsData = data.rows;
        this.loadImageUrls();
      },
      error => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
      }
    );
  }

  loadAttachments(product) {
    this.attachmentsService.all({ entity_id: 8, entity_pk_id: product.id , mimetype: "image" }).subscribe(
      data => {
        this.attachments = data.rows;
        //this.loadAttachmentsData();
      },
      error => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
      }
    );
  }

  selectProduct(product) {
    this.loadAttachmentsData(product);
    this.selectedProduct = product;
  }

  loadImageUrls(){
    this.imageUrls=[];
    this.attachmentsData.forEach(attachmentData => {
      this.imageUrls.push('data:image/jpeg;base64,'+attachmentData.b64);
    });
  }

  nextProduct(selectedProduct){
    //Buscamos el siguiente índice:
    this.products.forEach(product => {
      if(product.index == selectedProduct.index+1){
        this.selectedProduct = product;
      }
    });
  
    //Actualizamos las imágenes que se muestran:
    this.loadAttachmentsData(this.selectedProduct);
  }

  previousProduct(){
    //Buscamos el anterior índice:
    this.products.forEach(product=>{
      if(product.index == this.selectedProduct.index-1){
        this.selectedProduct = product;
      }
    });

    //Actualizamos las imágenes que se muestran:
    this.loadAttachmentsData(this.selectedProduct);
  }
}
