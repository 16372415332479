import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { ProvincesService } from '../provinces.service';
import { CoreDialogService } from 'src/app/core/dialogs/core-dialog.service';
import { TranslateService } from '@ngx-translate/core';
import { CountriesService } from '../../countries/countries.service';

@Component({
  selector: 'app-provinces-list',
  templateUrl: './provinces-list.component.html',
  styleUrls: ['./provinces-list.component.scss']
})
export class ProvincesListComponent implements OnInit {

  @ViewChild("grid") public grid: Table;
  public provincesFilters: any = {};
  public provinces: any[];
  public countries: any[];
  public totalRecords: number;
  public loading: boolean = false;
  private lastGridEvent: any;

  public first;
  public last;
  constructor(private provincesService: ProvincesService,
    private countriesService: CountriesService,
    private router: Router,
    private coreDialogService: CoreDialogService,
    private messageService: MessageService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.loadCountries();
  }

  search() {
    this.lastGridEvent.first = 0;
    this.loadData(this.lastGridEvent);
  }

  clearFilters() {
    this.provincesFilters = {};
    this.search();
  }

  loadData($event) {
    this.lastGridEvent = $event;
    Promise.resolve(null).then(() => this.loading = true);
    var params = {
      _page: $event.first,
      _pageSize: $event.rows,
      _sortBy: $event.sortField ? $event.sortField : "",
      _sortDirection: $event.sortOrder > 0 ? "asc" : "desc"
    };
    params = Object.assign(params, this.provincesFilters);
    this.provincesService.all(params).subscribe({
      next: (data: any) => {
        this.totalRecords = data.totalRows;
        this.provinces = data.rows;
        Promise.resolve(null).then(() => this.loading = false);
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
        Promise.resolve(null).then(() => this.loading = false);
      }
    });
  }

  loadCountries() {
    this.countriesService.all({}).subscribe({
      next: (data: any) => {
        this.countries = data.rows;
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
      }
    });
  }

  edit(province) {
    this.router.navigate(['/configuration/provinces/', province.id]);
  }

  delete(province) {
    this.coreDialogService.confirm({
      message: this.translateService.instant("component.province.delete_message", { name: province.name }),
      header: this.translateService.instant("general.confirmation_delete_title"),
      icon: 'pi pi-info-circle',
      accept: () => {
        this.provincesService.delete(province.id).subscribe({
          next: (data: any) => {
            this.messageService.add({ closable: false, severity: 'success', detail: this.translateService.instant("general.confirmation_delete") });

            this.loadData(this.lastGridEvent);
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
          }
        });
      },
      reject: () => {

      }
    });
  }

}
