<div class="p-grid">
    <!--<div class="p-col-8 p-field">
        <label>Template</label>
        <label>{{component.template}}</label>
    </div>-->
    <div class="p-col-8 p-field">
        <label>{{'component.configure.title_field' | translate}}</label>
        <input pInputText [(ngModel)]="component.title" />
    </div>
    <div class="p-col-2 p-field">
      <label>{{'component.configure.width' | translate}}</label>
        <p-dropdown [(ngModel)]="component.class" [options]="widthClasses" optionValue="id" optionLabel="description" [showClear]="true" appendTo="body"></p-dropdown>
    </div>
    <div class="p-col-2 p-field">
      <label>{{'component.configure.high' | translate}}</label>
        <input pInputText [(ngModel)]="component.height" placeholder="Automático" />
    </div>
    <div class="p-col-2 p-field" *ngIf="component.template=='table'">
      <label>{{'component.configure.allow_export' | translate}}</label>
        <p-checkbox [(ngModel)]="component.allow_export_xls" [binary]="true"></p-checkbox>
    </div>
    <div class="p-col-2 p-field" *ngIf="component.template=='form'">
      <label>{{'component.configure.allow_tracking' | translate}}</label>
        <p-checkbox [(ngModel)]="component.show_tracking" [binary]="true"></p-checkbox>
    </div>
    <div class="p-col-2 p-field" *ngIf="component.template=='table'">
      <label>{{'component.configure.show_header' | translate}}</label>
        <p-checkbox [(ngModel)]="component.show_header" [binary]="true"></p-checkbox>
    </div>
    <!--No ponerlo hasta tener los permisos o hasta hacerlo generico porque sino lo pone en todas partes.
        <div class="p-col-2 p-field" *ngIf="component.template=='table'">
        <label>Permitir ordenar filas</label>
        <p-checkbox [(ngModel)]="component.allow_reorder_rows" [binary]="true"></p-checkbox>
    </div>-->
</div>
    <div class="p-grid p-m-0 p-mb-4 p-jc-end">
      <button pButton type="button" (click)="addField()">
        <fa-icon [icon]="['fas', 'plus-circle']"></fa-icon>&nbsp;{{'component.configure.add_field' | translate}}
      </button>
    </div>
    <div class="p-mt-4 p-mb-4">
        <p-table [value]="component.fields" cdkDropList [cdkDropListData]="component.fields" (cdkDropListDropped)="drop($event)">
            <ng-template pTemplate="header">
                <tr>
                  <th width="250">{{'component.configure.field' | translate}}</th>
                  <th>{{'component.configure.ticket' | translate}}</th>
                  <th width="150" [hidden]="component.template=='card'">{{'component.configure.width' | translate}}</th>
                  <th width="200" [hidden]="component.template!='card'">{{'component.configure.position' | translate}}</th>
                  <th width="90" [hidden]="component.template!='form'">{{'component.configure.required' | translate}}</th>
                  <th width="100" [hidden]="component.template!='form'">{{'component.configure.read_only' | translate}}</th>
                    <th width="100">M</th>
                    <th width="100" [hidden]="component.template!='form'">{{'component.configure.actions' | translate}}</th>
                    <th width="100"></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-field let-rowIndex="rowIndex">
                <tr cdkDrag [cdkDragData]="field" *ngIf="!field.entityField?.is_disabled">
                  <td class="p-field p-pt-0 p-pb-0">
                    <!--<p-dropdown [(ngModel)]="field.model_property" [options]="entity.fields" optionValue="model_property" optionLabel="description" (onChange)="onFieldChange($event, field)" [showClear]="true" appendTo="body" [filter]="true"></p-dropdown>-->
                    <p-cascadeSelect class=""
                                     [(ngModel)]="field.model_property"
                                     [options]="entitiesToFilter"
                                     appendTo="body"
                                     optionLabel="description"
                                     optionValue="model_property"
                                     optionGroupLabel="entity"
                                     (onChange)="onFieldChange($event, field)"
                                     [ngModelOptions]="{standalone: true}"
                                     [optionGroupChildren]="['fields', 'subfields']" placeholder="{{'general.select' | translate}}">
                    </p-cascadeSelect>
                  </td>
                    <td class="p-field p-pt-0 p-pb-0">
                        <input pInputText [(ngModel)]="field.label" [placeholder]="field.entityField?.description" />
                    </td>
                    <td class="p-field p-pt-0 p-pb-0" [hidden]="component.template=='card'">
                        <p-dropdown *ngIf="component.template!='table'" [(ngModel)]="field.class" [options]="colSizes" optionValue="id" optionLabel="description" [showClear]="true" appendTo="body"></p-dropdown>
                        <input *ngIf="component.template=='table'" pInputText [(ngModel)]="field.width"/>
                    </td>
                    
                    <td class="p-field p-pt-0 p-pb-0" [hidden]="component.template!='card'">
                        <p-dropdown [(ngModel)]="field.type" [options]="optionsCard" optionValue="value" optionLabel="label" [showClear]="true" appendTo="body"></p-dropdown>
                    </td>
                    <td class="p-field p-pt-0 p-pb-0" [hidden]="component.template!='form'">
                        <p-checkbox [(ngModel)]="field.required" [binary]="true" [disabled]="field.readonly || field.entityField?.unmodifiable"></p-checkbox>
                    </td>
                    <td class="p-field p-pt-0 p-pb-0" [hidden]="component.template!='form'">
                        <p-checkbox [(ngModel)]="field.readonly" [binary]="true" [disabled]="field.required || field.entityField?.unmodifiable" *ngIf="!field.entityField?.unmodifiable"></p-checkbox>
                        <p-checkbox [ngModel]="true" [binary]="true" [disabled]="field.required || field.entityField?.unmodifiable" *ngIf="field.entityField?.unmodifiable"></p-checkbox>
                    </td>
                    <td class="p-field p-pt-0 p-pb-0">
                        <p-checkbox [(ngModel)]="field.show_in_mobile" [binary]="true"></p-checkbox>
                    </td>
                    <td class="p-field p-pt-0 p-pb-0" [hidden]="component.template!='form'">
                        <p-checkbox [(ngModel)]="field.showActions" *ngIf="field.entityField?.configuration?.actions!=null" [binary]="true"></p-checkbox>
                    </td>
                    <td class="grid-actions">
                        <a (click)="removeField(rowIndex)" class="color-danger">
                            <fa-icon [icon]="['far', 'trash-alt']"></fa-icon>
                        </a>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>

<div class="p-grid p-m-0 p-jc-end">
  <button pButton type="button" (click)="saveComponent()">
    <fa-icon [icon]="['fas', 'save']"></fa-icon>&nbsp;{{'general.action.save' | translate }}
  </button>
</div>
