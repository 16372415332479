import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SectorsDetailComponent } from './sectors-detail/sectors-detail.component';
import { SectorsListComponent } from './sectors-list/sectors-list.component';

const routes: Routes = [
  {path:'sectors', component:SectorsListComponent},
  {path:'sectors/:id', component:SectorsDetailComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SectorsRoutingModule { }
