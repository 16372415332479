<form #formDetails="ngForm">
  <div class="p-grid">
    <!--div class="p-col-12">
        <input type='file' (change)="readURL($event);" />
        <img id="blah" [src]="imageSrc || null" alt="your image" />
    </div-->
    <div class="p-col-12">
      <p-card header="{{'component.users.detail.title'|translate}}">
        <div class="p-grid">
          <!--Foto-->
          <div class="p-col-2">
            <div class="app-image-field" style="width: 100%; height: 125px;">
              <div class="image-container" (click)="onClickImage()">
                <i *ngIf="!image_source" class="far fa-image"></i>
                <a *ngIf="image_source" class="remove-image btn btn-danger btn-circle" (click)="removeImage()">
                  <i class="far fa-trash-alt"></i>
                </a>
                <p-button *ngIf="image_source" label="{{'general.delete'|translate}}" icon="pi pi-times"
                  styleClass="p-button-secondary remove-image" (click)="removeImage()"></p-button>

                <input #fileInput type="file" accept="image/*" (change)="updateSource($event)">
                <img *ngIf="image_source" [src]="image_source" src="">
              </div>
            </div>
          </div>

          <!--Campos de datos generales:-->
          <div class="p-col-10">
            <div class="p-grid">
              <div class=" p-field p-mb-0 p-md-4 p-pb-0">
                <label for="name">{{'user.entity_fields.name'|translate}}</label>
                <div class="p-inputgroup">
                  <input name="name" type="text" pInputText [(ngModel)]="user.name" [required]="true"
                    [ngModelOptions]="{standalone: true}" />
                </div>
              </div>
              <div class="p-field p-mb-0 p-md-4 p-pb-0">
                <label for="responsible">{{'user.entity_fields.responsible_user_id'|translate}}</label>
                <div class="p-inputgroup">
                  <p-dropdown name="responsible" [options]="responsibles" [(ngModel)]="user.responsible_user_id"
                    [ngModelOptions]="{standalone: true}" optionLabel="name" optionValue="id" [showClear]="true"
                    placeholder="{{'general.select'|translate}}" [disabled]="!user_write && !isSuperAdmin">
                  </p-dropdown>
                </div>
              </div>
              <div class="p-field p-mb-0 p-md-4 p-pb-0">
                <label for="email">{{'user.entity_fields.email'|translate}}</label>
                <div class="p-inputgroup">
                  <input name="email" type="text" pInputText [(ngModel)]="user.email" [required]="true"
                    [ngModelOptions]="{standalone: true}" [disabled]="!user_write && !isSuperAdmin" />
                </div>
              </div>
              <div class="p-field p-mb-0 p-md-4 p-pb-0" *ngIf="!user.id">
                <label for="password">{{'user.entity_fields.password'|translate}}</label>
                <div class="p-inputgroup">
                  <input name="password" type="text" pInputText [(ngModel)]="user.password" [required]="true"
                    [ngModelOptions]="{standalone: true}" />
                </div>
              </div>
              <div class="p-field p-mb-0 p-md-4 p-pb-0">
                <label for="position">{{'user.entity_fields.position'|translate}}</label>
                <div class="p-inputgroup">
                  <input name="position" type="text" pInputText [(ngModel)]="user.position"
                    [ngModelOptions]="{standalone: true}" [disabled]="!user_write && !isSuperAdmin" />
                </div>
              </div>
              <div class="p-field p-mb-0 p-md-4 p-pb-0">
                <label for="responsible">{{'user.entity_fields.language_id'|translate}}</label>
                <div class="p-inputgroup">
                  <p-dropdown name="language" [options]="languages" [(ngModel)]="user.language_id"
                    [ngModelOptions]="{standalone: true}" optionLabel="name" optionValue="id"
                    placeholder="{{'general.select'|translate}}">
                  </p-dropdown>
                </div>
              </div>
              <div class="p-field p-mb-0 p-md-4 p-pb-0">
                <label for="color">{{'user.entity_fields.color'|translate}}</label>
                <div class="p-inputgroup">
                  <p-colorPicker name="color" [(ngModel)]="user.color" ngControlDefault appendTo="body"></p-colorPicker>
                </div>
              </div>
              <div class="p-field p-mb-0 p-md-4 p-pb-0" *ngIf="user_write">
                <label for="activated">{{'user.entity_fields.activated'|translate}}</label>
                <div class="p-inputgroup">
                  <p-inputSwitch name="activated" [(ngModel)]="user.activated" [disabled]="!user_write"></p-inputSwitch>
                </div>
              </div>
            </div>
          </div>
        </div>
      </p-card>
    </div>
    <div class="p-col-12">
      <app-view #view [code]="'users.edit'" [model]="user" (onEvent)="onEvent($event)"></app-view>
    </div>
    <div class="p-col-12">
      <p-card>
        <ng-template pTemplate="header">
          <div class="p-card-title">{{'user.entity_fields.email'|translate}}</div>
        </ng-template>
        <div class="p-grid">
          <div class="p-col-12">
            <div class="p-field p-fluid">
              <label for="emails_cc">{{'user.entity_fields.emails_cc'|translate}}</label>
              <p-autoComplete [(ngModel)]="emails_cc" [suggestions]="searchResults" class="topAutoComplete"
                (completeMethod)="filterSearch($event)" field="email" [multiple]="true"
                [ngModelOptions]="{standalone: true}" [disabled]="disableFields"></p-autoComplete>
            </div>
            <div class="p-field p-fluid" *ngIf="showTinyMCE">
              <label for="emails_signature">{{'user.entity_fields.emails_signature'|translate}}</label>
              <editor [init]="tinyMceConfig" apiKey="uojbvej6pesw9jdh1l7l7csugjyu7cop2zrbv09dz71r7acy" 
                [ngModelOptions]="{standalone: true}" [(ngModel)]="user.emails_signature" name="emails_signature"
                ngDefaultControl plugins="link media image fullscreen code table quickbars"></editor>

            </div>
          </div>
        </div>
      </p-card>

      <p-card>
        <div class="p-d-flex">
          <p-button label="{{'general.action.delete'|translate}}" *ngIf="user.id" icon="pi pi-trash"
            styleClass="p-button-danger" (click)="delete()"></p-button>
          <div class="flex-grow-1"></div>

          <p-button label="{{'general.action.cancel'|translate}}" icon="pi pi-times" styleClass="p-button-secondary"
            (click)="cancel()"></p-button>
          <p-button label="{{'component.users.detail.change_password'|translate}}" icon="pi pi-key"
            styleClass="p-ml-2 p-button-primary" (click)="changePassword()"></p-button>
          <p-button label="{{'general.action.save' | translate}}" icon="pi pi-check" styleClass="p-ml-2"
            (click)="isDuplied()"></p-button>
        </div>
      </p-card>
    </div>
  </div>
</form>
<!--
<div class="p-col-12">
  <app-view #view [code]="'users.edit'" [model]="user" (onEvent)="onEvent($event)"></app-view>
</div>-->
