<!--<p-fullCalendar class="user-calendar" [events]="events" [options]="options" (datesRender)="handleDatesRender($event)"></p-fullCalendar>-->
<full-calendar class="user-calendar" [events]="events" [options]="options" (datesRender)="handleDatesRender($event)"></full-calendar>
<div [ngStyle]="{'top':yPos,'left':xPos}" class="tooltip-div" *ngIf="tooltip_info.show">
  <span class="tooltiptext">
    <b>{{tooltip_info.showType}}</b><br />
    {{tooltip_info.description}} <br />
    {{tooltip_info.date_from | date:'dd/MM/yyyy HH:mm'}} ({{tooltip_info.duration}}) <br />
    {{tooltip_info.organization}} <br />
    {{tooltip_info.responsible}}
  </span>
</div>
