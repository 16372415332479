import { moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Input, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { EntitiesService } from 'src/app/configuration/entities.service';
import { ViewsService } from '../../views.service';
import groupBy from 'lodash/groupBy';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-view-component-configure',
  templateUrl: 'view-component-configure.component.html',
  styleUrls: ['view-component-configure.component.scss'],
})
export class ViewComponentConfigureComponent implements OnInit {

  @Input("component") public component:any = {};
  public entity:any = {};
  public newField:any = {};
  public widthClasses:any[] = [
    { id: "p-md-4", description: "33%"},
    { id: "p-md-6", description: "50%"},
    { id: "p-md-8", description: "66%"},
    { id: "p-md-12", description: "100%"}
  ];
  public colSizes:any[] = [
    { id: "p-md-1", description: "1"},
    { id: "p-md-2", description: "2"},
    { id: "p-md-3", description: "3"},
    { id: "p-md-4", description: "4"},
    { id: "p-md-5", description: "5"},
    { id: "p-md-6", description: "6"},
    { id: "p-md-7", description: "7"},
    { id: "p-md-8", description: "8"},
    { id: "p-md-9", description: "9"},
    { id: "p-md-10", description: "10"},
    { id: "p-md-11", description: "11"},
    { id: "p-md-12", description: "12"},
  ]
  public entitiesToFilter:any = [];
  public optionsCard: any[] = [{ label: this.translateService.instant("component.configure.header"), value: "title" }, { label: this.translateService.instant("component.configure.title"), value:"subtitle"}];
  constructor(
    private entitiesService:EntitiesService,
    private messageService:MessageService,
    private viewsService:ViewsService,
    private config: DynamicDialogConfig,
    private dynamicDialogRef: DynamicDialogRef,
    public translateService: TranslateService
  ) { 
  }

  ngOnInit(): void {
    //clonamos para evitar cambios durante la edición
    this.component = Object.assign({}, this.config.data.component);
    this.entity = Object.assign({}, this.config.data.entity);
    this.entity.fields.forEach(field=>{
      if(field.description==null) field.description = field.name;
    });
    this.component.fields.forEach(field=>{
      var entityField = this.entity.fields.find(m=>m.id == field.entity_field_id);
      if(entityField!=null && field.model_property==null) field.model_property = entityField.model_property;
    });
    if(this.component.template=="table"){
      if(this.component.show_header==null) this.component.show_header = true;
      if(this.component.show_header==null) this.component.allow_reorder_rows = false;
    }
    this.entitiesToFilter = this.getSelectableEntityFields(true);
  }
  
  drop(event){
    moveItemInArray(this.component.fields, event.previousIndex, event.currentIndex);
  }

  onFieldChange(event:any, fieldConfig:any){
    
    let aux = this.entity.fields.find(m=>m.model_property == event.value);
    fieldConfig.entityField = aux;
    fieldConfig.entity_field_id = aux.id;
  }

  addField(){
    if(!Array.isArray(this.component.fields)) this.component.fields = [];
    let newField : any;
    if(this.component.template=="card") {
       newField = {type:"title", entity_field_id: null};
    }else{
       newField = {
        entity_field_id: null
      };
    }
    this.component.fields.push(newField);
  }

  saveComponent(){
    this.dynamicDialogRef.close(this.component);
  }

  removeField(index){
    this.component.fields.splice(index, 1);
  }

  composeCascade(entitiesResult, aux, level) {


    let entitiesResultLevel = entitiesResult.filter(m => m.model_property_path.length == level);
    const a = groupBy(entitiesResultLevel, function (n) {
      return n.model_property_path[level - 1].name;
    });
    let claves = Object.keys(a);

    for (let i = 0; i < claves.length; i++) {
      let clave = claves[i];
      let allNamesArray = a[clave][0].model_property_path.map(n => n.name);
      let allNames = allNamesArray.filter(Boolean).join(" / ");
      let aux2 = { entity: allNames, fields: a[clave] };
      aux.push(aux2);
    }
    let entitiesResultMoreLevel = entitiesResult.filter(m => m.model_property_path.length >= level + 1);

    if (entitiesResultMoreLevel.length > 0) {
      aux = this.composeCascade(entitiesResultMoreLevel, aux, level + 1)

    }
    return aux;
  }

  getSelectableEntityFields(include_navigation_properties: boolean, filterIfAllreadyExist: boolean = false) {
    if (this.entity?.fields != null) {

      //obtener entidades
      let entities = [];
      this.entity.fields.forEach(field => {
        if (entities.find(m => m.code == field.entity_code) == null) {
          entities.push({ code: field.entity_code, name: field.entity_name });
        }
      });
      let data = [];
      entities.forEach((entity) => {
        data.push({
          entity: entity.name,
          fields: this.entity.fields.filter(m => m.entity_code == entity.code)
        });
      });
      return data;
    } else {
      return [];
    }
  }

}
