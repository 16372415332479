<div class="page-title">{{view.getEntityBaseName(true)}}</div>
<ul class="breadcrumb">
    <li>
        <a [routerLink]="['/home']">
            <span class="color-soft-grey-axial">
                {{'general.breadcrumb.you_are' | translate}}
            </span>
            {{'general.breadcrumb.home' | translate}}
        </a>
    </li>
    <li><i class="pi pi-angle-right"></i></li>
    <li *ngIf="view"><a [routerLink]="['/campaigns']">{{view.getEntityBaseName(true)}}</a></li>
</ul>
<app-view #view [code]="'campaigns.list'" [model]="campaignsFilters" (onEvent)="onEvent($event)"></app-view>