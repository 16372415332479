import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { TrackingService } from './tracking.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-tracking',
  templateUrl: './tracking.component.html',
  styleUrls: ['./tracking.component.scss']
})
export class TrackingComponent implements OnInit {

  public trackings: any = [];
  constructor(
    private trackingService: TrackingService,
    private config: DynamicDialogConfig,
    private messageService: MessageService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.loadTracking();
  }

  loadTracking() {
    this.trackingService.all({
      entity_id: this.config.data.entity_id,
      entity_pk_id: this.config.data.entity_pk_id
    }).subscribe({
      next: (data: any) => {
        this.trackings = data.rows;
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
      }
    });
  }

  getActionDescription(action: string) {
    let ret = "";
    if (action == "add") ret = this.translateService.instant("general.tracking_add");
    if (action == "update") ret = this.translateService.instant("general.tracking_update");
    if (action == "remove") ret = this.translateService.instant("general.tracking_remove");
    if (action == "delete") ret = this.translateService.instant("general.tracking_delete");
    return ret;
  }

}
