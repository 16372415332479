import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuItem, Message, MessageService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CoreDialogService } from 'src/app/core/dialogs/core-dialog.service';
import { ViewComponent } from 'src/app/core/view/view.component';
import { UsersService } from 'src/app/users/users.service';
import { ImportService } from '../import.service';
import { FileUploadModule } from 'primeng/fileupload';
import { HttpClientModule } from '@angular/common/http';
import { EntitiesService } from 'src/app/configuration/entities.service';
import { UntypedFormBuilder, NgForm } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { LoginService } from '../../login/login.service';
import { LoaderService } from '../../core/loader/loader.service';
import { ImportProgressbarComponent } from './import-progressbar/import-progressbar.component';
import { ViewsService } from 'src/app/core/view/views.service';
import { ComponentService } from '../../core/view/component.service';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-import-detail',
  templateUrl: './import-detail.component.html',
  styleUrls: ['./import-detail.component.scss']
})
export class ImportDetailComponent implements OnInit {
  @ViewChild("view") public view: ViewComponent;
  @ViewChild("formDetails") public formDetails: NgForm;


  public filterValuesOptions: any[] = [];
  public steps: MenuItem[];
  public activeIndex: number = 0;
  public totallines: number = 0;
  public entities: any = [];
  public headersColumn: any = [];
  public headersColumnAux: any = [];
  public import: any = {};
  public file: any = {};
  public entity: any = {};
  //public identification_type : any;
  public allowedEntities: any;
  public disabledImportButton: boolean = true;
  public temporalCombo: any[] = [];
  public import_mode = [
    { label: this.translateService.instant("component.imports.detail.update_create"), code: 'update_create' },
    { label: this.translateService.instant("component.imports.detail.create"), code: 'create' },
    { label: this.translateService.instant("component.imports.detail.only_link"), code: 'only_link' }
  ];
  public import_mode2 = [
    { label: this.translateService.instant("component.imports.detail.update_create"), code: 'update_create' },
    { label: this.translateService.instant("component.imports.detail.create"), code: 'create' }
  ];
  public import_mode_only_link = [
    { label: this.translateService.instant("component.imports.detail.only_link"), code: 'only_link' }
  ];
  public msgs_resume: Message[];
  public msgs_resume_confirm: Message[];
  public confirm: boolean = false;
  //public highLines: number = 5000;
  public listFieldsMandatories: any = [];
  public isTransactionLines: boolean = false;
  public filterValuesOptionsMultiselects: any[] = [];

  constructor(private importService: ImportService,
    private loginService: LoginService,
    private usersService: UsersService,
    private dialogService: DialogService,
    private messageService: MessageService,
    private config: DynamicDialogConfig,
    private coreDialogService: CoreDialogService,
    private viewsService: ViewsService,
    private router: Router,
    private route: ActivatedRoute,
    private dynamicDialogRef: DynamicDialogRef,
    private entitiesService: EntitiesService,
    private formBuilder: UntypedFormBuilder,
    private datePipe: DatePipe,
    private loaderService: LoaderService,
    private componentService: ComponentService,
    private translateService: TranslateService)
  { }


  ngOnInit() {
    this.loadEntities();


    this.steps = [
      {
        label: this.translateService.instant("component.imports.detail.file"),
        command: (event: any) => {
          this.activeIndex = 0;
        }
      },
      {
        label: this.translateService.instant("component.imports.detail.mapping"),
        command: (event: any) => {
          this.activeIndex = 1;
        }
      },
      {
        label: this.translateService.instant("component.imports.detail.verify_and_ended"),
        command: (event: any) => {
          this.activeIndex = 2;
        }
      }
    ];
  }
  loadData(id: number) {
    if (id != 0) {
      this.importService.get(id).subscribe({
        next: (data:any) => {
            this.import = data;
            if(this.import.entity_id == 14) {
            this.isTransactionLines = true;
          }
          this.loadEntitiesFields();
        },
        error: (error:any) => {
          this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
        }
      });
    } else {
      this.import = {
        name: this.translateService.instant("component.imports.detail.import_date", { date: this.datePipe.transform(new Date(), "dd/MM/yyyy HH:mm") }),
        date: new Date(),
        status: "pending",
        metadata: {
          config: {
            select: [],
            identifications: []
          }
        }
      };
    }
  }

  onBasicUpload(event) {
    this.import.filename = event.originalEvent.body.filename;
    this.import.mimetype = event.originalEvent.body.mimetype;
    this.import.guid = event.originalEvent.body.guid;
    this.import.size = event.originalEvent.body.size;
    this.import.path = event.originalEvent.body.path;
  }
  onErrorUpload(event) {
    this.messageService.add({ closable: false, severity: 'error', detail: this.translateService.instant("component.imports.detail.error_uploading_file") });
  }
  loadColumns() {
    let id = this.import.id ?? 0;

    this.importService.readColumns(this.import.guid, id).subscribe(
      data => {
        this.headersColumn = [];
        var aux: any;
        aux = data;
        this.totallines = aux.total;
        this.import.status_total_lines = this.totallines;
        this.headersColumnAux = aux.columns;
        this.headersColumn.push({ code: "null", label: this.translateService.instant("component.imports.detail.not_import") })
        this.headersColumn.push({ code: "same_value", label: this.translateService.instant("component.imports.detail.same_value") })
        this.headersColumnAux.forEach(element => {
          this.headersColumn.push({ code: element, label: element });
        });

        //Intentamos borrar los fields y volverselos a cargar, pero nada....sigue sin preseleccionar 
        var ent = Object.assign([], this.entity.fields);
        this.entity.fields = [];
        setTimeout(() => {
          ent.forEach(element => {
            this.entity.fields.push(element);
          });
        }, 50);
        //fin de lo raro  
      },
      error => {
        console.log(error);
        this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
      }
    )
  }

  showHideCustomOptions(item, option) {

    if (item.header == "same_value") {
      if (this.getValueType(item) == option) return true;
    }
    return false;
  }

  getValueType(element) {
    if (typeof (this.entity.fields) !== "undefined") {
      let field = this.entity.fields.filter(m => m.id == element.id);
      if (field.length > 0) {
        return this.valueTypes(field[0].data_type, field[0].control_type);
      }
    }
  }
  valueTypes(data_type: string, control_type: string) {
    let valueType = "";
    if (data_type == "string") {
      valueType = 'input-text';
    }
    else if ((data_type == "number" && control_type != "dropdown") || data_type == "date") {
      valueType = (data_type == "number" ? 'input-number' : "input-datetime");
    }
    else if (data_type == "list") {
      valueType = "list";
    } else if (data_type == "list_multiple") {
      //console.log("dropdown-multiple")
      valueType = 'dropdown-multiple';
    } else if (control_type == 'checkbox' && data_type == "boolean") {
      valueType = 'input-checkbox';
    } else {
      valueType = 'input-text';
    }

    if (control_type == "dropdown") {
      valueType = 'input-dropdown';
    }

    return valueType;
  }
  getOptionsData(element: any) {
    
    let filterOptions: any[] = [];
    let field = this.entity.fields.filter(m => m.id == element.id);
    if (field.length > 0) {
      if (field[0].data_type == "list" || field[0].data_type == "list_multiple") {
       
        field[0].configuration.options.items.forEach(element => {
          //console.log(element.id, element.code, element.text)
          filterOptions.push({ id: element.code, name: element.text });
        });
      } else if (field[0].data_type == "number") {
        //Solución temporal para que no salgan todos los status independientemente del tipo de transacción.
        if (element.entity_code != null) this.temporalCombo[element.id] = this.temporalCombo[element.id].filter(m => m.transactionType?.code == element.entity_code || m.transactionType == null);
        
        return this.temporalCombo[element.id];
      }
    }
    return filterOptions;
  }
  loadEntities() {
    this.entitiesService.all({ for_import: true }).subscribe({
      next: (data:any) => {
        this.entities = data.rows;

        let entities2 = [];        

        if (!this.loginService.hasPermission('VIEW_TRANSACTION_LINES')) {
          this.entities = this.entities.filter(m => m.code != "transactionLine");
        }
        if (!this.loginService.hasPermission("PRODUCTS_READ")) {
          this.entities = this.entities.filter(m => m.code != "product");
        }
        //Si se define correctamente el ngIf en ENTITIES aqui se eliminará del listado si no cumple las condiciones.
        this.entities.forEach((entity: any) => {
          entity.name = this.componentService.getEntityName(entity);
          entity.name_plural = this.componentService.getEntityName(entity, true);
          if (this.componentService.isVisible(entity.metadata, null)) {
            entities2.push(entity);
          }
        });
        this.entities = JSON.parse(JSON.stringify(entities2));
        this.loadData(this.config.data.id);
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
      }
    });
  }

  identificationsChanged() {
    this.entity.fields.forEach(element => {
      element.is_required_aux = false
      console.log("Lo borramossssssssssssss");
    });
  }

  getEntityInfo(entity_id) {
    var aux = this.allowedEntities.find(m => m.entity.entity_id == entity_id);
    let import_mode_custom = this.import_mode;

    if (aux.mode == "only_link" && entity_id != this.import.entity_id) {

      import_mode_custom = this.import_mode_only_link
    }
    let importMode = import_mode_custom.find(m => m.code == aux.mode).label;
    let importType = aux.identifications.find(m => m.value == aux.type).label;
    var traduccion = this.translateService.instant("component.imports.detail.information_import", { importMode: importMode, importType: importType });

    return (aux.checked ? traduccion : "");
//    return (aux.checked ? "  (Modo de importación: '" + importMode + "'. Identificación única: '" + importType + "')" : "");

  }

  getValueFilterBy(entity_id) {

    var aux = this.allowedEntities.find(m => m.entity.entity_id == entity_id);
    let import_mode_custom = this.import_mode;
    if (aux.mode == "only_link") {
      import_mode_custom = this.import_mode_only_link
    }
    let identificationsEntities = aux.identifications.find(m => m.value == aux.type).entityFieldsIds;

    if (aux.checked) {

      //Sacamos los obligatorios de seleccion, luego los obligatorios y luego el resto.
      let importMode = import_mode_custom.find(m => m.code == aux.mode).code;
      let res = this.entity.fields.filter(m => identificationsEntities.includes(m.id) && m.entity_id == entity_id);
      res.forEach(element => {
        element.is_required_aux = true;
      });
      if (importMode != "only_link") {
        let others = this.entity.fields.filter(m => !identificationsEntities.includes(m.id) && m.entity_id == entity_id);
        let aux = res.concat(others);
        aux.forEach(element => {
          if (element.hasOwnProperty('header')) {
            //Lo buscamos en las opciones del csv, sino existe, le borramos el header.
            if (this.headersColumn.length > 0) {
              var exist = this.headersColumn.filter(m => m.code == element.header);
              if (exist.length == 0) delete element["header"];
            }
          }
        });
        return res.concat(others);
      } else {
        //Devolvemos los de la seleccion unicamente ya que solo se usa para comparar.
        res.forEach(element => {
          if (element.hasOwnProperty('header')) {
            //Lo buscamos en las opciones del csv, sino existe, le borramos el header.
            if (this.headersColumn.length > 0) {
              var exist = this.headersColumn.filter(m => m.code == element.header);
              if (exist.length == 0) delete element["header"];
            }
          }
        });
        return res;
      }
    } else {
      //No esta checheado, devolvemos solo los obligatorios por la "Identificación de manera única" de la entidad principal
      var principal = this.allowedEntities.find(m => m.entity_id == this.import.entity_id);
      let identificationsEntitiesPrincipal = principal.identifications.find(m => m.value == principal.type);
      let res = [];
      if (typeof (identificationsEntitiesPrincipal) !== "undefined" && identificationsEntitiesPrincipal != null) {

        //let res  = this.entity.fields.filter(m=> (identificationsEntities.includes(m.id) || m.is_required) && m.entity_id == entity_id);
        res = this.entity.fields.filter(m => (identificationsEntitiesPrincipal.entityFieldsIds.includes(m.id)) && m.entity_id == entity_id);
        res.forEach(element => {
          element.is_required_aux = true;
        });
      }

      //Lo buscamos en las opciones del csv, sino existe, le borramos el header.
      res.forEach(element => {
        if (element.hasOwnProperty('header')) {
          if (this.headersColumn.length > 0) {
            var exist = this.headersColumn.filter(m => m.code == element.header);
            if (exist.length == 0) delete element["header"];
          }
        }
      });
      return res;
    }
  }
  loadEntitiesFields() {
    let entity_id = this.import.entity_id ?? 0;
    this.importService.fields(entity_id).subscribe({
      next: (data: any) => {
        this.entity = data;

        let fields = [];
       // console.log("this.entity.fields.length INICIAL: ", this.entity.fields.length);
        this.entity.fields.forEach(field => {
         // field.name = field.full_model_property;
          field.description = this.componentService.getFieldDescription(field, false);
          if (this.componentService.isVisibleFieldCustom({ entityField: field }, null)) {
            fields.push(field);
          }
          //cargamos los datos de la importacion
          if (this.import.entity_id) {
            let columnselected = this.import.metadata.config.select.find(m => m.entity_field_id == field.id);

            if (columnselected != null) {
              field.value = columnselected.value,
                field.header = columnselected.header;
            }

            //SI ES UNA TRANSACCIÓN, TENEMOS  QUE PRESELECCIONAR LA TRANSACCIÓN COMO MINIMO Y SOLO MOSTRAR EL DE SOLO RELACIONAR.
            if (this.import.entity_id == 14) {
              this.isTransactionLines = true;

            }
          }
          //cargamos en un temporal todos los datos de  los posibles combos
          if (field.control_type == "dropdown" || field.control_type == "dropdown-multiple") {
            this.getComboOptions(field);
          }
        });
        this.entity.fields = JSON.parse(JSON.stringify(fields));
       // console.log(fields.length, this.entity.fields.length);
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
      }
    });

    this.importService.getImportAllowedEntities(entity_id).subscribe({
      next: (data: any) => {
        this.allowedEntities = data;
        if (this.import.entity_id == 14) {
          this.isTransactionLines = true;
        }
        if (!this.import.id) {
          // this.import.metadata.config.identifications = this.allowedEntities.filter(m=>m.entity_id==this.import.entity_id)[0];
          this.allowedEntities.forEach(element => {
            element.entity.code = element.entity.entity_code;
            element.entity.entity_name = this.componentService.getEntityName(element.entity);  
            element.checked = (element.entity.entity_id == entity_id ? true : (this.isTransactionLines && element.entity.alias == 'oportunity' ? true : false));
            element.entity_id = element.entity.entity_id,
              element.mode = (!this.isTransactionLines ? this.import_mode[0].code : (element.entity.alias != 'transactionLine' ? this.import_mode_only_link[0].code : this.import_mode[0].code)),
              element.type = element.identifications[0].value,
              element.ignore_null = true
          });
        } else {
          this.allowedEntities.forEach(element => {
            element.entity.code = element.entity.entity_code;
            element.entity.entity_name = this.componentService.getEntityName(element.entity);
            let columnselected = this.import.metadata.config.identifications.find(m => m.entity_id == element.entity.entity_id);
            if (columnselected != null) {
              element.checked = columnselected.checked;
              element.entity_id = columnselected.entity_id,
                element.type = columnselected.type;
              element.mode = columnselected.mode,
                element.ignore_null = columnselected.ignore_null;
            }
          });
        }

      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
      }
    });
  }
  getComboOptions(field) {
    if (field.configuration.options?.type == "api") {
      var url = "/api/" + field.configuration.options.url;
      this.importService.customVoidCombos(url, {}).subscribe({
        next: (data: any) => {
          this.temporalCombo[field.id] = data.rows;
        },
        error: (error: any) => {
          this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
        }
      });
    } else {
      field.configuration.options.items.forEach(e => {
        e.name = e.text;
        e.id = e.code;
      })
      this.temporalCombo[field.id] = field.configuration.options.items

      if (field.data_type == "list" || field.data_type == 'list_multiple') {
        field.configuration.options.items.forEach(element => {
         
          let id = field.configuration.field_value;
          let text = field.configuration.field_text;
          if (typeof this.filterValuesOptions[field.id] === "undefined") this.filterValuesOptions[field.id] = [];
          this.filterValuesOptions[field.id].push({ [id]: element.code, [text]: element.text });

        });
      }
    }
  }

  processingInfo() {
    this.import.metadata.config.identifications = [];
    this.allowedEntities.forEach(element => {
      this.import.metadata.config.identifications.push(
        {
          checked: element.checked,
          entity_id: element.entity.entity_id,
          mode: element.mode,
          type: element.type,
          ignore_null: element.ignore_null
        }
      )
    });
    //this.import.status = "processing";
    this.import.metadata.config.select = [];
    //rellenamos los datos del select 
    this.entity.fields.forEach(field => {
      //cargamos los datos de la importacion
      if (typeof (field.header) !== "undefined" && field.header != null) {
        this.import.metadata.config.select.push(
          {
            entity_id: field.entity_id,
            entity_field_id: field.id,
            value: (typeof (field.value) === "undefined" ? null : field.value),
            header: field.header
          }
        )
      }
    });
  }


  popupConfirmProcessing() {
    const ref = this.dialogService.open(ImportProgressbarComponent, {
      data: {
        id: this.import.id
      },
      header: this.translateService.instant("component.imports.detail.import_in_progress"),
      width: '35%',
      //closable: false
    });
    ref.onClose.subscribe((data: any) => {

      this.dynamicDialogRef.close();
    })
  }

  save(saveData) {

    this.importService.summarize(this.import.id).subscribe({
      next: (data: any) => {
        let stringMessage = "";
        data.forEach(element => {
          var textcreate = this.translateService.instant("component.imports.list.create");
          if (element.onlyLink) textcreate = this.translateService.instant("component.imports.list.not_found");
          stringMessage += "<b>" + this.componentService.getEntityName(element.entity, true) + ": </b>" + "<br>" + textcreate + ": " + element.insert + "<br>" + this.translateService.instant("component.imports.list.update") + ": " + element.updates + "<br><br>";
        });

        this.coreDialogService.confirm({
          message: stringMessage + '<br>' + this.translateService.instant("component.imports.list.question_import"),
          header: this.translateService.instant("component.imports.list.question_import_title"),
          icon: 'pi pi-info-circle',
          accept: () => {
            this.import.status = "validated";
            this.saveImport(true, false);
          },
          reject: () => {
            this.import.status = "validating";
            this.import.message = stringMessage;
            this.saveImport(false, true);
          }
        });
      },
      error: (error:any) => {
        this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
      }
      });
  }

  messageResume() {
    console.log("messageResume");
    let message1 = "";
    // let mode = this.import_mode.find(m=>m.code==this.import.metadata.config.type);
    // message1 = "Seleccionado el modo: " + mode.lab0el + "\n";
    this.processingInfo();

    var madatoriesChecked = this.allowedEntities.filter(m => m.checked).map(m => m.entity_id);
    var mandatoriesAndNotValue = null;
    var mandatoriesAndNotValueInfo = null;

    madatoriesChecked.forEach(element => {
      var aux = this.allowedEntities.find(m => m.entity.entity_id == element);
      let import_mode_custom = this.import_mode;
      if (aux.mode == "only_link") {

        import_mode_custom = this.import_mode_only_link
      }
      if (import_mode_custom.find(m => m.code == aux.mode).code != "only_link") {
        mandatoriesAndNotValue = this.entity.fields.filter(m => ((m.is_required && element == m.entity_id) || m.is_required_aux) && (typeof (m.header) === "undefined" || m.header == null || m.header == "null")).map(m => m.id);
        mandatoriesAndNotValueInfo = this.entity.fields.filter(m => ((m.is_required && element == m.entity_id) || m.is_required_aux) && (typeof (m.header) === "undefined" || m.header == null || m.header == "null"));
      } else {
        mandatoriesAndNotValue = this.entity.fields.filter(m => (element == m.entity_id && m.is_required_aux) && (typeof (m.header) === "undefined" || m.header == null || m.header == "null")).map(m => m.id);
        mandatoriesAndNotValueInfo = this.entity.fields.filter(m => (element == m.entity_id && m.is_required_aux) && (typeof (m.header) === "undefined" || m.header == null || m.header == "null"));
      }
    });

    if (mandatoriesAndNotValue.length > 0) {
      //Hay campos obligatorios. Vamos a buscar haber si están seleccionados.
      message1 += this.translateService.instant("component.imports.detail.mandatory_data_not_founded");
      message1 += "\n\n"
      mandatoriesAndNotValueInfo.forEach(element => {
        message1 += "De " + element.entity_name + " el campo " + element.description + ". \n<br>";
      });
      this.disabledImportButton = true;
    } else {
      this.disabledImportButton = false;
      let totalCols = this.import.metadata.config.select.filter(m => m.header != "null");
      message1 += this.translateService.instant("component.imports.detail.total_columns", { totalCols: totalCols.length }) + "\n";
      message1 += this.translateService.instant("component.imports.detail.total_lines", { totallines: this.totallines });
      /*if (this.totallines > this.highLines) {
        this.msgs_resume_confirm = [{ severity: 'error', summary: '', detail: "Hay más de " + this.highLines + " líneas en el fichero." }]
      }*/
      this.saveImport(false, false);
    }
    return message1;
  }

  nextStep() {
    this.activeIndex += 1;
    if (this.activeIndex == 1) {
      this.loadColumns();
    } else {
      this.msgs_resume = [
        { severity: 'info', summary: '', detail: this.messageResume() }
      ];
    }
  }

  prevStep() {
    this.activeIndex -= 1;
  }

  saveImport(redirect, closepopup) {
    if (typeof this.import.id === "undefined") {
      this.importService.add(this.import).subscribe({
        next: (data: any) => {
          this.import = data;
          if(redirect) this.popupConfirmProcessing();
          if(closepopup) this.dynamicDialogRef.close();
        },
        error:(error:any) => {
          this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
        }
      });
    } else {
      this.importService.save(this.import.id, this.import).subscribe({
        next: (data: any) => {
          if (redirect) this.popupConfirmProcessing();
          if (closepopup) this.dynamicDialogRef.close();
        },
        error: (error: any) => {
          this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
        }
      });
    }
  }

  downloadImport(importFile) {
    this.importService.download(importFile.id);
  }

}
