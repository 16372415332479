import { Component, OnInit, ViewChild } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CoreDialogService } from 'src/app/core/dialogs/core-dialog.service';
import { ViewComponent } from 'src/app/core/view/view.component';
import { RolesService } from '../../roles/roles.service';
import { TransactionStatusService } from '../transaction-status.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-transaction-status-detail',
  templateUrl: './transaction-status-detail.component.html',
  styleUrls: ['./transaction-status-detail.component.scss']
})
export class TransactionStatusDetailComponent implements OnInit {
  @ViewChild("view") public view: ViewComponent;
  public transactionStatus: any = {};
  public lastGridEvent: any;
  public selected_roles: any[] = [];
  public selected_role_status: any;
  public selected_role_data: any;
  public roles: any[] = [];

  public first;
  public last;
  constructor(
    private transactionStatusService: TransactionStatusService,
    private messageService: MessageService,
    private config: DynamicDialogConfig,
    private dialogService: DialogService,
    private coreDialogService: CoreDialogService,
    private dynamicDialogRef: DynamicDialogRef,
    private rolesService: RolesService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.loadData(this.config.data.id);
    this.loadRoles();
  }

  loadData(id) {
    if (id) {
      this.transactionStatusService.get(id).subscribe({
        next: (data: any) => {
          this.transactionStatus = data;
          //Si no tiene metadata, se lo inicializamos:
          if (this.transactionStatus.metadata == null) this.transactionStatus.metadata = { rolesStatus: [] };
          if (this.transactionStatus.metadata["rolesStatus"] == null) this.transactionStatus["rolesStatus"] = [];
          var rolesIds = []; // this.transactionStatus.metadata["permissions"]["roles"];
          //Carga la tabla y dropdown de Roles:
          this.rolesService.combo({}).subscribe({
            next: (data: any) => {
              this.roles = data.rows;
              this.selected_roles = this.roles.filter(function (e) {
                return rolesIds.indexOf(e.id) > -1;
              });
            }
          });
        },
        error: (error: any) => {
          this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
        }
      });
    } else {
      this.transactionStatus.transaction_type_id = this.config.data.transaction_type_id;
    }


  }

  loadRoles() {
    this.rolesService.all({}).subscribe({
      next: (data: any) => {
        this.roles = data.rows;
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
      }
    });
  }
  save(event: any) {
    if (!event.valid) {
      this.messageService.add({ closable: false, severity: "error", detail: this.translateService.instant("general.form_incomplete") });
      return;
    }
    if (typeof this.transactionStatus.id === "undefined") {
      this.transactionStatus.is_default = 0;
      this.transactionStatus.css_class = "{}";
      this.transactionStatusService.add(this.transactionStatus).subscribe({
        next: (data: any) => {
          this.transactionStatus = data;
          this.loadData(this.transactionStatus.id);
          this.messageService.add({ closable: false, severity: 'success', summary: this.translateService.instant("component.transactionsStatus.detail.add_correct") });
        },
        error: (error: any) => {
          this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
        }
      });
    } else {
      this.transactionStatusService.save(this.transactionStatus.id, this.transactionStatus).subscribe({
        next: (data: any) => {
          this.messageService.add({ closable: false, severity: "success", detail: this.translateService.instant("component.transactionsStatus.detail.save_correct") });
          this.dynamicDialogRef.close();
        },
        error: (error: any) => {
          this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
        }
      });
    }
  }
  cancel() {
    this.dynamicDialogRef.close();
  }
  delete() {
    this.coreDialogService.confirm({
      message: this.translateService.instant("component.transactionsStatus.delete_message", { name: this.transactionStatus.name }),
      header: this.translateService.instant("general.confirmation_delete_title"),
      icon: "pi pi-info-circle",
      accept: () => {
        this.transactionStatusService.delete(this.transactionStatus.id).subscribe({
          next: (data: any) => {
            this.messageService.add({ closable: false, severity: 'success', detail: this.translateService.instant("general.confirmation_delete") });
            this.dynamicDialogRef.close();
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        });
      },
      reject: () => {

      }
    })
  }
  isDuplied(event) {
    if (!event.valid) {
      this.messageService.add({ closable: false, severity: "error", detail: this.translateService.instant("general.form_incomplete") });
      return;
    }
    this.transactionStatusService.isDuplied(this.transactionStatus).subscribe(
      data => {
        if (data == true) {
          this.coreDialogService.notify({
            message: this.translateService.instant("component.transactionsStatus.detail.types_duplicates"),
            header: 'Error',
            icon: 'far exclamation-circle',
            color: 'var(--orange-500)',
            accept: () => { }
          });
        } else {
          this.save(event);
        }
      }
    );
  }
  onEvent(event) {
    if (event.component == "transactionStatus.edit.general") {
      if (event.event == "action" && event.action.name == "delete") this.delete();
      if (event.event == "action" && event.action.name == "cancel") this.cancel();
      if (event.event == "action" && event.action.name == "save") this.isDuplied(event);
    }

    if (event.component == "view" && event.event == "entity-loaded") {
      this.config.header = typeof (this.transactionStatus.id) !== "undefined" ?
        this.translateService.instant("component.transactionsStatus.detail.detail_entity", { entity_name: this.view.getEntityBaseName() })
        :
        this.translateService.instant("component.transactionsStatus.detail.new_entity", { entity_name: this.view.getEntityBaseName() });
    }
  }

  save_role() {
    if (this.selected_role_status != null) {
      this.roles.splice(this.roles.lastIndexOf(this.selected_role_status), 1);
      this.selected_roles.push(this.selected_role_status);
      this.selected_role_status = null;
    }
  }
  unlinkRole(role) {
    var erased = this.selected_roles.splice(this.selected_roles.lastIndexOf(role), 1);
    this.roles.push(erased[0]);
    this.selected_role_status = { name: this.translateService.instant("component.transactionsStatus.detail.select_rol") };
  }

  onChangeRole($event, type) {
    if (type == "status") {
      if (this.transactionStatus.metadata.rolesStatus == null) this.transactionStatus.metadata.rolesStatus = [];
      if (this.transactionStatus.metadata.rolesStatus.findIndex(r => r.id == $event.value.id) < 0) this.transactionStatus.metadata.rolesStatus.push({ id: $event.value.id, name: $event.value.name });
      this.selected_role_status = null;
    } else if (type == "data") {
      if (this.transactionStatus.metadata.rolesData == null) this.transactionStatus.metadata.rolesData = [];
      if (this.transactionStatus.metadata.rolesData.findIndex(r => r.id == $event.value.id) < 0) this.transactionStatus.metadata.rolesData.push({ id: $event.value.id, name: $event.value.name });
      this.selected_role_data = null;
    }

  }

  deleteRole(role, type) {
    if (type == "status") {
      var index = this.transactionStatus.metadata.rolesStatus.findIndex(r => r.id == role.id);
      this.transactionStatus.metadata.rolesStatus.splice(index, 1);
    } else if (type == "data") {
      var index = this.transactionStatus.metadata.rolesData.findIndex(r => r.id == role.id);
      this.transactionStatus.metadata.rolesData.splice(index, 1);
    }
  }
}
