import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { SplitButtonModule } from 'primeng/splitbutton';
import { TableModule } from 'primeng/table';
import { TimelineModule } from 'primeng/timeline';
import { CalendarModule } from 'src/app/calendar/calendar.module';
import { CoreModule } from 'src/app/core/core.module';
import { ViewsListComponent } from './view-list/views-list.component';
import { ViewsDetailComponent } from './view-detail/views-detail.component';
import { ViewsRoutingModule } from './views-routing.module';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
  declarations: [
    ViewsListComponent, 
    ViewsDetailComponent
  ],
  imports: [
    ViewsRoutingModule,
    CommonModule,
    ButtonModule,
    TableModule,
    ConfirmDialogModule,
    DialogModule,
    FormsModule,
    CheckboxModule,
    CoreModule,
    CardModule,
    FontAwesomeModule,
    InputTextareaModule,
    DropdownModule,
    CalendarModule,
    SplitButtonModule,
    TimelineModule,
    TranslateModule
  ],
  exports:[
    ViewsListComponent, 
    ViewsDetailComponent
  ]
})
export class ViewsModule { }
