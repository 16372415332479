<p-card>
  <div class="p-grid">
    <div class="p-col-12 p-field">
      <label for="role">{{'role.entity.name'|translate}}</label>
      <p-dropdown name="role" optionValue="id" [options]="roles" [(ngModel)]="user.role_id" optionLabel="name"
        appendTo="body" placeholder="{{'general.select' | translate}}" [showClear]="true"
        (onChange)="loadRolePermissions()">
      </p-dropdown>
    </div>
    <div class="p-col-12" *ngIf="roles_configuration">
      <p-checkbox name="checked_all" [(ngModel)]="checked_all" (onChange)="check_all()"
        [binary]="true"></p-checkbox><label for="checked_all">{{'general.select_all'|translate}}</label>
      <p-accordion>
        <div *ngFor="let permissionGroup of permissionsGroups">
          <p-accordionTab *ngIf="getPermissions(permissionGroup).length > 0">
            <ng-template pTemplate="header">{{ 'permissions_groups.' + permissionGroup.code | translate }}</ng-template>
            <ng-template pTemplate="content">
              <div class="p-grid">
                <div *ngFor="let permission of getPermissions(permissionGroup)" class="p-col-4">
                  <label>
                    <p-checkbox name="groupname" value="{{permission.id}}" [(ngModel)]="selectedPermissions"
                                *ngIf="rolePermissions.includes(permission.id+'')" disabled="true"></p-checkbox>
                    <p-checkbox name="groupname" value="{{permission.id}}" [(ngModel)]="selectedPermissions"
                                *ngIf="!rolePermissions.includes(permission.id+'')" (onChange)="updateChecked()"></p-checkbox>
                    {{ 'permissions.name.' + permission.code | translate }}
                    <i pTooltip="{{ 'permissions.description.' + permission.code | translate }}" tooltipPosition="bottom" class="pi pi-info-circle" style="font-size: 11px; color: #4EA294 !important; "></i>
                  </label>
                </div>
              </div>
            </ng-template>
          </p-accordionTab>
        </div>
      </p-accordion>
    </div>
    <!--div *ngFor="let permissionGroup of permissionsGroups" class="p-col-3">
            <p-card [header]="permissionGroup.name">
                <div *ngFor="let permission of getPermissions(permissionGroup)" class="p-field p-col-12">
                    <label>
                        <p-checkbox name="groupname" value="{{permission.id}}" [(ngModel)]="selectedPermissions"
                            *ngIf="rolePermissions.includes(permission.id+'')" disabled="true"></p-checkbox>
                        <p-checkbox name="groupname" value="{{permission.id}}" [(ngModel)]="selectedPermissions"
                            *ngIf="!rolePermissions.includes(permission.id+'')"></p-checkbox>
                        {{permission.name}}
                    </label>
                </div>
            </p-card>
        </div>
        <div class="p-col-12">
            <button pButton class="p-ml-2" style="float: right;" (click)="save()" icon="pi pi-check"
                label="{{'general.action.save' | translate}}"></button>
            <button pButton class="p-button-secondary" style="float: right;" (click)="cancel()" icon="pi pi-times"
                label="{{'general.action.cancel' | translate}}"></button>
        </div-->
  </div>
</p-card>
