import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { CoreDialogService } from 'src/app/core/dialogs/core-dialog.service';
import { CustomComponent } from 'src/app/core/view/component/custom/custom-component.interface';
import { ViewComponent } from 'src/app/core/view/view.component';
import { ViewsService } from 'src/app/core/view/views.service';
import { LoginService } from 'src/app/login/login.service';
import { TasksHistoriesService } from '../tasks-histories.service';
import { TasksHistoryDetailComponent } from '../tasks-history-detail/tasks-history-detail.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-tasks-history-line',
  templateUrl: './tasks-history-line.component.html',
  styleUrls: ['./tasks-history-line.component.scss']
})
export class TasksHistoryLineComponent implements OnInit, CustomComponent {
  @Input() public data: any
  @Input() public entity: any;
  @ViewChild("view") public view: ViewComponent;
  public action: any = {
    task_action_scheduled_date: new Date()
  };
  public tasksHistories: any = [];
  public canDeleteHistory: boolean;
  //public canEditHistory: boolean;

  constructor(
    private tasksHistoriesService: TasksHistoriesService,
    private messageService: MessageService,
    private coreDialogService: CoreDialogService,
    private loginService: LoginService,
    private dialogService: DialogService,
    private viewsService: ViewsService,
    private translateService: TranslateService
  ) { }
  

  ngOnInit(): void {
    this.loadTasksHistories();
    this.canDeleteHistory = this.loginService.hasPermission("TASKS_HISTORY_DELETE");
    //this.canEditHistory = this.loginService.hasPermission("TASKS_HISTORY_WRITE");

  }

  loadTasksHistories() {
    if(typeof (this.data.id) !== "undefined" ){
      this.tasksHistoriesService.all({ task_id: this.data.id }).subscribe({
        next: (data: any) => {
          this.tasksHistories = data.rows;
        },
        error: (error: any) => {
          this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
        }
      });
    }
  }

  editTaskHistory(taskHistory) {
    const ref = this.dialogService.open(TasksHistoryDetailComponent, {
      data: {
        taskHistory: taskHistory,
      },
      //header: user ? "Detalles del usuario" : "Nuevo usuario",
      width: '70%',
    });
    ref.onClose.subscribe((data: any) => {
      this.viewsService.changeView(this.view.code);
      this.view.fireEvent("tasksHistoryDetail.custom", "reload");
    });
  }

  deleteTaskHistory(taskHistory) {
    this.coreDialogService.confirm({
      message: this.translateService.instant("component.tasks.history_line.delete_history_message"),
      header: this.translateService.instant("general.confirmation_delete_title"),
      icon: "pi pi-info-circle",
      accept: () => {
        this.tasksHistoriesService.delete(taskHistory.id).subscribe({
          next: (data: any) => {
            this.messageService.add({ closable: false, severity: 'success', detail: this.translateService.instant("general.confirmation_delete") });
            this.loadTasksHistories();
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        });
      },
      reject: () => {

      }
    })
  }

  onEvent(component: string, event:string, args:any){
    if(component == "tasksHistoryDetail.custom" && event == "reload"){
      this.loadTasksHistories();
    }
  }
}
