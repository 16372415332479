import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OrganizationsTypesListComponent } from './organizations-types-list/organizations-types-list.component';

const routes: Routes = [
  { path: "organizationsTypes", component: OrganizationsTypesListComponent },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OrganizationsTypesRoutingModule { }
