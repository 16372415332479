import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { NotificationsService } from 'src/app/notifications/notifications.service';

@Component({
  selector: 'app-mailing-to-detail',
  templateUrl: './mailing-to-detail.component.html',
  styleUrls: ['./mailing-to-detail.component.scss']
})
export class MailingToDetailComponent implements OnInit {
  public notificationTo: any = {};
  public notificationStats: any[] = [];

  constructor(
    private notificationsService: NotificationsService,
    private dynamicDialogRef: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private translateService: TranslateService,
  ) { }

  ngOnInit(): void {
    this.loadNotificationTo(this.config.data.notification_to_id);
    this.loadNotificationStats(this.config.data.notification_to_id);
  }

  loadNotificationTo(id: number) {
    if (id) {
      this.notificationsService.getNotificationTo(id).subscribe(
        data => {
          this.notificationTo = data;
        },
        error => {
          //TODO message
        }
      );
    }
  }

  loadNotificationStats(id: number) {
    if (id) {
      this.notificationsService.allNotificationsStats({ notification_to_id: id }).subscribe(
        data => {
          this.notificationStats = data.rows;
        },
        error => {
          //TODO message
        }
      );
    }
  }

  getState(status) {
    if (status == 'sent') {
      var sent_status_string: string = this.translateService.instant("component.mailing.sent_status");
      return "<div class='badge badge-block badge-success'>" + sent_status_string + "</div>";
    } else if (status == "pending") {
      var pending_status_string: string = this.translateService.instant("component.mailing.pending_status");
      return "<div class='badge badge-block badge-warning'>" + pending_status_string + "</div>";
    } else if (status == "programmed") {
      var programmed_status_string: string = this.translateService.instant("component.mailing.programmed_status");
      return "<div class='badge badge-block badge-info'>" + programmed_status_string + "</div>";
    } else if (status == "error") {
      var error_status_string: string = this.translateService.instant("component.mailing.error_status");
      return "<div class='badge badge-block badge-danger'>" + error_status_string + "</div>";
    } else {
      var default_status_string: string = this.translateService.instant("component.mailing.default_status");
      return "<div class='badge badge-block badge-secondary'>" + default_status_string + "</div>";
    }
  }
}