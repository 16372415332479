<div class="p-grid">
  <div class="p-col p-field">
    <label for="username">{{'component.mailMarketing.test.write_test_mail_text'| translate}}</label>
    <input id="username" type="username" pInputText [(ngModel)]="marketingTest.email" placeholder="nombre@email.com" />
  </div>
</div>
<div class="p-grid" *ngIf="errorMessage!=''">
  <div class="p-col">
    <p-message severity="error" text="{{errorMessage}}" class="p-d-block"></p-message>
  </div>
</div>
<div class="p-grid">
  <div class="p-col">
    <button class="p-ml-2" style="float: right;" pButton type="button" label="{{'component.mailMarketing.test.send_test_mail' | translate}}" (click)="send()"
      *ngIf="notification.type != 'mailchimp' || notification.external_id!=null"></button>
    <button pButton class="p-button-link p-button-secondary" style="float: right;" (click)="cancel()" icon="pi pi-times"
      label="{{'general.action.cancel' | translate}}"></button>
  </div>
</div>