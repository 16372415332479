import { Component, OnInit, ViewChild } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { CompanyLicenseService } from 'src/app/configuration/company-license/company-license.service';
import { ZonesDetailComponent } from 'src/app/configuration/zones/zones-detail/zones-detail.component';
import { CoreDialogService } from 'src/app/core/dialogs/core-dialog.service';
import { ViewComponent } from 'src/app/core/view/view.component';
import { ViewsService } from 'src/app/core/view/views.service';
import { UsersDetailComponent } from '../users-detail/users-detail.component';
import { UsersService } from '../users.service';
import { TranslateService } from '@ngx-translate/core';
import { MailingDetailComponent } from 'src/app/mailing/mailing-detail/mailing-detail.component';

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss']
})
export class UsersListComponent implements OnInit {

  @ViewChild("view") public view: ViewComponent;
  public usersFilters: any = {};

  constructor(
    private usersService: UsersService,
    private licensesService: CompanyLicenseService,
    private viewsService: ViewsService,

    private messageService: MessageService,
    private coreDialogService: CoreDialogService,
    private dialogService: DialogService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
  }

  viewUser(user) {
    const ref = this.dialogService.open(UsersDetailComponent, {
      data: {
        id: user ? user.id : 0,
        responsible_id: localStorage.getItem("userId")
      },
      width: '70%',
    });
    ref.onClose.subscribe((data: any) => {
      this.viewsService.changeView(this.view.code);
      this.view.fireEvent("users.list.results", "reload");
    });
  }

  deleteUser(user) {
    this.coreDialogService.confirm({
      message: this.translateService.instant("component.users.delete_message", { name: user.name }),
      header: this.translateService.instant("general.confirmation_delete_title"),
      icon: "pi pi-info-circle",
      accept: () => {
        this.usersService.delete(user.id).subscribe({
          next: (data: any) => {
            this.messageService.add({ closable: false, severity: 'success', detail: this.translateService.instant("general.confirmation_delete") });
            this.view.fireEvent("users.list.results", "reload");
          },
          error: (error: any) => {
            this.messageService.add({ severity: "error", detail: error.error.title });
          }
        });
      },
      reject: () => { }
    });
  }

  newMail(user) {
    const ref = this.dialogService.open(MailingDetailComponent, {
      data: {
        to: user.email,
        entity_id: 18,
        entity_pk_id: user.id,
        template_type_id: 4,
        template_type_code: "MAIL_INTERNAL",
        searchRelated: false
      },
      header: this.translateService.instant("general.new_mail_title"),
      width: '70%',
    });
    ref.onClose.subscribe((data: any) => {
      if (data) {
        //this.dynamicDialogRef.close(data);
        location.reload();
      }
      if (typeof (this.view) !== "undefined") this.viewsService.changeView(this.view.code);
    });

  }

  onEvent(event: any) {
    if (event.component == "users.list.filters") {
      if (event.event == "action" && event.action.name == "search") this.view.fireEvent("users.list.results", "reload");
    } else if (event.component == "users.list.results") {
      if (event.event == "dblclick") this.viewUser(event.data);
      if (event.event == "action" && event.action.name == "add") this.checkNumUserInLicense();
      if (event.event == "action" && event.action.name == "sendEmail") this.newMail(event.data);
      if (event.event == "action" && event.action.name == "edit") this.viewUser(event.data);
      if (event.event == "action" && event.action.name == "delete") this.deleteUser(event.data);
    }
  }

  checkNumUserInLicense() {
    this.licensesService.canCreateUser().subscribe({
      next: (data: any) => {
        //Si la company tiene menos users que license.number_of_users...
        if (data) {
          //Permitirá la creación de nuevo usuario:
          this.viewUser(null);
        } else {
          this.openMessageInPopup();
          //this.messageService.add({ closable: false, severity: 'error', detail: "El número de usuarios supera al permitido en la licencia actual." });
        }
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
      }
    });
  }

  openMessageInPopup() {
    this.coreDialogService.notify({
      message: this.translateService.instant("component.users.limited_users_error_message"),
      header: this.translateService.instant('general.error'),
      icon: 'far exclamation-circle',
      color: 'var(--orange-500)',
      accept: () => { }
    });
  }
}
