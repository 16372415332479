import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MailjetService {

  url = "/api/mailjet";

  constructor(private http: HttpClient) { }

  verify(api_key, api_secret): Observable<object> {
    return this.http.get<object>(this.url + "/verify/" + api_key + "/" + api_secret);
  }

  getAllLists(api_key, api_secret): Observable<object> {
    return this.http.get<object>(this.url + "/getAllLists/" + api_key + "/" + api_secret);
  }

  getContactMetadata(api_key, api_secret): Observable<any> {
    return this.http.get<object>(this.url + "/contactmetadata/" + api_key + "/" + api_secret);
  }

  dashboard(id): Observable<object> {
    return this.http.get<object>(this.url + "/dashboard/" + id);
  }

}
