import { HttpClient } from '@angular/common/http';
import { Injectable, Type } from '@angular/core';
import { Observable } from 'rxjs';
import { CustomComponent } from './component/custom/custom-component.interface';

@Injectable({
  providedIn: 'root'
})
export class ViewsCustomService {
  url = "/api/";

  private registeredComponents:any[] = [];

  constructor(private http: HttpClient) { }

  saveView(id, obj): Observable<object> {
    return this.http.put<object>(this.url + "viewscustom/" + id, obj);
  }

  save(id, obj): Observable<object> {
    return this.http.put<object>(this.url + "viewscustom/" + id, obj);
  }

  add(obj): Observable<object> {
    return this.http.post<object>(this.url + "viewscustom/", obj);
  }

  delete(id): Observable<object> {
    return this.http.delete<object>(this.url + "viewscustom/" + id);
  }

}
