<app-view #view1 [code]="'organizations.quick'" [model]="organization" (onEvent)="onEvent($event)"></app-view>

<!--
<div class="p-field-checkbox">
  <p-checkbox inputId="showContact" [(ngModel)]="checks.showContact" [binary]="true"></p-checkbox>
  <label for="showContact">Crear contacto</label>
</div>
<app-view #view2 [code]="'contacts.quick'" [model]="contact" [hidden]="!checks.showContact"></app-view>

<br>

<div class="p-field-checkbox">
  <p-checkbox inputId="showTask" [(ngModel)]="checks.showTask" [binary]="true"></p-checkbox>
  <label for="showTask">Crear actividad</label>
</div>
<app-view #view3 [code]="'tasks.quick'" [model]="task"  [hidden]="!checks.showTask"></app-view>-->

<button pButton class="p-ml-2" style="float: right;" (click)="save()" icon="pi pi-check" label="{{'general.action.save' | translate}}"></button>
<button pButton class="p-button-secondary" style="float: right;" (click)="cancel()" icon="pi pi-times" label="{{'general.action.cancel' | translate}}"></button>
