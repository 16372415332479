<p-messages severity="info" class="p-d-block">
  <ng-template pTemplate>
    {{'component.integrations.office365_info1'|translate}}<br>
    {{'component.integrations.office365_info2'|translate}}
  </ng-template>
</p-messages>

<div class="p-grid">
  <div class="p-col-6" *ngFor="let user of integration.configuration.users">
    <p-card styleClass="p-mt-a4">
      <div class="user-config p-d-flex">
        <img src="/assets/img/no-user.jpeg">
        <div class="flex-grow-1 p-d-flex p-flex-column">
          <b>{{user.name}}</b>
          <div class="p-mt-1 p-d-flex p-ai-center">
            <fa-icon [icon]="['fas','paper-plane']"
              pTooltip="{{'component.integrations.office365_send_mail'|translate}}"
              class="p-mr-2 {{(user.mail.allow_send?'user-config-option-enabled':'user-config-option-disabled')}}"></fa-icon>
            <fa-icon [icon]="['fas','address-card']"
              pTooltip="{{'component.integrations.office365_sync_contacts'|translate}}"
              class="p-mr-2 {{(user.contacts.enabled?'user-config-option-enabled':'user-config-option-disabled')}}"></fa-icon>
            <fa-icon [icon]="['fas','envelope']"
              pTooltip="{{'component.integrations.office365_sync_mailbox'|translate}}"
              class="p-mr-2 {{(user.mail.enabled?'user-config-option-enabled':'user-config-option-disabled')}}"></fa-icon>
            <fa-icon [icon]="['fas','calendar']"
              pTooltip="{{'component.integrations.office365_sync_calendar'|translate}}"
              class="{{(user.calendar.enabled?'user-config-option-enabled':'user-config-option-disabled')}}"></fa-icon>
          </div>
          <div class="p-mt-1">
            <div class="badge badge-success"
              *ngIf="user.connected && (user.mail.allow_send || user.contacts.enabled || user.mail.enabled || user.calendar.enabled)">
              {{'component.integrations.connected'|translate}}</div>
            <div class="badge badge-warning"
              *ngIf="!user.connected && (user.mail.allow_send || user.contacts.enabled || user.mail.enabled || user.calendar.enabled)">
              {{'component.integrations.connection_pending'|translate}}</div>
            <div class="badge badge-secondary"
              *ngIf="(!user.mail.allow_send && !user.contacts.enabled && !user.mail.enabled && !user.calendar.enabled)">
              {{'component.integrations.without_connection'|translate}}</div>
          </div>
        </div>
        <div class="p-d-flex p-flex-column p-ai-center p-jc-center">
          <a (click)="configUser(user)">
            <fa-icon [icon]="['fas', 'cog']"></fa-icon>
          </a>
          <a class="p-mt-4 color-danger"
            *ngIf="user.connected && (user.mail.allow_send || user.contacts.enabled || user.mail.enabled || user.calendar.enabled)"
            pTooltip="{{'component.integrations.reconnect'|translate}}" tooltipPosition="top"
            (click)="user.connected=false">
            <fa-icon [icon]="['fas', 'refresh']"></fa-icon>
          </a>

        </div>
      </div>
    </p-card>
  </div>
</div>

<div class="p-col-12 p-d-flex p-mt-4">
  <div class="flex-grow-1"></div>
  <button class="p-ml-2" style="float: right;" icon="pi pi-save" pButton type="button"
    label="{{'general.action.save' | translate}}" (click)="save()"></button>
</div>