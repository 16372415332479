import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UserCalendarRoutingModule } from './user-calendar-routing.module';
import { UserCalendarComponent } from './user-calendar.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
  declarations: [UserCalendarComponent],
  imports: [
    CommonModule,
    UserCalendarRoutingModule,
    FullCalendarModule,
    TranslateModule
  ],
  exports:[
    UserCalendarComponent
  ]
})
export class UserCalendarModule { }
