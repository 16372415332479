import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ImportListComponent } from './import-list/import-list.component';

const routes: Routes = [
  { path: "import", component: ImportListComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ImportRoutingModule { }
