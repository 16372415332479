<!-- cargador bloqueante, caso de importaciones -->
<div *ngIf="loading && loadingPercent>0" class="app-loader p-grid p-jc-center p-ai-center">
  <div class=" p-col-12 align-items-center" style="text-align:center">
    <div class="lds-ripple"><div></div><div></div></div>
    <div *ngIf="loadingPercent>0">
      <div style="margin-top: -20px;"><h3>{{loadingPercent}}%</h3></div>
    </div>
  </div>
</div>
<!-- cargador generico -->
<div *ngIf="loading && (loadingPercent==null || loadingPercent<=0)" class="app-loader-top">
  <div class="slider">
    <div class="line"></div>
    <div class="subline inc"></div>
    <div class="subline dec"></div>
  </div>
</div>
