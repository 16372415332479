<ul class="breadcrumb">
  <li><a [routerLink]="['/home']"><span class="color-soft-grey-axial">{{'general.breadcrumb.you_are' | translate}}
      </span>{{'general.breadcrumb.home' | translate}}</a></li>
  <li><i class="pi pi-angle-right"></i></li>
  <li><a [routerLink]="['/configuration/entities']">{{'general.configuration' | translate}}</a></li>
  <li><i class="pi pi-angle-right"></i></li>
  <li><a [routerLink]="['/configuration/integrations']">{{'integration.entity.name_plural' | translate}}</a></li>
</ul>

<p-card>
  <ng-template pTemplate="header">
    <div class="p-card-title">
      {{ 'integration.entity.name_plural' | translate}}
    </div>
  </ng-template>
  <div class="integration-list">
    <!--<div class="p-col-12 p-field">
      <div class="p-inputgroup">
        <input [(ngModel)]="search" id="search" name="search" type="text" pInputText placeholder="Texto a buscar" autocomplete="false" />
      </div>
    </div>-->
    <p-panel styleClass="integration p-panel-header-disable" showHeader="false" *ngFor="let item of integrations">
      <div class="p-grid p-flex-column p-ai-center">
        <img src="/assets/img/integrations/{{item.code}}.png" style="max-height: 60px; max-width: 100%;" />
        <div class="p-mt-4 p-text-center">
          <b>{{item.name}}</b><br />
          {{item.description}}
        </div>
        <div class="p-mt-4 flex-grow">
          <button pButton icon="pi pi-cog" label="{{'general.configure'|translate}}" (click)="open(item.code)"></button>
        </div>
      </div>
    </p-panel>
  </div>
</p-card>