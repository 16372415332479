import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { Table } from 'primeng/table';
import { CoreDialogService } from 'src/app/core/dialogs/core-dialog.service';
import { LoginService } from 'src/app/login/login.service';
import { AttachmentsTypesService } from '../../configuration/attachments-types/attachments-types.service';
import { AttachmentsService } from '../attachments.service';
import { GenericAttachmentsDetailComponent } from '../generic-attachments-detail/generic-attachments-detail.component';
import { TranslateService } from '@ngx-translate/core';
import { SessionStorageService } from 'ngx-webstorage';

@Component({
  selector: 'app-generic-attachments-list',
  templateUrl: './generic-attachments-list.component.html',
  styleUrls: ['./generic-attachments-list.component.scss']
})
export class GenericAttachmentsListComponent implements OnInit {
  @ViewChild("grid") public grid: Table;
  public genericAttachmentsFilters: any = {};
  public genericAttachments: any[];
  public totalRecords: number;
  public loading: boolean = false;
  private lastGridEvent: any;
  public editAttachmentPermission: boolean = false;
  public createAttachmentPermission: boolean = false;
  public deleteAttachmentPermission: boolean = false;
  public attachmentsTypes: any = [];

  public first;
  public last;

  public fieldQuicks: any[] = [];
  public componentFilter: any;
  public hasFilterValue: boolean = false;
  public has_filter_panel: boolean = true;
  public show_filter_panel: boolean = false;
  public show_filter_panel_mobile: boolean = false;

  constructor(private attachmentsService: AttachmentsService,
    private router: Router,
    private coreDialogService: CoreDialogService,
    private messageService: MessageService,
    private dialogService: DialogService,
    private loginService: LoginService,
    private attachmentsTypesService: AttachmentsTypesService,
    private translateService: TranslateService,
    private sessionStorage: SessionStorageService
  ) {
    this.editAttachmentPermission = loginService.hasPermission("GENERIC_ATTACHMENTS_EDIT");
    this.createAttachmentPermission = loginService.hasPermission("GENERIC_ATTACHMENTS_CREATE");
    this.deleteAttachmentPermission = loginService.hasPermission("GENERIC_ATTACHMENTS_DELETE");
    this.createQuickFilters();

  }

  ngOnInit(): void {
    this.loadAttachmentsTypes();
  }
  loadAttachmentsTypes() {
    this.attachmentsTypesService.all({ is_generic: true }).subscribe({
      next: (data: any) => {
        this.attachmentsTypes = data.rows;
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
      }
    });
  }
  clearFilters() {
    this.genericAttachmentsFilters = {};
    this.sessionStorage.clear("genericAttachments.genericAttachments");
    this.hasFilterValue = false;
    this.search();
  }
  search() {
    this.lastGridEvent.first = 0;
    this.loadData(this.lastGridEvent);
  }
  loadData($event) {
    this.lastGridEvent = $event;
    Promise.resolve(null).then(() => this.loading = true);
    var params = {
      _page: $event.first / $event.rows,
      _pageSize: $event.rows,
      _sortBy: $event.sortField ? $event.sortField : "",
      _sortDirection: $event.sortOrder > 0 ? "asc" : "desc",
      is_generic: true
    };
    if (this.genericAttachmentsFilters.attachment_type_id == null) delete this.genericAttachmentsFilters['attachment_type_id'];
    params = Object.assign(params, this.genericAttachmentsFilters);
    this.attachmentsService.all(params).subscribe({
      next:( data:any) => {
        this.totalRecords = data.totalRows;
        this.genericAttachments = data.rows;
        Promise.resolve(null).then(() => this.loading = false);
      },
      error: (error:any) => {
        this.messageService.add({ severity: "error", detail: error.error.title });
        Promise.resolve(null).then(() => this.loading = false);
      }
    });
  }

  editAttachment(attachment) {
    const ref = this.dialogService.open(GenericAttachmentsDetailComponent, {
      data: {
        entity_id: attachment.entity_id ? attachment.entity_id : 12,
        entity_pk_id: 0,
        id: attachment.id ? attachment.id : 0
      },
      // header: attachment?"Detalles del adjunto":"Nuevo adjunto",
      width: '70%',
    });
    ref.onClose.subscribe((data: any) => {
      this.loadData(this.lastGridEvent);
    });
  }

  deleteAttachment(attachment) {
    this.coreDialogService.confirm({
      message: this.translateService.instant("component.generic_attachments.delete_message", { description: attachment.description }), //'¿Quieres borrar el documento ' + attachment.filename + '?',
      accept: () => {
        this.attachmentsService.delete(attachment.id).subscribe({
          next: (data: any) => {
            this.loadData(this.lastGridEvent);
          },
          error: (error: any) => {
            this.messageService.add({ severity: "error", detail: error.error.title });
          }
        });
      },
      reject: () => {

      }
    }
    );
  }

  downloadAttachment(attachment) {
    this.attachmentsService.download(attachment.guid);
  }


  createQuickFilters() {

    //Los buscamos en la sesión
    var sessionInfo = this.sessionStorage.retrieve("genericAttachments.genericAttachments");
    var selectedFields = [];

    if (sessionInfo != null) {
      this.hasFilterValue = true;
      this.genericAttachmentsFilters.attachment_type_id = (sessionInfo.filters.find(m => m.attachment_type_id) != undefined ? sessionInfo.filters.find(m => m.attachment_type_id).attachment_type_id : null);
      this.genericAttachmentsFilters.filename = (sessionInfo.filters.find(m => m.filename) != undefined ? sessionInfo.filters.find(m => m.filename).filename : null);
      this.genericAttachmentsFilters.description = (sessionInfo.filters.find(m => m.description) != undefined ? sessionInfo.filters.find(m => m.description).description : null);
    }
  }

  toggleFilter() {
    this.show_filter_panel = !this.show_filter_panel;
  }
  toggleFilterMobile() {
    this.show_filter_panel_mobile = !this.show_filter_panel_mobile;
  }

  loadFilterAndSearch() {
    this.sessionStorage.clear("genericAttachments.genericAttachments");
    if (this.genericAttachmentsFilters.attachment_type_id != null || (this.genericAttachmentsFilters.filename != null && this.genericAttachmentsFilters.filename != "") || (this.genericAttachmentsFilters.description != null  && this.genericAttachmentsFilters.description != "")) {
      this.hasFilterValue = true;
      var sessionInfo = { filters: [] };
      
      if (this.genericAttachmentsFilters.attachment_type_id != null) {
        sessionInfo.filters.push({ attachment_type_id: this.genericAttachmentsFilters.attachment_type_id });
      }
      if (this.genericAttachmentsFilters.filename != null && this.genericAttachmentsFilters.filename != "") sessionInfo.filters.push({ filename: this.genericAttachmentsFilters.filename });
      if (this.genericAttachmentsFilters.description != null && this.genericAttachmentsFilters.description != "") sessionInfo.filters.push({ description: this.genericAttachmentsFilters.description });
      this.sessionStorage.store("genericAttachments.genericAttachments", sessionInfo);
    } else {
      this.hasFilterValue = false;
    }
    this.search();
  }

}
