import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TaskStatusRoutingModule } from './task-status-routing.module';
import { TaskStatusListComponent } from './task-status-list/task-status-list.component';
import { TaskStatusDetailComponent } from './task-status-detail/task-status-detail.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { TableModule } from 'primeng/table';
import { ColorPickerModule } from 'primeng/colorpicker';
import { CheckboxModule } from 'primeng/checkbox';


@NgModule({
  declarations: [
    TaskStatusListComponent,
    TaskStatusDetailComponent
  ],
  imports: [
    CommonModule,
    TaskStatusRoutingModule,
    FormsModule,
    HttpClientModule,
    ButtonModule,
    InputTextModule,
    TableModule,
    CardModule,
    FontAwesomeModule,
    ConfirmDialogModule,
    DropdownModule,
    TranslateModule,
    ColorPickerModule,
    CheckboxModule
  ],
  exports:[
    TaskStatusListComponent,
    TaskStatusDetailComponent
  ]
})
export class TaskStatusModule { }
