import { Injectable } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { DialogConfirmComponent } from './dialogs-confirm/dialogs-confirm.component';

@Injectable({
  providedIn: 'root'
})
export class CoreDialogService {

  constructor(private dialogService: DialogService) {

  }
  public icon : any;
  public show_button_ok : boolean=false;

  confirm(params:any) {
    //params.baseZIndex = "2000";
    params.showHeader = false;
    params.icon ="far question-circle";
    params.acceptFn = function(){
      params.accept();
    };
    params.rejectFn = function(){
      params.reject();
    };
    this.dialogService.open(DialogConfirmComponent, params);
  }

  notify(params:any) {
    //params.baseZIndex = "2000";
    params.show_button_ok = true;
    params.showHeader = false;
    params.acceptFn = function(){
      params.accept();
    };/*
    params.rejectFn = function(){
      params.reject();
    };*/
    this.dialogService.open(DialogConfirmComponent, params);
  }

}
