import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Components
import { HomeListComponent } from './home-list/home-list.component';
import { HomeRoutingModule } from './home-routing.module';
import { CoreModule } from '../core/core.module';
import { ChartModule } from 'primeng/chart';
import { CardModule } from 'primeng/card';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
    imports: [
      CommonModule,
      HomeRoutingModule,              
      ChartModule,
      CoreModule,            
    CardModule,
    TranslateModule
    ],
    declarations: [
      HomeListComponent
    ]
  })
  export class HomeModule {
  }
