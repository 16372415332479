<div class="p-grid">
    <div class="p-col-12 p-field">
        <label for="zone">{{'zone.entity.name'|translate}}</label>
        <p-dropdown name="zone" optionValue="id" [options]="zones" [(ngModel)]="userZone.zone_id" optionLabel="name"
            appendTo="body" placeholder="{{'general.select' | translate}}"></p-dropdown>
    </div>
    <div class="p-col-12">
        <button pButton class="p-ml-2" style="float: right;" (click)="save()" icon="pi pi-check"
            label="{{'general.action.save' | translate}}"></button>
        <button pButton class="p-button-secondary p-button-text" style="float: right;" (click)="cancel()"
            icon="pi pi-times" label="{{'general.action.cancel' | translate}}"></button>
    </div>
</div>