import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { CoreDialogService } from 'src/app/core/dialogs/core-dialog.service';
import { ViewComponent } from 'src/app/core/view/view.component';
import { ViewsService } from 'src/app/core/view/views.service';
import { LoginService } from 'src/app/login/login.service';
import { MailAccountsDetailComponent } from '../mail-accounts-detail/mail-accounts-detail.component';
import { MailAccountsService } from '../mail-accounts.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-mail-accounts-list',
  templateUrl: './mail-accounts-list.component.html',
  styleUrls: ['./mail-accounts-list.component.scss']
})
export class MailAccountsListComponent implements OnInit {
  @ViewChild("view") public view: ViewComponent;
  public mailAccountsFilters: any = {};
  public mailAccounts: any[];
  public totalRecords: number;
  public loading: boolean = false;
  private lastGridEvent: any;
  public mail_types: any = [{ label: this.translateService.instant("component.mailAccount.list.server_smtp"), value: "smtp" }];

  public first;
  public last;
  constructor(
    private mailAccountsService: MailAccountsService,
    private coreDialogService: CoreDialogService,
    private messageService: MessageService,
    private dialogService: DialogService,
    private viewsService: ViewsService,
    private router: Router,
    private loginService: LoginService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.checkPossiblesTypes();
  }

  checkPossiblesTypes() {
    //Si tiene el feature 34 (Integración Correo/Calendarios office365), mete el office365:
    if (this.loginService.hasFeature("34")) this.mail_types.push({ label: this.translateService.instant("component.mailAccount.list.office365"), value: "office365" });

    //Si tiene el feature 42 (Integración MailChimp), mete el MailChimp:
    if (this.loginService.hasFeature("42")) this.mail_types.push({ label: this.translateService.instant("component.mailAccount.list.mailchimp"), value: "mailchimp" });

    //Si tiene el feature 43 (Integración Mailjet), mete el Mailjet:
    if (this.loginService.hasFeature("43")) this.mail_types.push({ label: this.translateService.instant("component.mailAccount.list.mailjet"), value: "mailjet" });

    //Si tiene el feature 46 (Integración Correo/Calendarios gmail), mete el gmail:
    if (this.loginService.hasFeature("46")) this.mail_types.push({ label: this.translateService.instant("component.mailAccount.list.gmail"), value: "gmail" });
}

  search() {
    this.lastGridEvent.first = 0;
    if (this.mailAccountsFilters.type == null) delete this.mailAccountsFilters.type;

    this.loadData(this.lastGridEvent);
  }

  clearFilters() {
    this.mailAccountsFilters = {};
    this.search();
  }

  loadData($event) {
    this.lastGridEvent = $event;
    Promise.resolve(null).then(() => this.loading = true);
    var params = {
      _page: $event.first,
      _pageSize: $event.rows,
      _sortBy: $event.sortField ? $event.sortField : "",
      _sortDirection: $event.sortOrder > 0 ? "asc" : "desc"
    };
    params = Object.assign(params, this.mailAccountsFilters);
    this.mailAccountsService.all(params).subscribe({
      next: (data: any) => {
        this.totalRecords = data.totalRows;
        this.mailAccounts = data.rows;
        Promise.resolve(null).then(() => this.loading = false);
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
        Promise.resolve(null).then(() => this.loading = false);
      }
    });
  }

  editMailAccount(mailAccount) {
    const ref = this.dialogService.open(MailAccountsDetailComponent, {
      data: {
        id: mailAccount ? mailAccount.id : 0
      },
      header: mailAccount.id ? this.translateService.instant("component.mailAccount.list.detail_entity") : this.translateService.instant("component.mailAccount.list.new_entity"),
      width: '50%',
    });
    ref.onClose.subscribe((data: any) => {
      if (typeof (this.view) !== "undefined") this.viewsService.changeView(this.view.code);
      /*if(data != null){
        this.loadData(this.lastGridEvent);
      }*/
      this.loadData(this.lastGridEvent);
    })
  }
  delete(mailAccount) {
    this.coreDialogService.confirm({
      message: this.translateService.instant("component.mailAccount.delete_message", { name: mailAccount.name }),
      header: this.translateService.instant("general.confirmation_delete_title"),
      icon: "pi pi-info-circle",
      accept: () => {
        this.mailAccountsService.delete(mailAccount.id).subscribe({
          next: (data: any) => {
            this.messageService.add({ closable: false, severity: 'success', detail: this.translateService.instant("general.confirmation_delete") });
            this.loadData(this.lastGridEvent);
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        });
      },
      reject: () => { }
    })
  }

  getAccountType(account:any) {
    var accountType = this.mail_types.find(m => m.value == account.type);
    return accountType?.label;
  }

  onEvent(event: any) {
    if (event.component == "mailAccounts.list.filters") {
      if (event.event == "action" && event.action.name == "search") this.view.fireEvent("mailAccounts.list.results", "reload");
    } else if (event.component == "mailAccounts.list.results") {
      if (event.event == "dblclick") this.editMailAccount(event.data);
      if (event.event == "action" && event.action.name == "add") this.editMailAccount(null);
      if (event.event == "action" && event.action.name == "edit") this.editMailAccount(event.data);
      if (event.event == "action" && event.action.name == "delete") this.delete(event.data);
    }
  }
}
