import { Component, OnInit, ViewChild } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { CoreDialogService } from 'src/app/core/dialogs/core-dialog.service';
import { ViewComponent } from 'src/app/core/view/view.component';
import { ViewsService } from 'src/app/core/view/views.service';
import { FamiliesDetailComponent } from '../families-detail/families-detail.component';
import { FamiliesService } from '../families.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-families-list',
  templateUrl: './families-list.component.html',
  styleUrls: ['./families-list.component.scss']
})
export class FamiliesListComponent implements OnInit {
  @ViewChild("view") public view: ViewComponent;
  public familiesFilters: any = {};

  constructor(
    private familiesService: FamiliesService,
    private viewsService: ViewsService,

    private messageService: MessageService,
    private coreDialogService: CoreDialogService,
    private dialogService: DialogService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
  }

  viewFamily(family) {
    const ref = this.dialogService.open(FamiliesDetailComponent, {
      data: {
        id: family ? family.id : 0
      },
      //header: family ? "Detalles de la familia" : "Nueva familia",
      width: '40%',
    });
    ref.onClose.subscribe((data: any) => {
      if (typeof (this.view) !== "undefined") this.viewsService.changeView(this.view.code);
      this.view.fireEvent("families.list.results", "reload");
    });
  }

  deleteFamily(family) {
    this.coreDialogService.confirm({
      message: this.translateService.instant("component.families.delete_message", { name: family.name }),
      header: this.translateService.instant("general.confirmation_delete_title"),
      icon: "pi pi-info-circle",
      accept: () => {
        this.familiesService.delete(family.id).subscribe({
          next: (data: any) => {
            this.messageService.add({ closable: false, severity: 'success', detail: this.translateService.instant("general.confirmation_delete") });
            this.view.fireEvent("families.list.results", "reload");
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        });
      },
      reject: () => { }
    });
  }

  onEvent(event: any) {
    if (event.component == "families.list.filters") {
      if (event.event == "action" && event.action.name == "search") this.view.fireEvent("families.list.results", "reload");
    } else if (event.component == "families.list.results") {
      if (event.event == "dblclick") this.viewFamily(event.data);
      if (event.event == "action" && event.action.name == "add") this.viewFamily(null);
      if (event.event == "action" && event.action.name == "edit") this.viewFamily(event.data);
      if (event.event == "action" && event.action.name == "delete") this.deleteFamily(event.data);
    }
  }
}
