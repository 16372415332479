import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, NgForm } from '@angular/forms';
import { MonacoEditorLoaderService, MonacoStandaloneCodeEditor } from '@materia-ui/ngx-monaco-editor';
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CoreDialogService } from 'src/app/core/dialogs/core-dialog.service';
import { ViewComponent } from 'src/app/core/view/view.component';
import { LoginService } from 'src/app/login/login.service';
import { EntitiesFieldsService } from '../entities-fields.service';
import { EntitiesService } from '../entities.service';
import { EntitiesDetailFieldsComponent } from './entities-detail-fields/entities-detail-fields.component';
import { TranslateService } from '@ngx-translate/core';
import { ComponentService } from '../../core/view/component.service';
import { DebugService } from '../../core/debug.service';

@Component({
  selector: 'app-entities-detail',
  templateUrl: './entities-detail.component.html',
  styleUrls: ['./entities-detail.component.scss']
})
export class EntitiesDetailComponent implements OnInit {
  public entitiesFields: any[];
  public entitiesFieldsForDuplicities: any[];
  public totalRecords: number;
  public loading: boolean = false;
  private lastGridEvent: any;
  public entity: any;
  public modelMonaco: any = {};
  public editorOptions = { theme: 'vs-dark', language: 'json' };
  public selectedEntities: any[] = [];

  public first;
  public last;

  //Permisos:
  public create_fields: boolean;
  public isSuperAdmin: boolean = false

  @ViewChild("formDetails") public formDetails: NgForm;


  constructor(
    private entitiesFieldsService: EntitiesFieldsService,
    private config: DynamicDialogConfig,
    private messageService: MessageService,
    private dialogService: DialogService,
    private coreDialogService: CoreDialogService,
    private entityService: EntitiesService,
    private entityFieldsService: EntitiesFieldsService,
    private loginService: LoginService,
    private dynamicDialogRef: DynamicDialogRef,
    private translateService: TranslateService,
    public componentService: ComponentService,
    public debugService: DebugService
  ) { }

  ngOnInit(): void {
    this.create_fields = this.loginService.hasPermission("CREATE_FIELDS");
    this.entity = { name: "" };
    this.isSuperAdmin = (localStorage.getItem("isSuperAdmin") === 'false' ? false : true);
  }
  loadData($event) {
    this.lastGridEvent = $event;
    Promise.resolve(null).then(() => this.loading = true);
    var params = {
      _page: $event.first,
      _pageSize: $event.rows,
      _sortBy: $event.sortField ? $event.sortField : "",
      _sortDirection: $event.sortOrder > 0 ? "asc" : "desc",
      entity_id: this.config.data.id
    }
    this.entityService.get(params.entity_id).subscribe({
      next: (data: any) => {
        this.entity = data;
        this.entity.name = this.componentService.getEntityName(this.entity, false);
        this.entity.name_plural = this.componentService.getEntityName(this.entity, true);

        this.loadEntitiesFieldsForDuplicities();
        this.modelMonaco = (this.entity.metadata != null ? JSON.stringify(this.entity.metadata, null, "\t") : JSON.stringify({}, null, "\t"));
        if (this.entity.metadata != null) this.selectedEntities = this.entity.metadata.duplicities;
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
      }
    });
    this.entitiesFieldsService.all(params).subscribe({
      next: (data: any) => {
        this.totalRecords = data.totalRows;
        this.entitiesFields = data.rows;
        this.entitiesFields.forEach((item: any) => {
          item.entity_code = item.entity.code;
          item.description = this.componentService.getFieldDescription(item, false);

          if (this.debugService.enabled) item.description += "  (" + (item.entity_code + ".entity.name") + ")";
        });
        Promise.resolve(null).then(() => this.loading = false);
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
        Promise.resolve(null).then(() => this.loading = false);
      }
    });
  }

  loadEntitiesFieldsForDuplicities() {
    this.entitiesFieldsService.all({ entity_id: this.config.data.id }).subscribe({
      next: (data: any) => {
        this.entitiesFieldsForDuplicities = data.rows;
        Promise.resolve(null).then(() => this.loading = false);
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
        Promise.resolve(null).then(() => this.loading = false);
      }
    });
  }

  edit(entityField) {
    if (!entityField) entityField = { id: 0 };
    const ref = this.dialogService.open(EntitiesDetailFieldsComponent, {
      data: {
        id: entityField.id,
        entity_id: this.config.data.id,
        entity_field_id: entityField.entity_field_id
      },
      width: '70%',
    });
    ref.onClose.subscribe((data: any) => {
      this.loadData(this.lastGridEvent);
    });
  }
  editorInit(editor: MonacoStandaloneCodeEditor) { }

  onMonacoChange(event) {
    this.entity.metadata = JSON.parse(this.modelMonaco);
  }

  save() {
    this.entity.metadata = JSON.parse(this.modelMonaco);
    this.entity.metadata.duplicities = this.selectedEntities;
    this.entityService.save(this.entity.id, this.entity).subscribe({
      next: (data: any) => {
        this.messageService.add({ closable: false, severity: "success", detail: this.translateService.instant("component.entity.detail.save_correct") });
        location.reload();
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
      }
    });
  }

  onRowReorder(event: any) {
    if (event.dragIndex != event.dropIndex) {
      this.entityFieldsService.reorderFields(event.dragIndex, event.dropIndex, this.entity.id).subscribe({
        next: (data: any) => {
          //this.messageService.add({ closable: false, severity: 'success', detail: "Acción realizada correctamente" });
          this.loadData(this.lastGridEvent);
        },
        error: (error: any) => {
          this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
        }
      });
    }
  }

  delete(entityField) {
    this.coreDialogService.confirm({
      message: this.translateService.instant("component.entity.delete_message", { name: entityField.name }),
      header: this.translateService.instant("general.confirmation_delete_title"),
      icon: "pi pi-info-circle",
      accept: () => {
        this.entitiesFieldsService.delete(entityField.id).subscribe({
          next: (res: any) => {
            this.messageService.add({ closable: false, severity: 'success', detail: this.translateService.instant("general.confirmation_delete") });
            this.loadData(this.lastGridEvent);
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        });
      },
      reject: () => {

      }
    })
  }
  cancel() {
    this.dynamicDialogRef.close();
  }
  onEvent(event) {

    if (event.component == "attachments.edit.general") {
      if (event.event == "onChange") { };
    }

    if (event.component == "view" && event.event == "entity-loaded") {
      //this.config.header = typeof (this.entity.id) !== "undefined" ? "Detalles de la " + this.view.getEntityBaseName() : "Nueva " + this.view.getEntityBaseName();
    }
  }
}

