import { Component, OnInit, ViewChild } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { CoreDialogService } from 'src/app/core/dialogs/core-dialog.service';
import { ViewComponent } from 'src/app/core/view/view.component';
import { TasksActionTypesService } from 'src/app/tasks/tasks-action-types.service';
import { TasksActionsTypesDetailComponent } from '../tasks-actions-types-detail/tasks-actions-types-detail.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-tasks-actions-types-list',
  templateUrl: './tasks-actions-types-list.component.html',
  styleUrls: ['./tasks-actions-types-list.component.scss']
})
export class TasksActionsTypesListComponent implements OnInit {

  @ViewChild("view") public view: ViewComponent;
  public tasksActionsTypesFilters: any = {};
  public tasksActionsTypes: any[];
  private lastGridEvent: any;
  public totalRecords: number;
  public loading: boolean = false;

  constructor(
    private tasksActionTypesService: TasksActionTypesService,
    private coreDialogService: CoreDialogService,
    private dialogService: DialogService,
    private messageService: MessageService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
  }

  loadData($event) {
    this.lastGridEvent = $event;
    Promise.resolve(null).then(() => this.loading = true);
    var params = {
      _page: $event.first,
      _pageSize: $event.rows,
      _sortBy: $event.sortField ? $event.sortField : "",
      _sortDirection: $event.sortOrder > 0 ? "asc" : "desc"
    }
    //params = Object.assign(params, this.organizationsFilters);
    this.tasksActionTypesService.all({}).subscribe({
      next: (data: any) => {
        this.totalRecords = data.totalRows;
        this.tasksActionsTypes = data.rows;
        Promise.resolve(null).then(() => this.loading = false);
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
        Promise.resolve(null).then(() => this.loading = false);
      }
    });
  }

  edit(taskActionType){
    const ref = this.dialogService.open(TasksActionsTypesDetailComponent,{
      data:{
        id: taskActionType!=null?taskActionType.id:null
      },
      header: taskActionType != null ? this.translateService.instant("component.tasksActionsTypes.edit_tasksActionsTypes") : this.translateService.instant("component.tasksActionsTypes.add_tasksActionsTypes"),
      width: '30%'
    });
    ref.onClose.subscribe((data:any) =>{
      this.loadData(this.lastGridEvent);
    })
  }
  delete(taskActionType){
    this.coreDialogService.confirm({
      message: this.translateService.instant("component.tasksActionsTypes.delete_message", { name: taskActionType.name }),
      header: this.translateService.instant("general.confirmation_delete_title"),
      icon: 'pi pi-info-circle',
      accept:()=>{
        this.tasksActionTypesService.delete(taskActionType.id).subscribe({
          next: (data: any) => {
            this.loadData(this.lastGridEvent);
            this.messageService.add({ closable: false, severity: "success", detail: this.translateService.instant("general.confirmation_delete") });
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
          }
        });
      },
      reject:()=>{
        
      }
    })
  }
}
