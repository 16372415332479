<p-panel header="Component debug" [toggleable]="true" [collapsed]="true" *ngIf="debugService.enabled" styleClass="p-panel-debug p-mb-4">
  <pre>{{component | json}}</pre>
</p-panel>
<p-panel header="Model debug" [toggleable]="true" [collapsed]="true" *ngIf="debugService.enabled" styleClass="p-panel-debug p-mb-4">
  <pre>{{model | json}}</pre>
</p-panel>

<div>
  <div class="fixed-toolbar border-top border-bottom p-pb-3">
    <div class="p-d-flex p-pt-3 p-pl-3 p-pr-3">

      <a class="p-button p-button-primary p-button-text " label="Limpiar filtros" (click)="resetFilters()"><i class="pi pi-filter-slash"></i>&nbsp;{{'general.filter.clean' | translate}}</a>
      <div class="flex-grow-1"></div>
      <a class="p-mr-1 p-button p-button-primary p-button-text" label="Buscar" (click)="openModalFilters($event)"> <i class="fas fa-plus"></i>&nbsp;{{'general.filter.add' | translate}} </a>
      <a *ngIf="!((allowViewPersonalize && personalized_views_write && personalize_interface) || (allowViewPersonalize && views_superadmin))" class="p-button" (click)="actionSearch()"> {{'general.filter.search' | translate}} </a>

      <p-splitButton appendTo="body"  styleClass="spliButton" *ngIf="(allowViewPersonalize && personalized_views_write && personalize_interface) || (allowViewPersonalize && views_superadmin)" label="{{'general.filter.search' | translate}}" (onClick)="actionSearch()" [model]="items"></p-splitButton>

    </div>
  </div>
  <div class="p-d-flex p-flex-column " style="scroll-behavior:auto">
    <div *ngIf="selectedEntitiesFieldsFilter.length <= 0" style=" text-align: center; padding: 30px;">{{'general.filter.no_fields' | translate}}</div>
    <div *ngFor="let field of selectedEntitiesFieldsFilter" class="p-field p-p-3 p-m-0 border-bottom">
      <label>{{componentService.getEntityName(field.entity)}}: {{componentService.getFieldDescription(field, false)}}</label>

      <input *ngIf="field.control_type=='input-text' || field.control_type=='input-textarea'"
             name="{{field.model_property}}"
             pInputText
             [(ngModel)]="model[field.model_property]"
             (keyup.enter)="actionSearch()"
             [ngModelOptions]="{standalone: true}" />

      <div *ngIf="field.control_type=='input-number'" class="p-d-flex">
        <input name="{{field.model_property}}_from" placeholder="Desde"
               type="number"
               [(ngModel)]="model[field.model_property + '_from']" [ngModelOptions]="{standalone: true}"
               class="p-inputtext" />
        <span>&nbsp;-&nbsp;</span>
        <input name="{{field.model_property}}_to" placeholder="Hasta"
               type="number"
               [(ngModel)]="model[field.model_property + '_to']" [ngModelOptions]="{standalone: true}"
               class="p-inputtext" />
      </div>
      <app-view-component-datetime *ngIf="(field.control_type=='input-date' || field.control_type=='input-datetime' )"
                                   name="{{field.model_property}}"
                                   [(ngModel)]="model[field.model_property]"
                                   [ngModelOptions]="{standalone: true}"
                                   [range]="true">
      </app-view-component-datetime>

      <p-colorPicker *ngIf="field.control_type=='input-colorpicker'"
                     name="{{field.model_property}}"
                     [(ngModel)]="model[field.model_property]" appendTo="body"></p-colorPicker>

      <!-- Puesto un selector multiple
        <p-checkbox *ngIf="field.control_type=='checkbox' || field.control_type=='input-switch'"
                  binary="true" name="{{field.model_property}}"
                  [(ngModel)]="model[field.model_property]" [ngModelOptions]="{standalone: true}">
      </p-checkbox>
        -->
    <div *ngIf="field.control_type=='checkbox' || field.control_type=='input-switch'">
      <p-dropdown name="{{field.model_property}}" [(ngModel)]="model[field.model_property]" [ngModelOptions]="{standalone: true}"
                  [options]="[{name: 'Todos', code: null}, {name: 'Si',code: true},{name: 'No', code: false}]"
                  appendTo="body" optionLabel="name" optionValue="code">
      </p-dropdown>
    </div>

      <p-multiSelect *ngIf="(field.control_type=='dropdown-multiple' ||field.control_type=='dropdown') "
                     name="{{field.model_property}}"
                     [(ngModel)]="model[field.model_property]"
                     [ngModelOptions]="{standalone: true}"
                     (onFilter)="onModelChange($event, field)"
                     [options]="temporalCombos[field.model_property]"
                     optionValue="{{field.configuration?.field_value}}"
                     optionLabel="{{field.configuration?.field_text}}" placeholder="{{'general.select' | translate}}" [filter]="true"
                     [showClear]="true" appendTo="body"></p-multiSelect>

    </div>


    <div class="p-d-flex p-jc-center">
      <!--
    <p-button *ngIf="!((view.id && view.user_id == created_by && personalize_interface) || (view.user_id && views_superadmin) || (views_superadmin && company.is_template))" class="p-button p-button-link" (click)="newView()"><fa-icon [icon]="['fas', 'save']"></fa-icon>&nbsp; Guardar filtros</p-button>
    <p-button *ngIf="(view.id && view.user_id == created_by && personalize_interface) || (view.user_id && views_superadmin) || (views_superadmin && company.is_template)" class="p-button p-button-link" (click)="editViewClick(false)"><fa-icon [icon]="['fas', 'save']"></fa-icon>&nbsp; Guardar filtros</p-button>
      -->
    </div>
  </div>
  </div>


<p-overlayPanel appendTo="body" #opFilter [style]="{ width: '300px' }">
  <ng-template pTemplate>
    <p-accordion>
      <p-accordionTab *ngFor="let allowedEntity of allowedEntitiesFilter">
        <ng-template pTemplate="header">{{componentService.getEntityName(allowedEntity)}}</ng-template>
        <ng-template pTemplate="content">
          <p-scrollPanel [style]="{width: '100%', height: '200px'}">
            <div *ngFor="let field of allowedEntitiesFieldsFilter[allowedEntity.code]">
              <div *ngIf="componentService.isVisibleFieldCustom({ entityField: field }, null)" class="p-d-flex p-m-2">
                <p-checkbox [binary]="true" [(ngModel)]="field.selected" label="{{componentService.getFieldDescription(field, false)}}" (onChange)="onFieldFilterChange($event, field, allowedEntity.code)"></p-checkbox>
              </div>
            </div>
          </p-scrollPanel>
        </ng-template>
      </p-accordionTab>
    </p-accordion>
  </ng-template>
</p-overlayPanel>
