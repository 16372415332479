import { Component, OnInit, ViewChild } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { CoreDialogService } from 'src/app/core/dialogs/core-dialog.service';
import { ViewComponent } from 'src/app/core/view/view.component';
import { TemplatesDetailComponent } from '../templates-detail/templates-detail.component';
import { TemplatesService } from '../templates.service';
import { BeeService } from '../../../core/bee.service';
import { TranslateService } from '@ngx-translate/core';
import { LoginService } from '../../../login/login.service';

@Component({
  selector: 'app-templates-list',
  templateUrl: './templates-list.component.html',
  styleUrls: ['./templates-list.component.scss']
})
export class TemplatesListComponent implements OnInit {
  @ViewChild("view") public view: ViewComponent;
  public templatesFilters: any = {};
  public templates: any[] = [];
  public totalRecords: number;
  public loading: boolean = false;
  public lastGridEvent: any;


  public first;
  public last;
  public template_type_id: number = null;
  private editor: any;


  constructor(
    private templatesService: TemplatesService,
    private coreDialogService: CoreDialogService,
    private messageService: MessageService,
    private dialogService: DialogService,
    private beeService: BeeService,
    private translateService: TranslateService,
    private loginService: LoginService
  ) { }

  ngOnInit(): void {
  }
  loadData($event) {
    this.lastGridEvent = $event;
    Promise.resolve(null).then(() => this.loading = true);
    var params = {
      _page: $event.first,
      _pageSize: $event.rows,
      _sortBy: $event.sortField ? $event.sortField : "",
      _sortDirection: $event.sortOrder > 0 ? "asc" : "desc"
    };
    params = Object.assign(params, this.templatesFilters);
    this.templatesService.all(params).subscribe(
      data => {
        this.totalRecords = data.totalRows;
        this.templates = data.rows;
        Promise.resolve(null).then(() => this.loading = false);
      },
      error => {
        this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
        Promise.resolve(null).then(() => this.loading = false);
      }
    );
  }
  search() {
    this.view.fireEvent("templates.list.results", "reload");
  }
  clearFilters() {
    this.templatesFilters = {};
  }
  delete(template) {
    this.coreDialogService.confirm({
      message: this.translateService.instant("component.templates.delete_message", { name: template.name }),
      header: this.translateService.instant("general.confirmation_delete_title"),
      icon: 'pi pi-info-circle',
      accept: () => {
        this.templatesService.delete(template.id).subscribe({
          next: (data: any) => {
            this.messageService.add({ closable: false, severity: "success", detail: this.translateService.instant('component.templates.delete_template_correct') });
            this.view.fireEvent("templates.list.results", "reload");
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
          }
        });
      },
      reject: () => {

      }
    });
  }
  edit(template) {
    const ref = this.dialogService.open(TemplatesDetailComponent, {
      data: {
        id: template != null ? template.id : null,
        template_type_id: this.template_type_id
      },
      header: template != null ? this.translateService.instant('component.templates.edit_template') : this.translateService.instant('component.templates.add_template'),
      width: '90%'
    });
    ref.onClose.subscribe((data: any) => {
      this.view.fireEvent("templates.list.results", "reload");
    });
  }
  onEvent(event: any) {
    if (event.component == "templates.list.filters") {
      if (event.event == "onChange") {
        this.template_type_id = event.data.template_type_id;
        this.templatesFilters = Object.assign(this.templatesFilters, { entity_id: event.data.entity_id });
      }
      if (event.event == "action" && event.action.name == "search") this.view.fireEvent("templates.list.results", "reload");
    } else if (event.component == "templates.list.results") {
      if (event.event == "dblclick") this.edit(event.data);
      if (event.event == "action" && event.action.name == "add") this.edit(null);
      if (event.event == "action" && event.action.name == "edit") this.edit(event.data);
      if (event.event == "action" && event.action.name == "delete") this.delete(event.data);
    }
  }
}


