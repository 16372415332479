<div>

  <p-card>
    <div class="p-d-flex user-config">
      <img src="/assets/img/no-user.jpeg">
      <div class="p-ml-2 p-flex-grow-1 p-d-flex p-flex-column">
        <h2 class="p-m-0">{{user.name}}</h2>
        <small><fa-icon [icon]="['fas', 'key']"></fa-icon>
          <div *ngIf="user.connected">
            {{user.id}}
          </div>
          <div *ngIf="!user.connected">
            {{'component.user_config.not_connected'|translate}}
          </div>
        </small>
        <small><fa-icon [icon]="['fas', 'envelope']"></fa-icon>
          <div *ngIf="user.connected">
            {{user.userPrincipalName}}
          </div>
          <div *ngIf="!user.connected">
            {{'component.user_config.not_connected'|translate}}
          </div>
        </small>
      </div>
    </div>
  </p-card>

  <p-card styleClass="p-mt-4">
    <ng-template pTemplate="header">
      <div class="p-card-title p-d-flex">
        {{'mailAccount.entity.name'|translate}}
      </div>
    </ng-template>
    <div class="p-grid">
      <div class="p-col-12 p-field p-m-0">
        <label>{{'component.user_config.allow_send_mail_with_account'|translate}}</label>
        <p-checkbox [binary]="true" [(ngModel)]="user.mail.allow_send"></p-checkbox>
      </div>
    </div>
  </p-card>

  <p-card styleClass="p-mt-4">
    <ng-template pTemplate="header">
      <div class="p-card-title p-d-flex">
        <div class="flex-grow-1">{{'component.user_config.contacts_synchronization'|translate}}</div>
        <div>
          <p-inputSwitch [(ngModel)]="user.contacts.enabled"></p-inputSwitch>
        </div>
      </div>
    </ng-template>
    <div *ngIf="user.contacts.enabled" class="p-grid">
      <div class="p-col-12 p-field p-m-0">
        <label>{{'component.user_config.synchronization_mode'|translate}}</label>
        <p-dropdown placeholder="{{'general.select'|translate}}" [options]="contactsModes"
          [(ngModel)]="user.contacts.mode" optionLabel="name" optionValue="value"></p-dropdown>
      </div>
      <div class="p-col-12 p-field p-m-0">
        <label>{{'component.user_config.delete_contacts_office365'|translate}}</label>
        <p-checkbox [binary]="true" [(ngModel)]="user.contacts.remote_delete"></p-checkbox>
      </div>
    </div>
    <div *ngIf="!user.contacts.enabled" class="p-grid">
      <div class="p-col-12">
        <small>{{'component.user_config.synchronization_disabled'|translate}}</small>
      </div>
    </div>
  </p-card>


  <p-card styleClass="p-mt-4">
    <ng-template pTemplate="header">
      <div class="p-card-title p-d-flex">
        <div class="flex-grow-1">{{'component.user_config.email_synchronization'|translate}}</div>
        <div>
          <p-inputSwitch [(ngModel)]="user.mail.enabled"></p-inputSwitch>
        </div>
      </div>
    </ng-template>
    <div *ngIf="user.mail.enabled" class="p-grid">
      <div class="p-col-12 p-field p-m-0">
        <label>{{'component.user_config.incoming_email_synchronization'|translate}}</label>
        <p-dropdown placeholder="{{'general.select'|translate}}" [options]="mailModes"
          [(ngModel)]="user.mail.mode_inbound" optionLabel="name" optionValue="value"></p-dropdown>
      </div>
      <div class="p-col-12 p-field p-m-0">
        <label>{{'component.user_config.outgoing_email_synchronization'|translate}}</label>
        <p-dropdown placeholder="{{'general.select'|translate}}" [options]="mailModes"
          [(ngModel)]="user.mail.mode_outbound" optionLabel="name" optionValue="value"></p-dropdown>
      </div>
    </div>
    <div *ngIf="!user.mail.enabled" class="p-grid">
      <div class="p-col-12">
        <small>{{'component.user_config.synchronization_disabled'|translate}}</small>
      </div>
    </div>
  </p-card>


  <p-card styleClass="p-mt-4">
    <ng-template pTemplate="header">
      <div class="p-card-title p-d-flex">
        <div class="flex-grow-1">{{'component.user_config.calendar_synchronization'|translate}}</div>
        <div>
          <p-inputSwitch [(ngModel)]="user.calendar.enabled"></p-inputSwitch>
        </div>
      </div>
    </ng-template>
    <div *ngIf="user.calendar.enabled" class="p-grid">
      <div class="p-col-12 p-field p-m-0">
        <label>{{'component.user_config.synchronization_mode'|translate}}</label>
        <p-dropdown placeholder="{{'general.select'|translate}}" [options]="calendarModes"
          [(ngModel)]="user.calendar.mode" optionLabel="name" optionValue="value"></p-dropdown>
      </div>
      <div class="p-col-12 p-field p-m-0" *ngIf="user.calendar.mode=='<' || user.calendar.mode=='<=>'">
        <label>{{'component.user_config.download_not_categorized_meetings'|translate}}</label>
        <p-checkbox [binary]="true" [(ngModel)]="user.calendar.sync_all"></p-checkbox>
      </div>
    </div>
    <div *ngIf="!user.calendar.enabled" class="p-grid">
      <div class="p-col-12">
        <small>{{'component.user_config.synchronization_disabled'|translate}}</small>
      </div>
    </div>
  </p-card>
</div>

<div class="p-col-12 p-d-flex p-mt-4">
  <div class="flex-grow-1"></div>
  <button pButton label="{{'general.action.cancel' | translate}}" icon="pi pi-times"
    styleClass="p-button-secondary p-button-text" (click)="cancel()"></button>
  <button class="p-ml-2" style="float: right;" icon="pi pi-save" pButton type="button"
    label="{{'general.action.save' | translate}}" (click)="save()"></button>
</div>