import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AttachmentsService } from 'src/app/attachments/attachments.service';

@Component({
  selector: 'app-mailing-attachments',
  templateUrl: './mailing-attachments.component.html',
  styleUrls: ['./mailing-attachments.component.scss']
})
export class MailingAttachmentsComponent implements OnInit {

  public entity_id: number;
  public entity_pk_id: number;
  public organization_id: number;
  public is_generic: boolean;
  public attachments: any[] = [];
  public selectedAttachments: any[];
  public attachmentsTypes: any[];
  constructor(
    private messageService: MessageService,
    private dynamicDialogRef: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public attachmentsService: AttachmentsService,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
    this.entity_id = this.config.data.entity_id;
    this.entity_pk_id = this.config.data.entity_pk_id;
    this.organization_id = this.config.data.organization_id;
    this.is_generic = this.config.data.is_generic;
    this.loadAttachments();
  }

  loadAttachments() {
    var aux: any = {};
    if (this.organization_id != null) {
      aux = { organization_id: this.organization_id, show_in_axial_files: true };
    } else {
      aux = { show_in_axial_files: true };
    }
    if (this.is_generic) aux.is_generic = this.is_generic;

    this.attachmentsService.all(aux).subscribe({
      next: (data: any) => {
        this.attachments = data.rows;

        let aux = this.attachments.map(m => m.attachmentType);
        this.attachmentsTypes = aux.filter((v, i) => v != null && aux.findIndex(item => item.id == v.id) === i);
        this.attachmentsTypes.push({ id: null, name: this.translateService.instant("component.mailing-attachments.unclassified") });
        //this.loadAttachmentsData();
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
      }
    });
  }
  getAttachments(attachment_type_id) {
    return this.attachments.filter(m => m.attachment_type_id == attachment_type_id);
  }
  selectAttachemnts() {
    let resultAttach = this.selectedAttachments.map(id => {
      let existAttach = this.attachments.filter(m => m.id == id);
      if (existAttach.length > 0) {
        if (existAttach[0].description) {
          existAttach[0].filename = existAttach[0].description;
          existAttach[0].name = existAttach[0].description;
        }
        return existAttach[0];
      }
    });

    this.dynamicDialogRef.close(resultAttach);
  }

  cancel() {
    this.dynamicDialogRef.close();
  }

  getImage(mimetype) {
    var ret = "other.png";
    if (mimetype == "application/pdf") {
      ret = "pdf.png";
    } else if (mimetype == "image/png" || mimetype == "image/jpeg" || mimetype == "image/jpg" || mimetype == "image/gif" || mimetype == "image/bmp") {
      ret = "img.png";
    } else if (mimetype == "application/msword" || mimetype == "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
      ret = "word.png";
    } else if (mimetype == "application/vnd.ms-excel" || mimetype == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
      ret = "xls.png";
    } else if (mimetype == "application/vnd.ms-powerpoint" || mimetype == "application/vnd.openxmlformats-officedocument.presentationml.presentation") {
      ret = "pwt.png";
    }
    return ret;
  }

}
