import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Components
import { LoginRoutingModule } from './login-routing.module';
import { LoginComponent } from './login-form/login-form.component';
import { InputTextModule } from 'primeng/inputtext';
import { PasswordModule } from 'primeng/password';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { ToastModule } from 'primeng/toast';
import { MessageModule } from 'primeng/message';
import { CheckboxModule } from 'primeng/checkbox';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { LoginChangeComponent } from './session-change/login-change.component';
import { DropdownModule } from 'primeng/dropdown';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    imports: [
      CommonModule,
      FormsModule,
      ReactiveFormsModule,
      LoginRoutingModule,
      InputTextModule,
      PasswordModule,
      ButtonModule,
      CardModule,
      ToastModule,
      CheckboxModule,
      MessageModule,
    DropdownModule,
    TranslateModule
    ],
    declarations: [
      LoginComponent,
      ForgetPasswordComponent,
      LoginChangeComponent
    ]
  })
  export class LoginModule {
  }
