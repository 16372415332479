<ul class="breadcrumb">
    <li><a [routerLink]="['/home']"><span class="color-soft-grey-axial">{{'general.breadcrumb.you_are' | translate}}
            </span>{{'general.breadcrumb.home' | translate}}</a></li>
    <li><i class="pi pi-angle-right"></i></li>
    <li><a [routerLink]="['/campaigns']">{{view.getEntityBaseName(true)}}</a></li>
    <li><i class="pi pi-angle-right"></i></li>
    <li>
        <a [routerLink]="['/campaigns', campaign.id]">{{campaign.name}}</a>
    </li>
</ul>
<app-view #view [code]="'campaigns.edit'" [model]="campaign" (onEvent)="onEvent($event)"></app-view>