<div class="p-field" *ngIf="contacts.length > 0" style="margin-bottom: 15px;">
  <h4 style="margin-bottom: 5px;">Contactos de la empresa</h4>
  <div>
    <p-table [value]="contacts" [scrollable]="true" scrollHeight="380px">
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 50px;"></th>
          <th>{{'contact.entity_fields.email'|translate}}</th>
          <th>{{'contact.entity_fields.name'|translate}}</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-contact>
        <tr>
          <td style="width: 50px;">
            <p-checkbox name="contact-{{contact.id}}" value="{{contact.id}}" [(ngModel)]="resultsEmails.contacts"
              [inputId]="'contact-' + contact.id"></p-checkbox>
          </td>
          <td>{{contact.email}}</td>
          <td>{{contact.fullname}}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>

<div class="p-field" *ngIf="users.length > 0">
  <h4 style="margin-bottom: 5px;">{{'component.user_searcher.users'|translate}}</h4>
  <div>
    <p-table [value]="users" [scrollable]="true" scrollHeight="380px">
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 50px;"></th>
          <th>{{'contact.entity_fields.email'|translate}}</th>
          <th>{{'contact.entity_fields.name'|translate}}</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-user>
        <tr>
          <td style="width: 50px;">
            <p-checkbox name="user-{{user.id}}" value="{{user.id}}" [(ngModel)]="resultsEmails.users"
              [inputId]="'user-' + user.id"></p-checkbox>
          </td>
          <td>{{user.email}}</td>
          <td>{{user.name}}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
<div class="p-field" *ngIf="contacts.length == 0">
  <h4 style="margin-bottom: 5px;">{{'component.user_searcher.search_contacts_on_all_tool'|translate}}</h4>
  <p-autoComplete [(ngModel)]="selectedItem" [suggestions]="searchResults" class="topAutoComplete"
    (completeMethod)="filterSearch($event)" field="email" [multiple]="false" (onSelect)="addElementToArray($event)"
    [style]="{'margin-bottom':'10px'}"></p-autoComplete>

  <p-table [value]="others" [scrollable]="true" scrollHeight="380px">
    <ng-template pTemplate="header">
      <tr>
        <th width="50"></th>
        <th>{{'contact.entity_fields.email'|translate}}</th>
        <th>{{'contact.entity_fields.name'|translate}}</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-other>
      <tr>
        <td width="50">
          <p-checkbox name="other-{{other.id}}" value="{{other.id}}" [(ngModel)]="resultsEmails.others"
            [inputId]="'other-' + other.id"></p-checkbox>
        </td>
        <td>{{other.email}}</td>
        <td>{{other.name}}</td>
      </tr>
    </ng-template>
  </p-table>

</div>
<div>
  <button pButton class="p-ml-2" style="float: right;" (click)="save()" icon="pi pi-check"
    label="{{'general.action.save' | translate}}"></button>
  <button pButton class="p-button-secondary" style="float: right;" (click)="cancel()" icon="pi pi-times"
    label="{{'general.action.cancel' | translate}}"></button>
</div>