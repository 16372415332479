<div class="p-grid">
  <div class="p-col-12">
    <app-view #view [code]="'transactionStatus.edit'" [model]="transactionStatus" (onEvent)="onEvent($event)">
    </app-view>
  </div>
  <div class="p-col" *ngIf="transactionStatus.block_pipeline">
    <!--app-transaction-status-blocking-roles></app-transaction-status-blocking-roles-->
    <p-card styleClass="p-card-content-fit">
      <ng-template pTemplate="header">
        <div class="p-card-title">
          {{'component.transactionsStatus.detail.not_bloqued' | translate}}
        </div>
      </ng-template>
      <div class="p-grid">
        <div class="p-col-12 p-field">
          <label for="roles">{{'component.transactionsStatus.detail.roles' | translate}}</label>
          <p-dropdown name="roles" [options]="roles" [(ngModel)]="selected_role_status"
            placeholder="{{ 'general.select' | translate}}" optionLabel="name"
            (onChange)="onChangeRole($event, 'status')" appendTo="body">
          </p-dropdown>
        </div>
        <div class="p-col-12">
          <p-table #grid [value]="transactionStatus.metadata.rolesStatus" [paginator]="true" [rows]="10"
            [totalRecords]="totalRecords" [showCurrentPageReport]="true"
            currentPageReportTemplate="{{ 'general.resumen_table_records' | translate: { first: first, last: last, totalRecords: totalRecords } }}"
            [rowsPerPageOptions]="[5,10,25,50]" paginatorDropdownAppendTo="body">
            <ng-template pTemplate="header">
              <tr>
                <th pSortableColumn="id" width="15%">ID</th>
                <th pSortableColumn="name" style="width: 35%;">
                  {{'transactionsStatus.entity_fields.name' | translate}}
                </th>
                <th pSortableColumn="confirm" width="35%">
                  {{'component.transactionsStatus.detail.skip_confirmation' | translate}}
                </th>
                <th width="15%"></th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-role>
              <tr>
                <td>{{role.id}}</td>
                <td>{{role.name}}</td>
                <td>
                  <p-checkbox id="confirmation" name="confirmation" [(ngModel)]="role.skipConfirmation"
                    binary="true"></p-checkbox>
                </td>
                <td class="grid-actions">
                  <!--a (click)="edit(role)">
                          <fa-icon [icon]="['far', 'edit']"></fa-icon>
                      </a-->
                  <a (click)="deleteRole(role,'status')" class="color-danger">
                    <fa-icon [icon]="['far', 'trash-alt']"></fa-icon>
                  </a>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </p-card>
  </div>
  <div class="p-col" *ngIf="transactionStatus.block_data">
    <p-card styleClass="p-card-content-fit">
      <ng-template pTemplate="header">
        <div class="p-card-title">

          {{'component.transactionsStatus.detail.not_bloqued_data' | translate}}
        </div>
      </ng-template>
      <div class="p-grid">
        <div class="p-col-12 p-field">
          <label for="roles">Roles</label>
          <p-dropdown name="roles" [options]="roles" [(ngModel)]="selected_role_data"
            placeholder="{{ 'general.select' | translate}}" optionLabel="name" (onChange)="onChangeRole($event, 'data')"
            appendTo="body">
          </p-dropdown>
        </div>
        <div class="p-col-12">
          <p-table #grid [value]="transactionStatus.metadata.rolesData" [paginator]="true" [rows]="10"
            [totalRecords]="totalRecords" [showCurrentPageReport]="true"
            currentPageReportTemplate="{{ 'general.resumen_table_records' | translate: { first: first, last: last, totalRecords: totalRecords } }}"
            [rowsPerPageOptions]="[5,10,25,50]" paginatorDropdownAppendTo="body">
            <ng-template pTemplate="header">
              <tr>
                <th pSortableColumn="id" width="100">
                  {{'transactionsStatus.entity_fields.id' | translate}}
                </th>
                <th pSortableColumn="name" style="width: 75%;">
                  {{'transactionsStatus.entity_fields.name' | translate}}
                </th>
                <th width="100"></th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-role>
              <tr>
                <td>{{role.id}}</td>
                <td>{{role.name}}</td>
                <td class="grid-actions">
                  <!--a (click)="edit(role)">
                          <fa-icon [icon]="['far', 'edit']"></fa-icon>
                      </a-->
                  <a (click)="deleteRole(role, 'data')" class="color-danger">
                    <fa-icon [icon]="['far', 'trash-alt']"></fa-icon>
                  </a>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </p-card>
  </div>
</div>
<!--div class="p-grid">
  <div class="p-col p-field">
    <p-checkbox style="float:left;" id="block_pipeline" name="block_pipeline"
      [(ngModel)]="transactionStatus.block_pipeline" binary="true" inputId="binary"></p-checkbox>
    <label for="remember" style="float:left;"> Bloquear pipeline</label>
  </div>


  <div class="p-grid">
    <h4>Seleccionar los roles que estarán exentos del bloqueo.</h4>
    <div class="p-col-6">
      <div class="p-col-12 p-field">
        <p-dropdown appendTo="body" [options]="roles" [(ngModel)]="selected_role" optionLabel="name"
          placeholder="{{'general.select'|translate}}" (onChange)="save_role()" name="selected_role">
        </p-dropdown>
      </div>
      <div class="p-col-12">
        <p-table #grid [value]="selected_roles">
          <ng-template pTemplate="header">
            <tr>
              <th>Nombre</th>
              <th width="100"></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-role>
            <tr>
              <td>{{role.name}}</td>
              <td class="grid-actions">
                <a (click)="unlinkRole(role)" class="color-danger">
                  <fa-icon [icon]="['far', 'trash-alt']"></fa-icon>
                </a>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td [attr.colspan]="3">
                No se encontraron datos
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
</div-->