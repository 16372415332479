import { animate, animateChild, group, query, sequence, style, transition, trigger } from "@angular/animations";

export const slideInAnimation =
  trigger('routeAnimations', [
    transition('* <=> login', [
      query(':enter', [
      style({ position: 'absolute', transform: 'scale(.97)', opacity: 0 }),
      animate('0.2s', style({ opacity: 1, transform: 'scale(1)' })),
      ], { optional: true })
    ]),
    transition('* <=> *', [
        query(':enter', [
        style({ position: 'absolute', width: '100%', paddingRight:'64px', transform: 'scale(.97)', opacity: 0 }),
        animate('0.2s', style({ opacity: 1, transform: 'scale(1)' })),
        ], { optional: true })
      ])
  ]);