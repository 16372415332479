import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FeaturesService {

  url = "/api/features";

  constructor(private http: HttpClient) { }

  all(params): Observable<any> {
    return this.http.get<any>(this.url, { params: params });
  }

  get(id): Observable<object> {
    return this.http.get<object>(this.url + "/" + id);
  }

  save(id, obj): Observable<object> {
    if(id==null || id == 0 || typeof id == "undefined"){
      return this.add(obj);
    }else{
      return this.http.put<object>(this.url + "/" + id, obj);
    }
  }

  add(obj): Observable<object> {
    return this.http.post<object>(this.url, obj);
  }

  delete(id): Observable<object> {
    return this.http.delete<object>(this.url + "/" + id);
  }
  getMonthlyPrice(params): Observable<object>{
    return this.http.get<object>(this.url + "/getMonthlyPrice", {params:params});
  }

}
