<p-card *ngIf="!integration.configuration.auth.enabled">
    <ng-template pTemplate="header">
        <div class="p-card-title p-d-flex">
            <div class="flex-grow-1">{{'component.integrations.connection_info'|translate}}</div>
            <div>
                <p-inputSwitch [(ngModel)]="integration.configuration.auth.enabled"></p-inputSwitch>
            </div>
        </div>
    </ng-template>
    {{'component.integrations.not_configured_mailjet_text1' | translate}}<br /><br />
    {{'component.integrations.not_configured_mailjet_text2' | translate}}<br /><br />
    {{'component.integrations.not_configured_mailjet_text3' | translate}}
</p-card>

<p-card *ngIf="integration.configuration.auth.enabled">
    <ng-template pTemplate="header">
        <div class="p-card-title p-d-flex">
            <div class="flex-grow-1">{{'component.integrations.connection_info'|translate}}</div>
            <div>
                <p-inputSwitch [(ngModel)]="integration.configuration.auth.enabled"></p-inputSwitch>
            </div>
        </div>
    </ng-template>

    <div class="p-grid">
        <div class="p-col-12 p-field p-m-0">
            <label for="api_key">{{'component.integrations.api_key' | translate}}</label>
            <input name="api_key" type="text" pInputText [(ngModel)]="integration.configuration.auth.api_key" />
        </div>
        <div class="p-col-12 p-field p-m-0">
            <label for="api_secret">{{'component.integrations.password' | translate}}</label>
            <input name="api_secret" type="password" pInputText
                [(ngModel)]="integration.configuration.auth.api_secret" />
        </div>
    </div>
</p-card>

<p-card *ngIf="integration.configuration.auth.enabled" styleClass="p-mt-4">
    <ng-template pTemplate="header">
        <div class="p-card-title p-d-flex">
            <div class="flex-grow-1">{{'component.integrations.mail'|translate}}</div>
            <div>
                <p-inputSwitch [(ngModel)]="integration.configuration.mail.enabled"></p-inputSwitch>
            </div>
        </div>
    </ng-template>
    <div class="p-grid" *ngIf="integration.configuration.mail.enabled">
        <div class="p-col-12 p-field p-m-0">
            <label>{{'component.integrations.allow_send_individual_mail_mailjet'|translate}}</label>
            <p-checkbox [binary]="true" [(ngModel)]="integration.configuration.mail.allow_use_for_mail"></p-checkbox>
        </div>
    </div>
    <div *ngIf="!integration.configuration.mail.enabled" class="p-grid">
        <div class="p-col-12">
            <small>{{'component.integrations.disabled_integrations'|translate}}</small>
        </div>
    </div>
</p-card>

<p-card *ngIf="integration.configuration.auth.enabled" styleClass="p-mt-4">
    <ng-template pTemplate="header">
        <div class="p-card-title p-d-flex">
            <div class="flex-grow-1">{{'contact.entity.name_plural'|translate}}</div>
            <div>
                <p-inputSwitch [(ngModel)]="integration.configuration.contacts.enabled"></p-inputSwitch>
            </div>
        </div>
    </ng-template>
    <div class="p-grid" *ngIf="integration.configuration.contacts.enabled">
        <div class="p-col-12 p-field p-m-0">
            <label>{{'component.integrations.list' | translate}}</label>
            <p-dropdown placeholder="{{'general.select'|translate}}" [options]="lists"
                [(ngModel)]="integration.configuration.contacts.list_id" optionLabel="Name" optionValue="ID"
                required></p-dropdown>
        </div>
        <div class="p-col-12 p-field p-m-0">
            <label>{{'component.integrations.sync_mode'|translate}}</label>
            <p-dropdown placeholder="{{'general.select'|translate}}" [options]="contactsModes"
                [(ngModel)]="integration.configuration.contacts.mode" optionLabel="name"
                optionValue="value"></p-dropdown>
        </div>
        <div class="p-col-12 p-field p-m-0">
            <label>{{'component.integrations.remote_delete_description_mailjet'|translate}}</label>
            <p-checkbox [binary]="true" [(ngModel)]="integration.configuration.contacts.remote_delete"></p-checkbox>
        </div>
        <div class="p-col-12 p-field p-m-0">
            <label>{{'component.integrations.binds_description'|translate}}</label>
            <p-table [value]="integration.configuration.contacts?.binds">
                <ng-template pTemplate="header">
                    <tr>
                        <th>Axial</th>
                        <th>Mailjet</th>
                        <th width="110"></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-bind>
                    <tr>
                        <td>
                            <p-dropdown placeholder="{{'general.select'|translate}}" [options]="entity.fields"
                                [(ngModel)]="bind.entity_field_id" optionLabel="description" filter="true"
                                appendTo="body" optionValue="id">
                            </p-dropdown>
                        </td>
                        <td>
                            <p-dropdown placeholder="{{'general.select'|translate}}" [options]="mergeFields"
                                filter="true" [(ngModel)]="bind.mailjet" optionLabel="Name" appendTo="body"
                                optionValue="ID"></p-dropdown>
                        </td>
                        <td>
                            <button class="p-ml-2 p-button-danger" icon="pi pi-trash" pButton type="button"
                                label="{{'component.integration.remove'|translate}}"
                                (click)="removeBind(bind)"></button>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="footer">
                    <tr class="bg-secondary">
                        <td>
                            <p-dropdown placeholder="{{'general.select'|translate}}" [options]="entity.fields"
                                [(ngModel)]="bind.entity_field_id" optionLabel="description" filter="true"
                                appendTo="body" optionValue="id">
                            </p-dropdown>
                        </td>
                        <td>
                            <p-dropdown placeholder="{{'general.select'|translate}}" [options]="mergeFields"
                                [(ngModel)]="bind.mailjet" optionLabel="Name" filter="true" appendTo="body"
                                optionValue="ID"></p-dropdown>
                        </td>
                        <td>
                            <button class="p-ml-2 p-d-block" icon="pi pi-save" pButton type="button"
                                label="{{'component.integrations.add'|translate}}" (click)="addBind()"></button>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
    <div *ngIf="!integration.configuration.contacts.enabled" class="p-grid">
        <div class="p-col-12">
            <small>{{'component.integrations.disabled_integrations'|translate}}</small>
        </div>
    </div>
</p-card>

<p-card *ngIf="integration.configuration.auth.enabled" styleClass="p-mt-4">
    <ng-template pTemplate="header">
        <div class="p-card-title p-d-flex">
            <div class="flex-grow-1">{{'component.integrations.campaigns' | translate}}</div>
            <div>
                <p-inputSwitch [(ngModel)]="integration.configuration.campaigns.enabled"></p-inputSwitch>
            </div>
        </div>
    </ng-template>
    <div class="p-grid" *ngIf="integration.configuration.campaigns.enabled">
        <div class="p-col-12 p-field p-m-0">
            <label>{{'component.integrations.sync_mode'|translate}}</label>
            <p-dropdown placeholder="{{'general.select'|translate}}" [options]="campaignsModes"
                [(ngModel)]="integration.configuration.campaigns.mode" optionLabel="name"
                optionValue="value"></p-dropdown>
        </div>
    </div>
    <div *ngIf="!integration.configuration.campaigns.enabled" class="p-grid">
        <div class="p-col-12">
            <small>{{'component.integrations.disabled_integrations'|translate}}</small>
        </div>
    </div>
</p-card>

<div class="p-mt-4" *ngIf="errorMessage != null">
    <p-messages severity="error" class="p-d-block">
        <ng-template pTemplate>
            {{errorMessage}}
        </ng-template>
    </p-messages>
</div>

<div class="p-col-12 p-d-flex p-mt-4">
    <div class="flex-grow-1"></div>
    <button pButton label="{{'general.action.cancel' | translate}}" icon="pi pi-times"
        class="p-button-secondary p-button-text" (click)="cancel()"></button>
    <button class="p-ml-2" style="float: right;" icon="pi pi-save" pButton type="button"
        label="{{'general.action.save' | translate}}" (click)="save()" *ngIf="connected"></button>
    <button class="p-ml-2" style="float: right;" icon="pi pi-link" pButton type="button"
        label="{{'component.integrations.connect_now'|translate}}" (click)="connect()" *ngIf="!connected"
        [disabled]="!integration.configuration.auth.enabled || integration?.configuration?.auth?.api_key == null || integration?.configuration?.auth?.api_key?.length == 0"></button>
</div>