<ul class="breadcrumb">
  <li><a [routerLink]="['/home']"><span class="color-soft-grey-axial">{{'general.breadcrumb.you_are' | translate}}
      </span>{{'general.breadcrumb.home' | translate}}</a></li>
  <li [hidden]="contact.organization_id == null"><i class="pi pi-angle-right"></i></li>
  <li [hidden]="contact.organization_id == null"><a
      [routerLink]="['/organizations']">{{'organization.entity.name_plural' | translate}}</a></li>
  <li [hidden]="contact.organization_id == null"><i class="pi pi-angle-right"></i></li>
  <li [hidden]="contact.organization_id == null">
    <a [routerLink]="['/organizations', 0, contact.organization_id]">{{contact.organization?.name}}</a>
  </li>
  <li><i class="pi pi-angle-right"></i></li>
  <li><a [routerLink]="['/leads']">{{view.getEntityBaseName(true)}}</a></li>
  <li><i class="pi pi-angle-right"></i></li>
  <li [hidden]="contact.id==null">
    <a [routerLink]="['/leads', contact.id]">
      {{contact.name}}
    </a>
  </li>
</ul>
<app-view #view [code]="'leads.edit'" [model]="contact" (onEvent)="onEvent($event)"></app-view>