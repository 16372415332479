import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationsListComponent } from './notifications-list/notifications-list.component';
import { FormsModule } from '@angular/forms';
import { NotificationsRoutingModule } from './notifications-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { TableModule } from 'primeng/table';
import { CardModule } from 'primeng/card';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DropdownModule } from 'primeng/dropdown';
import { MailInOutListComponent } from './mail-in-out-list/mail-in-out-list.component';
import { EditorModule } from '@tinymce/tinymce-angular';
import { ColorPickerModule } from 'ngx-color-picker';
import { AccordionModule } from 'primeng/accordion';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogModule } from 'primeng/dialog';
import { FileUploadModule } from 'primeng/fileupload';
import { InputSwitchModule } from 'primeng/inputswitch';
import { MultiSelectModule } from 'primeng/multiselect';
import { AppRoutingModule } from '../app-routing.module';
import { CoreModule } from '../core/core.module';
import { NotificationsInComponent } from './notifications-in/notifications-in.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NotificationsRoutingModule,
    HttpClientModule,
    ButtonModule,
    InputTextModule,
    TableModule,
    CardModule,
    FontAwesomeModule,
    ConfirmDialogModule,
    DropdownModule,
    DialogModule,
    CheckboxModule,
    CoreModule,
    FontAwesomeModule,
    AppRoutingModule,
    MultiSelectModule,
    DropdownModule,
    FileUploadModule,
    AccordionModule,
    InputSwitchModule,
    ColorPickerModule,
    AutoCompleteModule,
    EditorModule,
    TranslateModule
  ],
  declarations: [NotificationsListComponent, MailInOutListComponent, MailInOutListComponent, NotificationsInComponent]
})
export class NotificationsModule { }
