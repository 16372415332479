<ul class="breadcrumb">
  <li><a [routerLink]="['/home']"><span class="color-soft-grey-axial">{{'general.breadcrumb.you_are' | translate}} </span>{{'general.breadcrumb.home' | translate}}</a></li>
  <li><i class="pi pi-angle-right"></i></li>
  <li><a [routerLink]="['/imports']">Importar datos</a></li>
</ul>
<p-card class="p-md-12">
  <ng-template pTemplate="header">
    <div class="p-card-title">
      {{'general.filter.title' | translate }}
      <div class="p-card-title-actions">
        <a (click)="clearFilters()">{{'general.filter.clear' | translate }}</a>
        <a (click)="search()"><i class="fas fa-search"></i>&nbsp;{{'general.filter.search' | translate }} </a>
      </div>
    </div>
  </ng-template>
  <div class="p-grid">
    <div class="p-col p-field">
      <label for="name">{{'import.entity_fields.name' | translate }}</label>
      <input id="name" type="text" pInputText [(ngModel)]="filters.filename" />
    </div>
  </div>
</p-card>
<p-card styleClass="p-card-content-fit">
  <ng-template pTemplate="header">
    <div class="p-card-title">
      {{'component.imports.title' | translate }}
      <div class="p-card-title-actions">
        <a (click)="add()"><fa-icon [icon]="['fas', 'plus-circle']"></fa-icon> {{'component.imports.action.import' | translate }}</a>
      </div>
    </div>
  </ng-template>


  <p-table #grid [value]="imports" [lazy]="true" (onLazyLoad)="loadData($event)" [loading]="loading" [paginator]="true"
    [rows]="10" [totalRecords]="totalRecords" [showCurrentPageReport]="true"
    currentPageReportTemplate="{{ 'general.resumen_table_records' | translate: { first: first, last: last, totalRecords: totalRecords } }}"
    [rowsPerPageOptions]="[5,10,25,50]" [scrollable]="true" scrollHeight="flex" paginatorDropdownAppendTo="body">
    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="status" width="110" style="text-align: center;">{{'import.entity_fields.status' | translate }}</th>
        <th pSortableColumn="date" width="120" style="text-align: center;">{{'import.entity_fields.date' | translate }}</th>
        <th pSortableColumn="entity_id" width="100">{{'import.entity_fields.entity' | translate }}</th>
        <th pSortableColumn="createdBy.name" width="120">{{'import.entity_fields.created_by' | translate }}</th>
        <th pSortableColumn="filename" width="100">{{'import.entity_fields.name' | translate }}</th>
        <!--<th pSortableColumn="filename">Nombre fichero</th>-->
        <th ></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-import>
      <tr>
        <td width="120" style="text-align: center;">
          <span class="custom-marker badge badge-danger" *ngIf="import.status=='fail'">{{'component.imports.status_fail' | translate }}</span>
          <span class="custom-marker badge badge-success" *ngIf="import.status=='success'">{{'component.imports.status_success' | translate }}</span>
          <span class="custom-marker badge badge-warning" *ngIf="import.status=='pending'">{{'component.imports.status_pending' | translate }}</span>
          <span class="custom-marker badge badge-info"
                *ngIf="import.status=='processing' || import.status=='inProcess' || import.status=='validated'">
            {{'component.imports.status_processing' | translate }}
            {{(import.status_line*100/import.status_total_lines) | number : '0.0-0' }}%
          </span>
          <span class="custom-marker badge badge-primary" *ngIf="import.status=='validating'">{{'component.imports.status_validating' | translate }}</span>
        </td>
        <td width="140"> {{import.date | date: 'dd/MM/yyyy HH:mm'}}</td>
        <td width="120">{{componentService.getEntityName(import.entity)}}</td>
        <td>{{import.createdBy?.name}}</td>
        <td>{{import.name}}</td>
      <td class="grid-actions">
        <button *ngIf="import.status=='fail'" class="customButton p-button p-button-text button-only-icon color-danger p-mr-3" (click)="openInfo(import)" [disabled]="import.status!='validating' && import.status!='fail'">
          <i class="pi pi-exclamation-triangle" style=" font-size: 13px;"></i> 
        </button>
        <button *ngIf="import.status=='validating'" class="customButton p-button p-button-text button-only-icon color-green-axial p-mr-3" (click)="openInfo(import)" [disabled]="import.status!='validating' && import.status!='fail'" icon="pi pi-check">
          <i class="pi pi-check" style=" font-size: 13px;"></i>&nbsp;{{'component.imports.action.validate' | translate }}
        </button>
        <button *ngIf="import.status=='success'" class="customButton p-button p-button-text button-only-icon color-green-axial p-mr-3" (click)="openInfo(import)">
          <i class="pi pi-info-circle" style=" font-size: 13px;"></i>
        </button>
        <button (click)="duplicate(import.id)" class="customButton color-grey-axial p-button p-button-text button-only-icon  p-mr-1" [disabled]="import.status=='processing'||import.status=='inProcess'||import.status=='validated'">
          <i class="fa fa-clone"></i> {{'component.imports.action.duplicate' | translate }}
        </button>
        <button (click)="add(import.id)" class="customButton p-button p-button-text color-green-axial p-mr-1" [disabled]="import.status=='processing'||import.status=='inProcess'||import.status=='validated'">
          <i class="far fa-edit"></i>{{'component.imports.action.reuse' | translate }}
        </button>
        <button (click)="delete(import)" class="customButton p-button p-button-text button-only-icon color-danger">
          <i class="far fa-trash-alt"></i>
        </button>
      </td>
      </tr>
    </ng-template>
  </p-table>
</p-card>
