
<div class="page-title">{{organization.name}}</div>
<ul class="breadcrumb">
  <!--style="display: inline;"-->
  <li><a [routerLink]="['/home']"><span class="color-soft-grey-axial">{{'general.breadcrumb.you_are' | translate}} </span>{{'general.breadcrumb.home' | translate}}</a></li>
  <li><i class="pi pi-angle-right"></i></li>
  <li><a [routerLink]="['/organizations']">{{view.getEntityBaseName(true)}}</a></li>
  <li><i class="pi pi-angle-right"></i></li>
  <li><a [routerLink]="['/organizations/0', organization.id]">{{organization.name}}</a></li>
</ul>
<!--div style="text-align: right;">
    <p-button label="Enviar email" styleClass="p-button-secondary"></p-button>
</div-->
<app-view #view [code]="'organizations.preview'" [model]="organization" (onEvent)="onEvent($event)"></app-view>
<!--
<div class="p-grid">
    <div class="p-col-10">
        <app-view #view [code]="'organizations.preview'" [model]="organization" (onEvent)="onEvent($event)"></app-view>
    </div>
    <div class="p-col-2">
        <p-card class="">
            <ng-template pTemplate="header">
                <div class="p-card-title">
                    Actividades
                </div>
            </ng-template>
            <div class="color-danger"><b>13</b> días desde última actividad</div>
            <div><b>1</b> actividades pendientes</div>
        </p-card>
        <br>
        <p-card>
            <ng-template pTemplate="header">
                <div class="p-card-title">
                    Oportunidades
                </div>
            </ng-template>
            <div><b>0</b> oportunidades abiertas</div>
            <div><b>1</b> oportunidades éxito</div>
        </p-card>
    </div>
</div>
-->
