<p-card>
  <ng-template pTemplate="header">
    <div class="p-card-title">
      {{'component.integration.api_rest_connection'|translate}}
    </div>
  </ng-template>
  <div class="p-grid">
    <div class="p-col-12 p-field">
      <label for="user">Token</label>
      <input [(ngModel)]="integration.configuration.token" id="token" type="text" pInputText name="token" placeholder=""
        autocomplete="false" />
    </div>
  </div>
</p-card>
<div class="p-col-12 p-d-flex">
  <div class="flex-grow-1"></div>
  <p-button label="{{'general.action.cancel' | translate}}" icon="pi pi-times"
    styleClass="p-button-secondary p-button-text" (click)="cancel()">
  </p-button>
  <button class="p-ml-2" style="float: right;" icon="pi pi-save" pButton type="button"
    label="{{'general.action.save' | translate}}" (click)="save()"></button>
</div>