import { Component, OnInit, ViewChild } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { CoreDialogService } from 'src/app/core/dialogs/core-dialog.service';
import { ViewComponent } from 'src/app/core/view/view.component';
import { ZonesDetailComponent } from '../zones-detail/zones-detail.component';
import { ZonesService } from '../zones.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-zones-list',
  templateUrl: './zones-list.component.html',
  styleUrls: ['./zones-list.component.scss']
})
export class ZonesListComponent implements OnInit {
  @ViewChild("view") public view: ViewComponent;
  public zonesFilters: any = {};

  constructor(
    private zonesService: ZonesService,

    private messageService: MessageService,
    private coreDialogService: CoreDialogService,
    private dialogService: DialogService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
  }

  viewZone(zone) {
    const ref = this.dialogService.open(ZonesDetailComponent, {
      data: {
        id: zone ? zone.id : 0
      },
      //header: zone ? "Detalles de la zona" : "Nueva zona",
      width: '40%',
    });
    ref.onClose.subscribe((data: any) => {
      this.view.fireEvent("zones.list.results", "reload");
    });
  }

  deleteZone(zone) {
    this.coreDialogService.confirm({
      message: this.translateService.instant("component.zones.delete_message", { name: zone.name }),

      header: this.translateService.instant("general.confirmation_delete_title"),
      icon: "pi pi-info-circle",
      accept: () => {
        this.zonesService.delete(zone.id).subscribe({
          next: (data: any) => {
            this.messageService.add({ closable: false, severity: 'success', detail: this.translateService.instant("general.confirmation_delete") });
            this.view.fireEvent("zones.list.results", "reload");
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        });
      },
      reject: () => { }
    });
  }

  onEvent(event: any) {
    if (event.component == "zones.list.filters") {
      if (event.event == "action" && event.action.name == "search") this.view.fireEvent("zones.list.results", "reload");
    } else if (event.component == "zones.list.results") {
      if (event.event == "dblclick") this.viewZone(event.data);
      if (event.event == "action" && event.action.name == "add") this.viewZone(null);
      if (event.event == "action" && event.action.name == "edit") this.viewZone(event.data);
      if (event.event == "action" && event.action.name == "delete") this.deleteZone(event.data);
    }
  }
}
