<ul class="breadcrumb">
  <li><a [routerLink]="['/home']"><span class="color-soft-grey-axial">{{'general.breadcrumb.you_are' | translate}} </span>{{'general.breadcrumb.home' | translate}}</a></li>
  <li><i class="pi pi-angle-right"></i></li>
  <li><a [routerLink]="['/configuration/entities']">{{ 'general.configuration' | translate}}</a></li>
  <li><i class="pi pi-angle-right"></i></li>
  <li><a [routerLink]="['/configuration/entities']">{{ 'entity.entity.name_plural' | translate}}</a></li>
</ul>
<div class="p-grid p-grid-nogutter">
    <div class="p-col">
        <router-outlet></router-outlet>
        <p-card styleClass="p-card-content-fit">
            <ng-template pTemplate="header">
              <div class="p-card-title">
                {{ 'entity.entity.name_plural' | translate}}
                <div class="p-card-title-actions">
                </div>
              </div>
            </ng-template>
            <p-table #grid [value]="entities" [lazy]="true" (onLazyLoad)="loadData($event)" [loading]="loading"
                [paginator]="true" [rows]="10" [totalRecords]="totalRecords" [showCurrentPageReport]="true"
                currentPageReportTemplate="{{ 'general.resumen_table_records' | translate: { first: first, last: last, totalRecords: totalRecords } }}"
                [rowsPerPageOptions]="[5,10,25,50]"  paginatorDropdownAppendTo="body">
                <ng-template pTemplate="header">
                  <tr>
                    <th pSortableColumn="name">{{ 'entity.entity.name' | translate}}</th>
                    <th pSortableColumn="name_plural">{{ 'entity.entity.name_plural' | translate}}</th>
                    <th pSortableColumn="for_reports">{{ 'entity.entity_fields.for_reports' | translate}}</th>
                    <th width="100"></th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-entity>
                  <tr (dblclick)="edit(entity)">
                    <td>{{entity.name}}</td>
                    <td>{{entity.name_plural}}</td>
                    <td>{{entity.for_reports?"Sí":"No"}}</td>
                    <td class="grid-actions">
                      <a (click)="edit(entity)" *ngIf="access_entities_fields">
                        <fa-icon [icon]="['far', 'edit']"></fa-icon>
                      </a>
                    </td>
                  </tr>
                </ng-template>
            </p-table>
        </p-card>
    </div>
</div>
