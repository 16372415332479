<p-tabView>

    <p-tabPanel header="{{'component.entity.detail.data.title' | translate}}">
        <div class="p-grid">
            <div class="p-col-12 p-field">
              <label for="position">{{'component.entity.detail.position' | translate}}</label>
                <input name="position" type="number" pInputText [(ngModel)]="entityField.position" />
            </div>
            <div class="p-col-12 p-field">
              <label for="description">{{'component.entity.detail.name' | translate}}</label>
                <input name="description" type="text" pInputText [(ngModel)]="entityField.description" />
            </div>
            <div class="p-col-6 p-field">
              <label for="data_type">{{'component.entity.detail_fields.data_types' | translate}}</label>
                <p-dropdown appendTo="body" [options]="data_types" [(ngModel)]="selected_data_type" optionLabel="name"
                    optionValue="value" [disabled]="entityField.is_base_field" [showClear]="true"
                    (onChange)="updateControlType()"></p-dropdown>
            </div>
            <div class="p-col-6 p-field"
                *ngIf="entityField.data_type != 'list' && entityField.data_type != 'list_multiple' && entityField.data_type != 'date' && entityField.data_type != 'boolean'">
              <label for="default_value">{{'component.entity.detail_fields.default_value' | translate}}</label>
                <input name="default_value" type="text" pInputText [(ngModel)]="entityField.default_value" />
            </div>
            <div class="p-col-6 p-field" *ngIf="entityField.data_type == 'date'">
              <label for="default_value">{{'component.entity.detail_fields.default_value' | translate}}</label>
                <p-calendar dateFormat="dd/mm/yy" hourFormat="24" [showIcon]="true" [showTime]="true"  [touchUI]="true"
                    name="default_value" [(ngModel)]="entityField.default_value" appendTo="body" [stepMinute]="5">
                </p-calendar>
            </div>
            <div class="p-col-6 p-field" *ngIf="entityField.data_type == 'boolean'">
              <label for="default_value">{{'component.entity.detail_fields.default_value' | translate}}</label>
                <p-checkbox name="default_value" [(ngModel)]="entityField.default_value" binary="true"></p-checkbox>
            </div>
            <!--Tabla -->
            <div class="p-col-12" *ngIf="entityField.data_type == 'list' || entityField.data_type == 'list_multiple'">
                <p-card>
                    <ng-template pTemplate="header">
                      <div class="p-card-title">
                        {{'component.entity.detail_fields.elements' | translate}}
                        <div class="p-card-title-actions">
                          <a (click)="exportExcel()">
                            <fa-icon [icon]="['fas', 'file-excel']"></fa-icon>{{'component.entity.detail_fields.download_excel' | translate}}
                          </a>
                          <a (click)="addItemMasive()">
                            <fa-icon [icon]="['fas', 'plus-circle']"></fa-icon>{{'component.entity.detail_fields.add_masive' | translate}}
                          </a>
                          <a (click)="addItem()">
                            <fa-icon [icon]="['fas', 'plus-circle']"></fa-icon>{{'component.entity.detail_fields.add_item' | translate}}
                          </a>
                        </div>
                      </div>
                    </ng-template>
                    <div class="p-grid">
                        <!--div class="p-col p-field">
                        <label for="code">Código</label>
                        <input name="code" type="text" pInputText [(ngModel)]="code" />
                    </div>
                    <div class="p-col p-field">
                        <label for="text">Texto</label>
                        <input name="text" type="text" pInputText [(ngModel)]="text" />
                    </div-->
                        <p-table #grid [value]="entityField.configuration?.options?.items">
                            <ng-template pTemplate="header">
                                <tr>
                                  <th>{{'component.entity.detail_fields.code' | translate}}</th>
                                  <th>{{'component.entity.detail_fields.text' | translate}}</th>
                                    <th width="70"></th>
                                    <th width="70"></th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-item>
                                <tr>
                                    <td>{{item.code}}</td>
                                    <td>{{item.text}}</td>
                                    <td><a (click)="addItem(item)"><fa-icon [icon]="['far', 'edit']" class=""></fa-icon></a></td>
                                    <td><a (click)="deleteItem(item)"><fa-icon [icon]="['far', 'trash-alt']" class="color-danger"></fa-icon></a></td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </p-card>
            </div>
            <div class="p-col-2 p-field">
              <label for="is_disabled">{{'component.entity.detail_fields.disabled' | translate}}</label>
                <p-checkbox name="is_disabled" [(ngModel)]="entityField.is_disabled" [binary]="true"></p-checkbox>
            </div>
            <div class="p-col-2 p-field">
              <label for="require_roles">{{'component.entity.detail_fields.required_role' | translate}}</label>
                <p-checkbox name="require_roles" [(ngModel)]="require_roles" [binary]="true"></p-checkbox>
            </div>
            <div class="p-col-12" *ngIf="require_roles">
                <p-card>
                    <ng-template pTemplate="header">
                      <div class="p-card-title">
                        {{'component.entity.detail_fields.roles' | translate}}
                      </div>
                    </ng-template>
                    <div class="p-grid">
                        <div class="p-col-4" *ngFor="let role of roles">
                            <p-checkbox name="selected_roles" [(ngModel)]="selectedRoles" value="{{role.id}}">
                            </p-checkbox> {{role.name}}
                        </div>
                    </div>
                </p-card>
            </div>
          <p-message *ngIf="entityField.unmodifiable" severity="info" text="{{'component.entity.detail_fields.remember_unmodifiable' | translate}}"></p-message>
        </div>
    </p-tabPanel>
    <p-tabPanel header="{{'component.entity.detail.configuration' | translate}}">
        <form #formDetails="ngForm">
            <p-card header="{{'general.details' | translate}}">
                <div class="p-grid">
                    <ngx-monaco-editor [options]="editorOptions" [(ngModel)]="modelMonaco"
                        [ngModelOptions]="{standalone: true}" (init)="editorInit($event)"
                        (ngModelChange)="onMonacoChange($event)" style="height: 500px; width: 100%;">
                    </ngx-monaco-editor>
                </div>
            </p-card>

        </form>

    </p-tabPanel>
</p-tabView>
<div class="p-col-12 p-d-flex">
    <p-button label="{{'general.action.delete' | translate}}" *ngIf="entityField.id > 0 && !entityField.is_base_field" icon="pi pi-trash"
        styleClass="p-button-danger" (click)="delete()"></p-button>
    <div class="flex-grow-1"></div>
    <p-button label="{{'general.action.cancel' | translate}}" icon="pi pi-times" styleClass="p-button-secondary p-button-text" (click)="cancel()">
    </p-button>
    <p-button label="{{'general.action.save' | translate}}" icon="pi pi-check" styleClass="p-ml-2" (click)="save(true)"></p-button>
</div>
